import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import {
  fetchEmployeeByEmployeeId,
  fetchEmployeeDeviceListByEmployeeId,
} from '@/components/organization/Configuration/EmployeeSetting/query-request';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  FOLDER_NAME,
  BUCKET_NAME as IMAGE_BUCKET_NAME,
  MEMORY_STORE,
} from '@/helpers/constants';
import FpDataStore from '@/services/FpDatastore';
import { Form, Formik, getIn } from 'formik';
import { cloneDeep, isEqual } from 'lodash';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const EMPL_ROW_STATUS = {
  UNCHANGED: 0,
  NEW: 1,
  MODIFIED: 2,
  DELETED: 3,
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeInfoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantId: props.tenantId,
      selectedEmployee: null,
      isLoadingEmployee: true,
      dialogVisibleCount: 0,
      branchOptions: [],
    };
    this.formikRef = React.createRef();
    this.employeeChatToolsOptions = [
      { label: 'Teams', value: 'teams' },
      { label: 'なし', value: '' },
    ];
    this.toastRef = React.createRef();
    this.fpDataStore = new FpDataStore();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const {
      dialogVisibleCount: count,
      selectedEmployee,
      tenantId,
      sessionStore,
    } = this.props;
    const { dialogVisibleCount: prevCount } = prevProps;
    const branches = sessionStore[MEMORY_STORE.BRANCHES] || [];
    const branchOptions = branches
      .filter(branch => +branch.tenant_id === +tenantId)
      .map(branch => {
        return {
          label: branch.branch_name,
          value: branch.branch_id,
        };
      });
    if (count > 0 && count !== prevCount) {
      this.setState({
        tenantId,
        dialogVisibleCount: count,
        selectedEmployee: selectedEmployee,
        branchOptions,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  getSelectedFloorOptions = ({ branchId }) => {
    const { sessionStore } = this.props;
    const floors = sessionStore[MEMORY_STORE.FLOORS] || [];
    return floors
      .filter(floor => floor.branch_id === branchId)
      .map(floor => {
        return {
          label: floor.floor_name,
          value: floor.floor_id,
        };
      });
  };

  formSchema = Yup.object().shape({
    employee: Yup.object().shape({
      employeeName: Yup.string().required(COMMON_TEXT.REQUIRED),
      employeeNumber: Yup.string()
        .matches(/^[0-9]+$/, '数字のみでなければなりません')
        .required(COMMON_TEXT.REQUIRED),
      employeeDepartment: Yup.string().required(COMMON_TEXT.REQUIRED),
      employeeTeam: Yup.string().required(COMMON_TEXT.REQUIRED),
    }),
  });

  isFormFieldInvalid = ({ name, touched, errors }) => {
    const error = getIn(errors, name);
    return error ? 'p-invalid' : '';
  };

  getFormErrorMessage = ({ name, touched, errors }) => {
    return this.isFormFieldInvalid({ name, touched, errors }) ? (
      <span className="validation-error-message">{getIn(errors, name)}</span>
    ) : (
      <></>
    );
  };

  fetchEmployeeData = async ({ employeeId, rowIndexId }) => {
    let employeeInfo = await fetchEmployeeByEmployeeId({
      employeeId: employeeId,
    });
    let employeeDevices = await fetchEmployeeDeviceListByEmployeeId({
      employeeId: employeeId,
    });
    let employeePcMac = '';
    let employeeMobileMac = '';
    let employeePcName = '';
    let employeeMobileName = '';
    if (employeeDevices && employeeDevices.length > 0) {
      employeeDevices.forEach(element => {
        const { device_type } = element;
        if (device_type === 'pc') {
          employeePcMac = element.employee_device_mac;
          employeePcName = element.employee_device_name;
        } else if (device_type === 'phone') {
          employeeMobileMac = element.employee_device_mac;
          employeeMobileName = element.employee_device_name;
        }
      });
    }
    employeeInfo = employeeInfo?.[0] ?? {};
    let employee = {
      employeeId: employeeInfo.employee_id,
      employeeName: employeeInfo.employee_name,
      employeeNumber: employeeInfo.employee_number,
      employeePhoneNumber: employeeInfo.phone_number,
      employeeEmail: employeeInfo.email,
      employeeBranchId: employeeInfo.branch_id,
      employeeFloorId: employeeInfo.floor_id,
      employeeChatTools: employeeInfo.chat_tools,
      employeeDepartment: employeeInfo.department,
      employeeTeam: employeeInfo.team,
      employeeVerkadaId: employeeInfo.employee_identifer,
      employeeImage: employeeInfo.employee_image,
      employeePcMac,
      employeeMobileMac,
      employeePcName,
      employeeMobileName,
      rowIndexId,
    };
    employee = {
      ...employee,
      initEmployee: cloneDeep(employee),
    };
    return employee;
  };

  employeeDialogOnShow = async () => {
    const { selectedEmployee } = this.state;
    const { status, employeeId, rowIndexId } = selectedEmployee ?? {};
    if (status === EMPL_ROW_STATUS.UNCHANGED) {
      // edit existing employee
      this.setState({
        isLoadingEmployee: true,
      });
      const employee = await this.fetchEmployeeData({ employeeId, rowIndexId });
      this.setState({
        selectedEmployee: employee,
        isLoadingEmployee: false,
      });
    } else {
      // new or editing employee
      this.setState({
        isLoadingEmployee: false,
      });
    }
  };

  uploadFileToBucket = async ({ file, fileName }) => {
    const BUCKET_NAME = IMAGE_BUCKET_NAME.STATIC;
    const FILE_PATH = `${FOLDER_NAME.EMPLOYEE_IMAGE}/${fileName}`;
    const FULL_FILE_PATH = `${BUCKET_NAME}/${FILE_PATH}`;
    try {
      let metadata = {
        author: 'TK_FOREPAAS_APP',
      };

      await this.fpDataStore.uploadObject(
        { filename: FILE_PATH, bucket: BUCKET_NAME, metadata: metadata },
        file,
        progress => {}
      );
      return { success: true, fileUrl: `${FULL_FILE_PATH}` };
    } catch (err) {}
    return { success: false, fileUrl: null };
  };

  onTemplateUpload = async (event, { employee }) => {
    const files = event.files;
    if (files.length > 1) {
      this.toastRef.current.show({
        severity: 'error',
        summary: 'Error Message',
        detail: "Can't upload multiple files at once",
      });
      event.options.clear();
    }
    // Iterate over each file and perform a custom upload operation
    for (let file of files) {
      // Simulate a successful file upload with a delay

      // get extension from file name
      const extension = file.name.split('.').pop();

      // generate new file name
      const newFileName = `${uuidv4()}.${extension}`;
      try {
        if (file.size > 1000000) {
          // For example, check if file size is more than 1MB
          throw new Error('File size exceeds the limit of 1MB');
        }
        const { success: isUploadSuccess, fileUrl } =
          await this.uploadFileToBucket({
            file,
            fileName: newFileName,
          });
        if (!isUploadSuccess) {
          throw new Error('Upload file failed');
        }
        // set employee image
        employee['employeeImage'] = fileUrl;
        this.setState({
          selectedEmployee: employee,
        });

        event.options.clear();
        this.toastRef.current.show({
          severity: 'success',
          summary: 'Upload Message',
          detail: 'Upload successfully',
        });
      } catch (error) {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'Error Message',
          detail: error.message,
        });
        event.options.clear();
      }
    }
  };

  isEmployeeInfoModified = ({ employee, initEmployee }) => {
    const initKeys = Object.keys(initEmployee);
    for (let i = 0; i < initKeys.length; i++) {
      const key = initKeys[i];
      if (!isEqual(employee[key] ?? '', initEmployee[key] ?? '')) {
        return true;
      }
    }
    return false;
  };

  updateSelectedEmployee = async () => {
    if (this.formikRef.current) {
      this.formikRef.current.validateForm().then(errors => {
        if (errors['employee']) {
          return;
        }
        const { employee } = this.formikRef.current.values;
        const { initEmployee } = employee;
        if (employee.status === EMPL_ROW_STATUS.NEW) {
          this.props.updateEmployeeInfo({ employee, employeeIndex: -1 });
        } else {
          if (this.isEmployeeInfoModified({ employee, initEmployee })) {
            employee.status = EMPL_ROW_STATUS.MODIFIED;
          } else {
            employee.status = EMPL_ROW_STATUS.UNCHANGED;
          }
          this.props.updateEmployeeInfo({
            employee,
          });
        }
        this.hideDialog();
      });
    }
  };

  employeeDialogFooter = () => {
    const { isLoadingEmployee } = this.state;
    return (
      <React.Fragment>
        <Button
          label={COMMON_TEXT.CANCEL}
          icon="pi pi-times"
          text
          disabled={isLoadingEmployee}
          severity="secondary"
          onClick={this.hideDialog}
        />
        <Button
          label={COMMON_TEXT.SAVE}
          severity="info"
          className="submit-button"
          disabled={isLoadingEmployee}
          icon="pi pi-check"
          onClick={e => this.updateSelectedEmployee(e)}
        />
      </React.Fragment>
    );
  };

  hideDialog = () => {
    this.setState({
      selectedEmployee: null,
      isLoadingEmployee: true,
      dialogVisibleCount: 0,
    });
  };

  render() {
    const {
      selectedEmployee,
      isLoadingEmployee,
      branchOptions,
      dialogVisibleCount,
    } = this.state;
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <Dialog
          visible={dialogVisibleCount > 0}
          style={{ width: '44rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={COMMON_TEXT.EMPLOYEE_INFORMATION}
          modal
          className="p-fluid employee-setting-info-dialog"
          footer={this.employeeDialogFooter}
          onHide={this.hideDialog}
          onShow={this.employeeDialogOnShow}
          appendTo={'self'}
          resizable={false}
        >
          <div className="employee-info-container">
            {selectedEmployee?.employeeId && isLoadingEmployee ? (
              <LoadingSpinner />
            ) : !selectedEmployee ? (
              <>NO_DATA</>
            ) : (
              <Formik
                innerRef={this.formikRef}
                initialValues={{ employee: selectedEmployee }}
                enableReinitialize={true}
                validationSchema={this.formSchema}
                onSubmit={values => {}}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  errors,
                  resetForm,
                }) => (
                  <Form>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.NAME}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeeName`}
                          value={values.employee.employeeName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeeName`,
                            touched,
                            errors,
                          })}`}
                        />
                        {this.getFormErrorMessage({
                          name: `employee.employeeName`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.EMPLOYEE_NUMBER}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeeNumber`}
                          value={values.employee.employeeNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeeNumber`,
                            touched,
                            errors,
                          })}`}
                        />
                        {this.getFormErrorMessage({
                          name: `employee.employeeNumber`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.EMPLOYEE_PHONE_NUMBER}
                      </div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeePhoneNumber`}
                          value={values.employee.employeePhoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeePhoneNumber`,
                            touched,
                            errors,
                          })}`}
                        />
                        {this.getFormErrorMessage({
                          name: `employee.employeePhoneNumber`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">{COMMON_TEXT.EMPLOYEE_EMAIL}</div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeeEmail`}
                          value={values.employee.employeeEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeeEmail`,
                            touched,
                            errors,
                          })}`}
                        />
                        {this.getFormErrorMessage({
                          name: `employee.employeeEmail`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.EMPLOYEE_TOOL_CHAT}
                      </div>
                      <div className="input-text-field-container">
                        <Dropdown
                          id={`employee.employeeChatTools`}
                          value={values.employee.employeeChatTools}
                          options={this.employeeChatToolsOptions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeeChatTools`,
                            touched,
                            errors,
                          })}`}
                        ></Dropdown>
                        {this.getFormErrorMessage({
                          name: `employee.employeeChatTools`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.DEPARTMENT}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeeDepartment`}
                          value={values.employee.employeeDepartment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeeDepartment`,
                            touched,
                            errors,
                          })}`}
                        />
                        {this.getFormErrorMessage({
                          name: `employee.employeeDepartment`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.TEAM}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeeTeam`}
                          value={values.employee.employeeTeam}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeeTeam`,
                            touched,
                            errors,
                          })}`}
                        />
                        {this.getFormErrorMessage({
                          name: `employee.employeeTeam`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>

                    <div className="main-row-container">
                      <div className="title">{COMMON_TEXT.BRANCH}</div>
                      <div className="input-text-field-container">
                        <Dropdown
                          id={`employee.employeeBranchId`}
                          value={values.employee.employeeBranchId}
                          options={branchOptions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeeBranchId`,
                            touched,
                            errors,
                          })}`}
                        ></Dropdown>
                        {this.getFormErrorMessage({
                          name: `employee.employeeBranchId`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>

                    <div className="main-row-container">
                      <div className="title">{COMMON_TEXT.FLOOR}</div>
                      <div className="input-text-field-container">
                        <Dropdown
                          disabled={!values.employee.employeeBranchId}
                          id={`employee.employeeFloorId`}
                          value={values.employee.employeeFloorId}
                          options={this.getSelectedFloorOptions({
                            branchId: values.employee.employeeBranchId,
                          })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeeFloorId`,
                            touched,
                            errors,
                          })}`}
                        ></Dropdown>
                        {this.getFormErrorMessage({
                          name: `employee.employeeFloorId`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>

                    <div className="main-row-container">
                      <div className="title">{COMMON_TEXT.VERKADA_ID}</div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeeVerkadaId`}
                          value={values.employee.employeeVerkadaId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${this.isFormFieldInvalid({
                            name: `employee.employeeVerkadaId`,
                            touched,
                            errors,
                          })}`}
                        />
                        {this.getFormErrorMessage({
                          name: `employee.employeeVerkadaId`,
                          touched,
                          errors,
                        })}
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">{COMMON_TEXT.EMPLOYEE_IMAGE}</div>
                      <div className="input-text-field-container empl-image-field">
                        <div className="empl-image-preview-field">
                          {values.employee.employeeImage ? (
                            <Image
                              src={this.fpDataStore.getObjectDownloadUrlWithBucket(
                                values.employee.employeeImage
                              )}
                              preview
                              alt="No Image"
                              className="flex align-items-center justify-content-center"
                              style={{ width: '90%', height: 'auto' }}
                              width="36"
                              height="auto"
                            />
                          ) : (
                            <i className="pi pi-eye-slash"></i>
                          )}
                        </div>
                        <FileUpload
                          id={`employee.employeeImage`}
                          mode="advanced"
                          auto
                          name="emplImgUpload[]"
                          accept="image/*"
                          chooseOptions={{
                            icon: 'pi pi-cloud-upload',
                            iconOnly: true,
                          }}
                          customUpload
                          uploadHandler={e => {
                            this.onTemplateUpload(e, {
                              employee: values.employee,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.EMPLOYEE_DEVICE_PC_MAC}
                      </div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeePcMac`}
                          value={values.employee.employeePcMac}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.EMPLOYEE_DEVICE_PC_NAME}
                      </div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeePcName`}
                          value={values.employee.employeePcName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.EMPLOYEE_DEVICE_MOBILE_MAC}
                      </div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeeMobileMac`}
                          value={values.employee.employeeMobileMac}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="main-row-container">
                      <div className="title">
                        {COMMON_TEXT.EMPLOYEE_DEVICE_MOBILE_NAME}
                      </div>
                      <div className="input-text-field-container">
                        <InputText
                          id={`employee.employeeMobileName`}
                          value={values.employee.employeeMobileName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </Dialog>
      </>
    );
  }
}

EmployeeInfoDialog.propTypes = {
  selectedEmployee: PropTypes.object,
  dialogVisibleCount: PropTypes.number,
  tenantId: PropTypes.number,
};

export default EmployeeInfoDialog;
