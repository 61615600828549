import * as React from 'react';
import { useRef, useState } from 'react';
import './image-marker.less';
import { calculateMarkerPosition } from './utils';
import { Button } from 'primereact/button';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const DEFAULT_BUFFER = 12;

const ReactImageMarkerNotDraggable = ({
  src,
  markers,
  onAddMarker,
  markerComponent: MarkerComponent,
  bufferLeft = DEFAULT_BUFFER,
  bufferTop = DEFAULT_BUFFER,
  alt = 'Markers',
  extraClass = '',
  triggerEmployeeDetailSidebar,
}) => {
  const imageRef = useRef(null);
  const [isDisablePan] = useState(false);

  const handleImageClick = event => {
    if (!imageRef.current || !onAddMarker) {
      return;
    }
    const imageDimentions = imageRef.current.getBoundingClientRect();

    const [top, left] = calculateMarkerPosition(
      event,
      imageDimentions,
      window.scrollY,
      bufferLeft,
      bufferTop
    );

    onAddMarker({
      top,
      left,
    });
  };

  const handleMarkerClick = (event, marker) => {
    if (typeof triggerEmployeeDetailSidebar === 'function') {
      triggerEmployeeDetailSidebar(marker);
    }
  };

  const getItemPosition = marker => {
    const { top, left } = marker;
    return {
      top: `${top}%`,
      left: `${left}%`,
    };
  };

  return (
    <TransformWrapper
      wheel={{ step: 0.1, disabled: true }}
      minScale={0.2}
      maxScale={2.0}
      initialScale={1.0}
      panning={{ disabled: isDisablePan }}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <div className="image-marker-tools p-buttonset">
            <Button
              icon="pi pi-plus"
              size="small"
              outlined
              severity="info"
              onClick={() => zoomIn()}
            />
            <Button
              icon="pi pi-arrows-alt ml-1 mr-2"
              size="small"
              outlined
              severity="info"
              onClick={() => resetTransform()}
            />
            <Button
              icon="pi pi-minus"
              size="small"
              outlined
              severity="info"
              onClick={() => zoomOut()}
            />
          </div>
          <TransformComponent
            wrapperStyle={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
            contentStyle={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
          >
            <div className="image-marker">
              <img
                src={src}
                alt={alt}
                onClick={handleImageClick}
                className={'image-marker__image ' + extraClass}
                ref={imageRef}
              />
              {markers.map((marker, itemNumber) => (
                <div
                  className={`image-marker__marker ${
                    !MarkerComponent && 'image-marker__marker--default'
                  }`}
                  key={itemNumber}
                  style={{ ...getItemPosition(marker), cursor: 'pointer' }}
                  data-testid="marker"
                  onClick={event => handleMarkerClick(event, marker)}
                >
                  {MarkerComponent ? (
                    <MarkerComponent {...marker} itemNumber={itemNumber} />
                  ) : (
                    itemNumber + 1
                  )}
                </div>
              ))}
            </div>
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

ReactImageMarkerNotDraggable.defaultProps = {
  triggerEmployeeDetailSidebar: () => {},
};
export default ReactImageMarkerNotDraggable;
