import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getCurrentFullDateFormattedInTimeZone } from '@/helpers/utility';

const buildRequestQueryTable = (floorId, selectedDate) => {
  return {
    data: {
      fields: {
        date: ['select'],
        day_of_week: ['select'],
        device_identifer: ['select'],
        reserved: ['select'],
        reserved_rate: ['select'],
        reserved_and_unused: ['select'],
        occupied_rate: ['select'],
        unused_reservation: ['select'],
        unused_rate: ['select'],
        room_name: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
        reservation_counts: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      date: {
        eq: [selectedDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      room_name: 'asc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_TABLE,
  };
};

const buildRequestQueryOutlookRoomAvailability = ({ floorId }) => {
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        floor_id: ['select'],
        room_availability: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId],
      timestamp: {
        lte: [getCurrentFullDateFormattedInTimeZone()],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.ROOM_AVAILABILITIES_MART,
  };
};

export const fetchDataTable = async payload => {
  const { floorId, selectedDate } = payload;
  const request = buildRequestQueryTable(floorId, selectedDate);
  return executeQuery(request);
};

export const fetchRoomAvailabilityByFloorId = async payload => {
  const { floorId } = payload;
  const request = buildRequestQueryOutlookRoomAvailability({ floorId });
  return executeQuery(request);
};
