import { COMMON_TEXT } from '@/helpers/common-text';

// 1	employee_locations
// 2	room_availabilities
// 3	people_counts
// 4	performances
// 5	environments
// 6	meeting_rooms
// 7	deivce_status
// 8	meraki_logging
// 9	verkada_logging
// 10	employee_managements
// 11	people_counts_analysis
// 12 employee_attendance
// 13	signage_components

export const CSV_DOWNLOAD_COMPONENT = {
  NETWORK_PERFORMANCE_SCORE: 1,
  WIRELESS_SIGNAL: 2,
  NETWORK_CLIENT_COUNT: 3,
  NETWORK_USAGE: 4,
  WIRELESS_CHANNEL_UTILIZATION: 5,
  WIRELESS_LATENCY: 6,
  MEETING_ROOM_OUTLOOK_TABLE: 7,
  SENSOR_DATA_HISTORY: 8,
  PEOPLE_COUNTS_ANALYSIS: 9,
};

export const PERFORMANCE_COMPONENT = {
  NETWORK_PERFORMANCE_SCORE: 1,
  WIRELESS_SIGNAL: 2,
  NETWORK_CLIENT_COUNT: 3,
  NETWORK_USAGE: 4,
  WIRELESS_CHANNEL_UTILIZATION: 5,
  WIRELESS_LATENCY: 6,
};

export const COMPONENT_ID_GROUP = {
  EMPLOYEE_LOCATIONS: 1,
  ROOM_AVAILABILITIES: 2,
  PEOPLE_COUNTS: 3,
  PERFORMANCES: 4,
  ENVIRONMENTS: 5,
  MEETING_ROOMS: 6,
  DEVICE_STATUS: 7,
  MERAKI_LOGGING: 8,
  VERKADA_LOGGING: 9,
  EMPLOYEE_MANAGEMENTS: 10,
  PEOPLE_COUNTS_ANALYSIS: 11,
  EMPLOYEE_ATTENDANCE: 12,
  SIGNAGE_COMPONENTS: 13,
  CONFIGURATION: 999,
};

export const COMPONENT_CHILD_ID_GROUP = {
  TEMPERATURE: 1,
  HUMIDITY: 2,
  CARBON_DIOXIDE: 3,
  NOISE_LEVEL: 4,
};

export const NETWORK_SORT_OPTIONS = {
  DEVICE_NAME_ASC: 'device_name_asc',
  PERFORMANCE_SCORE_BAR_DESC: 'performance_score_bar_desc',
  PERFORMANCE_SCORE_BAR_ASC: 'performance_score_bar_asc',
};

export const CONFIGURATION_COMPONENT_INDEX = {
  TENANT_SETTING: 0,
  ORGANIZATION_SETTING: 1,
  LOCATION_SETTING: 2,
  FLOOR_SETTING: 3,
  VERKADA_DEVICE_SETTING: 4,
  DEVICE_LOCATION_SETTING: 5,
  COMPANY_EMPLOYEE_SETTING: 6,
  EMPLOYEE_TERMINAL_SETTING: 7,
  WORKING_HOUR_SETTING: 8,
  AREA_SETTING: 9,
  AIR_QUALITY_SETTING: 10,
};

export const CONFIGURATION_COMPONENT_LIST = [
  {
    index: CONFIGURATION_COMPONENT_INDEX.TENANT_SETTING,
    name: COMMON_TEXT.TENANT_SETTING,
    component: 'TenantSetting',
    url: '/configuration/tenant-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.ORGANIZATION_SETTING,
    name: COMMON_TEXT.ORGANIZATION_SETTING,
    component: 'OrganizationSetting',
    url: '/configuration/organization-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.LOCATION_SETTING,
    name: COMMON_TEXT.LOCATION_SETTING,
    component: 'LocationSetting',
    url: '/configuration/location-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.FLOOR_SETTING,
    name: COMMON_TEXT.FLOOR_SETTING,
    component: 'FloorSetting',
    url: '/configuration/floor-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.AREA_SETTING,
    name: COMMON_TEXT.AREA_SETTING,
    component: 'AreaSetting',
    url: '/configuration/area-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.VERKADA_DEVICE_SETTING,
    name: COMMON_TEXT.VERKADA_DEVICE_SETTING,
    component: 'VerkadaDeviceSetting',
    url: '/configuration/verkada-device-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.DEVICE_LOCATION_SETTING,
    name: COMMON_TEXT.DEVICE_LOCATION_SETTING,
    component: 'DeviceLocationSetting',
    url: '/configuration/device-location-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.CAMERA_ZONE_SETTING,
    name: COMMON_TEXT.CAMERA_ZONE_SETTING,
    component: 'CameraZoneSetting',
    url: '/configuration/camera-zone-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.AIR_QUALITY_SETTING,
    name: COMMON_TEXT.AIR_QUALITY_SETTING,
    component: 'AirQualitySetting',
    url: '/configuration/air-quality-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.COMPANY_EMPLOYEE_SETTING,
    name: COMMON_TEXT.COMPANY_EMPLOYEE_SETTING,
    component: 'EmployeeSetting',
    url: '/configuration/employee-setting',
  },
  {
    index: CONFIGURATION_COMPONENT_INDEX.WORKING_HOUR_SETTING,
    name: COMMON_TEXT.WORKING_HOUR_SETTING,
    component: 'WorkingHourSetting',
    url: '/configuration/working-hour-setting',
  },
];

export const PC_PHONE_TYPE = {
  PC: 'pc',
  PHONE: 'phone',
};
