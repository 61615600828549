import CustomCurrentTime from '@/components/CustomComponent/CustomCurrentTime';
import MeetingRoomUsage from '@/components/communication/MeetingRoomUsage';
import { fetchData } from '@/components/communication/MeetingRoomUsage/query-request';
import PeopleCounts from '@/components/communication/PeopleCounts';
import { getPeopleCountsAreaByFloorId } from '@/components/communication/PeopleCounts/query-request';
import AirQualityNow from '@/components/facility_management/AirQuality/AirQualityNow';
import {
  fetchSensorDataNow,
  fetchSensorDataThreshold,
} from '@/components/facility_management/AirQuality/AirQualityNow/query-request';
import {
  COMPONENT_CHILD_ID_GROUP,
  COMPONENT_ID_GROUP,
} from '@/helpers/constants';
import {
  calculateRectangle,
  transformCoordinateBottomLeftToTopLeft,
} from '@/helpers/utility';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';

const STOP_INTERVAL = 9999999999;

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class SignagePlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchedData: {}, // key is rowId, value is fetched data
      items: [],
      activeInterval: STOP_INTERVAL, // set high to wait for loading data
      activeIndex: 0,
      currentPage: 0,
      isAutoPlay: true,
      isFetchingAllData: false,
    };
    this.carouselRef = React.createRef();
  }

  componentDidMount() {
    const { items } = this.props;
    const firstItem = items[0] ?? {};
    this.getAllAndUpdateAllData({ items }).then(() => {
      this.setState({ items, activeInterval: firstItem.interval * 1000 });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { items, activeIndex, currentPage, isAutoPlay } = this.state;
    const isActiveIndexChanged = activeIndex !== prevState.activeIndex;
    const isPageChanged = currentPage !== prevState.currentPage;
    if (!isAutoPlay) {
      return;
    }
    if (isActiveIndexChanged || isPageChanged) {
      const nextActiveIndex = (activeIndex + 1) % items.length;
      const nextItem = items[nextActiveIndex];
      const { component_id, floor_id, rowId } = nextItem;
      this.getAndUpdateData({
        component_id,
        floor_id,
        rowId,
      });
    }
  }

  componentWillUnmount() {}

  getDataRoomUsageByFloor = async ({ floorId }) => {
    let dataFloorMap = await fetchData({
      floorId: floorId,
    });
    if (!dataFloorMap) {
      dataFloorMap = [];
    }

    dataFloorMap = dataFloorMap
      .sort((a, b) => {
        if (a?.device_name < b?.device_name) {
          return -1;
        }
        if (a?.device_name > b?.device_name) {
          return 1;
        }
        return 0;
      })
      .map(item => ({
        ...item,
        timestamp: format(new Date(item.timestamp), 'MM月dd日 - HH:mm'),
      }));

    const updatedFloorMapData = dataFloorMap.map(item => ({
      position: {
        x: parseFloat(item?.position_x),
        y: parseFloat(item?.position_y),
      },
      data: item ?? null,
    }));

    return { updatedFloorMapData, dataFloorMap };
  };

  getDataPeopleCountsByFloor = async ({ floorId }) => {
    let dataFloorMap = await getPeopleCountsAreaByFloorId({
      floorId: floorId,
    });

    if (!dataFloorMap) {
      dataFloorMap = [];
    }

    const dataPeopleCounts = dataFloorMap.map(item => {
      const { position_x, position_y, position_x1, position_y1 } = item;
      const { pointC, width, height } = calculateRectangle({
        positionX: position_x,
        positionY: position_y,
        positionX1: position_x1,
        positionY1: position_y1,
      });
      const { x, y } = transformCoordinateBottomLeftToTopLeft({
        x: pointC.x,
        y: pointC.y,
      });
      item.rect = {
        top: y,
        left: x,
        width,
        height,
      };
      return item;
    });

    return { dataPeopleCounts };
  };

  getSensorDataByFloor = async ({ floorId }) => {
    let sensorDataRes = await fetchSensorDataNow({
      floorId: floorId,
    });

    let sensorData = [];
    if (sensorDataRes && sensorDataRes.length > 0) {
      sensorData = sensorDataRes.map(item => ({
        position: {
          x: parseFloat(item?.position_x),
          y: parseFloat(item?.position_y),
        },
        data: item ?? null,
      }));
    }

    let sensorDataThreshold = await fetchSensorDataThreshold({
      floorId: floorId,
    });
    sensorDataThreshold = sensorDataThreshold?.[0] ?? {};
    return { sensorData, sensorDataThreshold };
  };

  getAllAndUpdateAllData = async ({ items }) => {
    this.setState({ isFetchingAllData: true });
    const { fetchedData } = this.state;
    const newFetchedData = { ...fetchedData };
    for (let i = 0; i < items.length; i++) {
      const { component_id, floor_id, rowId } = items[i];
      const { data } = await this.getDataByComponentAndFloor({
        component_id,
        floor_id,
      });
      newFetchedData[rowId] = data;
    }
    this.setState({ fetchedData: newFetchedData, isFetchingAllData: false });
  };

  getAndUpdateData = async ({ component_id, floor_id, rowId }) => {
    const { fetchedData } = this.state;
    const newFetchedData = { ...fetchedData };
    const { data } = await this.getDataByComponentAndFloor({
      component_id,
      floor_id,
    });
    newFetchedData[rowId] = data;
    this.setState({ fetchedData: newFetchedData });
  };

  getDataByComponentAndFloor = async ({ component_id, floor_id }) => {
    let data = [];
    switch (component_id) {
      case COMPONENT_ID_GROUP.ROOM_AVAILABILITIES:
        data = await this.getDataRoomUsageByFloor({ floorId: floor_id });
        break;
      case COMPONENT_ID_GROUP.PEOPLE_COUNTS:
        data = await this.getDataPeopleCountsByFloor({ floorId: floor_id });
        break;
      case COMPONENT_ID_GROUP.ENVIRONMENTS:
        data = await this.getSensorDataByFloor({ floorId: floor_id });
        break;
      default:
        break;
    }
    return { data: data ?? [] };
  };

  onPageChange = e => {
    const { items, isAutoPlay } = this.state;
    const newActiveIndex = e.page;
    let newInterval = items[e.page].interval * 1000;
    if (!isAutoPlay) {
      newInterval = STOP_INTERVAL;
    }
    this.setState({
      activeIndex: newActiveIndex,
      activeInterval: newInterval,
    });
  };

  getActiveComponent = ({
    component_id,
    component_child_id,
    rowId,
    floorId,
    fetchedData,
  }) => {
    const componentData = fetchedData[rowId];
    if (isNil(componentData)) {
      return <></>;
    }
    let signageModeData = {
      data: componentData ?? [],
      floor: floorId,
      timestamp: new Date().getTime(),
    };
    switch (component_id) {
      case COMPONENT_ID_GROUP.ROOM_AVAILABILITIES:
        return (
          <MeetingRoomUsage
            floor={floorId}
            isSignageMode={true}
            signageModeData={signageModeData}
          />
        );
      case COMPONENT_ID_GROUP.PEOPLE_COUNTS:
        return (
          <PeopleCounts
            signageModeData={signageModeData}
            isSignageMode={true}
          />
        );
      case COMPONENT_ID_GROUP.ENVIRONMENTS:
        switch (component_child_id) {
          case COMPONENT_CHILD_ID_GROUP.TEMPERATURE:
            return (
              <AirQualityNow
                signageModeData={{
                  ...signageModeData,
                  selectedSensorType: 'temperature',
                }}
                isSignageMode={true}
              />
            );
          case COMPONENT_CHILD_ID_GROUP.HUMIDITY:
            return (
              <AirQualityNow
                signageModeData={{
                  ...signageModeData,
                  selectedSensorType: 'humidity',
                }}
                isSignageMode={true}
              />
            );
          case COMPONENT_CHILD_ID_GROUP.CARBON_DIOXIDE:
            return (
              <AirQualityNow
                signageModeData={{
                  ...signageModeData,
                  selectedSensorType: 'carbon_dioxide',
                }}
                isSignageMode={true}
              />
            );
          case COMPONENT_CHILD_ID_GROUP.NOISE_LEVEL:
            return (
              <AirQualityNow
                signageModeData={{
                  ...signageModeData,
                  selectedSensorType: 'noise_level',
                }}
                isSignageMode={true}
              />
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  backButtonOnClicked = () => {
    const { activeIndex, items } = this.state;
    let prevActiveIndex = activeIndex - 1;
    if (prevActiveIndex < 0) {
      prevActiveIndex = items.length - 1;
    }
    this.setState({
      activeIndex: prevActiveIndex,
      currentPage: prevActiveIndex,
      isAutoPlay: false,
      activeInterval: STOP_INTERVAL,
    });
  };

  nextButtonOnClicked = () => {
    const { activeIndex, items } = this.state;
    const nextActiveIndex = (+activeIndex + 1) % items.length;
    this.setState({
      activeIndex: nextActiveIndex,
      currentPage: nextActiveIndex,
      isAutoPlay: false,
      activeInterval: STOP_INTERVAL,
    });
  };

  playButtonOnClicked = () => {
    const { isAutoPlay } = this.state;
    if (isAutoPlay) {
      this.setState({ isAutoPlay: false, activeInterval: STOP_INTERVAL });
    } else {
      const { activeIndex, items } = this.state;
      const nextActiveIndex = (+activeIndex + 1) % items.length;
      this.setState({
        isAutoPlay: true,
        activeInterval: 0,
        activeIndex: nextActiveIndex,
        currentPage: nextActiveIndex,
      });
    }
  };

  render() {
    const {
      items,
      activeInterval,
      fetchedData,
      currentPage,
      isAutoPlay,
      isFetchingAllData,
    } = this.state;
    const itemTemplate = item => {
      const { component_id, floor_id, component_child_id, rowId } = item;
      return (
        <div>
          {this.getActiveComponent({
            component_id: component_id,
            component_child_id: component_child_id,
            rowId: rowId,
            floorId: floor_id,
            fetchedData,
          })}
        </div>
      );
    };
    return (
      <div className="signage-play-container">
        <div className="card">
          <Fragment>
            {isFetchingAllData ? (
              <LoadingSpinner></LoadingSpinner>
            ) : (
              <>
                <div className="custom-current-time-container">
                  <CustomCurrentTime />
                </div>
                <Carousel
                  ref={this.carouselRef}
                  value={items}
                  page={currentPage}
                  numVisible={1}
                  numScroll={1}
                  className="custom-carousel"
                  circular
                  autoplayInterval={activeInterval}
                  itemTemplate={itemTemplate}
                  onPageChange={this.onPageChange}
                  showIndicators={false}
                  showNavigators={false}
                />
                <div className="control-button-container">
                  <Button
                    text
                    className="control-button control-back-btn"
                    icon="pi pi-backward"
                    severity="secondary"
                    onClick={() => this.backButtonOnClicked()}
                  ></Button>
                  <Button
                    text
                    className="control-button control-play-btn"
                    icon={`pi pi-${isAutoPlay ? 'pause' : 'play'}`}
                    severity="secondary"
                    onClick={() => this.playButtonOnClicked()}
                  ></Button>
                  <Button
                    text
                    className="control-button control-next-btn"
                    icon="pi pi-forward"
                    severity="secondary"
                    onClick={() => this.nextButtonOnClicked()}
                  ></Button>
                </div>
              </>
            )}
          </Fragment>
        </div>
      </div>
    );
  }
}

SignagePlay.propTypes = {
  items: PropTypes.array,
};

export default SignagePlay;
