import axios from 'axios';
import FpSdk from 'forepaas/sdk';
import FpClientAuthorityManager from 'forepaas/client-authority-manager';

class AuthToken {
  getBaseUrl() {
    return `${
      FpSdk.config.authentication.split('.forepaas.io')[0]
    }.forepaas.io`;
  }
  getLoginToken() {
    let token = null;
    if (FpClientAuthorityManager.FpAuthentication.localSession) {
      token = FpClientAuthorityManager.FpAuthentication.token;
    }
    return token;
  }

  getUserEmail() {
    let email = null;
    if (FpClientAuthorityManager.FpAuthentication.localSession) {
      email = FpClientAuthorityManager.FpAuthentication.getLogin();
    }
    return email;
  }

  async updateConfiguration({ flowId, payload }) {
    const token = this.getLoginToken();
    const email = this.getUserEmail();

    // Validate the token data
    if (!token || !email) {
      throw new Error('Invalid authentication');
    }

    payload = payload || {};
    payload.user = email;

    const url = `${this.getBaseUrl()}/dpe/v3/workflows/${flowId}/start?token=${token}`;

    try {
      const response = await axios.post(url, payload);
      return response.data;
    } catch (error) {
      console.error('Error updating configuration:', error);
      throw error;
    }
  }
}

export default AuthToken;
