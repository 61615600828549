import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { isUsingSampleData } from 'src/helpers/generate-random-data';
import { executeQuery } from 'src/helpers/request';

const buildQueryTableData = (tenantId, fromDate, toDate) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        category: ['select'],
        client_description: ['select'],
        client_id: ['select'],
        client_mac: ['select'],
        description: ['select'],
        device_name: ['select'],
        device_serial: ['select'],
        network_identifer: ['select'],
        network_name: ['select'],
        occurred_at: ['select'],
        product_type: ['select'],
        ssid_number: ['select'],
        tenant_id: ['select'],
        type: ['select'],
        log_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
      occurred_at: {
        gte: [fromDate],
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      occurred_at: 'desc',
    },
    table_name: QUERY_TABLES.MERAKI_NETWORK_EVENTS,
  };
};

const buildRequestQueryBranch = tenantId => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        branch_name: ['select'],
        tenant_id: ['select'],
        branch_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      branch_order: 'asc',
    },
    table_name: QUERY_TABLES.BRANCHES,
  };
};

const buildRequestQueryFloor = branchIdList => {
  const randomId = getRandomNumber900000to999999();
  branchIdList = branchIdList || [];
  branchIdList.push(randomId);
  return {
    data: {
      fields: {
        floor_id: ['select'],
        floorplan_id: ['select'],
        floor_name: ['select'],
        branch_id: ['select'],
        floor_map: ['select'],
        floor_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: branchIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      floor_order: 'asc',
    },
    table_name: QUERY_TABLES.FLOORS,
  };
};

const buildRequestVerkadaDevices = ({ floorId, deviceType }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        device_type: ['select'],
        door_type: ['select'],
        floor_id: ['select'],
        model: ['select'],
        org_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
      device_type: [deviceType],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.VERKADA_DEVICES,
  };
};

const buildRequestDevicePosition = floorId => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_position_id: ['select'],
        floor_id: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        room_name: ['select'],
        area_id: ['select'],
        area_name: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.DEVICE_POSITIONS,
  };
};

const buildRequestAreaByFloor = ({ floorId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        area_id: ['select'],
        area_name: ['select'],
        capacity_limit: ['select'],
        floor_id: ['select'],
        position_x: ['select'],
        position_x1: ['select'],
        position_y: ['select'],
        position_y1: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      area_id: 'asc',
    },
    table_name: QUERY_TABLES.AREAS,
  };
};

const buildRequestButlrDevices = ({ tenantId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        room_id: ['select'],
        room_name: ['select'],
        apikey_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.BUTLR_ROOMS,
  };
};

const buildRequestMerakiDevicesByTenantIdAndModel = ({ tenantId, model }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        model: ['select'],
        network_identifer: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomId],
      model: {
        like: [`${model}%`]
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.MERAKI_DEVICES,
  };
};

export const fetchMerakiNetworkEvents = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildQueryTableData(tenantId, fromDate, toDate);
  if (isUsingSampleData) {
    return [];
  }
  return executeQuery(request);
};

export const fetchBranchListByTenent = async tenentId => {
  const request = buildRequestQueryBranch(tenentId);
  return executeQuery(request);
};

export const fetchFloorListByBranchIdList = async branchIdList => {
  const request = buildRequestQueryFloor(branchIdList);
  return executeQuery(request);
};

export const fetchVerkadaDevicesByFloorAndType = async ({ floorId, deviceType }) => {
  const request = buildRequestVerkadaDevices({ floorId, deviceType });
  return executeQuery(request);
};

export const fetchDevicePositionByFloorId = async floorId => {
  const request = buildRequestDevicePosition(floorId);
  return executeQuery(request);
};

export const fetchAreasByFloor = async ({ floorId }) => {
  const request = buildRequestAreaByFloor({ floorId });
  return executeQuery(request);
};

export const fetchButlrDevicesByTenantId = async payload => {
  const { tenantId } = payload;
  const request = buildRequestButlrDevices({ tenantId });
  return executeQuery(request);
};

export const fetchMerakiDevicesByTenantIdAndModel = async payload => {
  const { tenantId, model } = payload;
  const request = buildRequestMerakiDevicesByTenantIdAndModel({ tenantId, model });
  return executeQuery(request);
};
