import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const buildQueryTenant = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        tenant_name: ['select'],
        allow_supervisor: ['select'],
        business_hour_begin: ['select'],
        components: ['select'],
        holidays: ['select'],
        schedule_tool: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.TENANTS,
  };
};

const buildQueryApiKey = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        product: ['select'], // microsoft, verkada, meraki, butlr, garoon, webex
        apikey_id: ['select'],
        graph_client_id: ['select'], // microsoft
        graph_tenant_id: ['select'], // microsoft
        client_id: ['select'], // webex
        subdomain: ['select'], // garoon
        username: ['select'], // butlr, garoon
        has_apikey: ['select'],
        has_client_secret: ['select'],
        has_refresh_token: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.APIKEYS,
  };
};

export const fetchTenantById = async payload => {
  const { tenantId } = payload;
  const request = buildQueryTenant({ tenantId });
  return executeQuery(request);
};

export const fetchApiKeyByTenantId = async payload => {
  const { tenantId } = payload;
  const request = buildQueryApiKey({ tenantId });
  return executeQuery(request);
};
