import { QUERY_TABLES } from '@/helpers/constants';
import {
  generateRandomDataForFloorMap,
  isUsingSampleData,
} from 'src/helpers/generate-random-data';
import { executeQuery } from 'src/helpers/request';
import { getCurrentFullDateFormattedInTimeZone } from 'src/helpers/utility';

const buildRequestQuery = floorId => {
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        device_type: ['select'],
        status: ['select'],
        timestamp: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        tenant_id: ['select'],
        icon: ['select'],
        product: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      timestamp: {
        lte: [getCurrentFullDateFormattedInTimeZone()],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.DEVICE_STATUS_MART,
  };
};

const buildRequestHistoryQuery = ({ floorId, fromDate, toDate }) => {
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        start_time: ['select'],
        end_time: ['select'],
        status: ['select'],
        floor_id: ['select'],
        device_name: ['select'],
        device_type: ['select'],
        tenant_id: ['select'],
        product: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      start_time: {
        lte: [toDate],
      },
      end_time: {
        gte: [fromDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      start_time: 'asc',
    },
    table_name: QUERY_TABLES.DEVICE_STATUS_MART_LINE,
  };
};

const buildRequestHistoryEmptyEndDateQuery = ({
  floorId,
  fromDate,
  toDate,
}) => {
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        start_time: ['select'],
        end_time: ['select'],
        status: ['select'],
        floor_id: ['select'],
        device_name: ['select'],
        device_type: ['select'],
        tenant_id: ['select'],
        product: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      start_time: {
        lte: [toDate],
      },
      end_time: {
        is_null: [],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      start_time: 'asc',
    },
    table_name: QUERY_TABLES.DEVICE_STATUS_MART_LINE,
  };
};

export const fetchData = async payload => {
  const { floorId } = payload;
  const request = buildRequestQuery(floorId);
  if (isUsingSampleData) {
    return generateRandomDataForFloorMap();
  }
  return executeQuery(request);
};

export const fetchDataHistory = async payload => {
  const { floorId, fromDate, toDate } = payload;
  const request = buildRequestHistoryQuery({
    floorId,
    fromDate,
    toDate,
  });
  return executeQuery(request);
};

export const fetchDataHistoryEmptyEndDate = async payload => {
  const { floorId, fromDate, toDate } = payload;
  const request = buildRequestHistoryEmptyEndDateQuery({
    floorId,
    fromDate,
    toDate,
  });
  return executeQuery(request);
};
