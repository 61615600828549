import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  API_KEY_PRODUCT_TYPE,
  EXECUTION_ID_TIMEOUT,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import {
  getConfigurationUrlWithParam,
  randomStringWithSymbol,
} from '@/helpers/utility';
import AuthToken from '@/services/auth-token';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { Form, Formik } from 'formik';
import { cloneDeep, isEmpty, isEqual, isNil, isNull } from 'lodash';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { fetchApiKeyByTenantId, fetchTenantById } from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class TenantSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      initialFormValues: {
        tenantId: null,
        tenantName: '',
        scheduleTool: '',
        merakiApiKeyId: null,
        merakiApiKey: null,
        verkadaApiKeyId: null,
        verkadaApiKey: null,
        microsoftApiKeyId: null,
        microsoftApiKey: null,
        microsoftGraphClientId: null,
        microsoftGraphTenantId: null,
        butlrUsername: null,
        butlrApiKey: null,
        garoonUsername: null,
        garoonApiKey: null,
        garoonSubdomain: null,
        webexApiKey: null,
        webexRefreshToken: null,
        webexClientId: null,
        webexClientSecret: null,
      },
      originalFormValues: null,
    };
    this.secretString = `THIS_IS_JUST_RANDOM_${randomStringWithSymbol(20)}`;
    this.toastRef = React.createRef();
    this.authToken = new AuthToken();
    this.merakiFieldList = [
      {
        key: 'merakiApiKey',
        respondKey: 'apikey',
        payloadKey: 'key',
        title: COMMON_TEXT.API_KEY,
        isMasked: true,
        hasApiKey: 0,
      },
    ];
    this.verkadaFieldList = [
      {
        key: 'verkadaApiKey',
        respondKey: 'apikey',
        payloadKey: 'key',
        title: COMMON_TEXT.API_KEY,
        isMasked: true,
        hasApiKey: 0,
      },
    ];
    this.microsoftFieldList = [
      {
        key: 'microsoftApiKey',
        respondKey: 'apikey',
        payloadKey: 'key',
        title: COMMON_TEXT.MICROSOFT_API_KEY,
        isMasked: true,
        hasApiKey: 0,
      },
      {
        key: 'microsoftGraphClientId',
        respondKey: 'graph_client_id',
        title: COMMON_TEXT.MICROSOFT_GRAPH_CLIENT_ID,
      },
      {
        key: 'microsoftGraphTenantId',
        respondKey: 'graph_tenant_id',
        title: COMMON_TEXT.MICROSOFT_GRAPH_TENANT_ID,
      },
    ];
    this.butlrFieldList = [
      {
        key: 'butlrUsername',
        respondKey: 'username',
        title: COMMON_TEXT.BUTLR_USERNAME,
      },
      {
        key: 'butlrApiKey',
        respondKey: 'apikey',
        payloadKey: 'key',
        title: COMMON_TEXT.BUTLR_API_KEY,
        isMasked: true,
        hasApiKey: 0,
      },
    ];
    this.garoonFieldList = [
      {
        key: 'garoonUsername',
        respondKey: 'username',
        title: COMMON_TEXT.GAROON_USERNAME,
      },
      {
        key: 'garoonApiKey',
        respondKey: 'apikey',
        payloadKey: 'key',
        title: COMMON_TEXT.GAROON_API_KEY,
        isMasked: true,
        hasApiKey: 0,
      },
      {
        key: 'garoonSubdomain',
        respondKey: 'subdomain',
        title: COMMON_TEXT.GAROON_SUBDOMAIN,
      },
    ];
    this.webexFieldList = [
      {
        key: 'webexApiKey',
        respondKey: 'apikey',
        payloadKey: 'key',
        title: COMMON_TEXT.WEBEX_API_KEY,
        isMasked: true,
        hasApiKey: 0,
      },
      {
        key: 'webexRefreshToken',
        respondKey: 'refresh_token',
        title: COMMON_TEXT.WEBEX_REFRESH_TOKEN,
        isMasked: true,
        hasRefreshToken: 0,
      },
      {
        key: 'webexClientId',
        respondKey: 'client_id',
        title: COMMON_TEXT.WEBEX_CLIENT_ID,
      },
      {
        key: 'webexClientSecret',
        respondKey: 'client_secret',
        title: COMMON_TEXT.WEBEX_CLIENT_SECRET,
        hasClientSecret: 0,
        isMasked: true,
      },
    ];
    this.scheduleToolOptions = [
      {
        label: 'Microsoft Office',
        value: 'microsoft',
      },
      {
        label: 'Garoon',
        value: 'garoon',
      },
      {
        label: 'なし',
        value: '',
      },
    ];
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  convertFieldToKeyValue = ({ apiKeyList }) => {
    const result = {};
    for (const apiKeyInfo of apiKeyList) {
      const {
        apikey_id,
        product,
        has_apikey,
        has_client_secret,
        has_refresh_token,
      } = apiKeyInfo;
      result[`${product}ApiKeyId`] = apikey_id;
      result[`${product}HasApiKey`] = has_apikey;
      result[`${product}HasClientSecret`] = has_client_secret;
      result[`${product}HasRefreshToken`] = has_refresh_token;
      let fieldList = [];
      switch (product) {
        case API_KEY_PRODUCT_TYPE.MERAKI:
          fieldList = [...this.merakiFieldList];
          break;
        case API_KEY_PRODUCT_TYPE.VERKADA:
          fieldList = [...this.verkadaFieldList];
          break;
        case API_KEY_PRODUCT_TYPE.MICROSOFT:
          fieldList = [...this.microsoftFieldList];
          break;
        case API_KEY_PRODUCT_TYPE.BUTLR:
          fieldList = [...this.butlrFieldList];
          break;
        case API_KEY_PRODUCT_TYPE.GAROON:
          fieldList = [...this.garoonFieldList];
          break;
        case API_KEY_PRODUCT_TYPE.WEBEX:
          fieldList = [...this.webexFieldList];
          break;
        default:
          break;
      }
      fieldList.forEach(item => {
        const { key, respondKey } = item;
        if (respondKey === 'apikey') {
          result['hasApiKey'] = has_apikey;
          result[key] = has_apikey > 0 ? this.secretString : '';
        } else if (respondKey === 'refresh_token') {
          result['hasRefreshToken'] = has_refresh_token;
          result[key] = has_refresh_token > 0 ? this.secretString : '';
        } else if (respondKey === 'client_secret') {
          result['hasClientSecret'] = has_client_secret;
          result[key] = has_client_secret > 0 ? this.secretString : '';
        } else {
          result[key] = apiKeyInfo[respondKey] ?? null;
        }
      });
    }
    return result;
  };

  getData = async () => {
    const { initialFormValues, tenant } = this.state;
    this.setState({ isLoading: true });
    let tenantInfo = await fetchTenantById({ tenantId: tenant });
    let updatedState = {};
    tenantInfo = tenantInfo?.[0] ?? {};
    const { tenant_name, tenant_id, schedule_tool } = tenantInfo;
    updatedState = {
      initialFormValues: {
        ...initialFormValues,
        tenantId: tenant_id,
        tenantName: tenant_name,
        scheduleTool: schedule_tool ?? '',
      },
    };

    let apiKeyList = await fetchApiKeyByTenantId({ tenantId: tenant });
    apiKeyList = apiKeyList || [];
    const keyFieldInfo = this.convertFieldToKeyValue({ apiKeyList });
    updatedState = {
      ...updatedState,
      initialFormValues: {
        ...updatedState.initialFormValues,
        ...keyFieldInfo,
      },
      isLoading: false,
    };
    const originalFormValues = cloneDeep(updatedState.initialFormValues);
    updatedState = {
      ...updatedState,
      originalFormValues,
    };
    this.setState(updatedState);
  };

  updateTenantInfo = async ({ tenantPayload }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.TENANTS,
        payload: tenantPayload,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateApiKeysInfo = async ({ apiKeyPayload }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.APIKEYS,
        payload: apiKeyPayload,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  getTenantInfoPayload = ({ data }) => {
    const { originalFormValues } = this.state;
    const {
      tenantId: originalTenantId,
      tenantName: originalTenantName,
      scheduleTool: originalScheduleTool,
    } = originalFormValues;

    const { tenantId, tenantName, scheduleTool } = data;
    if (
      tenantId === originalTenantId &&
      tenantName === originalTenantName &&
      scheduleTool === originalScheduleTool
    ) {
      return null;
    }
    return {
      values: [
        {
          name: tenantName,
          schedule_tool: scheduleTool,
        },
      ],
    };
  };

  transformPayloadKeyField = ({ data, type, changedFields }) => {
    const result = {};
    let fieldList = [];
    switch (type) {
      case API_KEY_PRODUCT_TYPE.MERAKI:
        fieldList = [...this.merakiFieldList];
        break;
      case API_KEY_PRODUCT_TYPE.VERKADA:
        fieldList = [...this.verkadaFieldList];
        break;
      case API_KEY_PRODUCT_TYPE.MICROSOFT:
        fieldList = [...this.microsoftFieldList];
        break;
      case API_KEY_PRODUCT_TYPE.BUTLR:
        fieldList = [...this.butlrFieldList];
        break;
      case API_KEY_PRODUCT_TYPE.GAROON:
        fieldList = [...this.garoonFieldList];
        break;
      case API_KEY_PRODUCT_TYPE.WEBEX:
        fieldList = [...this.webexFieldList];
        break;
      default:
        break;
    }
    fieldList.forEach(item => {
      const { key, respondKey, payloadKey } = item;
      if (changedFields.length > 0 && !changedFields.includes(key)) {
        return;
      }
      if (payloadKey) {
        result[payloadKey] = data[key] ?? null;
      } else {
        result[respondKey] = data[key] ?? null;
      }
    });
    return result;
  };

  convertToPayload = ({ data, fieldList, type }) => {
    const { originalFormValues } = this.state;
    let currentData = {};
    const keyId = `${type}ApiKeyId`;
    let prevData = {};
    let isDataChanged = false;
    let isAllFieldNull = true;
    const changedFields = [];
    fieldList.forEach(item => {
      const { key } = item;
      currentData[key] = data[key];
      prevData[key] = originalFormValues[key];
      isDataChanged =
        isDataChanged || !isEqual(currentData[key], prevData[key]);
      if (!isEqual(currentData[key], prevData[key])) {
        changedFields.push(key);
      }
      isAllFieldNull = isAllFieldNull && isNull(currentData[key]);
    });
    const id = data[keyId];
    const prevId = originalFormValues[keyId];
    let payload = null;
    const isCreated = isNil(id) && isNil(prevId) && isDataChanged;
    const isDeleted = id > 0 && prevId > 0 && isAllFieldNull;
    const isChanged =
      id > 0 && id === prevId && !isAllFieldNull && isDataChanged;
    if (isDeleted) {
      // Delete
      payload = {
        id: id,
        key: null,
      };
    } else if (isChanged || isCreated) {
      // Update or Add
      const transformData = this.transformPayloadKeyField({
        data: currentData,
        type,
        changedFields: changedFields,
      });
      payload = {
        id: isNil(id) ? null : id,
        product: type,
        ...transformData,
      };
    } else {
      // No change
    }
    return payload;
  };

  convertApiKeysInfoPayload = ({ data }) => {
    let apiKeyPayload = {
      values: [],
    };
    // filter data that is change on data comparing to originalFormValues
    const merakiPayload = this.convertToPayload({
      data,
      fieldList: this.merakiFieldList,
      type: API_KEY_PRODUCT_TYPE.MERAKI,
    });
    if (merakiPayload) {
      apiKeyPayload = {
        ...apiKeyPayload,
        values: [...apiKeyPayload.values, merakiPayload],
      };
    }

    const verkadaPayload = this.convertToPayload({
      data,
      fieldList: this.verkadaFieldList,
      type: API_KEY_PRODUCT_TYPE.VERKADA,
    });
    if (verkadaPayload) {
      apiKeyPayload = {
        ...apiKeyPayload,
        values: [...apiKeyPayload.values, verkadaPayload],
      };
    }

    const microsoftPayload = this.convertToPayload({
      data,
      fieldList: this.microsoftFieldList,
      type: API_KEY_PRODUCT_TYPE.MICROSOFT,
    });
    if (microsoftPayload) {
      apiKeyPayload = {
        ...apiKeyPayload,
        values: [...apiKeyPayload.values, microsoftPayload],
      };
    }

    const butlrPayload = this.convertToPayload({
      data,
      fieldList: this.butlrFieldList,
      type: API_KEY_PRODUCT_TYPE.BUTLR,
    });
    if (butlrPayload) {
      apiKeyPayload = {
        ...apiKeyPayload,
        values: [...apiKeyPayload.values, butlrPayload],
      };
    }

    const garoonPayload = this.convertToPayload({
      data,
      fieldList: this.garoonFieldList,
      type: API_KEY_PRODUCT_TYPE.GAROON,
    });
    if (garoonPayload) {
      apiKeyPayload = {
        ...apiKeyPayload,
        values: [...apiKeyPayload.values, garoonPayload],
      };
    }

    const webexPayload = this.convertToPayload({
      data,
      fieldList: this.webexFieldList,
      type: API_KEY_PRODUCT_TYPE.WEBEX,
    });
    if (webexPayload) {
      apiKeyPayload = {
        ...apiKeyPayload,
        values: [...apiKeyPayload.values, webexPayload],
      };
    }

    if (apiKeyPayload.values.length === 0) {
      return null;
    }
    return apiKeyPayload;
  };

  updateData = async ({ data }) => {
    const { sessionStore } = this.props;
    const tenantPayload = this.getTenantInfoPayload({ data });
    const apiKeyPayload = this.convertApiKeysInfoPayload({ data });
    if (tenantPayload == null && apiKeyPayload == null) {
      // nothing to update
      this.toastRef.current.show({
        severity: 'info',
        summary: '情報',
        detail: COMMON_TEXT.NO_CHANGE_TO_BE_SAVED,
        life: 3000,
        closable: true,
      });
      return;
    }
    this.setState({ isUpdating: true });
    let isSuccessTenant = null;
    let tenantExecutionId = null;
    let isSuccessApiKey = null;
    let apiKeyExecutionId = null;

    if (!isNull(tenantPayload)) {
      const { isSuccess, executionId } = await this.updateTenantInfo({
        tenantPayload,
      });
      isSuccessTenant = isSuccess;
      tenantExecutionId = executionId;
    }
    if (!isNull(apiKeyPayload)) {
      const { isSuccess, executionId } = await this.updateApiKeysInfo({
        apiKeyPayload,
      });
      isSuccessApiKey = isSuccess;
      apiKeyExecutionId = executionId;
    }

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (
      (isSuccessTenant && isSuccessApiKey) ||
      isSuccessTenant ||
      isSuccessApiKey
    ) {
      if (isSuccessTenant) {
        executionIdList.push({
          id: tenantExecutionId,
          expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
        });
      }
      if (isSuccessApiKey) {
        executionIdList.push({
          id: apiKeyExecutionId,
          expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
        });
      }
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    }
    if (!isSuccessTenant && !isSuccessApiKey) {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  renderSettingContent() {
    const { isLoading, isUpdating, initialFormValues } = this.state;

    const isFormFieldInvalid = (type, touched, errors) => {
      return touched[type] && errors[type] ? 'p-invalid' : '';
    };

    const handleSubmit = (values, { setSubmitting, errors }) => {
      if (!errors) {
        this.updateData({ data: values });
      } else {
      }
    };

    const getFormErrorMessage = (name, errors, touched) => {
      return isFormFieldInvalid(name, touched, errors) ? (
        <span className="validation-error-message">{errors[name]}</span>
      ) : (
        <></>
      );
    };

    const renderInputField = ({
      values,
      handleChange,
      touched,
      errors,
      fieldList,
    }) => {
      const deleteAccepted = ({ values }) => {
        const fieldObj = {};
        fieldList.forEach(item => {
          const { key } = item;
          fieldObj[key] = null;
        });
        const newInitialFormValues = {
          ...values,
          ...fieldObj,
        };
        this.setState({
          initialFormValues: newInitialFormValues,
        });
      };

      const deleteButtonOnClicked = ({ values }) => {
        const isAllEmpty = fieldList.every(
          item => isEmpty(values[item.key]) || isNil(values[item.key])
        );
        if (!isAllEmpty) {
          confirmDialog({
            id: `delete-api-key-confirm-dialog-microsoft`,
            message: '削除してよろしいですか？',
            header: 'APIキーの削除',
            icon: '',
            accept: () => deleteAccepted({ values }),
            reject: () => {},
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-secondary p-button-text',
            appendTo: 'self',
          });
        } else {
          deleteAccepted({ values });
        }
      };

      const addButtonOnClicked = ({ values }) => {
        const fieldObj = {};
        fieldList.forEach(item => {
          const { key } = item;
          fieldObj[key] = '';
        });
        const newInitialFormValues = {
          ...values,
          ...fieldObj,
        };
        this.setState({
          initialFormValues: newInitialFormValues,
        });
      };

      const secretFieldOnClicked = ({ keyItem }) => {
        const fieldObj = {};

        fieldList.forEach(item => {
          const { key } = item;
          if (key === keyItem) {
            fieldObj[key] = '';
          }
        });

        const newInitialFormValues = {
          ...values,
          ...fieldObj,
        };

        this.setState({
          initialFormValues: newInitialFormValues,
        });
      };

      const hasSomeValue = fieldList.some(item => !isNil(values[item.key]));
      return (
        <>
          {hasSomeValue ? (
            <>
              {fieldList.map((item, index) => {
                const { key, title, isMasked } = item;
                return (
                  <div
                    className={`input-text-field-container ${key}`}
                    key={`container-${key}-${index}`}
                  >
                    <span className="input-top-title">{title}</span>
                    {isMasked ? (
                      <Password
                        inputId={key}
                        feedback={false}
                        value={values[`${key}`]}
                        onChange={e => handleChange(e)}
                        onClick={e => {
                          secretFieldOnClicked({ keyItem: key });
                          // handleClickField({ keyItem: key });
                        }}
                        className={isFormFieldInvalid(key, touched, errors)}
                      />
                    ) : (
                      <InputText
                        id={key}
                        value={values[`${key}`]}
                        onChange={e => handleChange(e)}
                        className={isFormFieldInvalid(key, touched, errors)}
                      />
                    )}
                    {getFormErrorMessage(key, errors, touched)}
                  </div>
                );
              })}
              <div className="input-text-field-container">
                <Button
                  type="button"
                  icon="pi pi-trash"
                  text
                  severity="danger"
                  className="icon-only-delete-button"
                  onClick={e => {
                    deleteButtonOnClicked({ values });
                  }}
                />
              </div>
            </>
          ) : (
            <Button
              type="button"
              severity="secondary"
              label="追加"
              className="add-button"
              onClick={e => addButtonOnClicked({ values })}
            />
          )}
        </>
      );
    };

    const validateSchema = () => {
      const types = Object.keys(initialFormValues);
      // check if value of field is null
      const notNulltypes = types.filter(
        type => !isNull(initialFormValues[type])
      );
      // create yup schema object from not null field names
      const yupSchemaObject = {};
      notNulltypes.forEach(type => {
        if (type !== 'scheduleTool') {
          yupSchemaObject[type] = Yup.string().required(COMMON_TEXT.REQUIRED);
        }
      });
      return Yup.object(yupSchemaObject);
    };

    const rowList = [
      {
        product: 'Meraki',
        fieldList: this.merakiFieldList,
      },
      {
        product: 'Verkada',
        fieldList: this.verkadaFieldList,
      },
      {
        product: 'Microsoft',
        fieldList: this.microsoftFieldList,
      },
      {
        product: 'Butlr',
        fieldList: this.butlrFieldList,
      },
      {
        product: 'Garoon',
        fieldList: this.garoonFieldList,
      },
      {
        product: 'Webex',
        fieldList: this.webexFieldList,
      },
    ];

    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={initialFormValues}
            validationSchema={validateSchema()}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleChange, touched, errors, resetForm }) => (
              <Form>
                <div className="main-row-container tenant-name">
                  <div className="left-title">
                    <span>テナント名</span>
                  </div>
                  <div className="right-content">
                    <div className="input-text-field-container">
                      <InputText
                        id="tenantName"
                        value={values.tenantName}
                        onChange={e => handleChange(e)}
                        className={isFormFieldInvalid(
                          'tenantName',
                          touched,
                          errors
                        )}
                      />
                      {getFormErrorMessage('tenantName', errors, touched)}
                    </div>
                  </div>
                </div>
                <div className="main-row-container schedule-tool">
                  <div className="left-title">
                    <span>{COMMON_TEXT.SCHEDULE_TOOL}</span>
                  </div>
                  <div className="right-content">
                    <div className="input-text-field-container">
                      <Dropdown
                        id={`scheduleTool`}
                        value={values.scheduleTool}
                        options={this.scheduleToolOptions}
                        onChange={e => handleChange(e)}
                        className={isFormFieldInvalid(
                          'scheduleTool',
                          touched,
                          errors
                        )}
                      ></Dropdown>
                      {getFormErrorMessage('scheduleTool', errors, touched)}
                    </div>
                  </div>
                </div>
                <div className="main-row-container">
                  <div className="left-title">
                    <span>APIキー</span>
                  </div>
                  <div className="right-content">
                    <div className="row-container is-header">
                      <div className="product-field">
                        <span>プロダクト</span>
                      </div>
                    </div>
                    {rowList.map((row, index) => {
                      const { product, fieldList } = row;
                      return (
                        <div className="row-container" key={`row-${index}`}>
                          <div className="product-field">
                            <span>{product}</span>
                          </div>
                          <div className="key-field">
                            {renderInputField({
                              values,
                              handleChange,
                              touched,
                              errors,
                              fieldList,
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="main-row-container">
                  <Button
                    severity="info"
                    type="submit"
                    label="保存"
                    className="submit-button"
                    loading={isUpdating}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <div className="config-container custom-config-container tenant-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">{COMMON_TEXT.TENANT_SETTING}</div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">{this.renderSettingContent()}</div>
          </div>
        </div>
      </>
    );
  }
}

TenantSetting.propTypes = {};

export default TenantSetting;
