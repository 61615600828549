import { USER_PRIVILEGE_KEY } from '@/helpers/constants';
import FpClientAuthorityManager from 'forepaas/client-authority-manager';
import FpSdk from 'forepaas/sdk';
import { set as memoryActionSet } from 'forepaas/store/memory/action';
import { set as sessionActionSet } from 'forepaas/store/session/action';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

@connect(state => ({
  local: state.local,
}))

/**
 * Renders the current logged username with picture
 */
class Username extends Component {
  state = {};

  get username() {
    const { local } = this.props;
    if (local && local['client-authority-manager-session']) {
      return `${local['client-authority-manager-session'].lastname} ${local['client-authority-manager-session'].firstname}`;
    }
    return 'Not connected';
  }

  clearUserPrivilege = () => {
    FpSdk.modules.store.dispatch(memoryActionSet(USER_PRIVILEGE_KEY, ''));
    FpSdk.modules.store.dispatch(sessionActionSet(USER_PRIVILEGE_KEY, ''));
  };

  logout = e => {
    this.clearUserPrivilege();
    FpClientAuthorityManager.FpAuthentication.logout();
  };

  /**
   * render
   * @return {ReactElement} markup
   */
  render() {
    if (isMobile) {
      return <></>;
    }
    return (
      <div className={`username username-desktop`}>
        <div className="username-content">
          <div className="username-info">
            <p className="username-name">{this.username}</p>
            <p className="username-logout" onClick={this.logout}>
              ログアウト
            </p>
          </div>
        </div>
      </div>
    );
  }
}

Username.propTypes = {
  local: PropTypes.object,
};

export default Username;
