import { COMMON_TEXT } from 'src/helpers/common-text';
import { PEOPLE_COUNT_ANALYSIS_TYPE } from 'src/helpers/constants';
import {
  fetchDataChannelUtilization,
  fetchDataMeetingRoomOutlookTable,
  fetchDataNetworkClientCount,
  fetchDataNetworkUsage,
  fetchDataPeopleCountAnalysis,
  fetchDataPerformanceScore,
  fetchDataSensorHistory,
  fetchDataWirelessLatency,
  fetchDataWirelessSignal,
} from './query-request';

const PERFORMANCE_SCORE_CSV_FIELDS = [
  { label: '日時', key: 'timestamp' },
  { label: 'AP名', key: 'device_name' },
  { label: 'スコア', key: 'score' },
  { label: 'グレード', key: 'grade' },
];

const WIRELESS_SIGNAL_CSV_FIELDS = [
  { label: '日時', key: 'timestamp' },
  { label: 'AP名', key: 'device_name' },
  { label: 'SNR(dB)', key: 'snr' },
  { label: 'グレード', key: 'grade' },
];

const NETWORK_CLIENT_COUNT_CSV_FIELDS = [
  { label: '日時', key: 'timestamp' },
  { label: 'AP名', key: 'device_name' },
  { label: `${COMMON_TEXT.NETWORK_CLIENT_COUNT}(台)`, key: 'counts' },
];

const NETWORK_USAGE_CSV_FIELDS = [
  { label: '日時', key: 'timestamp' },
  { label: 'AP名', key: 'device_name' },
  { label: 'データレート(Kbps)', key: 'average_kbps' },
];

const WIRELESS_LATENCY_CSV_FIELDS = [
  { label: '日時', key: 'timestamp' },
  { label: 'AP名', key: 'device_name' },
  { label: `${COMMON_TEXT.BACKGROUND}(ミリ秒)`, key: 'background' },
  { label: `${COMMON_TEXT.BEST_EFFORT}(ミリ秒)`, key: 'besteffort' },
  { label: `${COMMON_TEXT.VOICE}(ミリ秒)`, key: 'voice' },
  { label: `${COMMON_TEXT.VIDEO}(ミリ秒)`, key: 'video' },
];

const MEETING_ROOM_OUTLOOK_TABLE_CSV_FIELDS = [
  { label: '日時', key: 'date' },
  { label: COMMON_TEXT.ROOM_NAME, key: 'room_name' },
  { label: COMMON_TEXT.RESERVED, key: 'reserved' },
  { label: COMMON_TEXT.RESERVATION_COUNTS, key: 'reservation_counts' },
  { label: COMMON_TEXT.RESERVED_AND_UNUSED, key: 'reserved_and_unused' },
  { label: COMMON_TEXT.OCCUPIED_9_18, key: 'occupied_rate' },
  { label: '空予約', key: 'unused_reservation' },
  { label: COMMON_TEXT.EMPTY_BOOKING_RATE, key: 'unused_rate' },
];

const SENSOR_DATA_HISTORY_CSV_FIELDS = [
  { label: '日時', key: 'timestamp' },
  { label: COMMON_TEXT.DEVICE_NAME, key: 'device_name' },
  { label: COMMON_TEXT.TEMPERATURE, key: 'temperature' },
  { label: COMMON_TEXT.HUMIDITY, key: 'humidity' },
  { label: COMMON_TEXT.CARBON_DIOXIDE, key: 'carbon_dioxide' },
  { label: COMMON_TEXT.NOISE_LEVEL, key: 'noise_level' },
];

const WIRELESS_CHANNEL_UTILIZATION_CSV_FIELDS = [
  { label: '日時', key: 'timestamp' },
  { label: 'AP名', key: 'device_name' },
  { label: 'チャネル利用率(%)', key: 'utilization_total' },
];

const PEOPLE_COUNT_ANALYSIS_CSV_FIELDS_COUNTS = [
  { label: '日時', key: 'timestamp' },
  { label: COMMON_TEXT.AREA_NAME, key: 'area_name' },
  { label: COMMON_TEXT.COUNTS, key: 'counts' },
];

const PEOPLE_COUNT_ANALYSIS_CSV_FIELDS_CAPACITY_RATE = [
  { label: '日時', key: 'timestamp' },
  { label: COMMON_TEXT.AREA_NAME, key: 'area_name' },
  { label: COMMON_TEXT.CAPACITY_RATE, key: 'capacity_rate' },
];

const getCsvData = ({ data, fields }) => {
  const csvRows = [];
  const headerLabels = fields.map(field => field.label);
  const keyValues = fields.map(field => field.key);
  // Add header label at first row
  csvRows.push(headerLabels.join(','));
  // Create CSV Data
  if (Array.isArray(data)) {
    for (const row of data) {
      const values = keyValues.map(key => {
        const value = row[key];
        return value;
      });
      csvRows.push(values.join(','));
    }
  }
  return csvRows.join('\n');
};

/**
 * Get data for performance score
 */
const getDataPerformanceScore = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  let data = await fetchDataPerformanceScore({
    floorId: floorId,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
    deviceIdList: deviceIdList,
  });
  data = data || [];
  return { data };
};

const getCsvDataPerformanceScore = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  const { data } = await getDataPerformanceScore({
    fromDateTime,
    toDateTime,
    deviceIdList,
    floorId,
  });
  const csvData = getCsvData({ data, fields: PERFORMANCE_SCORE_CSV_FIELDS });
  return { csvData: csvData };
};

/**
 * Get data for wireless signal
 */
const getDataWirelessSignal = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  let data = await fetchDataWirelessSignal({
    floorId: floorId,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
    deviceIdList: deviceIdList,
  });
  data = data || [];
  return { data };
};

const getCsvDataWirelessSignal = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  const { data } = await getDataWirelessSignal({
    fromDateTime,
    toDateTime,
    deviceIdList,
    floorId,
  });
  const csvData = getCsvData({ data, fields: WIRELESS_SIGNAL_CSV_FIELDS });
  return { csvData: csvData };
};

/**
 * Get data for network client count
 */
const getDataNetworkClientCount = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  let data = await fetchDataNetworkClientCount({
    floorId: floorId,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
    deviceIdList: deviceIdList,
  });
  data = data || [];
  return { data };
};

const getCsvDataNetworkClientCount = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  const { data } = await getDataNetworkClientCount({
    fromDateTime,
    toDateTime,
    deviceIdList,
    floorId,
  });
  const csvData = getCsvData({ data, fields: NETWORK_CLIENT_COUNT_CSV_FIELDS });
  return { csvData: csvData };
};

/**
 * Get data for network usage
 */
const getDataNetworkUsage = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  let data = await fetchDataNetworkUsage({
    floorId: floorId,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
    deviceIdList: deviceIdList,
  });
  data = data || [];
  return { data };
};

const getCsvDataNetworkUsage = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  const { data } = await getDataNetworkUsage({
    fromDateTime,
    toDateTime,
    deviceIdList,
    floorId,
  });
  const csvData = getCsvData({ data, fields: NETWORK_USAGE_CSV_FIELDS });
  return { csvData: csvData };
};

/**
 * Get data for wireless latency
 */
const getDataWirelessLatency = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  let data = await fetchDataWirelessLatency({
    floorId: floorId,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
    deviceIdList: deviceIdList,
  });
  data = data || [];
  return { data };
};

const getCsvDataWirelessLatency = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  const { data } = await getDataWirelessLatency({
    fromDateTime,
    toDateTime,
    deviceIdList,
    floorId,
  });
  const csvData = getCsvData({ data, fields: WIRELESS_LATENCY_CSV_FIELDS });
  return { csvData: csvData };
};

/*
 * Get data for wireless channel
 */
const getDataWirelessChannel = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
  channelType,
}) => {
  let data = await fetchDataChannelUtilization({
    floorId: floorId,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
    deviceIdList: deviceIdList,
    channelType: channelType,
  });
  data = data || [];
  return { data };
};

const getCsvDataWirelessChannel = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
  channelType,
}) => {
  const { data } = await getDataWirelessChannel({
    fromDateTime,
    toDateTime,
    deviceIdList,
    floorId,
    channelType,
  });
  const csvData = getCsvData({
    data,
    fields: WIRELESS_CHANNEL_UTILIZATION_CSV_FIELDS,
  });
  return { csvData: csvData };
};

/**
 * Get data for meeting room outlook table
 */
const getDataMeetingRoomOutlookTable = async ({
  fromDateTime,
  toDateTime,
  floorId,
}) => {
  let data = await fetchDataMeetingRoomOutlookTable({
    floorId: floorId,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
  });
  data = data || [];
  return { data };
};

const getCsvDataMeetingRoomOutlookTable = async ({
  fromDateTime,
  toDateTime,
  floorId,
}) => {
  const { data } = await getDataMeetingRoomOutlookTable({
    fromDateTime,
    toDateTime,
    floorId,
  });
  const csvData = getCsvData({
    data,
    fields: MEETING_ROOM_OUTLOOK_TABLE_CSV_FIELDS,
  });
  return { csvData: csvData };
};

/**
 * Get data for sensor data history
 */
const getDataSensorHistory = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  let data = await fetchDataSensorHistory({
    floorId: floorId,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
    deviceIdList: deviceIdList,
  });
  data = data || [];
  return { data };
};

const getCsvDataSensorHistory = async ({
  fromDateTime,
  toDateTime,
  deviceIdList,
  floorId,
}) => {
  const { data } = await getDataSensorHistory({
    fromDateTime,
    toDateTime,
    deviceIdList,
    floorId,
  });
  const csvData = getCsvData({ data, fields: SENSOR_DATA_HISTORY_CSV_FIELDS });
  return { csvData: csvData };
};

/**
 * Get data for people count analysis
 */
const getDataPeopleCountAnalysis = async ({
  fromDateTime,
  toDateTime,
  areaIdList,
  floorId,
}) => {
  let data = await fetchDataPeopleCountAnalysis({
    floorId: floorId,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
    areaIdList: areaIdList,
  });
  data = data || [];
  return { data };
};

const getCsvDataPeopleCountAnalysis = async ({
  fromDateTime,
  toDateTime,
  areaIdList,
  floorId,
  type,
}) => {
  const { data } = await getDataPeopleCountAnalysis({
    fromDateTime,
    toDateTime,
    areaIdList,
    floorId,
  });
  let csvData = '';
  if (type === PEOPLE_COUNT_ANALYSIS_TYPE.COUNTS) {
    csvData = getCsvData({
      data,
      fields: PEOPLE_COUNT_ANALYSIS_CSV_FIELDS_COUNTS,
    });
  } else if (type === PEOPLE_COUNT_ANALYSIS_TYPE.CAPACITY_RATE) {
    csvData = getCsvData({
      data,
      fields: PEOPLE_COUNT_ANALYSIS_CSV_FIELDS_CAPACITY_RATE,
    });
  }
  return { csvData: csvData };
};

export {
  getCsvDataMeetingRoomOutlookTable,
  getCsvDataNetworkClientCount,
  getCsvDataNetworkUsage,
  getCsvDataPeopleCountAnalysis,
  getCsvDataPerformanceScore,
  getCsvDataSensorHistory,
  getCsvDataWirelessChannel,
  getCsvDataWirelessLatency,
  getCsvDataWirelessSignal,
};
