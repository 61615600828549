import { COMMON_TEXT } from '@/helpers/common-text';
import {
  COMPONENT_ID_GROUP,
  SIDEBAR_CATEGORY
} from '@/helpers/constants';
// eslint-disable-next-line no-unused-vars

// https://react-icons.github.io/react-icons/icons?name=ri
// 1	employee_locations
// 2	room_availabilities
// 3	people_counts
// 4	performances
// 5	environments
// 6	meeting_rooms
// 7	deivce_status
// 8	meraki_logging
// 9	verkada_logging
// 10	employee_managements
// 11	people_counts_analysis
// 12 employee_attendance
// 13	SIGNAGE_COMPONENTS

export const router = [
  {
    icon: '',
    url: '/employee-locations',
    text: COMMON_TEXT.EMPLOYEE_LOCATIONS,
    category: SIDEBAR_CATEGORY.COMMUNICATION,
    componentIdGroup: COMPONENT_ID_GROUP.EMPLOYEE_LOCATIONS,
  },
  {
    icon: '',
    url: '/meeting-room-usage',
    text: COMMON_TEXT.MEETING_ROOM_USAGE,
    category: SIDEBAR_CATEGORY.COMMUNICATION,
    componentIdGroup: COMPONENT_ID_GROUP.ROOM_AVAILABILITIES,
  },
  {
    icon: '',
    url: '/people-counts',
    text: COMMON_TEXT.PEOPLE_COUNTS,
    category: SIDEBAR_CATEGORY.COMMUNICATION,
    componentIdGroup: COMPONENT_ID_GROUP.PEOPLE_COUNTS,
  },
  {
    icon: '',
    url: '/employee-attendance',
    text: COMMON_TEXT.EMPLOYEE_ATTENDANCE_RATE,
    category: SIDEBAR_CATEGORY.COMMUNICATION,
    componentIdGroup: COMPONENT_ID_GROUP.EMPLOYEE_ATTENDANCE,
  },
  {
    icon: '',
    url: '/performances',
    text: COMMON_TEXT.NETWORK,
    category: SIDEBAR_CATEGORY.FACILITY,
    componentIdGroup: COMPONENT_ID_GROUP.PERFORMANCES,
  },
  {
    icon: '',
    url: '/air-quality',
    text: COMMON_TEXT.AIR_QUALITY,
    category: SIDEBAR_CATEGORY.FACILITY,
    componentIdGroup: COMPONENT_ID_GROUP.ENVIRONMENTS,
  },
  {
    icon: '',
    url: '/meeting-room-outlook-usage',
    text: COMMON_TEXT.MEETING_ROOM_OUTLOOK_USAGE,
    category: SIDEBAR_CATEGORY.FACILITY,
    componentIdGroup: COMPONENT_ID_GROUP.MEETING_ROOMS,
  },
  {
    icon: '',
    url: '/people-counts-analysis',
    text: COMMON_TEXT.PEOPLE_COUNTS_ANALYSIS,
    category: SIDEBAR_CATEGORY.FACILITY,
    componentIdGroup: COMPONENT_ID_GROUP.PEOPLE_COUNTS_ANALYSIS,
  },
  {
    icon: '',
    url: '/device-status-overview',
    text: COMMON_TEXT.DEVICE_STATUS_OVERVIEW,
    category: SIDEBAR_CATEGORY.FACILITY,
    componentIdGroup: COMPONENT_ID_GROUP.DEVICE_STATUS,
  },
  {
    icon: '',
    url: '/meraki-network-events',
    text: COMMON_TEXT.MERAKI_NETWORK_EVENTS,
    category: SIDEBAR_CATEGORY.FACILITY,
    componentIdGroup: COMPONENT_ID_GROUP.MERAKI_LOGGING,
  },
  {
    icon: '',
    url: '/verkada-network-events',
    text: COMMON_TEXT.VERKADA_NETWORK_EVENTS,
    category: SIDEBAR_CATEGORY.FACILITY,
    componentIdGroup: COMPONENT_ID_GROUP.VERKADA_LOGGING,
  },
  {
    icon: '',
    url: '/employee-access-log',
    text: COMMON_TEXT.EMPLOYEE_ACCESS_LOG,
    category: SIDEBAR_CATEGORY.LABOR,
    componentIdGroup: COMPONENT_ID_GROUP.EMPLOYEE_MANAGEMENTS,
  },
  {
    icon: '',
    url: '/configuration',
    text: COMMON_TEXT.DATA_CONFIGURATION,
    category: SIDEBAR_CATEGORY.ORGANIZATION,
    componentIdGroup: COMPONENT_ID_GROUP.CONFIGURATION,
  },
  {
    icon: '',
    url: '/signage-components',
    text: COMMON_TEXT.SIGNAGE_MODE,
    category: SIDEBAR_CATEGORY.ORGANIZATION,
    componentIdGroup: COMPONENT_ID_GROUP.SIGNAGE_COMPONENTS,
  },
  // {
  //   icon: '',
  //   url: '/account',
  //   text: COMMON_TEXT.ACCOUNT,
  //   category: SIDEBAR_CATEGORY.ORGANIZATION,
  //   componentIdGroup: 999,
  // },
  // {
  //   icon: '',
  //   url: '/summary',
  //   text: COMMON_TEXT.SUMMARY,
  //   category: SIDEBAR_CATEGORY.ORGANIZATION,
  //   componentIdGroup: 999,
  // },
];
