import CustomBarChart from '@/components/CustomComponent/CustomBarChart';
import CustomDropdown from '@/components/CustomComponent/CustomDropdown/CustomDropdown';
import CustomLineChart from '@/components/CustomComponent/CustomLineChart';
import { COMMON_TEXT } from '@/helpers/common-text';
import { LINE_CHART_COLOR_LIST, QUERY_STRING_STORE } from '@/helpers/constants';
import { compareAsc, format, sub } from 'date-fns';
import debounce from 'lodash/debounce';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  convertDecimalToPercentage,
  dayTickListFromToday,
  getRandomHexColor,
} from 'src/helpers/utility';
import { fetchDataBarChart, fetchDataLineChart } from './query-request';

@connect(state => ({
  querystring: state.querystring,
}))
class MeetingRoomOutlookBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartData: {
        data: [],
        xAxisDataKey: 'room_name',
      },
      lineChartData: {
        xAxisDataKey: 'date',
        displayItems: [],
      },
      barChartType: 'weekly', //[weekly, monthly]
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoadingBarChart: false,
      isLoadingLineChart: false,
      selectedLineList: null,
      lineOptions: [],
      deviceInfoMap: {},
    };
    this.barChartDropDownOptions = [
      { label: COMMON_TEXT.PAST_ONE_WEEK, value: 'weekly' },
      { label: COMMON_TEXT.PAST_ONE_MONTH, value: 'monthly' },
    ];
  }

  componentDidMount() {
    const { floor } = this.state;
    if (floor) {
      this.getBarChartData();
    }
  }

  componentDidUpdate(prevProps) {
    const floor =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const prevFloor =
      prevProps?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const { selectedLimit } = this.props;
    if (floor !== prevFloor || selectedLimit !== prevProps.selectedLimit) {
      this.setState({ floor }, () => {
        this.getBarChartData();
      });
    }
  }

  extractDeviceInfo(data) {
    const deviceIdentifiers = new Set();
    const idSet = new Set();

    data.forEach(obj => {
      const { device_identifer, room_name } = obj;
      if (!idSet.has(device_identifer)) {
        idSet.add(device_identifer);
        deviceIdentifiers.add({ id: device_identifer, name: room_name });
      }
    });
    const arr = Array.from(deviceIdentifiers);
    for (let i = 0; i < arr.length; i++) {
      const color = LINE_CHART_COLOR_LIST[i];
      arr[i]['fillColor'] = color ?? getRandomHexColor();
    }
    return arr.reduce((acc, obj) => {
      const { id, name, fillColor } = obj;
      acc[id] = { id, name, fillColor };
      return acc;
    }, {});
  }

  getBarChartData = async () => {
    this.setState({ isLoadingBarChart: true });
    const { barChartData, floor, barChartType } = this.state;
    const { selectedLimit } = this.props;
    let res = await fetchDataBarChart({
      floorId: floor,
      limit: selectedLimit,
      type: barChartType,
    });

    let deviceInfoMap = {};
    if (res && res.length > 0) {
      deviceInfoMap = this.extractDeviceInfo(res);
      barChartData.data = res
        .map(item => ({
          ...item,
          reserved_rate: convertDecimalToPercentage(item.reserved_rate),
          fillColor: deviceInfoMap[item.device_identifer]['fillColor'],
        }))
        .sort((a, b) => b.reserved_rate - a.reserved_rate);
    }

    this.setState(
      {
        barChartData,
        deviceInfoMap,
        isLoadingBarChart: false,
      },
      () => {
        this.getLineChartData();
      }
    );
  };

  getLineChartData = async () => {
    const {
      lineChartData,
      floor,
      deviceInfoMap,
      barChartType,
      selectedLineList,
    } = this.state;
    let fromDate = null;
    const currentDate = new Date();
    if (barChartType === 'weekly') {
      fromDate = sub(currentDate, { weeks: 1 });
    } else {
      fromDate = sub(currentDate, { months: 1 });
    }
    this.setState({ isLoadingLineChart: true });
    let deviceIdList = [];
    if (selectedLineList !== null) {
      deviceIdList = selectedLineList.map(item => item.code);
    } else {
      deviceIdList = Object.keys(deviceInfoMap);
    }
    let res = await fetchDataLineChart({
      floorId: floor,
      deviceIdList,
      fromDate: format(fromDate, 'yyyy-MM-dd'),
      toDate: format(currentDate, 'yyyy-MM-dd'),
    });
    const dayTickList = dayTickListFromToday({
      isWeekly: barChartType === 'weekly',
      isMonthly: barChartType === 'monthly',
    });
    const displayItems = [];
    if (res && res.length > 0) {
      for (const key in deviceInfoMap) {
        if (Object.hasOwnProperty.call(deviceInfoMap, key)) {
          const deviceId = deviceInfoMap[key]['id'];
          const roomName = deviceInfoMap[key]['name'];
          const filteredData = res.filter(
            item => item.device_identifer === deviceId
          );
          const dateSet = new Set(filteredData.map(item => item.date));
          const missingDateSet = Array.from(
            new Set(dayTickList.filter(item => !dateSet.has(item)))
          );
          let data = filteredData
            .map(item => ({
              date: item.date,
              reserved_rate: convertDecimalToPercentage(item['reserved_rate']),
            }))
            .concat(
              missingDateSet.map(item => ({
                date: item,
                reserved_rate: null,
              })) ?? []
            );
          data = data.sort((a, b) =>
            compareAsc(new Date(a.date), new Date(b.date))
          );

          displayItems.push({
            name: roomName,
            deviceId: deviceId,
            data: data,
            fillColor: deviceInfoMap[key]['fillColor'],
          });
        }
      }
    }

    const newLineChartData = {
      ...lineChartData,
      displayItems: displayItems,
    };

    if (selectedLineList === null) {
      // Set default selected line list
      // Update line multiselect options
      const newLineOptions = displayItems
        .map(item => ({
          name: item?.name?.toString().toUpperCase(),
          code: item?.deviceId,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      // this.allDeviceLineOptions = cloneDeep(newLineOptions);
      this.setState({
        lineChartData: newLineChartData,
        isLoadingLineChart: false,
        lineOptions: newLineOptions,
        selectedLineList: newLineOptions,
      });
    } else {
      this.setState({
        lineChartData: newLineChartData,
        isLoadingLineChart: false,
      });
    }
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderBarChart(barChartData) {
    const { data = [], xAxisDataKey } = barChartData;
    const { selectedLineList, isLoadingBarChart } = this.state;
    const filteredData = data.filter(item =>
      selectedLineList?.some(
        line =>
          line.code?.toLowerCase() === item['device_identifer']?.toLowerCase()
      )
    );
    return (
      <CustomBarChart
        data={filteredData}
        xAxisDataKey={xAxisDataKey}
        yAxisDataKey={'reserved_rate'}
        yAxisUnit={'%'}
        yAxisLabel={`予約率`}
        isLoading={isLoadingBarChart}
      />
    );
  }

  renderLineChart(lineChartData) {
    const { displayItems = [] } = lineChartData;
    const { selectedLineList, isLoadingLineChart } = this.state;
    const filteredDisplayItems = displayItems.filter(item =>
      selectedLineList.some(
        line => line.code?.toLowerCase() === item.deviceId?.toLowerCase()
      )
    );

    return (
      <CustomLineChart
        data={filteredDisplayItems}
        xAxisDataKey={'date'}
        yAxisDataKey={'reserved_rate'}
        yAxisLabel={`予約率`}
        isLoading={isLoadingLineChart}
      />
    );
  }

  barChartTypeDropDownOptionsOnChange = debounce(e => {
    const newValue = e.value;
    this.setState({ barChartType: newValue }, () => {
      const { onBarChartTypeChange } = this.props;
      onBarChartTypeChange(newValue);
      this.getBarChartData();
    });
  }, 300);

  renderBarChartTypeDropdown() {
    const { barChartType, isLoadingBarChart, isLoadingLineChart } = this.state;
    return (
      <CustomDropdown
        label="過去"
        disabled={isLoadingBarChart || isLoadingLineChart}
        value={barChartType}
        options={this.barChartDropDownOptions}
        onChange={this.barChartTypeDropDownOptionsOnChange}
        className=""
        style={{ width: '140px' }}
      ></CustomDropdown>
    );
  }

  multiselectLineChartOnChanged = e => {
    this.setState({ selectedLineList: e.value });
  };

  renderMultiSelectLineChart = () => {
    const { selectedLineList, lineOptions } = this.state;

    const itemTemplate = option => {
      return (
        <div className="flex align-items-center">
          <div
            style={{
              backgroundColor: option.fillColor,
              padding: '0.125rem 0.5rem',
              borderRadius: '4px',
            }}
          >
            {option.name}
          </div>
        </div>
      );
    };
    const footerTemplate = () => {
      const length = selectedLineList ? selectedLineList.length : 0;

      return (
        <div className="py-2 px-3">
          <span>
            <b>{length}</b> アイテムが選択されました
          </span>
        </div>
      );
    };
    return (
      <MultiSelect
        value={selectedLineList}
        options={lineOptions}
        disabled={lineOptions.length === 0}
        onChange={this.multiselectLineChartOnChanged}
        optionLabel="name"
        placeholder={COMMON_TEXT.SELECT}
        itemTemplate={itemTemplate}
        panelFooterTemplate={footerTemplate}
        className="mr-2"
        display="chip"
        style={{ minWidth: '220px', maxWidth: '420px', width: '100%' }}
      />
    );
  };

  render() {
    let { barChartData, lineChartData } = this.state;

    return (
      <div className="network-container meeting-room-outlook-booking mb-0 pb-0">
        <div className="network-title-container">
          <div className="text-left">
            <div>{COMMON_TEXT.MEETING_ROOM_OUTLOOK_BOOKING}</div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end"></div>
        </div>
        <div className="network-tool-container">
          <div className="col-fixed text-left grid grid-nogutter align-items-center">
            <b>{COMMON_TEXT.RESERVATION_TIME_BY_ROOM}</b>
          </div>
          <div className="col grid grid-nogutter align-items-center justify-content-end">
            {this.renderMultiSelectLineChart()}
            {this.renderBarChartTypeDropdown()}
          </div>
        </div>
        <div className="network-content">
          {this.renderMessageError() || (
            <>
              <div className="data-container">
                <div className="data-container__item">
                  <div className="title-chart-container">
                    <div className="title-chart"></div>
                    <div className="title-chart__right"></div>
                  </div>
                  <div
                    className="chart-container"
                    style={{ minHeight: '300px' }}
                  >
                    {this.renderBarChart(barChartData)}
                  </div>
                </div>
                <div className="data-container__item">
                  <div className="title-chart-container">
                    <div className="title-chart"></div>
                    <div className="title-chart__right"></div>
                  </div>
                  <div
                    className="chart-container"
                    style={{ minHeight: '300px' }}
                  >
                    {this.renderLineChart(lineChartData)}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

MeetingRoomOutlookBooking.propTypes = {};

export default MeetingRoomOutlookBooking;
