import AreaMarker from '@/components/CustomComponent/AreaMarker';
import CustomDropdown from '@/components/CustomComponent/CustomDropdown/CustomDropdown';
import DeviceMarker from '@/components/CustomComponent/DeviceMarker';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ImageAreaMarker from '@/components/CustomComponent/ReactImageAreaMarker';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  DEVICE_CATEGORY,
  EXECUTION_ID_TIMEOUT,
  MEMORY_STORE,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import {
  calculateRectangle,
  generateRandomString,
  getConfigurationUrlWithParam,
  transformCoordinateBottomLeftToTopLeft,
  transformPositionToMarkerCoordinate,
  transformRectangleToAreaPosition,
} from '@/helpers/utility';
import AuthToken from '@/services/auth-token';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { cloneDeep, isEqual } from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchAreasByFloor,
  fetchBranchListByTenent,
  fetchButlrDevicesByTenantId,
  fetchDevicePositionByFloorId,
  fetchFloorListByBranchIdList,
  fetchMerakiDevicesByTenantIdAndModel,
  fetchVerkadaDevicesByFloorAndType,
} from './query-request';

const UPDATE_AREA_STATUS = {
  DELETED: 'DELETED',
  MODIFIED: 'MODIFIED',
  NEW: 'NEW',
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class AreaSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],

      isLoading: false,
      isUpdating: false,
      branchList: [],
      floorList: [],
      verkadaDevicesList: [],
      merakiDevicesList: [],
      butlrDevicesList: [],
      areaList: [],
      isLoadingDevice: false,
      deviceMarkerPositionList: [],
      selectedFloorBranch: null,
      floorMapUrl: '',
      floorMapError: false,
      isFloorMapLoading: false,
      deviceAreaTabIndex: 0,
      isUpdateDialogVisible: false,
      updateDataPayload: { values: [] },
      selectedArea: null,
      isDeviceAreaDialogVisible: false,
      showCameraMarkerCategories: [],
      selectedDeviceCategory: DEVICE_CATEGORY.MERAKI,
    };
    this.toastRef = React.createRef();
    this.areaListStateMap = {};
    this.authToken = new AuthToken();
    this.originData = {
      // key = floor_id, data is verkadaDevicesList: [],
      // merakiDevicesList: [],
      // areaList: [],
      // deviceMarkerPositionList: [],
    }; // to store the original device list
    this.orgSelectedVerkadaDevicesList = [];
    this.orgSelectedMerakiDevicesList = [];
    this.orgSelectedButlrDevicesList = [];
    this.payloadTableData = {
      // key = floor_id, data is newAreaList, deletedAreaList, modifiedAreaList
    };
    this.deviceCategoryOptions = [
      { label: 'Meraki', value: DEVICE_CATEGORY.MERAKI },
      { label: 'Verkada', value: DEVICE_CATEGORY.VERKADA },
      { label: 'Butlr', value: DEVICE_CATEGORY.BUTLR },
    ];
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.setState({ deviceList: [] });
        this.getData();
      });
    }
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getData = async () => {
    this.setState({
      isLoading: true,
      isFloorMapLoading: true,
      isLoadingDevice: true,
    });
    await fetchBranchListByTenent(this.state.tenant).then(branchList => {
      if (branchList && branchList.length > 0) {
        this.setState({ branchList });
        const branchIdList = this.state.branchList.map(
          branch => branch.branch_id
        );
        fetchFloorListByBranchIdList(branchIdList).then(floorList => {
          const sortedFloorListByBranchOrder = floorList.sort(
            (a, b) =>
              branchIdList.indexOf(a.branch_id) -
              branchIdList.indexOf(b.branch_id)
          );
          if (floorList && floorList.length > 0) {
            this.setState(
              {
                floorList: sortedFloorListByBranchOrder,
                selectedFloorBranch: sortedFloorListByBranchOrder[0],
              },
              () => {
                this.getFloorMapUrl(this.state.selectedFloorBranch.floor_id);
                this.getDeviceList({
                  selectedFloorBranch: this.state.selectedFloorBranch,
                  selectedTenantId: this.state.tenant,
                });
              }
            );
          }
        });
      }
    });

    this.setState({
      isLoading: false,
    });
  };

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    this.validateFloorMapUrl(
      floor?.floor_map,
      () => {
        this.setState({
          floorMapUrl: floor?.floor_map,
          floorMapError: false,
          isFloorMapLoading: false,
        });
      },
      () => {
        this.setState({
          floorMapUrl: '',
          floorMapError: false,
          isFloorMapLoading: false,
        });
      }
    );
  };

  validateFloorMapUrl(url, successCallback, errorCallback) {
    var img = new Image();
    img.onload = function () {
      successCallback(url);
    };
    img.onerror = function () {
      errorCallback(url);
    };
    img.src = url;
  }

  getDeviceList = async ({ selectedFloorBranch, selectedTenantId }) => {
    this.setState({ isLoadingDevice: true });
    const floorId = selectedFloorBranch.floor_id;
    const areaListState = this.retrieveAreaListStateByFloorId({
      floorId,
    });
    let verkadaDevicesList = [];
    let merakiDevicesList = [];
    let butlrDevicesList = [];
    let deviceMarkerPositionList = [];
    let areaList = [];
    if (areaListState) {
      verkadaDevicesList = cloneDeep(areaListState.verkadaDevicesList);
      merakiDevicesList = cloneDeep(areaListState.merakiDevicesList);
      butlrDevicesList = cloneDeep(areaListState.butlrDevicesList);
      areaList = cloneDeep(areaListState.areaList);
      deviceMarkerPositionList = cloneDeep(
        areaListState.deviceMarkerPositionList
      );
    } else {
      // Fetch device positions
      const devicePositions = await fetchDevicePositionByFloorId(floorId);
      // Fetch verkada devices
      const verkadaList = await this.getVerkadaDevicesListByFloorId(
        floorId,
        devicePositions
      );
      // Fetch meraki devices
      const merakiList = await this.getMerakiDevicesList({
        tenantId: selectedTenantId,
        devicePositions,
      });
      // Fetch butlr devices
      const butlrList = await this.getButlrDevicesList(
        selectedTenantId,
        devicePositions
      );

      // Fetch area list
      const areaListResponse = await this.getAreaList({ floorId });
      // Calculate area marker position
      for (const area of areaListResponse) {
        const { position_x, position_y, position_x1, position_y1 } = area;
        const { pointC, width, height } = calculateRectangle({
          positionX: position_x,
          positionY: position_y,
          positionX1: position_x1,
          positionY1: position_y1,
        });
        const { x, y } = transformCoordinateBottomLeftToTopLeft({
          x: pointC.x,
          y: pointC.y,
        });
        area.rect = {
          top: y,
          left: x,
          width,
          height,
        };
      }

      verkadaDevicesList = cloneDeep(verkadaList);
      merakiDevicesList = cloneDeep(merakiList);
      butlrDevicesList = cloneDeep(butlrList);
      deviceMarkerPositionList.push(
        ...this.setDevicePositionToMap(merakiDevicesList)
      );
      deviceMarkerPositionList.push(
        ...this.setDevicePositionToMap(verkadaDevicesList)
      );
      deviceMarkerPositionList.push(
        ...this.setDevicePositionToMap(butlrDevicesList)
      );

      const deviceMarkerPositionMapByAreaId = {};
      deviceMarkerPositionList.forEach((deviceMarkerItem, index) => {
        const { device } = deviceMarkerItem;
        const { area_id } = device;
        if (area_id) {
          if (!deviceMarkerPositionMapByAreaId[area_id]) {
            deviceMarkerPositionMapByAreaId[area_id] = [];
          }
          deviceMarkerPositionMapByAreaId[area_id].push(device);
        }
      });

      areaListResponse.forEach(area => {
        const { area_id } = area;
        if (deviceMarkerPositionMapByAreaId[area_id]) {
          area.device_positions = deviceMarkerPositionMapByAreaId[area_id];
        } else {
          area.device_positions = [];
        }
      });

      areaList = cloneDeep(areaListResponse);

      // Save to areaListStateMap
      this.areaListStateMap[floorId] = {
        deviceMarkerPositionList,
        merakiDevicesList,
        verkadaDevicesList,
        butlrDevicesList,
        areaList,
      };

      // Save original data
      this.originData[+floorId] = {
        verkadaDevicesList: cloneDeep(verkadaDevicesList),
        merakiDevicesList: cloneDeep(merakiDevicesList),
        butlrDevicesList: cloneDeep(butlrDevicesList),
        areaList: cloneDeep(areaListResponse),
        deviceMarkerPositionList: cloneDeep(deviceMarkerPositionList),
      };
    }
    this.setState({
      verkadaDevicesList,
      merakiDevicesList,
      deviceMarkerPositionList,
      butlrDevicesList,
      areaList,
      isLoadingDevice: false,
    });
  };

  setDevicePositionToMap = deviceList => {
    const copyDeviceList = cloneDeep(deviceList);
    const markers = [];
    copyDeviceList.forEach(device => {
      const { hasPosition, position_x, position_y } = device;
      if (hasPosition) {
        const { top, left } = transformPositionToMarkerCoordinate({
          x: position_x,
          y: position_y,
        });
        markers.push({
          top,
          left,
          isDelete: false,
          device,
        });
      }
    });
    return markers;
  };

  getVerkadaDevicesListByFloorId = async (floorId, devicePositions) => {
    const devicesRes = await fetchVerkadaDevicesByFloorAndType({
      floorId,
      deviceType: 'camera',
    });
    const deviceList = devicesRes
      .sort((a, b) => a.device_name?.localeCompare(b.device_name))
      .map(device => {
        let devicePosition = devicePositions.filter(
          deviceposition =>
            deviceposition.device_identifer === device.device_identifer
        );
        if (devicePosition && devicePosition.length > 0) {
          const position = cloneDeep(devicePosition[0]);
          return {
            hasPosition: true,
            deviceCategory: DEVICE_CATEGORY.VERKADA,
            ...position,
            ...device,
          };
        } else {
          const position = { position_x: null, position_y: null };
          return {
            hasPosition: false,
            deviceCategory: DEVICE_CATEGORY.VERKADA,
            ...position,
            ...device,
          };
        }
      });
    return deviceList;
  };

  getMerakiDevicesList = async ({ tenantId, devicePositions }) => {
    const merakiDevices = await fetchMerakiDevicesByTenantIdAndModel({
      tenantId: tenantId,
      model: 'MV',
    });
    const deviceList = merakiDevices
      .sort((a, b) => a.device_name?.localeCompare(b.device_name))
      .map(device => {
        let devicePosition = devicePositions.filter(
          deviceposition =>
            deviceposition.device_identifer === device.device_identifer
        );
        if (devicePosition && devicePosition.length > 0) {
          const position = cloneDeep(devicePosition[0]);
          return {
            hasPosition: true,
            deviceCategory: DEVICE_CATEGORY.MERAKI,
            ...position,
            ...device,
          };
        } else {
          const position = { position_x: null, position_y: null };
          return {
            hasPosition: false,
            deviceCategory: DEVICE_CATEGORY.MERAKI,
            ...position,
            ...device,
          };
        }
      });
    return deviceList;
  };

  getButlrDevicesList = async (tenantId, devicePositions) => {
    const butlrDevices = await fetchButlrDevicesByTenantId({ tenantId });
    return butlrDevices
      .sort((a, b) => a.room_name?.localeCompare(b.room_name))
      .map(butlrDevice => {
        let devicePosition = devicePositions.filter(
          deviceposition =>
            deviceposition.device_identifer === butlrDevice.room_id
        );
        if (devicePosition && devicePosition.length > 0) {
          const position = cloneDeep(devicePosition[0]);
          return {
            hasPosition: true,
            deviceCategory: DEVICE_CATEGORY.BUTLR,
            ...position,
            device_name: butlrDevice.room_name,
            device_identifer: butlrDevice.room_id,
            ...butlrDevice,
          };
        } else {
          const position = { position_x: null, position_y: null };
          return {
            hasPosition: false,
            deviceCategory: DEVICE_CATEGORY.BUTLR,
            ...position,
            device_name: butlrDevice.room_name,
            device_identifer: butlrDevice.room_id,
            ...butlrDevice,
          };
        }
      });
  };

  getAreaList = async ({ floorId }) => {
    let areaList = await fetchAreasByFloor({ floorId });
    areaList = areaList || [];
    return areaList;
  };

  getBranchName = branchId => {
    if (branchId) {
      const branch = this.state.branchList.filter(
        branch => branch.branch_id === branchId
      );
      return branch[0].branch_name + ' - ';
    }
  };

  onChangeSelectedFloorAction = selectedFloorBranch => {
    this.updateMarkerPosition({
      floorId: this.state.selectedFloorBranch.floor_id,
      areaList: this.state.areaList,
      deviceMarkerPositionList: this.state.deviceMarkerPositionList,
      merakiDevicesList: this.state.merakiDevicesList,
      verkadaDevicesList: this.state.verkadaDevicesList,
    });
    this.setState({ selectedFloorBranch, isFloorMapLoading: true }, () => {
      this.getFloorMapUrl(selectedFloorBranch.floor_id);
      this.getDeviceList({
        selectedFloorBranch,
        selectedTenantId: this.state.tenant,
      });
    });
  };

  retrieveAreaListStateByFloorId = ({ floorId }) => {
    return this.areaListStateMap[floorId] || null;
  };

  updateMarkerPosition = ({
    floorId,
    areaList,
    deviceMarkerPositionList,
    merakiDevicesList,
    verkadaDevicesList,
    butlrDevicesList,
  }) => {
    floorId = +floorId;
    this.areaListStateMap[floorId] = {
      deviceMarkerPositionList: cloneDeep(deviceMarkerPositionList),
      merakiDevicesList: cloneDeep(merakiDevicesList),
      verkadaDevicesList: cloneDeep(verkadaDevicesList),
      butlrDevicesList: cloneDeep(butlrDevicesList),
      areaList: cloneDeep(areaList),
    };
  };

  updateButtonOnClicked = () => {
    const isAreaChanged = ({ orgArea, newArea }) => {
      const {
        area_name: orgAreaName,
        capacity_limit: orgCapacityLimit,
        device_positions: orgDevicePositions,
        rect: orgRect,
      } = orgArea;
      const {
        area_name: newAreaName,
        capacity_limit: newCapacityLimit,
        device_positions: newDevicePositions,
        newRect,
      } = newArea;
      if (
        orgAreaName !== newAreaName ||
        orgCapacityLimit !== newCapacityLimit ||
        !isEqual(orgDevicePositions, newDevicePositions)
      ) {
        return true;
      }
      if (newRect && !isEqual(orgRect, newRect)) {
        return true;
      }
      return false;
    };
    this.updateMarkerPosition({
      floorId: this.state.selectedFloorBranch.floor_id,
      areaList: this.state.areaList,
      deviceMarkerPositionList: this.state.deviceMarkerPositionList,
      merakiDevicesList: this.state.merakiDevicesList,
      verkadaDevicesList: this.state.verkadaDevicesList,
      butlrDevicesList: this.state.butlrDevicesList,
    });

    const updatePayload = {
      values: [],
    };
    for (const floorId in this.areaListStateMap) {
      const stateData = this.areaListStateMap[+floorId];
      if (!stateData) {
        continue;
      }
      const {
        areaList,
        merakiDevicesList,
        verkadaDevicesList,
        butlrDevicesList,
      } = stateData;
      const originData = this.originData[+floorId];
      const { areaList: originAreaList } = originData;

      const newAreaList = [];
      const deletedAreaList = [];
      const modifiedAreaList = [];

      for (const areaItem of areaList) {
        const area = cloneDeep(areaItem);

        // Update area rect
        if (area.newRect) {
          const newPosition = transformRectangleToAreaPosition(area.newRect);
          area.position_x = newPosition.position_x;
          area.position_y = newPosition.position_y;
          area.position_x1 = newPosition.position_x1;
          area.position_y1 = newPosition.position_y1;
        }
        // delete unused properties
        delete area.isEdit;

        const devicePositions = [];
        const filteredMerakiDevicesList = merakiDevicesList.filter(
          item => item.area_id === area.area_id
        );
        const filteredVerkadaDevicesList = verkadaDevicesList.filter(
          item => item.area_id === area.area_id
        );
        const filteredButlrDevicesList = butlrDevicesList.filter(
          item => item.area_id === area.area_id
        );
        const filteredDeviceList = [
          ...filteredMerakiDevicesList,
          ...filteredVerkadaDevicesList,
          ...filteredButlrDevicesList,
        ];

        // Update new device position value
        filteredDeviceList.forEach(device => {
          devicePositions.push(device);
        });
        area.device_positions = devicePositions;

        if (area.is_new && !area.is_deleted) {
          // process new area
          newAreaList.push(area);
        } else if (!area.is_new & area.is_deleted && area.position_x === null) {
          // process deleted area
          deletedAreaList.push(area);
        } else {
          // process modified area
          const orgArea = originAreaList.find(
            originArea => originArea.area_id === area.area_id
          );
          // check if area is modified
          if (orgArea && isAreaChanged({ orgArea, newArea: area })) {
            modifiedAreaList.push(area);
          }
        }
      }

      const areaValues = [];
      if (newAreaList.length > 0) {
        for (const area of newAreaList) {
          let devicePositions = [];
          if (area.device_positions) {
            devicePositions = area.device_positions.map(
              item => item.device_position_id
            );
          }
          areaValues.push({
            area_id: null,
            area_name: area.area_name,
            position_x: area.position_x,
            position_y: area.position_y,
            position_x1: area.position_x1,
            position_y1: area.position_y1,
            capacity_limit: area.capacity_limit,
            floor_id: area.floor_id,
            device_positions: devicePositions,
          });
        }
      }

      if (deletedAreaList.length > 0) {
        for (const area of deletedAreaList) {
          areaValues.push({
            area_id: area.area_id,
            position_x: null,
          });
        }
      }

      if (modifiedAreaList.length > 0) {
        for (const area of modifiedAreaList) {
          let devicePositions = [];
          if (area.device_positions) {
            devicePositions = area.device_positions.map(
              item => item.device_position_id
            );
          }
          areaValues.push({
            area_id: area.area_id,
            area_name: area.area_name,
            position_x: area.position_x,
            position_y: area.position_y,
            position_x1: area.position_x1,
            position_y1: area.position_y1,
            capacity_limit: area.capacity_limit,
            floor_id: area.floor_id,
            device_positions: devicePositions,
          });
        }
      }
      if (areaValues.length > 0) {
        updatePayload.values.push({
          floor_id: +floorId,
          areas: areaValues,
        });
        // Update data to be displayed in table dialog
        this.payloadTableData[+floorId] = {
          newAreaList,
          deletedAreaList,
          modifiedAreaList,
        };
      }
    }
    if (updatePayload.values.length > 0) {
      this.setState({
        isUpdateDialogVisible: true,
        updateDataPayload: updatePayload,
      });
    } else {
      this.toastRef.current.show({
        severity: 'info',
        summary: '情報',
        detail: COMMON_TEXT.NO_CHANGE_TO_BE_SAVED,
        life: 3000,
        closable: true,
      });
    }
  };

  saveMarkPosition = ({ updateDataPayload }) => {
    if (updateDataPayload.values.length > 0) {
      this.updateData({ updatePayLoad: updateDataPayload });
    }
  };

  updateAreaInfo = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.AREAS,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await this.updateAreaInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  renderAreaMarker = props => {
    return <AreaMarker {...props}></AreaMarker>;
  };

  renderMapMarker = props => {
    const { isDelete, device } = props;
    if (isDelete) {
      return <></>;
    }
    if (device) {
      const { device } = props;
      const randomTooltipString = generateRandomString(10);
      if (device) {
        const { device_name } = device;
        return (
          <>
            <Tooltip
              target={`.map-marker-id-${randomTooltipString}`}
              mouseTrack
              mouseTrackLeft={10}
            />
            <DeviceMarker
              deviceinfo={device}
              className={[
                `pi pi-map-marker map-marker-style map-marker-id-${randomTooltipString}`,
              ]}
              data-pr-tooltip={device_name}
              data-pr-classname="child-div-tooltip"
            />
          </>
        );
      }
    }
    return <></>;
  };

  renderDeviceList = ({ selectedArea }) => {
    const {
      merakiDevicesList,
      verkadaDevicesList,
      butlrDevicesList,
      selectedDeviceCategory,
    } = this.state;
    const addDeviceToArea = ({ device }) => {
      const { deviceCategory, device_identifer } = device;
      if (deviceCategory === DEVICE_CATEGORY.MERAKI) {
        const idx = merakiDevicesList.findIndex(
          item => item.device_identifer === device_identifer
        );
        if (idx > -1) {
          merakiDevicesList[idx].area_id = selectedArea.area_id;
          merakiDevicesList[idx].area_name = selectedArea.area_name;
        }
      } else if (deviceCategory === DEVICE_CATEGORY.VERKADA) {
        const idx = verkadaDevicesList.findIndex(
          item => item.device_identifer === device_identifer
        );
        if (idx > -1) {
          verkadaDevicesList[idx].area_id = selectedArea.area_id;
          verkadaDevicesList[idx].area_name = selectedArea.area_name;
        }
      } else if (deviceCategory === DEVICE_CATEGORY.BUTLR) {
        const idx = butlrDevicesList.findIndex(
          item => item.device_identifer === device_identifer
        );
        if (idx !== -1) {
          butlrDevicesList[idx].area_id = selectedArea.area_id;
          butlrDevicesList[idx].area_name = selectedArea.area_name;
        }
      }
      this.setState({
        merakiDevicesList,
        verkadaDevicesList,
        butlrDevicesList,
      });
    };
    const removeDeviceFromArea = ({ device }) => {
      const { deviceCategory, device_identifer } = device;
      if (deviceCategory === DEVICE_CATEGORY.MERAKI) {
        const foundIndex = merakiDevicesList.findIndex(
          item => item.device_identifer === device_identifer
        );
        if (foundIndex !== -1) {
          merakiDevicesList[foundIndex].area_id = null;
          merakiDevicesList[foundIndex].area_name = '';
        }
      } else if (deviceCategory === DEVICE_CATEGORY.VERKADA) {
        const foundIndex = verkadaDevicesList.findIndex(
          item => item.device_identifer === device_identifer
        );
        if (foundIndex !== -1) {
          verkadaDevicesList[foundIndex].area_id = null;
          verkadaDevicesList[foundIndex].area_name = '';
        }
      } else if (deviceCategory === DEVICE_CATEGORY.BUTLR) {
        const foundIndex = butlrDevicesList.findIndex(
          item => item.device_identifer === device_identifer
        );
        if (foundIndex !== -1) {
          butlrDevicesList[foundIndex].area_id = null;
          butlrDevicesList[foundIndex].area_name = '';
        }
      }
      this.setState({
        merakiDevicesList,
        verkadaDevicesList,
        butlrDevicesList,
      });
    };

    let deviceList = [];
    switch (selectedDeviceCategory) {
      case DEVICE_CATEGORY.MERAKI:
        deviceList = merakiDevicesList || [];
        break;
      case DEVICE_CATEGORY.VERKADA:
        deviceList = verkadaDevicesList || [];
        break;
      case DEVICE_CATEGORY.BUTLR:
        deviceList = butlrDevicesList || [];
        break;
      default:
        break;
    }

    deviceList = deviceList.filter(item => item.hasPosition);
    return deviceList.length > 0 ? (
      <>
        {deviceList.map((device, index) => (
          <div className="device-item" key={`device-item-${index}`}>
            <div className="device-label">
              <div
                className={`marker-button-container ${
                  !device.hasPosition ? 'not-set-position' : ''
                }`}
              >
                <div className="marker-button-icon">
                  <DeviceMarker deviceinfo={device} size={24} />
                </div>
                <div className={`ml-2 text-left`}>{device.device_name}</div>
              </div>
            </div>
            <div
              className={`device-zone ${
                selectedArea?.area_id === device?.area_id && 'same-area'
              }`}
            >
              {device.area_id ? device.area_name : '-'}
            </div>
            <div className="button-container">
              {device.area_id ? (
                <>
                  <Button
                    className="trash-button"
                    onClick={e => removeDeviceFromArea({ device })}
                    text
                    icon="pi pi-sync"
                    severity="danger"
                  ></Button>
                </>
              ) : (
                <>
                  <Button
                    className="add-area-button"
                    onClick={e => addDeviceToArea({ device })}
                    text
                    icon="pi pi-plus"
                    severity="info"
                  ></Button>
                </>
              )}
            </div>
          </div>
        ))}
      </>
    ) : (
      <div className="mt-2">{COMMON_TEXT.NO_DATA}</div>
    );
  };

  renderAreaList = () => {
    const { areaList, selectedFloorBranch } = this.state;
    const addNewArea = () => {
      const defaultPosition = {
        position_x: 0.4,
        position_y: 0.4,
        position_x1: 0.6,
        position_y1: 0.6,
      };
      const { pointC, width, height } = calculateRectangle({
        positionX: defaultPosition.position_x,
        positionY: defaultPosition.position_y,
        positionX1: defaultPosition.position_x1,
        positionY1: defaultPosition.position_y1,
      });
      const { x, y } = transformCoordinateBottomLeftToTopLeft({
        x: pointC.x,
        y: pointC.y,
      });
      const rect = {
        top: y,
        left: x,
        width,
        height,
      };
      const defaultArea = {
        area_id: `NEW_AREA_ID_${generateRandomString(6)}`,
        area_name: `area${
          areaList.length + 1 > 9
            ? areaList.length + 1
            : `0${areaList.length + 1}`
        }`,
        capacity_limit: 0,
        is_new: true,
        floor_id: selectedFloorBranch.floor_id,
        rect: rect,
        newRect: rect,
        device_positions: [],
        ...defaultPosition,
      };
      areaList.push(defaultArea);
      this.setState({ areaList });
    };

    const areaNameInputOnChange = (area, value) => {
      area.area_name = value;
      this.setState({});
    };

    const editAreaOnClicked = ({ area, index }) => {
      if (area.isEdit) {
        area.isEdit = false;
      } else {
        area.isEdit = true;
      }
      areaList[index] = area;
      this.setState({ areaList });
    };

    const areaNameOnClicked = area => {
      const { merakiDevicesList, verkadaDevicesList, butlrDevicesList } =
        this.state;
      this.orgSelectedVerkadaDevicesList = cloneDeep(verkadaDevicesList);
      this.orgSelectedMerakiDevicesList = cloneDeep(merakiDevicesList);
      this.orgSelectedButlrDevicesList = cloneDeep(butlrDevicesList);
      this.setState({
        selectedArea: cloneDeep(area),
        isDeviceAreaDialogVisible: true,
      });
    };

    const removeArea = ({ area, index }) => {
      area.position_x = null;
      area.is_deleted = true;
      areaList[index] = area;
      this.setState({ areaList });
    };

    const deleteAreaOnClicked = (area, index) => {
      confirmDialog({
        message: () => (
          <div className="delete-message-container" style={{ width: '100%' }}>
            <h5>このマーカーを地図から削除しますか？</h5>
            <div
              className="mt-4 flex align-items-center"
              style={{
                paddingBottom: '0.25rem',
              }}
            >
              <div style={{ width: '70px' }}>{`${COMMON_TEXT.NAME}`}</div>
              <div>
                <b>{`${area.area_name}`}</b>
              </div>
            </div>
          </div>
        ),
        header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
        icon: '',
        accept: () => {
          removeArea({ area, index });
        },
        reject: () => {},
        acceptClassName: 'p-button-danger',
        rejectClassName: 'p-button-secondary p-button-text',
        appendTo: 'self',
      });
    };

    const displayAreaList = areaList.filter(area => !area.is_deleted);

    return (
      <>
        {displayAreaList.length > 0 ? (
          <>
            {displayAreaList.map((area, index) => (
              <div className="area-item" key={`area-${index}`}>
                <div className="area-icon"></div>
                <div className="area-name">
                  {area.isEdit ? (
                    <InputText
                      value={area.area_name}
                      className="w-full"
                      onChange={e =>
                        areaNameInputOnChange(area, e.target.value)
                      }
                    />
                  ) : (
                    <Button
                      className="name-button"
                      onClick={e => areaNameOnClicked(area)}
                      text
                      label={
                        <span>
                          {area.area_name}
                          {area.is_new ? (
                            <span style={{ color: 'red' }}>*</span>
                          ) : (
                            <></>
                          )}
                        </span>
                      }
                      severity="secondary"
                    ></Button>
                  )}
                </div>
                <div className="area-button">
                  <Button
                    className="edit-button"
                    onClick={e => editAreaOnClicked({ area, index })}
                    text
                    icon={`pi ${area.isEdit ? 'pi-check' : 'pi-pencil'}`}
                    severity="warn"
                  ></Button>
                  {!area.isEdit && (
                    <Button
                      className="trash-button"
                      onClick={e => deleteAreaOnClicked(area, index)}
                      text
                      icon="pi pi-trash"
                      severity="danger"
                    ></Button>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mt-2 mb-2">{COMMON_TEXT.NO_DATA}</div>
        )}
        <div>
          <Button
            className="add-new-area-button"
            onClick={e => addNewArea()}
            text
            label={COMMON_TEXT.ADD_NEW}
            icon="pi pi-plus"
            severity="info"
          ></Button>
        </div>
      </>
    );
  };

  onAreaMarkersChange = ({ areaList: newAreaList }) => {
    const { areaList } = this.state;
    areaList.forEach((area, index) => {
      const newArea = newAreaList.find(item => item.area_id === area.area_id);
      if (newArea) {
        const { newRect } = newArea;
        if (newRect) {
          area.newRect = newRect;
        }
      }
    });
    this.setState({ areaList });
  };

  renderSettingContent() {
    const {
      isLoading,
      isUpdating,
      floorList,
      selectedFloorBranch,
      floorMapUrl,
      floorMapError,
      isFloorMapLoading,
      isLoadingDevice,
      deviceMarkerPositionList,
      areaList,
      showCameraMarkerCategories,
    } = this.state;

    const filteredAreaList = areaList.filter(area => !area.is_deleted);
    let markers = [...deviceMarkerPositionList];
    markers = markers.filter(marker => {
      const { device } = marker;
      if (device) {
        const { deviceCategory } = device;
        return showCameraMarkerCategories.includes(deviceCategory);
      }
      return false;
    });

    const isFloorMapError = !floorMapUrl || floorMapError;

    const markerItemTemplate = option => {
      const markerObj = {
        deviceCategory: option.value,
      };
      return (
        <div className="flex align-items-center justify-content-start column-gap-2">
          <DeviceMarker deviceinfo={markerObj} size={20} />
          <div>{option.label}</div>
        </div>
      );
    };

    return (
      <>
        <div className="setting-content">
          <div className="panel">
            <div className="floor-list-container">
              <div className="title-text">{`${COMMON_TEXT.BRANCH} - ${COMMON_TEXT.FLOOR}`}</div>
              <div className="floor-list">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  floorList.length > 0 &&
                  floorList.map((floor, index) => (
                    <div
                      key={`floor-item-index-${index}`}
                      className={`floor-item ${
                        selectedFloorBranch?.floor_id === floor.floor_id &&
                        'checked'
                      }`}
                    >
                      <RadioButton
                        inputId={floor.floor_id}
                        name="floor"
                        value={floor}
                        checked={
                          selectedFloorBranch?.floor_id === floor.floor_id
                        }
                        onChange={e =>
                          this.onChangeSelectedFloorAction(e.value)
                        }
                      ></RadioButton>
                      <label htmlFor={floor.floor_id} className="ml-2">
                        {this.getBranchName(floor.branch_id)}
                        {floor.floor_name}
                      </label>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="floor-map-container">
              <div className="title-text">
                <div className="marker-toggle-container">
                  <label>{COMMON_TEXT.DEVICE_DISPLAY}</label>
                  <MultiSelect
                    value={showCameraMarkerCategories}
                    onChange={e =>
                      this.setState({ showCameraMarkerCategories: e.value })
                    }
                    selectAllLabel="すべて表示"
                    itemTemplate={markerItemTemplate}
                    display="chip"
                    placeholder={COMMON_TEXT.SHOW_HIDE_CAMERA}
                    options={this.deviceCategoryOptions}
                    className="w-full"
                    panelClassName="marker-toggle-panel"
                  />
                </div>
              </div>
              <div className="floor-map">
                {isFloorMapLoading || isLoadingDevice ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    {!isFloorMapError && (
                      <ImageAreaMarker
                        bufferLeft={0}
                        bufferTop={0}
                        src={floorMapUrl}
                        markerComponent={this.renderMapMarker}
                        areaMarkerComponent={this.renderAreaMarker}
                        markers={isFloorMapError ? [] : markers}
                        areaMarkers={
                          isFloorMapError ? [] : filteredAreaList || []
                        }
                        onAreaMarkersChange={this.onAreaMarkersChange}
                        isDisableDragMarker={false}
                      />
                    )}
                    <>
                      {isFloorMapError && (
                        <Message
                          severity="error"
                          text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                        />
                      )}
                    </>
                  </>
                )}
              </div>
            </div>
            <div className="area-list-container">
              <div className="title-text">{COMMON_TEXT.AREA_SETTING}</div>
              <div className="area-list">
                {isLoadingDevice ? (
                  <LoadingSpinner />
                ) : (
                  <>{this.renderAreaList()}</>
                )}
              </div>
            </div>
          </div>
          <div className="button-container">
            <Button
              type="submit"
              severity="info"
              label="保存"
              loading={isUpdating}
              className="submit-button"
              onClick={this.updateButtonOnClicked}
            />
          </div>
        </div>
      </>
    );
  }

  renderUpdatePayloadTable = () => {
    const { floorList } = this.state;
    const tableValues = [];
    // loop through payloadTableData
    for (const floorId in this.payloadTableData) {
      let floorName = '';
      const floor = floorList.find(item => item.floor_id === +floorId);
      if (floor) {
        floorName = floor.floor_name;
      }
      const payloadValues = this.payloadTableData[+floorId];
      if (payloadValues) {
        const { newAreaList, deletedAreaList, modifiedAreaList } =
          payloadValues;
        newAreaList.forEach(item => {
          tableValues.push({
            floor_name: floorName,
            area_name: item.area_name,
            status: UPDATE_AREA_STATUS.NEW,
          });
        });
        deletedAreaList.forEach(item => {
          tableValues.push({
            floor_name: floorName,
            area_name: item.area_name,
            status: UPDATE_AREA_STATUS.DELETED,
          });
        });
        modifiedAreaList.forEach(item => {
          tableValues.push({
            floor_name: floorName,
            area_name: item.area_name,
            status: UPDATE_AREA_STATUS.MODIFIED,
          });
        });
      }
    }

    const statusBodyTemplate = rowData => {
      return (
        <Tag
          value={getTextStatus(rowData.status)}
          severity={getStatus(rowData.status)}
        />
      );
    };

    const getTextStatus = status => {
      switch (status) {
        case UPDATE_AREA_STATUS.DELETED:
          return COMMON_TEXT.STATUS_DELETED;

        case UPDATE_AREA_STATUS.MODIFIED:
          return COMMON_TEXT.STATUS_MODIFIED;

        case UPDATE_AREA_STATUS.NEW:
          return COMMON_TEXT.STATUS_NEW;

        default:
          return '';
      }
    };

    const getStatus = status => {
      switch (status) {
        case UPDATE_AREA_STATUS.DELETED:
          return 'danger';

        case UPDATE_AREA_STATUS.MODIFIED:
          return 'warning';

        case UPDATE_AREA_STATUS.NEW:
          return 'info';

        default:
          return 'info';
      }
    };

    return (
      <div className="payload-info-container">
        <DataTable
          value={tableValues}
          rowGroupMode="rowspan"
          groupRowsBy="floor_name"
          sortOrder={0}
          scrollable
          scrollHeight="400px"
          header={null}
          tableStyle={{ width: '100%' }}
          size="small"
        >
          <Column
            field="floor_name"
            header={COMMON_TEXT.FLOOR}
            style={{}}
          ></Column>
          <Column
            field="area_name"
            header={COMMON_TEXT.DEVICE}
            style={{}}
          ></Column>
          <Column
            field="status"
            header={COMMON_TEXT.STATUS}
            body={statusBodyTemplate}
            style={{}}
          ></Column>
        </DataTable>
      </div>
    );
  };

  render() {
    const {
      isUpdateDialogVisible,
      isDeviceAreaDialogVisible,
      selectedArea,
      updateDataPayload,
      isUpdating,
      selectedDeviceCategory,
    } = this.state;
    const updateDataDialogFooter = () => {
      return (
        <React.Fragment>
          <Button
            label={COMMON_TEXT.CANCEL}
            icon="pi pi-times"
            text
            disabled={isUpdating}
            severity="secondary"
            onClick={() => this.setState({ isUpdateDialogVisible: false })}
          />
          <Button
            label={COMMON_TEXT.SAVE}
            severity="info"
            className="submit-button"
            loading={isUpdating}
            icon="pi pi-check"
            onClick={e => this.saveMarkPosition({ updateDataPayload })}
          />
        </React.Fragment>
      );
    };

    const resetAreaDevice = () => {
      this.setState({
        merakiDevicesList: cloneDeep(this.orgSelectedMerakiDevicesList),
        verkadaDevicesList: cloneDeep(this.orgSelectedVerkadaDevicesList),
        butlrDevicesList: cloneDeep(this.orgSelectedButlrDevicesList),
        isDeviceAreaDialogVisible: false,
      });
    };

    const updateAreaDeviceButtonOnClicked = () => {
      const {
        selectedArea,
        areaList,
        merakiDevicesList,
        verkadaDevicesList,
        butlrDevicesList,
      } = this.state;
      this.orgSelectedVerkadaDevicesList = cloneDeep(verkadaDevicesList);
      this.orgSelectedMerakiDevicesList = cloneDeep(merakiDevicesList);
      this.orgSelectedButlrDevicesList = cloneDeep(butlrDevicesList);
      const areaIndex = areaList.findIndex(
        item => item.area_id === selectedArea.area_id
      );
      if (areaIndex !== -1) {
        areaList[areaIndex].capacity_limit = +selectedArea.capacity_limit;
      }
      this.setState({
        isDeviceAreaDialogVisible: false,
        areaList,
      });
    };

    const areaDeviceDialogFooter = () => {
      return (
        <React.Fragment>
          <Button
            label={COMMON_TEXT.CANCEL}
            icon="pi pi-times"
            text
            disabled={isUpdating}
            severity="secondary"
            onClick={() => {
              resetAreaDevice();
            }}
          />
          <Button
            label={COMMON_TEXT.SAVE}
            severity="info"
            className="submit-button"
            loading={isUpdating}
            icon="pi pi-check"
            onClick={e => {
              updateAreaDeviceButtonOnClicked();
            }}
          />
        </React.Fragment>
      );
    };

    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <Dialog
          visible={isUpdateDialogVisible}
          style={{ width: '44rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={COMMON_TEXT.AREA_SETTING}
          modal
          className="p-fluid"
          footer={updateDataDialogFooter}
          onHide={() => this.setState({ isUpdateDialogVisible: false })}
          appendTo={'self'}
        >
          <div className="confirmation-content">
            {this.renderUpdatePayloadTable()}
          </div>
        </Dialog>
        <div className="config-container custom-config-container area-setting-container">
          <Dialog
            visible={isDeviceAreaDialogVisible}
            style={{ width: '44rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header={COMMON_TEXT.AREA_SETTING}
            modal
            className="p-fluid device-area-dialog"
            footer={areaDeviceDialogFooter}
            onHide={() => {
              resetAreaDevice();
            }}
            appendTo={'self'}
          >
            {selectedArea && (
              <div className="confirmation-content">
                <div className="area-device-message-container">
                  <div className="area-device-title">
                    <h5>{`${COMMON_TEXT.AREA_SETTING} - ${selectedArea?.area_name}`}</h5>
                    <div className="capacity">
                      <label>{COMMON_TEXT.AREA_CAPACITY}</label>
                      <InputText
                        id="area_capacity_input"
                        value={selectedArea?.capacity_limit}
                        className="capacity-input"
                        type="number"
                        onChange={e => {
                          selectedArea.capacity_limit = e.target.value;
                          this.setState({});
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2 flex align-items-center" style={{}}>
                    <div className="device-list-container">
                      <div className="category-dropdown-container">
                        <CustomDropdown
                          className="device-category-dropdown"
                          value={selectedDeviceCategory}
                          options={this.deviceCategoryOptions}
                          onChange={e =>
                            this.setState({ selectedDeviceCategory: e.value })
                          }
                        ></CustomDropdown>
                      </div>
                      <div className="device-list">
                        {this.renderDeviceList({ selectedArea })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Dialog>
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">{COMMON_TEXT.AREA_SETTING}</div>
            </div>
            <div className="grid-nogutter align-items-center justify-content-end grid">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">{this.renderSettingContent()}</div>
        </div>
      </>
    );
  }
}

AreaSetting.propTypes = {};

export default AreaSetting;
