import CustomBarChart from '@/components/CustomComponent/CustomBarChart';
import CustomLineChart from '@/components/CustomComponent/CustomLineChart';
import NetworkTitle from '@/components/CustomComponent/NetworkTitle';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CSV_DOWNLOAD_COMPONENT,
  PERFORMANCE_COMPONENT,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import { compareAsc, format } from 'date-fns';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomCSVDownload from 'src/components/CustomComponent/CustomCSVDownload/CustomCSVDownload';
import {
  checkListChanges,
  sortObjectOrArrayByDeviceIdList,
  timestampTickList,
} from 'src/helpers/utility';
import {
  fetchData24MartBar,
  fetchData24MartLine,
  fetchData5MartBar,
  fetchData5MartLine,
} from './query-request';

@connect(state => ({
  querystring: state.querystring,
}))
class WirelessChannelUtilization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChart24Data: [],
      lineChart24Data: [],
      barChart5Data: [],
      lineChart5Data: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoading24Bar: false,
      isLoading5Bar: false,
      isLoading24Line: false,
      isLoading5Line: false,
      csvDownload: false,
    };
    this.deviceInfoOrderedMap = {};
  }

  componentDidMount() {
    const { deviceInfoOrderedList: deviceList } = this.props;
    this.deviceInfoOrderedMap = deviceList.reduce((acc, item) => {
      acc[item.deviceId] = item;
      return acc;
    }, {});
    this.getAllData();
  }

  componentDidUpdate(prevProps) {
    const { deviceInfoOrderedList: deviceList, selectedDate } = this.props;
    const {
      selectedDate: prevSelectedDate,
      deviceInfoOrderedList: prevDeviceList,
    } = prevProps;
    const isDateChanged = selectedDate !== prevSelectedDate;
    const { elementsChanged, orderChanged } = checkListChanges(
      deviceList,
      prevDeviceList,
      'deviceId'
    );
    const floor =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    if (elementsChanged || isDateChanged) {
      this.deviceInfoOrderedMap = deviceList.reduce((acc, item) => {
        acc[item.deviceId] = item;
        return acc;
      }, {});
      this.setState({ floor }, () => {
        this.getAllData();
      });
    } else if (orderChanged) {
      const deviceIdList = deviceList.map(item => item.deviceId);
      const { barChart24Data, barChart5Data, lineChart24Data, lineChart5Data } =
        this.state;
      const {
        sortedBarChart24Data,
        sortedBarChart5Data,
        sortedLineChart24Data,
        sortedLineChart5Data,
      } = this.triggerUpdateAllDataSorting({
        barChart24Data,
        barChart5Data,
        lineChart24Data,
        lineChart5Data,
        deviceIdList,
      });
      this.setState({
        barChart24Data: sortedBarChart24Data,
        barChart5Data: sortedBarChart5Data,
        lineChart24Data: sortedLineChart24Data,
        lineChart5Data: sortedLineChart5Data,
      });
    }
  }

  triggerUpdateBarChartDataSorting = ({
    barChart24Data,
    barChart5Data,
    deviceIdList,
  }) => {
    const { sortedDataList: sortedBarChart24Data } =
      sortObjectOrArrayByDeviceIdList({
        deviceIdList,
        dataObject: barChart24Data,
        sortKey: 'device_identifer',
      });

    const { sortedDataList: sortedBarChart5Data } =
      sortObjectOrArrayByDeviceIdList({
        deviceIdList,
        dataObject: barChart5Data,
        sortKey: 'device_identifer',
      });
    return {
      sortedBarChart24Data,
      sortedBarChart5Data,
    };
  };

  triggerUpdateLineChartDataSorting = ({
    lineChart24Data,
    lineChart5Data,
    deviceIdList,
  }) => {
    const { sortedDataList: sortedLineChart24Data } =
      sortObjectOrArrayByDeviceIdList({
        deviceIdList,
        dataObject: lineChart24Data,
        sortKey: 'deviceId',
      });

    const { sortedDataList: sortedLineChart5Data } =
      sortObjectOrArrayByDeviceIdList({
        deviceIdList,
        dataObject: lineChart5Data,
        sortKey: 'deviceId',
      });
    return {
      sortedLineChart24Data,
      sortedLineChart5Data,
    };
  };

  triggerUpdateAllDataSorting = ({
    barChart24Data,
    barChart5Data,
    lineChart24Data,
    lineChart5Data,
    deviceIdList,
  }) => {
    const { sortedBarChart24Data, sortedBarChart5Data } =
      this.triggerUpdateBarChartDataSorting({
        barChart24Data,
        barChart5Data,
        deviceIdList,
      });

    const { sortedLineChart24Data, sortedLineChart5Data } =
      this.triggerUpdateLineChartDataSorting({
        lineChart24Data,
        lineChart5Data,
        deviceIdList,
      });
    return {
      sortedBarChart24Data,
      sortedBarChart5Data,
      sortedLineChart24Data,
      sortedLineChart5Data,
    };
  };

  getAllData = async () => {
    this.getAllBarChartData();
    this.getAllLineChartData();
  };

  getAllBarChartData = async () => {
    let {
      floor,
      barChart24Data: currentBarChart24Data,
      barChart5Data: currentBarChart5Data,
    } = this.state;
    const {
      deviceInfoOrderedList,
      selectedDate,
      addedDeviceIdList,
      isResetDeviceIdList,
    } = this.props;
    const deviceIdList = deviceInfoOrderedList.map(item => item.deviceId);
    if (!deviceIdList || deviceIdList.length === 0) {
      return;
    }
    if (!addedDeviceIdList || addedDeviceIdList.length === 0) {
      return;
    }
    if (isResetDeviceIdList) {
      currentBarChart24Data = [];
      currentBarChart5Data = [];
    }
    this.setState({
      isLoading24Bar: true,
      isLoading5Bar: true,
    });
    const { barChartData: barChart24Data } = await this.getBarChartData({
      type: '24',
      floor,
      deviceIdList: addedDeviceIdList,
      selectedDate,
    });
    currentBarChart24Data = currentBarChart24Data.concat(barChart24Data);

    const { barChartData: barChart5Data } = await this.getBarChartData({
      type: '5',
      floor,
      deviceIdList: addedDeviceIdList,
      selectedDate,
    });

    currentBarChart5Data = currentBarChart5Data.concat(barChart5Data);

    const { sortedBarChart24Data, sortedBarChart5Data } =
      this.triggerUpdateBarChartDataSorting({
        barChart24Data: currentBarChart24Data,
        barChart5Data: currentBarChart5Data,
        deviceIdList,
      });

    this.setState({
      barChart24Data: sortedBarChart24Data,
      barChart5Data: sortedBarChart5Data,
      isLoading24Bar: false,
      isLoading5Bar: false,
    });
  };

  getAllLineChartData = async () => {
    let {
      floor,
      lineChart24Data: currentLineChart24Data,
      lineChart5Data: currentLineChart5Data,
    } = this.state;
    const {
      deviceInfoOrderedList,
      selectedDate,
      addedDeviceIdList,
      isResetDeviceIdList,
    } = this.props;
    const deviceIdList = deviceInfoOrderedList.map(item => item.deviceId);
    if (!deviceIdList || deviceIdList.length === 0) {
      return;
    }
    if (!addedDeviceIdList || addedDeviceIdList.length === 0) {
      return;
    }
    if (isResetDeviceIdList) {
      currentLineChart24Data = [];
      currentLineChart5Data = [];
    }
    this.setState({
      isLoading24Line: true,
      isLoading5Line: true,
    });
    const { lineChartData: lineChart24Data } = await this.getLineChartData({
      floor,
      deviceIdList: addedDeviceIdList,
      selectedDate,
      type: '24',
    });
    currentLineChart24Data = currentLineChart24Data.concat(lineChart24Data);

    const { lineChartData: lineChart5Data } = await this.getLineChartData({
      floor,
      deviceIdList: addedDeviceIdList,
      selectedDate,
      type: '5',
    });
    currentLineChart5Data = currentLineChart5Data.concat(lineChart5Data);

    const { sortedLineChart24Data, sortedLineChart5Data } =
      this.triggerUpdateLineChartDataSorting({
        lineChart24Data: currentLineChart24Data,
        lineChart5Data: currentLineChart5Data,
        deviceIdList,
      });

    this.setState({
      lineChart24Data: sortedLineChart24Data,
      lineChart5Data: sortedLineChart5Data,
      isLoading24Line: false,
      isLoading5Line: false,
    });
  };

  getBarChartData = async ({ type, floor, deviceIdList, selectedDate }) => {
    let dataBarChartRes = [];
    if (type === '24') {
      dataBarChartRes = await fetchData24MartBar({
        floorId: floor,
        deviceIdList: deviceIdList,
        selectedDate: format(selectedDate, 'yyyy-MM-dd'),
      });
    } else if (type === '5') {
      dataBarChartRes = await fetchData5MartBar({
        floorId: floor,
        deviceIdList: deviceIdList,
        selectedDate: format(selectedDate, 'yyyy-MM-dd'),
      });
    }

    let barChartData = [];
    if (dataBarChartRes && dataBarChartRes.length > 0) {
      barChartData = dataBarChartRes.map(item => ({
        ...item,
        fillColor:
          this.deviceInfoOrderedMap[item.device_identifer]?.['fillColor'],
      }));
    }
    return { barChartData };
  };

  getLineChartData = async ({ floor, deviceIdList, selectedDate, type }) => {
    let dataLineChartRes = [];
    const defaultTimestampTickList = timestampTickList();
    if (type === '24') {
      dataLineChartRes = await fetchData24MartLine({
        floorId: floor,
        fromDate: format(selectedDate, 'yyyy-MM-dd 00:00:00'),
        toDate: format(selectedDate, 'yyyy-MM-dd 23:59:59'),
        deviceIdList: deviceIdList,
      });
    } else if (type === '5') {
      dataLineChartRes = await fetchData5MartLine({
        floorId: floor,
        fromDate: format(selectedDate, 'yyyy-MM-dd 00:00:00'),
        toDate: format(selectedDate, 'yyyy-MM-dd 23:59:59'),
        deviceIdList: deviceIdList,
      });
    }
    const lineChartData = [];
    if (dataLineChartRes && dataLineChartRes.length > 0) {
      for (const key in this.deviceInfoOrderedMap) {
        if (Object.hasOwnProperty.call(this.deviceInfoOrderedMap, key)) {
          const deviceId = this.deviceInfoOrderedMap[key]['deviceId'];
          const timestampSet = new Set(
            dataLineChartRes.map(item =>
              format(new Date(item.timestamp), 'HH:mm')
            )
          );
          let timestampArray = Array.from(timestampSet).sort((a, b) => a > b);
          const maxTimestamp = timestampArray[timestampArray.length - 1];
          const missingTimestampList = defaultTimestampTickList.filter(
            item => !timestampSet.has(item) && item <= maxTimestamp
          );
          const dataByDeviceId = dataLineChartRes.filter(
            item => item.device_identifer === deviceId
          );
          if (dataByDeviceId.length > 0) {
            lineChartData.push({
              name: this.deviceInfoOrderedMap[key]['deviceName'],
              deviceId: deviceId,
              data: dataByDeviceId
                .map(item => ({
                  timestamp: item.timestamp,
                  utilization_total: item.utilization_total,
                }))
                .concat(
                  missingTimestampList.map(item => ({
                    timestamp: format(selectedDate, `yyyy-MM-dd ${item}:00`),
                    counts: null,
                  })) ?? []
                )
                .sort((a, b) =>
                  compareAsc(new Date(a.timestamp), new Date(b.timestamp))
                ),
              fillColor: this.deviceInfoOrderedMap[key]['fillColor'],
            });
          }
        }
      }
    }
    return { lineChartData };
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderBarChart({ data: barChartData, type }) {
    const { selectedDeviceIdLineList } = this.props;
    const { isLoading24Bar, isLoading5Bar } = this.state;
    const filteredData = barChartData.filter(item =>
      selectedDeviceIdLineList.some(
        deviceId =>
          deviceId?.toLowerCase() === item['device_identifer']?.toLowerCase()
      )
    );

    return (
      <CustomBarChart
        data={filteredData}
        xAxisDataKey={'device_name'}
        yAxisDataKey={'utilization_total'}
        yAxisLabel={'%'}
        isLoading={type === '24' ? isLoading24Bar : isLoading5Bar}
      />
    );
  }

  getThresholdValue = ({ type }) => {
    const { lineChartThreshold } = this.props;
    let threshold = null;
    let thresholdDirection = null;
    if (lineChartThreshold) {
      const { channel_utilizations_24, channel_utilizations_5 } =
        lineChartThreshold;
      if (type === '24') {
        threshold = channel_utilizations_24?.['threshold'] ?? null;
        thresholdDirection = channel_utilizations_24?.['direction'] ?? 'upper';
      } else {
        threshold = channel_utilizations_5?.['threshold'] ?? null;
        thresholdDirection = channel_utilizations_5?.['direction'] ?? 'upper';
      }
    }
    return { threshold, thresholdDirection };
  };

  renderLineChart({ data: lineChartData, type }) {
    const { selectedDeviceIdLineList } = this.props;
    const { isLoading24Line, isLoading5Line } = this.state;
    const filteredDisplayItems = lineChartData.filter(item =>
      selectedDeviceIdLineList.some(
        deviceId => deviceId?.toLowerCase() === item.deviceId?.toLowerCase()
      )
    );

    const { threshold, thresholdDirection } = this.getThresholdValue({ type });

    return (
      <CustomLineChart
        data={filteredDisplayItems}
        xAxisDataKey={'timestamp'}
        yAxisDataKey={'utilization_total'}
        yAxisMaxValue={100}
        yAxisMinValue={0}
        yAxisLabel={'%'}
        threshold={threshold}
        thresholdDirection={thresholdDirection}
        isLoading={type === '24' ? isLoading24Line : isLoading5Line}
      />
    );
  }

  renderDownloadButton() {
    const { csvDownload } = this.state;
    return (
      <>
        <Button
          type="button"
          label={csvDownload ? `戻る` : 'CSVダウンロード'}
          className={csvDownload ? 'back-button' : 'submit-button csv-download'}
          onClick={() => {
            this.setState({
              csvDownload: !csvDownload,
            });
          }}
        />
      </>
    );
  }

  render() {
    const {
      barChart24Data,
      barChart5Data,
      lineChart24Data,
      lineChart5Data,

      csvDownload,
      floor,
    } = this.state;
    const { selectedDeviceIdLineList, selectedDate } = this.props;
    const { threshold: threshold24 } = this.getThresholdValue({ type: '24' });
    const { threshold: threshold5 } = this.getThresholdValue({ type: '5' });
    return (
      <div className="network-container wireless-channel-utilization">
        <div className="network-title-container">
          <div className="text-left">
            <NetworkTitle
              thresholds={[
                { type: '24', value: threshold24 },
                { type: '5', value: threshold5 },
              ]}
              type={PERFORMANCE_COMPONENT.WIRELESS_CHANNEL_UTILIZATION}
            ></NetworkTitle>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            <div>{this.renderDownloadButton()}</div>
          </div>
        </div>
        {csvDownload ? (
          <CustomCSVDownload
            fromDate={selectedDate}
            toDate={selectedDate}
            type={CSV_DOWNLOAD_COMPONENT.WIRELESS_CHANNEL_UTILIZATION}
            deviceIdList={[...selectedDeviceIdLineList]}
            floorId={floor}
          ></CustomCSVDownload>
        ) : (
          <div className="network-content">
            {this.renderMessageError() || (
              <>
                <div className="data-container">
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        2.4 Ghz - {COMMON_TEXT.AVERAGE_SCORE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderBarChart({
                        data: barChart24Data,
                        type: '24',
                      })}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        2.4 Ghz - {COMMON_TEXT.SCORE_TRANSITION}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart({
                        data: lineChart24Data,
                        type: '24',
                      })}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        5.0 Ghz - {COMMON_TEXT.AVERAGE_SCORE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderBarChart({ data: barChart5Data, type: '5' })}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        5.0 Ghz - {COMMON_TEXT.SCORE_TRANSITION}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart({
                        data: lineChart5Data,
                        type: '5',
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

WirelessChannelUtilization.propTypes = {};

export default WirelessChannelUtilization;
