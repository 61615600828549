import { QUERY_TABLES } from '@/helpers/constants';
import { isUsingSampleData } from 'src/helpers/generate-random-data';
import { executeQuery } from 'src/helpers/request';

const buildQueryTableData = filterPayload => {
  return {
    data: {
      fields: {
        log_id: ['select'],
        timestamp: ['select'],
        device_name: ['select'],
        event_description: ['select'],
        event_name: ['select'],
        ip_address: ['select'],
        user_name: ['select'],
        details: ['select'],
      },
      skip: 0,
      limit: 1000,
    },
    filter: filterPayload,
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.VERKADA_AUDITLOGS,
  };
};

export const fetchVerkadaAuditLogs = async payload => {
  const request = buildQueryTableData(payload);
  if (isUsingSampleData) {
    return [];
  }
  return executeQuery(request);
};
