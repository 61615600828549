import CustomCalendar from '@/components/CustomComponent/CustomCalendar/CustomCalendar';
import CustomDropdown from '@/components/CustomComponent/CustomDropdown/CustomDropdown';
import { NETWORK_SORT_OPTIONS, QUERY_STRING_STORE } from '@/helpers/constants';
import debounce from 'lodash/debounce';
import { MultiSelect } from 'primereact/multiselect';
import { ScrollTop } from 'primereact/scrolltop';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { COMMON_TEXT } from 'src/helpers/common-text';
import NetworkClientCount from './NetworkClientCount';
import NetworkPerformanceScore from './NetworkPerformanceScore';
import NetworkUsage from './NetworkUsage';
import WirelessChannelUtilization from './WirelessChannelUtilization';
import WirelessLatency from './WirelessLatency';
import WirelessSignal from './WirelessSignal';
import { fetchLineChartThresholds } from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class Network extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      selectedDate: new Date(),
      selectedLimit: 5,
      selectedSort: NETWORK_SORT_OPTIONS.DEVICE_NAME_ASC,
      selectedLineList: [],
      lineOptions: [],
      selectedDeviceIdLineList: [],
      deviceInfoOrderedList: [],
      addedDeviceIdList: [],
      isResetDeviceIdList: false,
      lineChartThresholds: {},
    };
    this.limitDropDownOptions = [
      { label: COMMON_TEXT.TOP_3, value: 3 },
      { label: COMMON_TEXT.TOP_5, value: 5 },
      { label: COMMON_TEXT.TOP_10, value: 10 },
      { label: COMMON_TEXT.TOP_ALL, value: 0 },
    ];
    this.updateDeviceInfoOrderList = this.updateDeviceInfoOrderList.bind(this);
    this.sortDropDownOptions = [
      {
        label: COMMON_TEXT.SORT_BY_DEVICE_NAME,
        value: NETWORK_SORT_OPTIONS.DEVICE_NAME_ASC,
      },
      {
        label: COMMON_TEXT.SORT_BY_PERFORMANCE_SCORE_DESC,
        value: NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_DESC,
      },
      {
        label: COMMON_TEXT.SORT_BY_PERFORMANCE_SCORE_ASC,
        value: NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_ASC,
      },
    ];
    this.loadedDeviceIdList = [];
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.getLineChartThresholds();
    }
  }

  getLineChartThresholds = async () => {
    const { tenant } = this.state;
    const res = await fetchLineChartThresholds({ tenantId: tenant });
    // group res by chart_name
    const groupByChartName = res.reduce((acc, item) => {
      const { chart_name } = item;
      if (!acc[chart_name]) {
        acc[chart_name] = [];
      }
      acc[chart_name] = item;
      return acc;
    }, {});

    this.setState({ lineChartThresholds: groupByChartName });
  };

  componentDidUpdate(prevProps) {
    const tenant =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevProps?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant, lineChartThresholds: {} }, () => {
        this.getLineChartThresholds();
      });
    }
  }

  datePickerOnChanged = e => {
    const BOUNCE_IN_MILISECONDS = 300;
    if (e.value) {
      const debouncedFunc = debounce(() => {
        this.setState({ selectedDate: e.value });
      }, BOUNCE_IN_MILISECONDS);
      if (this.pendingRequest) {
        // Cancel any pending requests
        clearTimeout(this.pendingRequest);
      }
      // Schedule the debounced function to run after 500ms
      this.pendingRequest = setTimeout(() => {
        debouncedFunc();
        this.pendingRequest = null;
      }, BOUNCE_IN_MILISECONDS);
    }
  };

  sortDropDownOptionsOnChange = debounce(e => {
    this.setState({ selectedSort: e.value }, () => {
      // Do something here
    });
  }, 300);

  renderSortDropdown() {
    const { selectedSort } = this.state;
    return (
      <div>
        <CustomDropdown
          label="ソート順"
          value={selectedSort}
          options={this.sortDropDownOptions}
          onChange={this.sortDropDownOptionsOnChange}
          style={{ width: '180px' }}
        ></CustomDropdown>
      </div>
    );
  }

  renderDatePicker() {
    const { selectedDate } = this.state;
    const maxDate = new Date();
    return (
      <CustomCalendar
        value={selectedDate}
        onChange={this.datePickerOnChanged}
        maxDate={maxDate}
      ></CustomCalendar>
    );
  }

  // Children Component Callback
  updateDeviceInfoOrderList({ deviceInfoOrderedList: newValue, isLoadMore }) {
    let newDeviceIdList = [];
    let isReset = false;
    if (
      !this.loadedDeviceIdList ||
      this.loadedDeviceIdList.length === 0 ||
      !isLoadMore
    ) {
      this.loadedDeviceIdList = [];
      isReset = true;
    }

    // filter out the device id that is not in the loaded list
    newValue.forEach(item => {
      if (!this.loadedDeviceIdList.includes(item.deviceId)) {
        newDeviceIdList.push(item.deviceId);
        this.loadedDeviceIdList.push(item.deviceId);
      }
    });

    let lineOptions = newValue
      .map(item => {
        const { deviceName, deviceId } = item;
        return {
          name: deviceName,
          code: deviceId,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    const selectedDeviceIdLineList = lineOptions.map(item => item.code);
    this.setState({
      selectedLineList: [...lineOptions],
      lineOptions,
      addedDeviceIdList: [...newDeviceIdList],
      isResetDeviceIdList: isReset,
      deviceInfoOrderedList: newValue,
      selectedDeviceIdLineList,
    });
  }

  limitDropDownOptionsOnChange = debounce(e => {
    this.setState({ selectedLimit: e.value }, () => {});
  }, 300);

  renderLimitDropdown() {
    const { selectedLimit } = this.state;
    return (
      <div>
        <CustomDropdown
          label="選択限度"
          value={selectedLimit}
          options={this.limitDropDownOptions}
          onChange={this.limitDropDownOptionsOnChange}
          style={{ width: '130px' }}
        ></CustomDropdown>
      </div>
    );
  }

  multiselectLineChartOnChanged = e => {
    const selectedDeviceIdLineList = e.value.map(item => item.code);
    this.setState({ selectedLineList: e.value, selectedDeviceIdLineList });
  };

  renderMultiSelectLineChart = () => {
    const { selectedLineList, lineOptions } = this.state;

    const itemTemplate = option => {
      return (
        <div className="flex align-items-center">
          <div
            style={{
              backgroundColor: option.fillColor,
              padding: '0.125rem 0.5rem',
              borderRadius: '4px',
            }}
          >
            {option.name}
          </div>
        </div>
      );
    };
    const footerTemplate = () => {
      const length = selectedLineList ? selectedLineList.length : 0;

      return (
        <div className="py-2 px-3">
          <span>
            <b>{length}</b> アイテムが選択されました
          </span>
        </div>
      );
    };
    return (
      <MultiSelect
        value={selectedLineList}
        options={lineOptions}
        disabled={lineOptions.length === 0}
        onChange={this.multiselectLineChartOnChanged}
        optionLabel="name"
        placeholder={COMMON_TEXT.SELECT}
        itemTemplate={itemTemplate}
        panelFooterTemplate={footerTemplate}
        className="multiselect-style"
        display="chip"
        filter
      />
    );
  };

  render() {
    const {
      selectedDate,
      selectedSort,
      selectedLimit,
      selectedDeviceIdLineList,
      deviceInfoOrderedList,
      addedDeviceIdList,
      isResetDeviceIdList,
      lineChartThresholds,
    } = this.state;

    return (
      <div className="config-container custom-config-container network-usage">
        <div className="config-title-container performance-config-title-container">
          <div className="text-left title-container">
            <div className="title-text">{COMMON_TEXT.NETWORK}</div>
          </div>
          <div className="performance-config-container">
            <div className="upper">
              <div className="mt-1">{this.renderLimitDropdown()}</div>
              <div className="mt-1">{this.renderSortDropdown()}</div>
              <div className="mt-1">{this.renderDatePicker()}</div>
            </div>
            <div className="lower mt-1 mb-1">
              <div className="multiselect-container">
                {this.renderMultiSelectLineChart()}
              </div>
            </div>
          </div>
        </div>
        <div className="config-content">
          <NetworkPerformanceScore
            selectedDate={selectedDate}
            selectedLimit={selectedLimit}
            updateDeviceInfoOrderList={this.updateDeviceInfoOrderList}
            selectedDeviceIdLineList={selectedDeviceIdLineList}
            sortOption={selectedSort}
          />
          <WirelessSignal
            selectedDate={selectedDate}
            selectedDeviceIdLineList={selectedDeviceIdLineList}
            deviceInfoOrderedList={deviceInfoOrderedList}
            addedDeviceIdList={addedDeviceIdList}
            isResetDeviceIdList={isResetDeviceIdList}
          />
          <NetworkClientCount
            selectedDate={selectedDate}
            selectedDeviceIdLineList={selectedDeviceIdLineList}
            deviceInfoOrderedList={deviceInfoOrderedList}
            addedDeviceIdList={addedDeviceIdList}
            isResetDeviceIdList={isResetDeviceIdList}
            lineChartThreshold={lineChartThresholds}
          />
          <NetworkUsage
            selectedDate={selectedDate}
            selectedDeviceIdLineList={selectedDeviceIdLineList}
            deviceInfoOrderedList={deviceInfoOrderedList}
            addedDeviceIdList={addedDeviceIdList}
            isResetDeviceIdList={isResetDeviceIdList}
            lineChartThreshold={lineChartThresholds}
          />
          <WirelessChannelUtilization
            selectedDate={selectedDate}
            selectedDeviceIdLineList={selectedDeviceIdLineList}
            deviceInfoOrderedList={deviceInfoOrderedList}
            addedDeviceIdList={addedDeviceIdList}
            isResetDeviceIdList={isResetDeviceIdList}
            lineChartThreshold={lineChartThresholds}
          />
          <WirelessLatency
            selectedDate={selectedDate}
            selectedDeviceIdLineList={selectedDeviceIdLineList}
            deviceInfoOrderedList={deviceInfoOrderedList}
            addedDeviceIdList={addedDeviceIdList}
            isResetDeviceIdList={isResetDeviceIdList}
            lineChartThreshold={lineChartThresholds}
          />
          <ScrollTop
            target="parent"
            threshold={400}
            className=""
            style={{
              height: 40,
              width: 40,
              maxHeight: 40,
              minHeight: 40,
              maxWidth: 40,
              minWidth: 40,
            }}
            icon="pi pi-arrow-up text-base"
          />
        </div>
      </div>
    );
  }
}

Network.propTypes = {};

export default Network;
