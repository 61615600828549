import { QUERY_TABLES } from '@/helpers/constants';
import {
  generateRandomDataForBarChartWirelessSignal,
  generateRandomDataForFloorMap,
  isUsingSampleData,
} from 'src/helpers/generate-random-data';
import { executeQuery } from 'src/helpers/request';

const buildRequestQueryHeatMap = (floorId, fromDate, toDate, deviceIdList) => {
  return {
    data: {
      fields: {
        timestamp: ['select'],
        device_identifer: ['select'],
        device_name: ['select'],
        snr: ['select'],
        grade: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId],
      timestamp: {
        gte: [fromDate],
        lte: [toDate],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.SIGNAL_QUALITY_HISTORIES_MART_HEATMAP,
  };
};

const buildRequestQueryFloorMap = floorId => {
  return {
    data: {
      fields: {
        timestamp: ['select'],
        device_identifer: ['select'],
        device_name: ['select'],
        snr: ['select'],
        grade: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
        channel_5: ['select'],
        channel_24: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.SIGNAL_QUALITY_HISTORIES_MART_FLOORMAP,
  };
};

export const fetchDataHeatMap = async payload => {
  const { floorId, fromDate, toDate, deviceIdList } = payload;
  const request = buildRequestQueryHeatMap(
    floorId,
    fromDate,
    toDate,
    deviceIdList
  );
  if (isUsingSampleData) {
    return generateRandomDataForBarChartWirelessSignal();
  }
  return executeQuery(request);
};

export const fetchDataFloorMap = async payload => {
  const { floorId } = payload;
  const request = buildRequestQueryFloorMap(floorId);
  if (isUsingSampleData) {
    return generateRandomDataForFloorMap();
  }
  return executeQuery(request);
};
