import AreaMarker from '@/components/CustomComponent/AreaMarker';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ImageAreaMarker from '@/components/CustomComponent/ReactImageAreaMarker';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  AREA_LEGEND,
  MEMORY_STORE,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import {
  calculateRectangle,
  transformCoordinateBottomLeftToTopLeft,
} from '@/helpers/utility';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPeopleCountsAreaByFloorId } from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class PeopleCounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoading: false,
      floorMapUrl: '',
      floorMapError: false,
    };
    this.tooltipRef = React.createRef();
  }

  validateFloorMapUrl(url, successCallback, errorCallback) {
    var img = new Image();
    img.onload = function () {
      successCallback(url);
    };
    img.onerror = function () {
      errorCallback(url);
    };
    img.src = url;
  }

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    this.validateFloorMapUrl(
      floor?.floor_map,
      () => {
        this.setState({ floorMapError: false });
      },
      () => {
        this.setState({ floorMapError: true });
      }
    );
    return floor ? floor.floor_map : '';
  };

  componentDidMount() {
    const { isSignageMode, signageModeData } = this.props;
    if (isSignageMode) {
      const { data, floor } = signageModeData;
      const { dataPeopleCounts } = data;
      this.setState({
        data: dataPeopleCounts,
        floor,
        floorMapUrl: this.getFloorMapUrl(floor),
      });
    } else {
      const { querystring } = this.props;
      const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      if (floor) {
        this.setState(
          {
            floor,
            floorMapUrl: this.getFloorMapUrl(floor),
          },
          () => {
            this.fetchDataByFloorId();
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring, isSignageMode, signageModeData } = this.props;
    const {
      querystring: prevQuerystring,
      signageModeData: prevSignageModeData,
    } = prevProps;
    if (isSignageMode) {
      const { data, floor, timestamp } = signageModeData;
      const { timestamp: prevTimestamp } = prevSignageModeData;
      const { dataPeopleCounts } = data;
      const isSignageModeDataChanged = timestamp !== prevTimestamp;
      if (isSignageModeDataChanged) {
        this.setState(
          {
            data: dataPeopleCounts,
            floor,
            floorMapUrl: this.getFloorMapUrl(floor),
          },
          () => {}
        );
      }
    } else {
      const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      const prevFloor =
        prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      const isFloorChanged = floor !== prevFloor;
      if (isFloorChanged) {
        this.setState(
          { floor, floorMapUrl: this.getFloorMapUrl(floor) },
          () => {
            this.fetchDataByFloorId();
          }
        );
      }
    }
  }

  fetchDataByFloorId = async () => {
    const { floor } = this.state;
    this.setState({ isLoading: true });
    let dataFloorMap = await getPeopleCountsAreaByFloorId({
      floorId: floor,
    });

    if (!dataFloorMap) {
      dataFloorMap = [];
    }

    const updatedFloorMapData = dataFloorMap.map(item => {
      const { position_x, position_y, position_x1, position_y1 } = item;
      const { pointC, width, height } = calculateRectangle({
        positionX: position_x,
        positionY: position_y,
        positionX1: position_x1,
        positionY1: position_y1,
      });
      const { x, y } = transformCoordinateBottomLeftToTopLeft({
        x: pointC.x,
        y: pointC.y,
      });
      item.rect = {
        top: y,
        left: x,
        width,
        height,
      };
      return item;
    });

    this.setState({
      data: updatedFloorMapData,
      isLoading: false,
    });
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderRefreshButton() {
    const { isLoading, floor } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoading}
        disabled={!floor}
        className="refresh-button"
        severity="info"
        size="small"
        onClick={() => this.fetchDataByFloorId()}
      />
    );
  }

  AreaMarkerViewOnly = props => {
    return <AreaMarker {...props} isViewOnly={true}></AreaMarker>;
  };

  infoButtonOnClick = e => {
    if (this.tooltipRef.current) {
      this.tooltipRef.current.show(e);
    }
  };

  renderLegendContent = () => {
    return (
      <div className="legend-container">
        <div className="legend-header">
          <span>{COMMON_TEXT.PEOPLE_COUNTS_LEGEND}</span>
        </div>
        <div className="legend-content">
          {AREA_LEGEND.map((item, index) => {
            const { color, label, value } = item;
            return (
              <div className="item-row" key={`legend-item-row-${index}`}>
                <div className="item-name-container">
                  <div
                    className="item-color"
                    style={{ backgroundColor: color }}
                  ></div>
                  <div className="item-name">
                    <span>{label}</span>
                  </div>
                </div>
                <div className="item-value">
                  <span>{value}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const {
      data: floorMapData,
      isLoading,
      floorMapUrl,
      floorMapError,
    } = this.state;
    return (
      <div className="config-container custom-config-container people-counts">
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">{COMMON_TEXT.PEOPLE_COUNTS}</div>
            <Tooltip
              ref={this.tooltipRef}
              target={`.custom-legend-button`}
              position="right"
              className="custom-legend-tooltip"
              event="hover"
            >
              <div className="data-container-legend">
                {this.renderLegendContent()}
              </div>
            </Tooltip>
            <Button
              type="button"
              className={`custom-legend-button custom-legend-button}`}
              severity="secondary"
              text
              onClick={e => this.infoButtonOnClick(e)}
            >
              <i className="pi pi-info-circle"></i>
            </Button>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            {!this.props.isSignageMode && this.renderRefreshButton()}
          </div>
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <div className="main-container">
                <div className="image-container">
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <ImageAreaMarker
                        bufferLeft={0}
                        bufferTop={0}
                        src={floorMapUrl}
                        areaMarkerComponent={this.AreaMarkerViewOnly}
                        markers={[]}
                        areaMarkers={floorMapError ? [] : floorMapData || []}
                        isDisableDragMarker={false}
                        extraClass={
                          this.props.isSignageMode ? 'custom-image-marker' : ''
                        }
                      />
                      <>
                        {floorMapError && (
                          <Message
                            severity="error"
                            text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                          />
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

PeopleCounts.propTypes = {};

export default PeopleCounts;
