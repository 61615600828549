import CustomCalendar from '@/components/CustomComponent/CustomCalendar/CustomCalendar';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  MEETING_ROOM_STATUS_BG_COLOR,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
} from '@/helpers/constants';
import {
  convertArrayToObject,
  generateRandomString,
  sortObjectKeys,
} from '@/helpers/utility';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  isSameDay,
  setHours,
  startOfDay,
} from 'date-fns';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import debounce from 'lodash/debounce';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Slider } from 'primereact/slider';
import { Tooltip } from 'primereact/tooltip';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  fetchDataOutlookStartAfterStartAndEndAfterEnd,
  fetchDataOutlookStartAfterStartAndEndBeforerEnd,
  fetchDataOutlookStartBeforeStartAndEndAfterEnd,
  fetchDataOutlookStartBeforeStartAndEndAfterStart,
  fetchRoomAvailabilityByFloorId,
} from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class MeetingRoomOutlook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlookData: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoadingOutlook: false,
      earliestTime: null,
      latestTime: null,
      selectedDate: new Date(),
      //new
      sliderValues: [9, 18],
      selectedTimeRange: {
        from: '09:00',
        to: '18:00',
      },
    };
    this.pendingRequest = null;
  }

  componentDidMount() {
    const { onDateChanged } = this.props;
    const { selectedDate } = this.state;
    if (selectedDate) {
      onDateChanged(selectedDate);
    }
    const prevSliderState =
      this.props.sessionStore?.[SESSION_STORAGE_KEY.MEETING_ROOM_SLIDER_STATE];
    if (prevSliderState) {
      const { sliderValues, selectedTimeRange } = JSON.parse(prevSliderState);
      this.setState({
        sliderValues: sliderValues,
        selectedTimeRange: selectedTimeRange,
      });
    }
    this.getMeetingRoomOutlookData();
  }

  componentDidUpdate(prevProps) {
    const floor =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const { selectedDate } = this.state;
    if (
      floor !==
      prevProps?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0]
    ) {
      this.setState({ floor }, () => {
        this.getMeetingRoomOutlookData();
      });
    }
    if (
      selectedDate &&
      prevProps.selectedDate &&
      !isSameDay(selectedDate, prevProps.selectedDate)
    ) {
      this.getMeetingRoomOutlookData();
    }
  }

  getMeetingRoomOutlookData = async () => {
    this.getOutlookData();
  };

  getAllMeetingRoomOutlookDataByCase = async ({
    floorId,
    startDateTime,
    endDateTime,
  }) => {
    // case 1: fetch data with start is before startDateTime and end is after startDateTime and is before endDateTime
    const res1 = await fetchDataOutlookStartBeforeStartAndEndAfterStart({
      floorId,
      startDateTime,
      endDateTime,
    });
    // case 2: fetch data with start is before startDateTime and end is after endDateTime
    const res2 = await fetchDataOutlookStartBeforeStartAndEndAfterEnd({
      floorId,
      startDateTime,
      endDateTime,
    });
    // case 3: fetch data with start is after startDateTime and end is before endDateTime
    const res3 = await fetchDataOutlookStartAfterStartAndEndBeforerEnd({
      floorId,
      startDateTime,
      endDateTime,
    });
    // case 4: fetch data with start is after startDateTime and end is after endDateTime
    const res4 = await fetchDataOutlookStartAfterStartAndEndAfterEnd({
      floorId,
      startDateTime,
      endDateTime,
    });
    return [...res1, ...res2, ...res3, ...res4];
  };

  getOutlookData = async () => {
    const { selectedDate, floor } = this.state;
    if (!floor || !selectedDate) {
      return;
    }
    this.setState({ isLoadingOutlook: true });
    try {
      const currentDate = new Date();
      const isCurrentDate = isSameDay(selectedDate, currentDate);
      const startDateTime = format(selectedDate, 'yyyy-MM-dd 00:00:00');
      const endDateTime = format(selectedDate, 'yyyy-MM-dd 23:59:59');
      let outlookData = await this.getAllMeetingRoomOutlookDataByCase({
        floorId: floor,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      });
      outlookData = outlookData || [];
      const outlookDataDeviceIdSet = new Set(
        outlookData.map(item => item.device_identifer)
      );
      let deviceStatusMap = await this.getRoomAvailabilityByFloorId({
        floorId: floor,
      });
      const deviceStatusMapDeviceIdSet = new Set(Object.keys(deviceStatusMap));
      // find device Id that is in deviceStatusMapDeviceIdSet but not in outlookDataDeviceIdSet
      const diffDeviceIdSet = new Set(
        [...deviceStatusMapDeviceIdSet].filter(
          x => !outlookDataDeviceIdSet.has(x)
        )
      );
      outlookData = outlookData.concat(
        [...diffDeviceIdSet].map(device_identifer => ({
          device_identifer,
          room_name: deviceStatusMap[device_identifer]?.['room_name'] ?? '',
          reserved_start: null,
          reserved_end: null,
          occupied_start: null,
          occupied_end: null,
          occupied_minutes: null,
          reserved_minutes: null,
          subject: '',
          organizer: '',
        }))
      );
      if (!isCurrentDate) {
        // Not display room availability status for not current day
        const deviceIdKeys = Object.keys(deviceStatusMap);
        for (let i = 0; i < deviceIdKeys.length; i++) {
          const deviceIdKey = deviceIdKeys[i];
          const dev = deviceStatusMap[deviceIdKey];
          if (dev) {
            deviceStatusMap[deviceIdKey] = {
              ...dev,
              is_room_available: -1,
              room_available_ts: null,
            };
          }
        }
      }

      const newOutlookData = outlookData.map(item => {
        return {
          ...item,
          is_room_available:
            deviceStatusMap[item.device_identifer]?.['is_room_available'] ?? -1,
          room_available_ts:
            deviceStatusMap[item.device_identifer]?.['room_available_ts'] ??
            null,
        };
      });
      const newState = {
        outlookData: newOutlookData,
        isLoadingOutlook: false,
      };
      this.setState(newState);
    } catch (error) {
      this.setState({ isLoadingOutlook: false });
    }
  };

  getRoomAvailabilityByFloorId = async ({ floorId }) => {
    let deviceStatusMap = {};
    try {
      const res = await fetchRoomAvailabilityByFloorId({ floorId });
      const deviceStatusList = res?.map(item => ({
        device_identifer: item.device_identifer,
        is_room_available: item.room_availability === 1,
        room_available_ts: item.timestamp,
        room_name: item.device_name,
      }));
      deviceStatusMap = deviceStatusList.reduce((acc, curr) => {
        acc[curr.device_identifer] = {
          room_name: curr.room_name,
          is_room_available: curr.is_room_available,
          room_available_ts: curr.room_available_ts,
        };
        return acc;
      }, {});
    } catch (error) {
      console.error(error);
    }
    return deviceStatusMap;
  };

  renderTooltipContent(tooltipData) {
    const {
      subject,
      reserved_start,
      reserved_end,
      occupied_minutes,
      reserved_minutes,
      organizer,
    } = tooltipData;
    const reservedStartTime = reserved_start?.split(' ')?.[1]?.substring(0, 5);
    const reservedEndTime = reserved_end?.split(' ')?.[1]?.substring(0, 5);
    return (
      <div className="tooltip-content">
        <div className="tooltip-content-item">
          <div>{`${reservedStartTime} - ${reservedEndTime}`}</div>
        </div>
        <div className="tooltip-content-item">
          <div>{`(${occupied_minutes ?? '-'} / ${reserved_minutes})`}</div>
        </div>
        <div className="tooltip-content-item subject">
          <div>
            <b>{subject}</b>
          </div>
        </div>
        <div className="tooltip-content-item">
          <div>{organizer}</div>
        </div>
      </div>
    );
  }

  getEarliestAndLatestTime(arr) {
    let earliestTime = null;
    let latestTime = null;

    arr.forEach(item => {
      if (item.reserved_start) {
        const reservedStart = new Date(item.reserved_start);
        earliestTime = !earliestTime
          ? reservedStart
          : isBefore(reservedStart, earliestTime)
          ? reservedStart
          : earliestTime;
        latestTime = !latestTime
          ? reservedStart
          : isAfter(reservedStart, latestTime)
          ? reservedStart
          : latestTime;
      }

      if (item.reserved_end) {
        const reservedEnd = new Date(item.reserved_end);
        earliestTime = !earliestTime
          ? reservedEnd
          : isBefore(reservedEnd, earliestTime)
          ? reservedEnd
          : earliestTime;
        latestTime = !latestTime
          ? reservedEnd
          : isAfter(reservedEnd, latestTime)
          ? reservedEnd
          : latestTime;
      }

      if (item.occupied_start) {
        const occupiedStart = new Date(item.occupied_start);
        earliestTime = !earliestTime
          ? occupiedStart
          : isBefore(occupiedStart, earliestTime)
          ? occupiedStart
          : earliestTime;
        latestTime = !latestTime
          ? occupiedStart
          : isAfter(occupiedStart, latestTime)
          ? occupiedStart
          : latestTime;
      }

      if (item.occupied_end) {
        const occupiedEnd = new Date(item.occupied_end);
        earliestTime = !earliestTime
          ? occupiedEnd
          : isBefore(occupiedEnd, earliestTime)
          ? occupiedEnd
          : earliestTime;
        latestTime = !latestTime
          ? occupiedEnd
          : isAfter(occupiedEnd, latestTime)
          ? occupiedEnd
          : latestTime;
      }
    });

    const defaultEarliestTime = setHours(startOfDay(earliestTime), 9);
    const defaultLatestTime = setHours(startOfDay(latestTime), 18);

    if (isAfter(earliestTime, defaultEarliestTime)) {
      earliestTime = defaultEarliestTime;
    }

    if (isBefore(latestTime, defaultLatestTime)) {
      latestTime = defaultLatestTime;
    }

    return { earliestTime, latestTime };
  }

  calculateNowLinePosition(startDate, endDate) {
    const formattedStartDate = format(startDate, 'yyyy-MM-dd HH:mm');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd HH:mm');
    // Create a Date object representing the start of today
    const today = startOfDay(new Date());

    // Get the current time in hours and minutes
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    // Add the current time to the start of today to get the current time as a Date object
    const now = addMinutes(addHours(today, hours), minutes);

    const totalMinutes = differenceInMinutes(
      new Date(formattedEndDate),
      new Date(formattedStartDate)
    );
    const isSameDayWithToday =
      isSameDay(new Date(formattedStartDate), new Date(formattedEndDate)) &&
      isSameDay(new Date(formattedStartDate), today);
    // Calculate the number of minutes that have passed since start time
    // If today is not the same day as the start and end dates, set elapsedMinutes to -1
    let elapsedMinutes = -1;
    if (isSameDayWithToday) {
      elapsedMinutes = differenceInMinutes(
        new Date(format(now, 'yyyy-MM-dd HH:mm')),
        new Date(formattedStartDate)
      );
    }

    // Calculate the percentage of time that has passed between start and end times
    const percentageElapsed =
      elapsedMinutes > 0 ? (elapsedMinutes / totalMinutes) * 100 : -1;
    return {
      percentageElapsed,
      nowTimeString: format(now, 'HH:mm'),
    };
  }

  renderNowLine(start, end, dataByRoomNameCount) {
    let { percentageElapsed: nowLineLeftPosition, nowTimeString } =
      this.calculateNowLinePosition(start, end);
    if (nowLineLeftPosition >= 0) {
      let nowLineHeight = dataByRoomNameCount * 35 + 25 + 50;
      let nowLineTopPosition = -20;
      return (
        <div
          className="now-line"
          style={{
            left: `${`${nowLineLeftPosition}%`}`,
            height: `${nowLineHeight}px`,
            top: `${nowLineTopPosition}px`,
          }}
        >
          <div className="now-line-tooltip">{`${nowTimeString}`}</div>
        </div>
      );
    }
    return <></>;
  }

  calculateOffset(
    reserved_start,
    reserved_end,
    occupied_start,
    occupied_end,
    start,
    end
  ) {
    const st = new Date(reserved_start).getTime();
    const et = new Date(reserved_end).getTime();
    const ost = occupied_start ? new Date(occupied_start).getTime() : null;
    const oet = occupied_end ? new Date(occupied_end).getTime() : null;

    const endMinusStart = end - start;
    const width = Math.min(((et - st) / endMinusStart) * 100, 100);
    const leftOffset = Math.max(((st - start) / endMinusStart) * 100, 0);

    let occupiedWidth = Math.min(((oet - ost) / (et - st)) * 100, 100);
    // check if occupied end is before start or occupied start is after end
    if (ost && oet && (oet < start || ost > end)) {
      occupiedWidth = 0;
    }
    const occupiedLeftOffset = Math.max(((ost - st) / (et - st)) * 100, 0);
    return {
      reservedWidth: width,
      reservedLeftOffset: leftOffset,
      occupiedWidth: occupiedWidth,
      occupiedLeftOffset: occupiedLeftOffset,
    };
  }

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderRefreshButton() {
    const { isLoadingOutlook, floor } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoadingOutlook}
        disabled={!floor}
        className="refresh-button"
        severity="info"
        size="small"
        onClick={() => this.getMeetingRoomOutlookData()}
      />
    );
  }

  datePickerOnChanged = e => {
    const BOUNCE_IN_MILISECONDS = 300;
    const { selectedDate } = this.state;
    if (!isSameDay(e.value, selectedDate)) {
      const debouncedFunc = debounce(() => {
        // passing data back to parent
        const { onDateChanged } = this.props;
        onDateChanged(e.value);
        this.getMeetingRoomOutlookData();
      }, BOUNCE_IN_MILISECONDS);
      if (this.pendingRequest) {
        // Cancel any pending requests
        clearTimeout(this.pendingRequest);
      }
      // Schedule the debounced function to run after 500ms
      this.pendingRequest = setTimeout(() => {
        debouncedFunc();
        this.pendingRequest = null;
      }, BOUNCE_IN_MILISECONDS);
    }

    this.setState({ selectedDate: e.value });
  };

  renderDatePicker() {
    const { selectedDate } = this.state;
    const maxDate = new Date();
    return (
      <CustomCalendar
        value={selectedDate}
        onChange={this.datePickerOnChanged}
        maxDate={maxDate}
        style={{ marginRight: '1rem' }}
      ></CustomCalendar>
    );
  }

  renderReverseOccupiedContainer(roomData, start, end) {
    return (
      <div className="reverse-occupied-container">
        {roomData.map(item => {
          const {
            reserved_start,
            reserved_end,
            occupied_start,
            occupied_end,
            occupied_minutes,
          } = item;
          const {
            reservedWidth,
            reservedLeftOffset,
            occupiedWidth,
            occupiedLeftOffset,
          } = this.calculateOffset(
            reserved_start,
            reserved_end,
            occupied_start,
            occupied_end,
            start,
            end
          );
          const targetId = generateRandomString(8);

          const childDiv = (
            <Fragment key={`child-div-${targetId}`}>
              <Tooltip
                className="child-div-tooltip"
                target={`.child-div-tooltip-target-${targetId}`}
                baseZIndex={2000}
                autoHide={false}
                position="top"
                event="hover"
              >
                {this.renderTooltipContent(item)}
              </Tooltip>
              <div
                className={`child-div child-div-tooltip-target-${targetId}`}
                style={{
                  width: `${reservedWidth}%`,
                  left: `${reservedLeftOffset}%`,
                  backgroundColor:
                    occupied_minutes === null ||
                    occupied_minutes.toString().trim() === ''
                      ? MEETING_ROOM_STATUS_BG_COLOR.NOT_FINISH_BG_COLOR
                      : MEETING_ROOM_STATUS_BG_COLOR.RESERVED_BG_COLOR,
                }}
              >
                {/* <div className="meeting-name">{subject}</div> */}
                {occupied_start && occupied_end && (
                  <div
                    className="occupied-child-div"
                    style={{
                      width: `${occupiedWidth}%`,
                      left: `${occupiedLeftOffset}%`,
                      backgroundColor:
                        MEETING_ROOM_STATUS_BG_COLOR.OCCUPIED_BG_COLOR,
                    }}
                  />
                )}
              </div>
            </Fragment>
          );

          return childDiv;
        })}
      </div>
    );
  }

  getLabelTimeList = ({ fromTimeStr, toTimeStr }) => {
    // Map the numeric values to time strings
    const convertTimeStringsToValues = times => {
      return times.map(time => {
        const [hours] = time.split(':');
        return parseInt(hours, 10);
      });
    };
    const convertValuesToTimeStrings = val => {
      const hours = val;
      const formattedHours = hours < 10 ? '0' + hours : hours;
      return `${formattedHours}:00`;
    };
    const numericValues = convertTimeStringsToValues([fromTimeStr, toTimeStr]);
    const labels = [];
    const diff = numericValues[1] - numericValues[0];

    for (let i = numericValues[0]; i <= numericValues[1]; i++) {
      let timeStr = convertValuesToTimeStrings(i);
      const leftPosition = ((i - numericValues[0]) / diff) * 100;
      labels.push({ time: timeStr, leftPosition });
    }
    return labels;
  };

  renderMeetingOutlook({ outlookData }) {
    if (!outlookData || outlookData.length === 0) {
      return <Message severity="warn" text={COMMON_TEXT.NO_DATA} />;
    }
    const { selectedDate, selectedTimeRange } = this.state;
    const fromTimeStr = selectedTimeRange.from;
    let toTimeStr = selectedTimeRange.to;
    let formatStart = 'yyyy-MM-dd ' + fromTimeStr;
    let formatEnd = 'yyyy-MM-dd ' + toTimeStr;
    let timelabels = this.getLabelTimeList({
      fromTimeStr,
      toTimeStr,
    });
    const formattedStartDate = format(selectedDate, formatStart);
    const formattedEndDate = format(selectedDate, formatEnd);

    const dataByRoomName = sortObjectKeys(
      convertArrayToObject(outlookData, 'room_name')
    );

    return (
      <div className="main-outside-div-container">
        <div key="empty-checkbox" className="main-div-item-container view-all">
          {this.renderSliderTimeRange()}
        </div>
        <div key="empty" className="main-div-item-container empty-container">
          <div className="room-name">{''}</div>
          <div className="main-div-container">
            {timelabels.map(({ time, leftPosition }) => (
              <div
                key={time}
                className="time-label"
                style={{ left: `calc(${leftPosition}% - 25px)` }}
              >
                {time}
              </div>
            ))}
            {this.renderNowLine(
              new Date(formattedStartDate).getTime(),
              new Date(formattedEndDate).getTime(),
              Object.keys(dataByRoomName).length
            )}
          </div>
        </div>
        <ScrollPanel style={{ width: '100%', height: '100%' }}>
          {Object.keys(dataByRoomName).map(roomName => {
            const roomData = dataByRoomName[roomName];
            let is_room_available = false;
            let room_available_ts = null;
            if (roomData?.length > 0) {
              is_room_available = roomData[0]['is_room_available'];
              room_available_ts = roomData[0]['room_available_ts'] ?? '-';
            }
            const targetId = generateRandomString(8);
            return (
              <div key={roomName} className="main-div-item-container">
                <div className="room-name">
                  <span className="text-left">{roomName}</span>
                  {is_room_available >= 0 ? (
                    <>
                      <span
                        className={`room-available-status room-available-target-${targetId} ${
                          is_room_available ? 'available' : 'unavailable'
                        }`}
                      ></span>
                      <Tooltip
                        className="child-div-tooltip"
                        target={`.room-available-target-${targetId}`}
                        baseZIndex={2000}
                        autoHide={false}
                        position="right"
                        event="hover"
                      >
                        <div className="tooltip-content">
                          <div className="tooltip-content-item">
                            <div>{COMMON_TEXT.LAST_UPDATED_TIME}</div>
                            <div>{room_available_ts}</div>
                          </div>
                        </div>
                      </Tooltip>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="main-div-container">
                  {this.renderReverseOccupiedContainer(
                    roomData,
                    new Date(formattedStartDate).getTime(),
                    new Date(formattedEndDate).getTime()
                  )}
                </div>
              </div>
            );
          })}
        </ScrollPanel>
      </div>
    );
  }

  renderSliderTimeRange() {
    // Map the numeric values to time strings
    const convertSliderValuesToTimeStrings = vals => {
      return vals.map(val => {
        if (val === 24) {
          return '23:59';
        }
        const hours = val;
        const formattedHours = hours < 10 ? '0' + hours : hours;
        return `${formattedHours}:00`;
      });
    };

    const handleSliderChange = e => {
      if (e.value[0] > e.value[1]) {
        return;
      }
      const timeStrings = convertSliderValuesToTimeStrings(e.value);
      const updatedSliderState = {
        sliderValues: e.value,
        selectedTimeRange: {
          from: timeStrings[0],
          to: timeStrings[1],
        },
      };
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.MEETING_ROOM_SLIDER_STATE,
          JSON.stringify(updatedSliderState)
        )
      );
      this.setState(updatedSliderState);
    };

    const { sliderValues, selectedTimeRange } = this.state;

    return (
      <div className="outlook-title-container">
        <div className="slider-container">
          <Slider
            value={sliderValues}
            onChange={handleSliderChange}
            range
            min={0}
            max={24}
            step={1}
          />
          <div
            className="time-slider-tooltip-start"
            style={{ left: `calc(${(sliderValues[0] / 24) * 100}% - 1.2rem)` }}
          >
            {selectedTimeRange.from}
          </div>
          <div
            className="time-slider-tooltip-end"
            style={{ left: `calc(${(sliderValues[1] / 24) * 100}% - 1.2rem)` }}
          >
            {selectedTimeRange.to}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isLoadingOutlook, outlookData } = this.state;

    return (
      <div className="network-container meeting-room-outlook">
        <div className="network-title-container">
          <div className="text-left">
            <div>{COMMON_TEXT.MEETING_ROOM_OUTLOOK_BAR}</div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            {this.renderDatePicker()}
            {this.renderRefreshButton()}
          </div>
        </div>
        <div className="network-content">
          {this.renderMessageError() || (
            <>
              <div className="outlook-container">
                {isLoadingOutlook ? (
                  <LoadingSpinner />
                ) : (
                  <>{this.renderMeetingOutlook({ outlookData })}</>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

MeetingRoomOutlook.propTypes = {};

export default MeetingRoomOutlook;
