import { CustomAxisTick } from '@/components/CustomComponent/CustomAxisTick';
import { CustomTooltipLineChart } from '@/components/CustomComponent/CustomTooltipLineChart';
import { CustomYAxisTick } from '@/components/CustomComponent/CustomYAxisTick';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import { COMMON_TEXT } from '@/helpers/common-text';
import { CHART_NAME, THRESHOLD_TYPE } from '@/helpers/constants';
import { generateRandomString } from '@/helpers/utility';
import { differenceInHours, getHours, getMinutes } from 'date-fns';
import { cloneDeep, isEqual, isNil, max, min } from 'lodash';
import { Message } from 'primereact/message';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const loadingLayerStyle = {
  position: 'absolute',
  left: 70,
  top: 40,
  right: 10,
  bottom: 105,
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const LoadingContainer = props => {
  return (
    <div style={loadingLayerStyle}>
      <LoadingSpinner />
    </div>
  );
};

const NoDataContainer = props => {
  return (
    <div style={loadingLayerStyle}>
      <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
    </div>
  );
};

const CustomizedXAxisLabel = ({ viewBox, value }) => {
  const { x, y, width, height } = viewBox;
  return (
    <text
      x={x + width / 2}
      y={y + height / 2 + 8}
      fill="#666"
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize={'0.875rem'}
      fontWeight={600}
    >
      {value}
    </text>
  );
};

class CustomLineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxTickVal: undefined,
      lineChartData: [],
      isShowThreshold: false,
    };
    this.ticks = [];
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({ lineChartData: data });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.ticks && this.ticks.length) {
      const maxTick = Math.max(...this.ticks);
      if (maxTick !== this.state.maxTickVal) {
        this.setState({ maxTickVal: maxTick });
      }
    }
    // check if props.data is changed, update state.lineChartData
    const { data: prevData } = prevProps;
    const { data } = this.props;
    if (isEqual(prevData, data) === false) {
      this.setState({ lineChartData: this.props.data });
    }
  }

  tickFormatter = tick => {
    this.ticks.push(tick);
    return tick;
  };

  getTickListByDateTime = firstDataList => {
    let tickList = [];
    if (firstDataList.length === 0) {
      return tickList;
    }
    const ticketSet = new Set();
    let lastHour = null;
    for (let i = 0; i < firstDataList.length; i++) {
      const { timestamp } = firstDataList[i];
      const parsedTs = new Date(timestamp);
      const currentHour = getHours(parsedTs);
      if (getMinutes(parsedTs) === 0 && currentHour !== lastHour) {
        lastHour = currentHour;
        ticketSet.add(timestamp);
      }
    }
    tickList = Array.from(ticketSet);
    if (tickList.length === 0) {
      tickList = firstDataList.map(({ timestamp }) => timestamp);
    }
    return tickList;
  };

  getTickListByDate = firstDataList => {
    let tickList = [];
    if (firstDataList.length === 0) {
      return tickList;
    }
    const { date: firstTimestamp } = firstDataList?.[0];
    let currentCompareTs = new Date(firstTimestamp);
    for (let i = 0; i < firstDataList.length; i++) {
      const { date } = firstDataList[i];
      const parsedTs = new Date(date);
      const hourDifference = differenceInHours(parsedTs, currentCompareTs);
      if (i === 0 || Math.abs(hourDifference) >= 24) {
        currentCompareTs = new Date(date);
        tickList.push(date);
      }
    }
    if (tickList.length === 0) {
      tickList = firstDataList.map(({ timestamp }) => timestamp);
    }
    return tickList;
  };

  renderLabelContent = props => {
    return (
      <text
        fill="#333333"
        offset="0"
        x={props.viewBox.cx}
        y={props.viewBox.cy}
        textAnchor="middle"
        fontWeight={700}
      >
        <tspan x="56" dy="16">
          {`(${props.value})`}
        </tspan>
      </text>
    );
  };

  findMinMaxValueYAxis = ({
    lineChartData,
    yAxisDataKey,
    maxTickVal,
    yAxisMaxValue,
    yAxisMinValue,
  }) => {
    let valueArray = new Set();
    for (let { data } of lineChartData) {
      data.forEach((item, index) => {
        valueArray.add(item[yAxisDataKey]);
      });
    }
    if (maxTickVal) {
      valueArray.add(maxTickVal);
    }
    if (yAxisMaxValue) {
      valueArray.add(yAxisMaxValue);
    }
    if (yAxisMinValue >= 0) {
      valueArray.add(yAxisMinValue);
    }
    return {
      max: max(Array.from(valueArray)),
      min: min(Array.from(valueArray)),
    };
  };

  renderLineData = ({ lineChartData, yAxisDataKey }) => {
    if (!lineChartData || lineChartData.length === 0) {
      return null;
    }
    return lineChartData.map(({ name, data, type, fillColor }, index) => (
      <Line
        key={`line-${index}`}
        name={name}
        data={data}
        type={type ?? 'monotone'}
        dataKey={yAxisDataKey}
        stroke={fillColor}
        dot={false}
      />
    ));
  };

  // This is for generate area data for display threshold
  renderAreaData = ({ areaData, yAxisDataKey }) => {
    if (!areaData || areaData.length === 0) {
      return null;
    }
    return areaData.map(({ name, data, type, fillColor }, index) => (
      <Area
        key={`area-${index}`}
        name={name}
        data={data}
        type={type ?? 'monotone'}
        dataKey={yAxisDataKey}
        stroke={'transparent'}
        fill={fillColor}
        dot={false}
      />
    ));
  };

  getAreaData = ({
    lineChartData,
    threshold,
    thresholdDirection,
    thresholdRange,
    yAxisDataKey,
    yAxisMaxValue,
    yAxisMinValue,
  }) => {
    let areaDataList = [];
    let firstLineData = cloneDeep(lineChartData[0]);
    if (!isNil(thresholdRange) && thresholdRange.length > 0) {
      const { data: lineDataList } = firstLineData;
      for (let threshold of thresholdRange) {
        const { fromValue, toValue, color } = threshold;
        const areaItem = {
          name: generateRandomString(10),
          data: [],
          fillColor: color,
        };
        let newToValue = toValue;
        let newFromValue = fromValue;

        // check if newToValue and newFromValue is in range of yAxisMaxValue and yAxisMinValue
        // if not in range, no processing
        if (toValue < yAxisMinValue || fromValue > yAxisMaxValue) {
          // ignore this threshold
          continue;
        } else if (toValue > yAxisMaxValue && fromValue < yAxisMinValue) {
          newToValue = yAxisMaxValue;
          newFromValue = yAxisMinValue;
        } else if (toValue > yAxisMaxValue && fromValue >= yAxisMinValue) {
          newToValue = yAxisMaxValue;
        } else if (fromValue < yAxisMinValue && toValue <= yAxisMaxValue) {
          newFromValue = yAxisMinValue;
        }
        lineDataList.forEach(dataItem => {
          const { timestamp } = dataItem;
          areaItem.data.push({
            timestamp,
            [yAxisDataKey]: [newFromValue, newToValue],
          });
        });
        areaDataList.push(areaItem);
      }
    } else {
      if (!isNil(threshold) && firstLineData) {
        firstLineData['fillColor'] = '#FFDEE4';
        const { data: firstLineDataArray } = firstLineData;
        if (thresholdDirection === THRESHOLD_TYPE.UPPER) {
          for (let dataItem of firstLineDataArray) {
            dataItem[yAxisDataKey] = [threshold, yAxisMaxValue];
          }
          areaDataList = [firstLineData];
        } else if (thresholdDirection === THRESHOLD_TYPE.LOWER) {
          for (let dataItem of firstLineDataArray) {
            dataItem[yAxisDataKey] = threshold;
          }
          areaDataList = [firstLineData];
        }
      }
    }
    return areaDataList;
  };

  getXAxisLabel = ({ threshold, thresholdDirection }) => {
    let xAxisLabel = null;
    // if (threshold) {
    //   if (thresholdDirection === THRESHOLD_TYPE.UPPER) {
    //     xAxisLabel = `${
    //       COMMON_TEXT.RESONABLE_VALUE
    //     }: < ${formatNumberWithCommas(threshold)}`;
    //   } else if (thresholdDirection === THRESHOLD_TYPE.LOWER) {
    //     xAxisLabel = `${
    //       COMMON_TEXT.RESONABLE_VALUE
    //     }: > ${formatNumberWithCommas(threshold)}`;
    //   }
    // }
    return xAxisLabel;
  };

  getYAxisLabel = ({ yAxisLabel, yAxisDataKey }) => {
    return yAxisLabel ?? yAxisDataKey;
  };

  getTooltipLabel = ({ tooltipLabel, yAxisDataKey }) => {
    return tooltipLabel ?? yAxisDataKey;
  };

  getYAxisDomain = ({ yAxisMaxValue, yAxisMinValue }) => {
    if (yAxisMaxValue && yAxisMinValue) {
      return [yAxisMinValue, yAxisMaxValue];
    } else if (yAxisMaxValue && !yAxisMinValue) {
      return [dataMin => dataMin, dataMax => yAxisMaxValue];
    } else {
      return [dataMin => dataMin, dataMax => dataMax];
    }
  };

  getXAxisTickList = ({ xAxisDataKey, lineChartData }) => {
    let tickList = [];
    const firstDataList = lineChartData[0]?.['data'] || [];
    if (xAxisDataKey === 'timestamp') {
      tickList = this.getTickListByDateTime(firstDataList);
    } else if (xAxisDataKey === 'date') {
      tickList = this.getTickListByDate(firstDataList);
    }
    return tickList;
  };

  render() {
    let {
      name,
      xAxisDataKey,
      yAxisDataKey,
      yAxisMaxValue,
      yAxisMinValue,
      yAxisLabel: yAxisLabelProp,
      isLoading,
      threshold,
      thresholdDirection,
      thresholdRange, // for air quality
      containerHeight,
      tooltipLabel: tooltipLabelProp,
    } = this.props;

    let { lineChartData: data, maxTickVal } = this.state;
    data = data || [];

    const isEmptyData = data.length === 0;
    containerHeight = containerHeight ?? 360;
    if (isLoading) {
      return (
        <div style={{ position: 'relative', minHeight: containerHeight }}>
          <LoadingContainer></LoadingContainer>
        </div>
      );
    } else if (isEmptyData) {
      return (
        <div style={{ position: 'relative', minHeight: containerHeight }}>
          <NoDataContainer></NoDataContainer>
        </div>
      );
    }

    // get xAxisLabel
    const xAxisLabel = this.getXAxisLabel({ threshold, thresholdDirection });
    const yAxisLabel = this.getYAxisLabel({
      yAxisLabel: yAxisLabelProp,
      yAxisDataKey,
    });
    const tooltipLabel = this.getTooltipLabel({
      tooltipLabel: tooltipLabelProp,
      yAxisDataKey,
    });
    let { max: newYAxisMaxValue, min: newYAxisMinValue } =
      this.findMinMaxValueYAxis({
        lineChartData: data,
        yAxisDataKey,
        maxTickVal,
        yAxisMaxValue,
        yAxisMinValue,
      });
    const yAxisDomain = this.getYAxisDomain({
      yAxisMaxValue: newYAxisMaxValue,
      yAxisMinValue: newYAxisMinValue,
    });
    const yAxisTicks = [];
    let tickMaxValue = Math.ceil(newYAxisMaxValue);
    let tickMinValue = Math.floor(newYAxisMinValue);
    if (name === CHART_NAME.CARBON_DIOXIDE_LINE_CHART) {
      // round up to nearest 10 for CO2
      tickMaxValue = Math.ceil(newYAxisMaxValue / 10) * 10;
      tickMinValue = Math.floor(newYAxisMinValue / 10) * 10;
    }
    if (tickMinValue !== tickMaxValue) {
      for (let i = 0; i < 4; i++) {
        const tickValue =
          tickMinValue + ((tickMaxValue - tickMinValue) / 4) * i;
        if (name === CHART_NAME.CARBON_DIOXIDE_LINE_CHART) {
          yAxisTicks.push(Math.floor(tickValue / 10) * 10);
        } else {
          yAxisTicks.push(Math.floor(tickValue));
        }
      }
      yAxisTicks.push(tickMaxValue);
    }

    const areaData = this.getAreaData({
      lineChartData: data,
      threshold,
      thresholdDirection,
      thresholdRange,
      yAxisDataKey,
      yAxisMaxValue: newYAxisMaxValue,
      yAxisMinValue: newYAxisMinValue,
    });

    // Assume that data is sorted by timestamp
    const tickList = this.getXAxisTickList({
      xAxisDataKey,
      lineChartData: data,
    });

    return (
      <div style={{ position: 'relative' }}>
        <ResponsiveContainer
          width={'100%'}
          height={containerHeight}
          minHeight={containerHeight}
        >
          <ComposedChart margin={{ top: 40, right: 10, left: 10, bottom: 5 }}>
            <Tooltip
              content={<CustomTooltipLineChart dataKeyLabel={tooltipLabel} />}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={xAxisDataKey}
              type="category"
              allowDuplicatedCategory={false}
              padding={{ left: 10, right: 10 }}
              tick={
                <CustomAxisTick
                  rotate={-45}
                  textAnchor="end"
                  type={xAxisDataKey}
                />
              }
              ticks={tickList}
              height={100}
              interval={0}
              label={xAxisLabel && <CustomizedXAxisLabel value={xAxisLabel} />}
            />
            <YAxis
              dataKey={yAxisDataKey}
              tick={<CustomYAxisTick />}
              ticks={yAxisTicks}
              tickFormatter={this.tickFormatter}
              type="number"
              allowDecimals={yAxisDataKey !== 'counts'}
              domain={yAxisDomain}
            >
              <Label
                value={yAxisLabel}
                content={this.renderLabelContent}
                offset={0}
                position="top"
              />
            </YAxis>
            {this.renderLineData({ lineChartData: data, yAxisDataKey })}
            {this.renderAreaData({ areaData: areaData, yAxisDataKey })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

CustomLineChart.propTypes = {
  name: PropTypes.string,
  chartType: PropTypes.string,
  data: PropTypes.array,
  xAxisDataKey: PropTypes.string,
  yAxisDataKey: PropTypes.string,
  yAxisMaxValue: PropTypes.number,
  yAxisMinvalue: PropTypes.number,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  threshold: PropTypes.number, // for normal threshold
  thresholdDirection: PropTypes.string, // for normal threshold
  thresholdRange: PropTypes.array, // for air quality
  containerHeight: PropTypes.number,
  tooltipLabel: PropTypes.string,
};

export default CustomLineChart;
