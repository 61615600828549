import { QUERY_TABLES } from '@/helpers/constants';
import { isUsingSampleData } from 'src/helpers/generate-random-data';
import { executeQuery } from 'src/helpers/request';

const buildRequestQueryBarChart = (floorId, limit, type) => {
  const tableName =
    type === 'weekly'
      ? QUERY_TABLES.MEETING_ROOM_MART_TABLE_UNUSED_WEEKLY
      : QUERY_TABLES.MEETING_ROOM_MART_TABLE_UNUSED_MONTHLY;
  if (!limit) {
    limit = 0;
  }
  return {
    data: {
      fields: {
        floor_id: ['select'],
        organizer: ['select'],
        tenant_id: ['select'],
        unused_minutes: ['select'],
      },
      skip: 0,
      limit: limit,
    },
    filter: {
      floor_id: [floorId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      unused_minutes: 'desc',
    },
    table_name: tableName,
  };
};

export const fetchDataBarChart = async payload => {
  const { floorId, limit, type } = payload;
  const request = buildRequestQueryBarChart(floorId, limit, type);
  if (isUsingSampleData) {
    return [];
  }
  return executeQuery(request);
};

export const fetchDataLineChart = async payload => {
  return [];
};
