import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from 'src/helpers/request';
import { getRandomNumberRange } from 'src/helpers/utility';

const buildRequestQuery = branchId => {
  const randomBranchId = getRandomNumberRange({ min: 900000, max: 999999 });
  return {
    data: {
      fields: {
        base_branch_id: ['select'],
        current_floor_id: ['select'],
        department: ['select'],
        email: ['select'],
        employee_id: ['select'],
        employee_image: ['select'],
        employee_name: ['select'],
        employee_number: ['select'],
        phone_number: ['select'],
        team: ['select'],
        tenant_id: ['select'],
        timestamp: ['select'],
        url_teams: ['select'],
        chat_tool_presence: ['select'],
        chat_tool_presence_color: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      base_branch_id: [branchId, randomBranchId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.EMPLOYEE_LOCATIONS_MART_TABLE,
  };
};

const buildRequestEmployeeInfo = ({ employeeId, branchId, tenantId }) => {
  const randomTenantId = getRandomNumberRange({ min: 900000, max: 999999 });
  return {
    data: {
      fields: {
        base_branch_id: ['select'],
        current_floor_id: ['select'],
        department: ['select'],
        email: ['select'],
        employee_id: ['select'],
        employee_image: ['select'],
        employee_name: ['select'],
        employee_number: ['select'],
        phone_number: ['select'],
        team: ['select'],
        tenant_id: ['select'],
        timestamp: ['select'],
        url_teams: ['select'],
        chat_tool_presence: ['select'],
        chat_tool_presence_color: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      employee_id: {
        eq: [employeeId],
      },
      base_branch_id: {
        eq: [branchId],
      },
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.EMPLOYEE_LOCATIONS_MART_TABLE,
  };
};

const buildRequestEmployeeLocationEmployeeIdList = ({ employeeIdList, tenantId, floorId }) => {
  const randomTenantId = getRandomNumberRange({ min: 900000, max: 999999 });
  return {
    data: {
      fields: {
        branch_name: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        department: ['select'],
        device_type: ['select'],
        employee_device_mac: ['select'],
        employee_device_name: ['select'],
        employee_id: ['select'],
        employee_image: ['select'],
        employee_name: ['select'],
        floor_id: ['select'],
        floor_name: ['select'],
        team: ['select'],
        tenant_id: ['select'],
        timestamp: ['select']
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      employee_id: employeeIdList,
      tenant_id: [tenantId, randomTenantId],
      floor_id: [floorId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.EMPLOYEE_LOCATIONS_MART,
  };
};

const buildRequestEmployeeLocation = ({ employeeIdList, tenantId }) => {
  const randomTenantId = getRandomNumberRange({ min: 900000, max: 999999 });
  return {
    data: {
      fields: {
        branch_name: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        department: ['select'],
        device_type: ['select'],
        employee_device_mac: ['select'],
        employee_device_name: ['select'],
        employee_id: ['select'],
        employee_image: ['select'],
        employee_name: ['select'],
        floor_id: ['select'],
        floor_name: ['select'],
        team: ['select'],
        tenant_id: ['select'],
        timestamp: ['select']
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      employee_id: employeeIdList,
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.EMPLOYEE_LOCATIONS_MART,
  };
};

const buildRequestEmployeeEventsQuery = ({ employeeId, fromDate, toDate, tenantId }) => {
  return {
    data: {
      fields: {
        allday: ['select'],
        employee_event_id: ['select'],
        employee_id: ['select'],
        end_time: ['select'],
        location: ['select'],
        start_time: ['select'],
        subject: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      employee_id: [employeeId],
      start_time: {
        gte: [fromDate],
        lte: [toDate],
      },
      tenant_id: [tenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      start_time: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEE_EVENTS,
  };
};

export const fetchDataByBranchId = async payload => {
  const { branchId } = payload;
  const request = buildRequestQuery(branchId);
  return executeQuery(request);
};

export const fetchEmployeeLocationByEmployeeIdList = async payload => {
  const { employeeIdList, tenantId, floorId } = payload;
  const request = buildRequestEmployeeLocationEmployeeIdList({ employeeIdList, tenantId, floorId });
  return executeQuery(request);
};

export const fetchEmployeeEvents = async payload => {
  const { employeeId, fromDate, toDate, tenantId } = payload;
  const request = buildRequestEmployeeEventsQuery({ employeeId, fromDate, toDate, tenantId });
  return executeQuery(request);
};

export const fetchEmployeeInfo = async payload => {
  const { employeeId, branchId, tenantId } = payload;
  const request = buildRequestEmployeeInfo({ employeeId, branchId, tenantId });
  return executeQuery(request);
};

export const fetchEmployeeLocationByEmployeeId = async payload => {
  const { employeeId, tenantId } = payload;
  const employeeIdList = [employeeId];
  const request = buildRequestEmployeeLocation({ employeeIdList, tenantId });
  return executeQuery(request);
};
