import CustomCalendar from '@/components/CustomComponent/CustomCalendar/CustomCalendar';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  DEVICE_TYPE,
  MEMORY_STORE,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import FpDataStore from '@/services/FpDatastore';
import { addDays, format, isBefore } from 'date-fns';
import { cloneDeep } from 'lodash';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceStatusOverviewHistory from './DeviceStatusOverviewHistory';
import DeviceStatusOverviewNow from './DeviceStatusOverviewNow';
import {
  fetchData,
  fetchDataHistory,
  fetchDataHistoryEmptyEndDate,
} from './query-request';

const fpDataStore = new FpDataStore();

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class DeviceStatusOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTable: [],
      dataTableHistory: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoading: false,
      isLoadingHistory: false,
      floorMapUrl: '',
      floorMapError: false,
      selectedDeviceModelList: [],
      deviceModelOptions: [],
      tabActiveIndex: 0,
      startDate: new Date(),
      endDate: new Date(),
    };

    this.dataTableRef = React.createRef();
    this.dataHistoryTableRef = React.createRef();
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      product: { value: null, matchMode: FilterMatchMode.CONTAINS },
      device_type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      device_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    };
  }

  validateFloorMapUrl(url, successCallback, errorCallback) {
    var img = new Image();
    img.onload = function () {
      successCallback(url);
    };
    img.onerror = function () {
      errorCallback(url);
    };
    img.src = url;
  }

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    this.validateFloorMapUrl(
      floor?.floor_map,
      () => {
        this.setState({ floorMapError: false });
      },
      () => {
        this.setState({ floorMapError: true });
      }
    );
    return floor ? floor.floor_map : '';
  };

  componentDidMount() {
    const { floor } = this.state;
    let startDate = addDays(new Date(), -7);
    startDate.setHours(0, 0, 0, 0); // Set time to 0:00
    let endDate = addDays(new Date(), -1);
    endDate.setHours(23, 59, 59, 999); // Set time to 23:59
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
      },
      () => {
        if (floor) {
          this.setState({ floorMapUrl: this.getFloorMapUrl(floor) });
          this.fetchDataByFloorId();
          this.fetchDataHistoryByFloorId();
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    const floor =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    if (
      floor !==
      prevProps?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0]
    ) {
      this.setState({ floor, floorMapUrl: this.getFloorMapUrl(floor) }, () => {
        this.fetchDataByFloorId();
        this.fetchDataHistoryByFloorId();
      });
    }
  }

  getDeviceOptions = ({ dataList }) => {
    const deviceTypeSet = new Set(dataList.map(item => item.device_type));

    const deviceOptions = [];
    for (const key in DEVICE_TYPE) {
      if (DEVICE_TYPE.hasOwnProperty(key) && deviceTypeSet.has(key)) {
        deviceOptions.push({ code: key, name: DEVICE_TYPE[key] });
      }
    }
    return deviceOptions;
  };

  fetchDataByFloorId = async () => {
    const { floor } = this.state;
    this.setState({ isLoading: true });
    let dataFloorMap = await fetchData({
      floorId: floor,
    });

    if (!dataFloorMap) {
      dataFloorMap = [];
    }

    const deviceOptions = this.getDeviceOptions({ dataList: dataFloorMap });

    const statusIconList = dataFloorMap
      .filter(item => item.icon && item.icon !== 'default')
      .map(item => item.icon);
    const statusIconBase64Map = {};
    for (const statusIcon of statusIconList) {
      const statusIconBase64 =
        fpDataStore.getObjectDownloadUrlWithBucket(statusIcon);
      statusIconBase64Map[statusIcon] = statusIconBase64;
    }

    const updatedFloorMapData = dataFloorMap.map(item => ({
      position: {
        x: parseFloat(item?.position_x),
        y: parseFloat(item?.position_y),
      },
      data: item ?? null,
      iconImage: statusIconBase64Map[item.icon] ?? 'default',
    }));

    this.setState({
      data: updatedFloorMapData,
      dataTable: cloneDeep(dataFloorMap),
      deviceModelOptions: deviceOptions,
      selectedDeviceModelList: deviceOptions,
      isLoading: false,
    });
  };

  fetchDataHistoryByFloorId = async () => {
    const { floor, startDate, endDate } = this.state;
    this.setState({ isLoadingHistory: true });
    let dataHistoryRes = await fetchDataHistory({
      floorId: floor,
      fromDate: format(startDate, 'yyyy-MM-dd 00:00:00'),
      toDate: format(endDate, 'yyyy-MM-dd 23:59:59'),
    });

    dataHistoryRes = dataHistoryRes ?? [];

    let dataHistoryEmptyEndDateRes = await fetchDataHistoryEmptyEndDate({
      floorId: floor,
      fromDate: format(startDate, 'yyyy-MM-dd 00:00:00'),
      toDate: format(endDate, 'yyyy-MM-dd 23:59:59'),
    });

    dataHistoryEmptyEndDateRes = dataHistoryEmptyEndDateRes ?? [];
    dataHistoryRes = dataHistoryRes.concat(dataHistoryEmptyEndDateRes);

    this.setState({
      dataTableHistory: cloneDeep(dataHistoryRes),
      isLoadingHistory: false,
    });
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderRefreshButton() {
    const { isLoading, isLoadingHistory } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoading || isLoadingHistory}
        className="refresh-button"
        severity="info"
        size="small"
        onClick={() => {
          this.fetchDataByFloorId();
          this.fetchDataHistoryByFloorId();
        }}
      />
    );
  }

  multiSelectDeviceModelOnChanged = e => {
    this.setState({ selectedDeviceModelList: e.value });
  };

  renderMultiSelectDeviceModel = () => {
    const { selectedDeviceModelList, deviceModelOptions } = this.state;

    const itemTemplate = option => {
      return (
        <div className="flex align-items-center">
          <div
            style={{
              backgroundColor: option.fillColor,
              padding: '0.125rem 0.5rem',
              borderRadius: '4px',
            }}
          >
            {option.name}
          </div>
        </div>
      );
    };
    const footerTemplate = () => {
      const length = selectedDeviceModelList
        ? selectedDeviceModelList.length
        : 0;

      return (
        <div className="py-2 px-3">
          <span>
            <b>{length}</b> アイテムが選択されました
          </span>
        </div>
      );
    };
    return (
      <MultiSelect
        value={selectedDeviceModelList}
        options={deviceModelOptions}
        onChange={this.multiSelectDeviceModelOnChanged}
        optionLabel="name"
        placeholder={COMMON_TEXT.SELECT}
        itemTemplate={itemTemplate}
        panelFooterTemplate={footerTemplate}
        className="multiselect-style"
        display="chip"
        filter
      />
    );
  };

  tabHeaderTemplate = options => {
    const icon =
      options.titleElement.props.children === 'NOW'
        ? 'pi pi-clock'
        : 'pi pi-history';
    const label =
      options.titleElement.props.children === 'NOW' ? '現在' : '履歴';
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <i className={`pi ${icon} mr-2`} />
        {label}
      </button>
    );
  };

  datePickerOnChanged = (e, type) => {
    const { startDate, endDate } = this.state;
    let newVal = e.value;
    let updatedState = {};

    if (type === 'startDate') {
      if (newVal && endDate && isBefore(endDate, newVal)) {
        let newEndDate = new Date(newVal.getTime());
        newEndDate.setHours(23, 59, 59, 999);
        updatedState = { startDate: newVal, endDate: newEndDate };
      } else {
        updatedState = { startDate: newVal, endDate: endDate };
      }
    } else if (type === 'endDate') {
      let newEndDate = new Date(newVal.getTime());
      newEndDate.setHours(23, 59, 59, 999);
      if (startDate && newVal && isBefore(newVal, startDate)) {
        updatedState = { startDate: newVal, endDate: newEndDate };
      } else {
        updatedState = { startDate: startDate, endDate: newEndDate };
      }
    }

    this.setState(updatedState, () => {
      this.fetchDataHistoryByFloorId();
    });
  };

  renderDatePicker() {
    const { startDate, endDate } = this.state;
    const maxDate = new Date();
    return (
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left' }}>
        <CustomCalendar
          value={startDate}
          onChange={value => this.datePickerOnChanged(value, 'startDate')}
          maxDate={maxDate}
          style={{ marginRight: '1rem' }}
          label="開始日付"
        ></CustomCalendar>

        <CustomCalendar
          value={endDate}
          onChange={value => this.datePickerOnChanged(value, 'endDate')}
          maxDate={maxDate}
          style={{ marginRight: '1rem' }}
          label="終了日付"
        ></CustomCalendar>
      </div>
    );
  }

  render() {
    const {
      tabActiveIndex,
      data,
      dataTable,
      dataTableHistory,
      isLoading,
      isLoadingHistory,
      startDate,
      endDate,
      floorMapUrl,
      floorMapError,
      selectedDeviceModelList,
      deviceModelOptions,
    } = this.state;

    return (
      <div className="config-container custom-config-container device-status-overview">
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">
              {COMMON_TEXT.DEVICE_STATUS_OVERVIEW}
            </div>
          </div>
          <div className="config-custom-container">
            <div className="multiselect-container mr-2">
              {tabActiveIndex === 0
                ? this.renderMultiSelectDeviceModel()
                : this.renderDatePicker()}
            </div>
            <div className="">{this.renderRefreshButton()}</div>
          </div>
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <TabView
                className=""
                activeIndex={tabActiveIndex}
                renderActiveOnly={true}
                onTabChange={e => this.setState({ tabActiveIndex: e.index })}
              >
                <TabPanel header="NOW" headerTemplate={this.tabHeaderTemplate}>
                  <div className="m-0">
                    {tabActiveIndex === 0 && (
                      <DeviceStatusOverviewNow
                        dataTable={dataTable}
                        data={data}
                        isLoading={isLoading}
                        floorMapUrl={floorMapUrl}
                        floorMapError={floorMapError}
                        selectedDeviceModelList={selectedDeviceModelList}
                        deviceModelOptions={deviceModelOptions}
                      ></DeviceStatusOverviewNow>
                    )}
                  </div>
                </TabPanel>
                <TabPanel
                  header="HISTORY"
                  headerTemplate={this.tabHeaderTemplate}
                >
                  <div className="m-0">
                    {tabActiveIndex === 1 && (
                      <DeviceStatusOverviewHistory
                        dataTableHistory={dataTableHistory}
                        isLoadingHistory={isLoadingHistory}
                        endDate={endDate}
                        startDate={startDate}
                      ></DeviceStatusOverviewHistory>
                    )}
                  </div>
                </TabPanel>
              </TabView>
            </>
          )}
        </div>
      </div>
    );
  }
}

DeviceStatusOverview.propTypes = {};

export default DeviceStatusOverview;
