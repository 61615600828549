import { QUERY_TABLES } from '@/helpers/constants';
import { isUsingSampleData } from 'src/helpers/generate-random-data';
import { executeQuery } from 'src/helpers/request';

const buildQueryTableData = (filterPayload) => {
  return {
    data: {
      fields: {
        timestamp: ['select'],
        admin_name: ['select'],
        network_name: ['select'],
        ssid_name: ['select'],
        page: ['select'],
        label: ['select'],
        old_value: ['select'],
        new_value: ['select'],
      },
      skip: 0,
      limit: 1000,
    },
    filter: filterPayload,
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.MERAKI_CONFIGURATION_CHANGES,
  };
};

export const fetchMerakiConfigurationEvents = async payload => {
  const request = buildQueryTableData(payload);
  if (isUsingSampleData) {
    return [];
  }
  return executeQuery(request);
};
