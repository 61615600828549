import { percentToPixel, pixelToPercent } from '@/helpers/utility';
import { Button } from 'primereact/button';
import * as React from 'react';
import { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import './image-marker.less';
import { calculateMarkerPosition } from './utils';

const DEFAULT_BUFFER = 12;
const INITIAL_SCALE = 1.0;

const ImageMarker = ({
  src,
  markers,
  onAddMarker,
  markerComponent: MarkerComponent,
  bufferLeft = DEFAULT_BUFFER,
  bufferTop = DEFAULT_BUFFER,
  alt = 'Markers',
  extraClass = '',
  isDisableDragMarker = true,
  onMarkerClicked,
}) => {
  const imageRef = useRef(null);
  const [isDisablePan, setIsDisablePan] = useState(false);
  const [currentScaleValue, setCurrentScaleValue] = useState(INITIAL_SCALE);

  const handleImageClick = event => {
    if (!imageRef.current || !onAddMarker) {
      return;
    }
    const imageDimentions = imageRef.current.getBoundingClientRect();

    const [top, left] = calculateMarkerPosition(
      event,
      imageDimentions,
      window.scrollY,
      bufferLeft,
      bufferTop
    );

    onAddMarker({
      top,
      left,
    });
  };

  const markerOnClicked = ({ data }) => {
    if (onMarkerClicked) {
      onMarkerClicked({ data });
    }
  };

  const getItemPosition = marker => {
    const { top, left } = marker;
    return {
      top: `${top}%`,
      left: `${left}%`,
    };
  };

  const startDrag = () => {
    setIsDisablePan(true);
  };

  const dragAction = (e, data, marker) => {
    const imageDimentions = imageRef.current.getBoundingClientRect();
    const currentMarkerXPx =
      percentToPixel(marker.left, imageDimentions.width) +
      data.x * currentScaleValue;
    const currentMarkerXPercent = pixelToPercent(
      currentMarkerXPx,
      imageDimentions.width
    );
    const currentMarkerYPx =
      percentToPixel(marker.top, imageDimentions.height) +
      data.y * currentScaleValue;
    const currentMarkerYPercent = pixelToPercent(
      currentMarkerYPx,
      imageDimentions.height
    );
    const { device } = marker;
    const { position } = device;
    if (position) {
      marker.device.position.position_x = currentMarkerXPercent / 100;
      marker.device.position.position_y = currentMarkerYPercent / 100;
    } else {
      marker.device.position_x = currentMarkerXPercent / 100;
      marker.device.position_y = currentMarkerYPercent / 100;
    }
  };

  const stopDrag = () => {
    setIsDisablePan(false);
  };

  const isImagePortrait = () => {
    if (imageRef.current) {
      return imageRef.current.naturalHeight > imageRef.current.naturalWidth;
    }
    return false;
  };

  return (
    <TransformWrapper
      wheel={{ step: 0.1, disabled: true }}
      minScale={0.2}
      maxScale={2.0}
      initialScale={INITIAL_SCALE}
      panning={{ disabled: isDisablePan }}
      onTransformed={(ref, event) => {
        setCurrentScaleValue(ref.state.scale);
      }}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <div className="image-marker-tools p-buttonset">
            <Button
              icon="pi pi-plus"
              size="small"
              outlined
              severity="info"
              onClick={() => zoomIn()}
            />
            <Button
              icon="pi pi-arrows-alt ml-1 mr-2"
              size="small"
              outlined
              severity="info"
              onClick={() => resetTransform()}
            />
            <Button
              icon="pi pi-minus"
              size="small"
              outlined
              severity="info"
              onClick={() => zoomOut()}
            />
          </div>
          <TransformComponent
            wrapperStyle={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
            contentStyle={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
          >
            <div className="image-marker">
              <img
                src={src}
                alt={alt}
                onClick={handleImageClick}
                className={
                  'image-marker__image ' +
                  extraClass +
                  (isImagePortrait()
                    ? ' image-marker__image--portrait'
                    : ' image-marker__image--landscape')
                }
                ref={imageRef}
              />
              {markers.map((marker, itemNumber) => (
                <Draggable
                  key={`draggable-marker-${itemNumber}`}
                  onStart={startDrag}
                  onDrag={(e, data) => dragAction(e, data, marker)}
                  onStop={stopDrag}
                  disabled={isDisableDragMarker}
                  scale={currentScaleValue}
                >
                  <div
                    className={`image-marker__marker ${
                      !MarkerComponent && 'image-marker__marker--default'
                    }`}
                    key={itemNumber}
                    style={{ ...getItemPosition(marker), cursor: 'pointer' }}
                    data-testid="marker"
                  >
                    {MarkerComponent ? (
                      <MarkerComponent
                        {...marker}
                        itemNumber={itemNumber}
                        markerOnClicked={markerOnClicked}
                      />
                    ) : (
                      itemNumber + 1
                    )}
                  </div>
                </Draggable>
              ))}
            </div>
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

export default ImageMarker;
