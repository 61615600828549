import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getRandomNumberRange } from '@/helpers/utility';

const buildQueryPeopleCountsAreaMart = ({ floorId }) => {
  const randomFloorId = getRandomNumberRange({ min: 900000, max: 999999 });
  return {
    data: {
      fields: {
        area_id: ['select'],
        area_name: ['select'],
        counts: ['select'],
        capacity_limit: ['select'],
        capacity_rate: ['select'],
        capacity_zone: ['select'],
        timestamp: ['select'],
        floor_id: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        position_x1: ['select'],
        position_y1: ['select'],
        tenant_id: ['select'],
      },
      limit: null,
      skip: null,
    },
    filter: {
      floor_id: {
        eq: [floorId, randomFloorId],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.PEOPLE_COUNTS_AREA_MART,
  };
};

export const getPeopleCountsAreaByFloorId = async payload => {
  const { floorId } = payload;
  const request = buildQueryPeopleCountsAreaMart({ floorId });
  return executeQuery(request);
};
