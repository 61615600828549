import CustomCSVDownload from '@/components/CustomComponent/CustomCSVDownload/CustomCSVDownload';
import HeatMap from '@/components/CustomComponent/HeatMap';
import NetworkPerformanceCustomBarChart from '@/components/CustomComponent/NetworkPerformanceCustomBarChart';
import NetworkTitle from '@/components/CustomComponent/NetworkTitle';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  BAR_CHART_GRADE_COLOR,
  CSV_DOWNLOAD_COMPONENT,
  HEATMAP_SCORE_COLORS,
  LINE_CHART_COLOR_LIST,
  NETWORK_SORT_OPTIONS,
  PERFORMANCE_COMPONENT,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import {
  getRandomHexColor,
  sortObjectOrArrayByDeviceIdList,
} from '@/helpers/utility';
import { compareAsc, format, isSameDay } from 'date-fns';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ResponsiveContainer } from 'recharts';
import {
  fetchDataHeatMap,
  fetchDataTableAndBarChart,
  fetchDataTableAndBarChartByDeviceIdList,
  fetchDataTableAndBarChartLite,
} from './query-request';

@connect(state => ({
  querystring: state.querystring,
}))
class NetworkPerformanceScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartData: {
        data: [],
        xAxisDataKey: 'device_name',
      },
      deviceInfoOrderedList: [],
      heatMapData: [],
      xAxisHeatMapData: [],
      yAxisHeatMapData: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoadingTableAndBarChart: false,
      isLoadingHeatMap: false,
      isDisplayLoadMoreButton: false,
      csvDownload: false,
    };
    this.loadedDeviceIdList = [];
    this.allDeviceIdList = [];
  }

  componentDidMount() {
    const { floor } = this.state;
    const { selectedDate } = this.props;
    if (floor && selectedDate) {
      this.fetchDataForBarChart({ isLoadMore: false });
    }
  }

  componentDidUpdate(prevProps) {
    const floor =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const prevFloor =
      prevProps?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const { selectedLimit, selectedDate, sortOption } = this.props;
    const {
      sortOption: prevSortOption,
      selectedDate: prevSelectedDate,
      selectedLimit: prevSelectedLimit,
    } = prevProps;
    const isDateChanged = !isSameDay(selectedDate, prevSelectedDate);
    const isSelectedLimitChanged = selectedLimit !== prevSelectedLimit;
    const isSortOptionChanged = sortOption !== prevSortOption;
    if (floor !== prevFloor) {
      this.setState({ floor, isDisplayLoadMoreButton: false }, () => {
        this.fetchDataForBarChart({ isLoadMore: false });
      });
    } else if (isSelectedLimitChanged || isDateChanged || isSortOptionChanged) {
      this.setState({ isDisplayLoadMoreButton: false }, () => {
        this.fetchDataForBarChart({ isLoadMore: false });
      });
    }
  }

  sortDataBarChart(barChartData, sortOption) {
    const deviceInfoOrderedList = [];
    let sortedBarChartData = [];
    if (sortOption === NETWORK_SORT_OPTIONS.DEVICE_NAME_ASC) {
      sortedBarChartData = barChartData.sort((a, b) =>
        a.device_name.localeCompare(b.device_name)
      );
    } else if (sortOption === NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_DESC) {
      sortedBarChartData = barChartData.sort(
        (a, b) =>
          b.score - a.score || a.device_name.localeCompare(b.device_name)
      );
    } else if (sortOption === NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_ASC) {
      sortedBarChartData = barChartData.sort(
        (a, b) =>
          a.score - b.score || a.device_name.localeCompare(b.device_name)
      );
    }
    for (let i = 0; i < sortedBarChartData.length; i++) {
      deviceInfoOrderedList.push({
        index: i,
        deviceId: sortedBarChartData[i]['device_identifer'],
        deviceName: sortedBarChartData[i]['device_name'],
      });
    }

    return {
      sortedBarChartData,
      deviceInfoOrderedList,
    };
  }

  sortDataHeatMap(heatMapData, orderedDeviceIdList) {
    const xAxis = this.computeXAxisHeatMap(heatMapData);
    let yAxis = this.computeYAxisHeatMap(heatMapData);
    heatMapData = this.fillHeatMapData(xAxis, yAxis, heatMapData);
    const { sortedDataList } = sortObjectOrArrayByDeviceIdList({
      deviceIdList: orderedDeviceIdList.map(item => item.deviceId),
      dataObject: yAxis,
      sortKey: 'deviceId',
    });
    yAxis = sortedDataList;
    yAxis.reverse();
    return { heatMapData, xAxis, yAxis };
  }

  // set isLoadMore = true when there is not param isLoadMore
  fetchDataForBarChart = async ({ isLoadMore }) => {
    const { floor, barChartData } = this.state;
    const { selectedDate, selectedLimit, sortOption } = this.props;
    if (!floor) {
      return;
    }
    this.setState({ isLoadingTableAndBarChart: true });
    const isSelectLimitTypeAll = selectedLimit === 0;
    const dateString = format(selectedDate, 'yyyy-MM-dd');
    let mappingData = [];
    let willLoadHeatmapDeviceIdList = [];
    let isReset = false;
    if (isSelectLimitTypeAll) {
      const willLoadDeviceIdList = [];
      if (isLoadMore) {
        mappingData = barChartData.data || [];
        let count = 0;
        for (const deviceId of this.allDeviceIdList) {
          if (count >= 15) {
            break;
          }
          if (!this.loadedDeviceIdList.includes(deviceId)) {
            willLoadDeviceIdList.push(deviceId);
            this.loadedDeviceIdList.push(deviceId);
            count += 1;
          }
        }
        if (this.loadedDeviceIdList.length >= this.allDeviceIdList.length) {
          this.setState({ isDisplayLoadMoreButton: false });
        }
      } else {
        // init load, load all data
        let dataAllRes = await fetchDataTableAndBarChartLite({
          floorId: floor,
          sortOption,
          selectedDate: dateString,
        });
        const allDeviceIdList = dataAllRes.map(item => item.device_identifer);
        dataAllRes = dataAllRes || [];
        // reset allDeviceIdList
        this.allDeviceIdList = [];
        this.allDeviceIdList.push(...allDeviceIdList);
        if (this.allDeviceIdList.length > 0) {
          this.setState({ isDisplayLoadMoreButton: true });
        }
        // only get 15 first items
        willLoadDeviceIdList.push(...allDeviceIdList.slice(0, 15));
        // reset loadedDeviceIdList
        this.loadedDeviceIdList = [];
        this.loadedDeviceIdList.push(...willLoadDeviceIdList);
        isReset = true;
      }
      // update willLoadHeatmapDeviceIdList
      willLoadHeatmapDeviceIdList = [...willLoadDeviceIdList];
      if (willLoadDeviceIdList.length > 0) {
        let resp = await fetchDataTableAndBarChartByDeviceIdList({
          floorId: floor,
          deviceIdList: willLoadDeviceIdList,
          selectedDate: dateString,
          sortOption,
        });
        resp = resp || [];
        const newMappingData = resp.map(item => {
          return {
            device_identifer: item?.device_identifer,
            device_name: item?.device_name,
            score: item?.score,
            fillColor:
              BAR_CHART_GRADE_COLOR[item?.grade?.toUpperCase()] ?? '#ffffff',
          };
        });
        mappingData.push(...newMappingData);
      } else {
        this.setState({ isDisplayLoadMoreButton: false });
      }
    } else {
      let dataResponse = await fetchDataTableAndBarChart({
        floorId: floor,
        selectedLimit,
        sortOption,
        selectedDate: dateString,
      });
      dataResponse = dataResponse || [];
      mappingData = dataResponse.map(item => {
        return {
          device_identifer: item?.device_identifer,
          device_name: item?.device_name,
          score: item?.score,
          fillColor:
            BAR_CHART_GRADE_COLOR[item?.grade?.toUpperCase()] ?? '#ffffff',
        };
      });
      // get deviceIdList for heatmap
      willLoadHeatmapDeviceIdList = dataResponse.map(item => {
        return item?.device_identifer;
      });
      isReset = true;
    }

    // Sort data by selected sort option
    const { sortedBarChartData, deviceInfoOrderedList } = this.sortDataBarChart(
      mappingData,
      sortOption
    );

    // Update color for deviceInfoOrderedList
    deviceInfoOrderedList.forEach(item => {
      const color = LINE_CHART_COLOR_LIST[item['index']];
      item['fillColor'] = color ?? getRandomHexColor();
    });

    this.setState(
      {
        deviceInfoOrderedList,
        barChartData: {
          ...barChartData,
          data: sortedBarChartData,
        },
        isLoadingTableAndBarChart: false,
      },
      () => {
        // Update deviceInfoOrderedList back to Network
        this.props.updateDeviceInfoOrderList({
          deviceInfoOrderedList,
          isLoadMore,
        });
        this.fetchDataForHeatMap({ willLoadHeatmapDeviceIdList, isReset });
      }
    );
  };

  // This function is used to fill the missing data in the heat map
  fillHeatMapData(xAxis, yAxis, sortedHeatMapData) {
    // Convert sortedHeatMapData into a map for fast lookup
    let dataMap = new Map(
      sortedHeatMapData.map(item => [`${item.x}|${item.y}`, item])
    );

    xAxis.forEach(xItem => {
      yAxis.forEach(yItem => {
        let key = `${xItem.category}|${yItem.category}`;
        if (!dataMap.has(key)) {
          sortedHeatMapData.push({
            device_identifer: yItem.deviceId,
            x: xItem.category,
            y: yItem.category,
            columnSettings: {
              fill: '#ffffff', // Use default fill color or specify as needed
            },
            value: -1,
          });
        }
      });
    });

    // Return the updated heat map data
    return sortedHeatMapData;
  }

  fetchDataForHeatMap = async ({ willLoadHeatmapDeviceIdList, isReset }) => {
    let { floor, deviceInfoOrderedList, heatMapData } = this.state;
    const { selectedDate } = this.props;
    if (
      !floor ||
      !selectedDate ||
      deviceInfoOrderedList?.size === 0 ||
      willLoadHeatmapDeviceIdList?.length === 0
    ) {
      return;
    }
    this.setState({ isLoadingHeatMap: true });
    let heatMapDataRes = await fetchDataHeatMap({
      floorId: floor,
      fromDate: format(selectedDate, 'yyyy-MM-dd 00:00:00'),
      toDate: format(selectedDate, 'yyyy-MM-dd 23:59:59'),
      deviceIdentiferList: willLoadHeatmapDeviceIdList,
    });

    if (!heatMapDataRes) {
      heatMapDataRes = [];
    }

    let updatedHeatMapData = heatMapDataRes.map(item => ({
      device_identifer: item.device_identifer,
      x: item.timestamp,
      y: item.device_name,
      columnSettings: {
        fill: HEATMAP_SCORE_COLORS[item.grade?.toUpperCase()] ?? '#ffffff',
      },
      value: item.score,
    }));
    if (isReset) {
      updatedHeatMapData = updatedHeatMapData.sort((a, b) =>
        compareAsc(new Date(a.x), new Date(b.x))
      );
    } else {
      updatedHeatMapData = updatedHeatMapData
        .concat(heatMapData)
        .sort((a, b) => compareAsc(new Date(a.x), new Date(b.x)));
    }
    const {
      heatMapData: newHeatMapData,
      xAxis,
      yAxis,
    } = this.sortDataHeatMap(updatedHeatMapData, deviceInfoOrderedList);

    const updatedState = {
      heatMapData: [...newHeatMapData],
      xAxisHeatMapData: xAxis,
      yAxisHeatMapData: yAxis,
      isLoadingHeatMap: false,
    };

    this.setState(updatedState, () => {});
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  computeXAxisHeatMap = heatMapData => {
    const xAxis = [];
    const xAxisLabelSet = new Set(heatMapData.map(item => item.x));
    const xAxisLabelArray = Array.from(xAxisLabelSet).map(label => ({
      label,
      datetime: new Date(label.split(' ')[0] + 'T' + label.split(' ')[1]),
    }));
    if (xAxisLabelArray.length === 0) {
      return xAxis;
    }

    // Sort the array by datetime
    xAxisLabelArray.sort((a, b) => a.datetime - b.datetime);

    for (let i = 0; i < xAxisLabelArray.length; i++) {
      const labels = xAxisLabelArray[i].label.split(' ');
      const labelTime = labels[1].substring(0, 5);
      const categoryDate = labels[0];
      const datetime = xAxisLabelArray[i].datetime;

      let categoryLabel = '';
      if (datetime.getHours() % 3 === 0 && datetime.getMinutes() === 0) {
        categoryLabel = labelTime;
      }

      xAxis.push({
        category: xAxisLabelArray[i].label,
        id: `xAxisLabel-${i}`,
        categoryLabel: categoryLabel,
        categoryDate: categoryDate,
      });
    }
    return xAxis;
  };

  computeYAxisHeatMap = heatMapData => {
    const yAxis = [];
    const yAxisLabelSet = new Set();
    const yAxisLabelArray = [];
    for (const data of heatMapData) {
      if (!yAxisLabelSet.has(data['device_identifer'])) {
        yAxisLabelSet.add(data['device_identifer']);
        yAxisLabelArray.push({
          deviceName: data.y,
          deviceId: data['device_identifer'],
        });
      }
    }
    for (let i = 0; i < yAxisLabelArray.length; i++) {
      yAxis.push({
        deviceId: yAxisLabelArray[i]['deviceId'],
        category: yAxisLabelArray[i]['deviceName'],
        id: `yAxisLabel-${i}`,
      });
    }
    return yAxis.sort((a, b) => b.category.localeCompare(a.category));
  };

  renderHeatMap(heatMapData, xAxis, yAxis) {
    const { isLoadingHeatMap } = this.state;
    let filteredYAxis = [];
    let filteredData = [];
    if (heatMapData && heatMapData.length > 0) {
      const { selectedDeviceIdLineList } = this.props;
      filteredYAxis = yAxis.filter(item =>
        selectedDeviceIdLineList.some(
          deviceId =>
            deviceId?.toLowerCase() === item['deviceId']?.toLowerCase()
        )
      );
      filteredData = heatMapData.filter(item =>
        selectedDeviceIdLineList.some(
          deviceId =>
            deviceId?.toLowerCase() === item['device_identifer']?.toLowerCase()
        )
      );
    }
    return (
      <HeatMap
        horizontalData={xAxis}
        verticalData={filteredYAxis}
        data={filteredData}
        name="heatmap"
        tooltipLabelList={['デバイス名', '日時', 'Score']}
        headerTitle={COMMON_TEXT.CHANGE}
        isLoading={isLoadingHeatMap}
      />
    );
  }

  renderBarChart(barChartData) {
    let { data = [], xAxisDataKey } = barChartData;
    const { selectedDeviceIdLineList } = this.props;
    const { isLoadingTableAndBarChart } = this.state;
    const filteredData = data.filter(item =>
      selectedDeviceIdLineList.some(
        deviceId =>
          deviceId?.toLowerCase() === item['device_identifer']?.toLowerCase()
      )
    );

    return (
      <NetworkPerformanceCustomBarChart
        data={filteredData}
        xAxisDataKey={xAxisDataKey}
        yAxisDataKey={'score'}
        yAxisLabel={COMMON_TEXT.AVERAGE_SCORE}
        isLoading={isLoadingTableAndBarChart}
      />
    );
  }

  renderLoadMoreDataButton() {
    const { isLoading, isDisplayLoadMoreButton } = this.state;
    return (
      isDisplayLoadMoreButton && (
        <div className="load-more-button-container">
          <Button
            label={`もっと見る`}
            loading={isLoading}
            severity="primary"
            size="large"
            text
            onClick={() => this.fetchDataForBarChart({ isLoadMore: true })}
          />
        </div>
      )
    );
  }

  renderDownloadButton() {
    const { csvDownload } = this.state;
    return (
      <>
        <Button
          type="button"
          label={csvDownload ? `戻る` : 'CSVダウンロード'}
          className={csvDownload ? 'back-button' : 'submit-button csv-download'}
          onClick={() => {
            this.setState({
              csvDownload: !csvDownload,
            });
          }}
        />
      </>
    );
  }

  render() {
    const {
      barChartData,
      heatMapData,
      xAxisHeatMapData,
      yAxisHeatMapData,
      csvDownload,
      floor,
    } = this.state;
    const { selectedDeviceIdLineList, selectedDate } = this.props;

    return (
      <>
        <div className="network-container network-performance-score">
          <div className="network-title-container">
            <div className="text-left">
              <NetworkTitle
                type={PERFORMANCE_COMPONENT.NETWORK_PERFORMANCE_SCORE}
              ></NetworkTitle>
            </div>
            <div className="flex-container">
              <div style={{ paddingLeft: '1rem' }}>
                {this.renderDownloadButton()}
              </div>
            </div>
          </div>
          {csvDownload ? (
            <CustomCSVDownload
              fromDate={selectedDate}
              toDate={selectedDate}
              type={CSV_DOWNLOAD_COMPONENT.NETWORK_PERFORMANCE_SCORE}
              deviceIdList={[...selectedDeviceIdLineList]}
              floorId={floor}
            ></CustomCSVDownload>
          ) : (
            <div className="network-content">
              {this.renderMessageError() || (
                <>
                  <div className="data-container">
                    <div className="data-container__item data-container__item--heatmap">
                      <div className="heatmap-container">
                        <ResponsiveContainer width={'100%'} height={540}>
                          {this.renderHeatMap(
                            heatMapData,
                            xAxisHeatMapData,
                            yAxisHeatMapData
                          )}
                        </ResponsiveContainer>
                        {this.renderLoadMoreDataButton()}
                      </div>
                    </div>
                    <div className="data-container__item data-container__item--barchart">
                      <div className="chart-container">
                        {this.renderBarChart(barChartData)}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

NetworkPerformanceScore.propTypes = {};

export default NetworkPerformanceScore;
