import { QUERY_TABLES } from '@/helpers/constants';
import {
  generateRandomDataForBarChartWirelessChannelUtilization,
  generateRandomDataForLineChartWirelessChannelUtilization,
  isUsingSampleData,
} from 'src/helpers/generate-random-data';
import { executeQuery } from 'src/helpers/request';

const buildRequestQuery24MartBar = ({
  floorId,
  deviceIdList,
  selectedDate,
}) => {
  return {
    data: {
      fields: {
        date: ['select'],
        tenant_id: ['select'],
        floor_id: ['select'],
        device_name: ['select'],
        device_identifer: ['select'],
        utilization_total: ['select'],
      },
      skip: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      device_identifer: deviceIdList,
      date: [selectedDate],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      utilization_total: 'desc',
    },
    table_name: QUERY_TABLES.CHANNEL_UTILIZATIONS_24_MART_BAR_V2,
  };
};

const buildRequestQuery24MartLine = (
  floorId,
  fromDate,
  toDate,
  deviceIdList
) => {
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        floor_id: ['select'],
        device_name: ['select'],
        device_identifer: ['select'],
        utilization_total: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      timestamp: {
        gte: [fromDate],
        lte: [toDate],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.CHANNEL_UTILIZATIONS_24_MART_LINE,
  };
};

const buildRequestQuery5MartBar = ({ floorId, deviceIdList, selectedDate }) => {
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        floor_id: ['select'],
        device_name: ['select'],
        device_identifer: ['select'],
        utilization_total: ['select'],
      },
      skip: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      device_identifer: deviceIdList,
      date: [selectedDate],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      utilization_total: 'desc',
    },
    table_name: QUERY_TABLES.CHANNEL_UTILIZATIONS_5_MART_BAR_V2,
  };
};

const buildRequestQuery5MartLine = (
  floorId,
  fromDate,
  toDate,
  deviceIdList
) => {
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        floor_id: ['select'],
        device_name: ['select'],
        device_identifer: ['select'],
        utilization_total: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      timestamp: {
        gte: [fromDate],
        lte: [toDate],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.CHANNEL_UTILIZATIONS_5_MART_LINE,
  };
};

export const fetchData24MartBar = async payload => {
  const { floorId, deviceIdList, selectedDate } = payload;
  const request = buildRequestQuery24MartBar({
    floorId,
    deviceIdList,
    selectedDate,
  });
  if (isUsingSampleData) {
    return generateRandomDataForBarChartWirelessChannelUtilization();
  }
  return executeQuery(request);
};

export const fetchData24MartLine = async payload => {
  const { floorId, fromDate, toDate, deviceIdList } = payload;
  const request = buildRequestQuery24MartLine(
    floorId,
    fromDate,
    toDate,
    deviceIdList
  );
  if (isUsingSampleData) {
    return generateRandomDataForLineChartWirelessChannelUtilization();
  }
  return executeQuery(request);
};

export const fetchData5MartBar = async payload => {
  const { floorId, deviceIdList, selectedDate } = payload;
  const request = buildRequestQuery5MartBar({
    floorId,
    deviceIdList,
    selectedDate,
  });
  if (isUsingSampleData) {
    return generateRandomDataForBarChartWirelessChannelUtilization();
  }
  return executeQuery(request);
};

export const fetchData5MartLine = async payload => {
  const { floorId, fromDate, toDate, deviceIdList } = payload;
  const request = buildRequestQuery5MartLine(
    floorId,
    fromDate,
    toDate,
    deviceIdList
  );
  if (isUsingSampleData) {
    return generateRandomDataForLineChartWirelessChannelUtilization();
  }
  return executeQuery(request);
};
