import { format } from 'date-fns';

export const isUsingSampleData = false;

export function generateRandomDataForBarChart() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 5; // Number of objects to create

  for (let i = 0; i < itemCount; i++) {
    const deviceIdentifier = deviceIdentifiers[i];

    const item = {
      device_identifer: deviceIdentifier,
      device_name: deviceIdentifier,
      score: getRandomDataValue(),
      fillColor: getRandomColor(),
    };

    res.push(item);
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
  }

  function getRandomColor() {
    // Generate a random color or implement your custom logic here
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  // end set random data
  return res;
}

export function generateRandomDataForBarChartNetworkPerformanceScore() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
    'Identifier 6',
    'Identifier 7',
    'Identifier 8',
    'Identifier 9',
  ];
  const grade = ['poor', 'bad', 'fair', 'good', 'excellent'];
  const itemCount = deviceIdentifiers.length; // Number of objects to create

  for (let i = 0; i < itemCount; i++) {
    const deviceIdentifier = deviceIdentifiers[i % deviceIdentifiers.length];

    const item = {
      device_identifer: deviceIdentifier,
      device_name: deviceIdentifier,
      score: getRandomDataValue(),
      grade: grade[i],
      floor_id: 1,
    };

    res.push(item);
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
  }

  // end set random data
  return res;
}

export function generateRandomDataForHeatMapNetworkPerformanceScore() {
  const identifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
    'Identifier 6',
    'Identifier 7',
    'Identifier 8',
    'Identifier 9',
  ];
  const grades = ['poor', 'bad', 'fair', 'good', 'excellent'];
  const result = [];

  function getRandomTimestamp() {
    const getRandomDate = (start, end) =>
      new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime())
      );
    const formatDate = date => format(date, 'yyyy-MM-dd HH:mm:ss');

    const startDate = addDays(new Date(), -365);
    const endDate = new Date();
    const randomDate = getRandomDate(startDate, endDate);

    const incrementedDate = addMinutes(
      addSeconds(randomDate, getRandomNumber()),
      getRandomNumber()
    );
    return formatDate(incrementedDate);
  }

  function getRandomItem(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  function getRandomNumber() {
    return Math.floor(Math.random() * 100);
  }

  for (let i = 0; i < 20; i++) {
    const timestamp = getRandomTimestamp();
    const device_identifer = identifiers[i % identifiers.length];
    const device_name = identifiers[i % identifiers.length];
    const score = getRandomNumber();
    const grade = getRandomItem(grades);
    const floor_id = 1;

    const item = {
      timestamp,
      device_identifer,
      device_name,
      score,
      grade,
      floor_id,
    };

    result.push(item);
  }

  return result.sort((a, b) => {
    return new Date(a.timestamp) - new Date(b.timestamp);
  });
}

export function generateRandomDataForBarChartNetworkClientCount() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 5; // Number of objects to create

  for (let i = 0; i < itemCount; i++) {
    const deviceIdentifier = deviceIdentifiers[i];

    const item = {
      device_identifer: deviceIdentifier,
      device_name: deviceIdentifier,
      counts: getRandomDataValue(),
    };

    res.push(item);
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
  }

  // end set random data
  return res.sort((a, b) => b.counts - a.counts);
}

export function generateRandomDataForBarChartNetworkUsage() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 5; // Number of objects to create

  for (let i = 0; i < itemCount; i++) {
    const deviceIdentifier = deviceIdentifiers[i];

    const item = {
      device_identifer: deviceIdentifier,
      device_name: deviceIdentifier,
      average_kbps: getRandomDataValue(),
    };

    res.push(item);
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
  }

  // end set random data
  return res.sort((a, b) => b.average_kbps - a.average_kbps);
}

export function generateRandomDataForBarChartWirelessChannelUtilization() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 5; // Number of objects to create

  for (let i = 0; i < itemCount; i++) {
    const deviceIdentifier = deviceIdentifiers[i];

    const item = {
      device_identifer: deviceIdentifier,
      device_name: deviceIdentifier,
      utilization_total: getRandomDataValue(),
    };

    res.push(item);
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
  }

  // end set random data
  return res.sort((a, b) => b.utilization_total - a.utilization_total);
}

const { addDays, addMinutes, addSeconds } = require('date-fns');

export function generateRandomDataForBarChartWirelessSignal() {
  const identifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
    'Identifier 6',
    'Identifier 7',
    'Identifier 8',
    'Identifier 9',
  ];
  const grades = ['poor', 'bad', 'fair', 'good', 'excellent'];
  const result = [];

  function getRandomTimestamp() {
    const getRandomDate = (start, end) =>
      new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime())
      );
    const formatDate = date => format(date, 'yyyy-MM-dd HH:mm:ss');

    const startDate = addDays(new Date(), -365);
    const endDate = new Date();
    const randomDate = getRandomDate(startDate, endDate);

    const incrementedDate = addMinutes(
      addSeconds(randomDate, getRandomNumber()),
      getRandomNumber()
    );
    return formatDate(incrementedDate);
  }

  function getRandomItem(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  function getRandomNumber() {
    return Math.floor(Math.random() * 100);
  }

  for (let i = 0; i < 20; i++) {
    const timestamp = getRandomTimestamp();
    const device_identifer = identifiers[i % identifiers.length];
    const device_name = identifiers[i % identifiers.length];
    const snr = getRandomNumber();
    const grade = getRandomItem(grades);
    const floor_id = 1;

    const item = {
      timestamp,
      device_identifer,
      device_name,
      snr,
      grade,
      floor_id,
    };

    result.push(item);
  }

  return result.sort((a, b) => {
    return new Date(a.timestamp) - new Date(b.timestamp);
  });
}

export function generateRandomDataForBarChartWirelessLatency() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 5; // Number of objects to create

  for (let i = 0; i < itemCount; i++) {
    const deviceIdentifier = deviceIdentifiers[i];

    const item = {
      device_identifer: deviceIdentifier,
      device_name: deviceIdentifier,
      floor_id: 1,
      background: getRandomDataValue(),
      besteffort: getRandomDataValue(),
      video: getRandomDataValue(),
      voice: getRandomDataValue(),
    };

    res.push(item);
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
  }

  // end set random data
  return res;
}

export function generateRandomDataForLineChartWirelessLatency() {
  let res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 50; // Number of objects to create

  const baseTimestamp = new Date('2023-05-15 00:00:00').getTime();
  for (const di of deviceIdentifiers) {
    for (let i = 0; i < itemCount; i++) {
      const timestamp = new Date(baseTimestamp + (i / 5) * 5 * 60 * 1000); // Increase timestamp by 5 minutes for every i increment of 5
      const deviceIdentifier = di;

      const item = {
        device_identifer: deviceIdentifier,
        device_name: deviceIdentifier,
        floor_id: 1,
        background: getRandomDataValue(),
        besteffort: getRandomDataValue(),
        video: getRandomDataValue(),
        voice: getRandomDataValue(),
        timestamp: format(timestamp, 'yyyy-MM-dd HH:mm:ss'),
      };

      res.push(item);
    }
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    // Generate a random data value within a specific range
    const minValue = 300;
    const maxValue = 1000;
    const range = maxValue - minValue;
    const randomValue = Math.floor(Math.random() * (range / 50 + 1)) * 50;
    return randomValue + minValue;
  }

  // end set random data
  res = res.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
  return res;
}

export function generateRandomDataForLineChartWirelessChannelUtilization() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 50; // Number of objects to create

  const baseTimestamp = new Date('2023-05-15 00:00:00').getTime();
  for (const di of deviceIdentifiers) {
    for (let i = 0; i < itemCount; i++) {
      const timestamp = new Date(baseTimestamp + (i / 5) * 5 * 60 * 1000); // Increase timestamp by 5 minutes for every i increment of 5
      const deviceIdentifier = di;

      const item = {
        device_identifer: deviceIdentifier,
        device_name: deviceIdentifier,
        floor_id: 1,
        utilization_total: getRandomDataValue(),
        timestamp: format(timestamp, 'yyyy-MM-dd HH:mm:ss'),
      };

      res.push(item);
    }
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    const minValue = 300;
    const maxValue = 1000;
    const range = maxValue - minValue;
    const randomValue = Math.floor(Math.random() * (range / 50 + 1)) * 50;
    return randomValue + minValue;
  }

  // end set random data
  return res;
}

export function generateRandomDataForLineChartNetworkUsage() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 50; // Number of objects to create

  const baseTimestamp = new Date('2023-05-15 00:00:00').getTime();
  for (const di of deviceIdentifiers) {
    for (let i = 0; i < itemCount; i++) {
      const timestamp = new Date(baseTimestamp + (i / 5) * 5 * 60 * 1000); // Increase timestamp by 5 minutes for every i increment of 5
      const deviceIdentifier = di;

      const item = {
        device_identifer: deviceIdentifier,
        device_name: deviceIdentifier,
        floor_id: 1,
        average_kbps: getRandomDataValue(),
        timestamp: format(timestamp, 'yyyy-MM-dd HH:mm:ss'),
      };

      res.push(item);
    }
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    const minValue = 300;
    const maxValue = 1000;
    const range = maxValue - minValue;
    const randomValue = Math.floor(Math.random() * (range / 50 + 1)) * 50;
    return randomValue + minValue;
  }

  // end set random data
  return res;
}

export function generateRandomDataForLineChartNetworkClientCount() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 50; // Number of objects to create

  const baseTimestamp = new Date('2023-05-15 00:00:00').getTime();
  for (const di of deviceIdentifiers) {
    for (let i = 0; i < itemCount; i++) {
      const timestamp = new Date(baseTimestamp + (i / 5) * 5 * 60 * 1000); // Increase timestamp by 5 minutes for every i increment of 5
      const deviceIdentifier = di;

      const item = {
        device_identifer: deviceIdentifier,
        device_name: deviceIdentifier,
        floor_id: 1,
        counts: getRandomDataValue(),
        timestamp: format(timestamp, 'yyyy-MM-dd HH:mm:ss'),
      };

      res.push(item);
    }
  }

  function getRandomDataValue() {
    // Generate a random data value within a specific range
    const minValue = 300;
    const maxValue = 1000;
    const range = maxValue - minValue;
    const randomValue = Math.floor(Math.random() * (range / 50 + 1)) * 50;
    return randomValue + minValue;
  }

  // end set random data
  return res;
}

export function generateRandomDataForFloorMap() {
  const res = [];
  // set random data
  const deviceIdentifiers = [
    'Identifier 1',
    'Identifier 2',
    'Identifier 3',
    'Identifier 4',
    'Identifier 5',
  ];
  const itemCount = 5; // Number of objects to create

  const statusList = ['online', 'offline', 'unknown', 'dormant', 'alerting'];
  const iconList = [
    'default',
    'static/icon/mv_online.png',
    'static/icon/mv_dormant.png',
    'static/icon/mt30_online.png',
    'static/icon/mt30_online.png',
  ];

  for (let i = 0; i < itemCount; i++) {
    const deviceIdentifier = deviceIdentifiers[i];

    const item = {
      device_identifer: deviceIdentifier,
      device_name: deviceIdentifier,
      counts: getRandomDataValue(1, 40),
      room_availability: getRandomDataValue(0, 1),
      status: statusList[i % statusList.length],
      position_x: generateDecimalRandomValue(),
      position_y: generateDecimalRandomValue(),
      floor_id: 1,
      icon: iconList[i % iconList.length],
      timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };

    res.push(item);
  }

  function getRandomDataValue(min, max) {
    // Generate a random integer within the specified range
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function generateDecimalRandomValue() {
    const decimalPlaces = 2;
    const min = 0.0;
    const max = 1.0;

    const randomValue = Math.random() * (max - min) + min;
    return Number(randomValue.toFixed(decimalPlaces));
  }

  // end set random data
  return res;
}

export function generateRandomDataForFloorMapEmployeeLocation() {
  const res = [];
  // set random data
  const employeeNameList = [
    'Employee 1',
    'Employee 2',
    'Employee 3',
    'Employee 4',
    'Employee 5',
  ];
  const itemCount = 5; // Number of objects to create

  for (let i = 0; i < itemCount; i++) {
    const deviceIdentifier = employeeNameList[i];

    const item = {
      employee_device_mac: deviceIdentifier,
      employee_device_name: deviceIdentifier,
      employee_name: deviceIdentifier,
      department: 'Department 1',
      team: 'Team 1',
      employee_image: 'static/employee_image/urano.jpg',
      position_x: generateDecimalRandomValue(),
      position_y: generateDecimalRandomValue(),
      floor_id: 1,
      timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };

    res.push(item);
  }

  function generateDecimalRandomValue() {
    const decimalPlaces = 2;
    const min = 0.0;
    const max = 1.0;

    const randomValue = Math.random() * (max - min) + min;
    return Number(randomValue.toFixed(decimalPlaces));
  }

  // end set random data
  return res;
}

export function generateRandomDataMeetingRoomOutLook() {
  const data = [];
  const options = ['a', 'b', 'c', 'd', 'e'];

  // Helper function to get random minute
  function getRandomMinute(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  // Function to get random date with specific hour and minute
  function getRandomDateTime() {
    let date = new Date(); // This will give you current date
    date.setHours(getRandomMinute(14, 20), getRandomMinute(0, 59), 0); // Setting random hours and minutes within the current date
    return date;
  }

  // Function to get minutes difference between two dates
  function getMinuteDifference(date1, date2) {
    let diff = Math.abs(date2 - date1);
    return Math.floor(diff / 1000 / 60);
  }

  for (const opt of options) {
    const reserved_start = getRandomDateTime();
    let reserved_end = new Date(reserved_start.getTime());
    reserved_end.setMinutes(
      reserved_end.getMinutes() + getRandomMinute(60, 180)
    ); // Reservation lasts between 1 and 120 minutes

    let occupied_start = new Date(reserved_start.getTime());
    occupied_start.setMinutes(
      occupied_start.getMinutes() +
        getRandomMinute(
          1,
          getMinuteDifference(reserved_start, reserved_end) - 1
        )
    ); // Occupied start after reserved start and leaves at least 1 minute for end

    let occupied_end = new Date(occupied_start.getTime());
    occupied_end.setMinutes(
      occupied_end.getMinutes() +
        getRandomMinute(1, getMinuteDifference(occupied_start, reserved_end))
    ); // Occupied end before reserved end

    const obj = {
      organizer: `organizer ${opt}`,
      subject: `subject ${opt}`,
      reserved_start: reserved_start
        .toISOString()
        .slice(0, 19)
        .replace('T', ' '),
      reserved_end: reserved_end.toISOString().slice(0, 19).replace('T', ' '),
      reserved_minutes: getMinuteDifference(reserved_start, reserved_end),
      room_name: `room ${opt}`,
      device_identifer: `device ${opt}`,
      occupied_minutes: getMinuteDifference(occupied_start, occupied_end),
      occupied_start: occupied_start
        .toISOString()
        .slice(0, 19)
        .replace('T', ' '),
      occupied_end: occupied_end.toISOString().slice(0, 19).replace('T', ' '),
      unused_start1: null,
      unused_end1: null,
      unused_start2: null,
      unused_end2: null,
      unused_minutes1: null,
      unused_minutes2: null,
      event_id: null,
    };

    data.push(obj);
  }

  return data;
}

export function generateRandomDataMeetingRoomOutLookTable() {
  const data = [];
  const options = ['a', 'b', 'c', 'd', 'e'];

  // Helper function to get random minute
  function getRandomMinute(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  // Function to get random date with specific hour and minute
  function getRandomDateTime() {
    let date = new Date(); // This will give you current date
    date.setHours(getRandomMinute(14, 20), getRandomMinute(0, 59), 0); // Setting random hours and minutes within the current date
    return date;
  }

  function generateDecimalRandomValue() {
    const decimalPlaces = 2;
    const min = 0.0;
    const max = 1.0;

    const randomValue = Math.random() * (max - min) + min;
    return Number(randomValue.toFixed(decimalPlaces));
  }

  for (const opt of options) {
    const obj = {
      date: format(getRandomDateTime(), 'yyyy-MM-dd'),
      device_identifer: `device ${opt}`,
      reserved: getRandomMinute(60, 180),
      reserved_and_unused: getRandomMinute(60, 180),
      occupied_rate: generateDecimalRandomValue(),
      unused_reservation: getRandomMinute(60, 180),
      unused_rate: generateDecimalRandomValue(),
      room_name: `room ${opt}`,
      floor_id: 1,
    };

    data.push(obj);
  }
  return data;
}
