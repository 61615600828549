import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import { format } from 'date-fns';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchMerakiConfigurationEvents } from './query-request';

@connect(state => ({
  querystring: state.querystring,
}))
class MerakiChangelog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      filterData: [],
      isShowTable: false,
    };
    this.isAccordionTabInitOpen = false;
    this.dataTableRef = React.createRef();
    this.filters = {
      timestamp: { value: null, matchMode: FilterMatchMode.CONTAINS },
      admin_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      network_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      ssid_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      page: { value: null, matchMode: FilterMatchMode.CONTAINS },
      label: { value: null, matchMode: FilterMatchMode.CONTAINS },
      old_value: { value: null, matchMode: FilterMatchMode.CONTAINS },
      new_value: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
    this.dataColumns = [
      {
        field: 'timestamp',
        header: COMMON_TEXT.MERAKI_CONFIG_CHANGE_TIMESTAMP,
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'admin_name',
        header: COMMON_TEXT.MERAKI_CONFIG_CHANGE_ADMIN_NAME,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'network_name',
        header: COMMON_TEXT.MERAKI_CONFIG_CHANGE_NETWORK_NAME,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'ssid_name',
        header: COMMON_TEXT.MERAKI_CONFIG_CHANGE_SSID_NAME,
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'page',
        header: COMMON_TEXT.MERAKI_CONFIG_CHANGE_PAGE,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'label',
        header: COMMON_TEXT.MERAKI_CONFIG_CHANGE_LABEL,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'old_value',
        header: COMMON_TEXT.OLD_VALUE,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'new_value',
        header: COMMON_TEXT.NEW_VALUE,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
        filter: true,
      },
    ];
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.mapFilterData();
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({
        isShowTable: false,
        tableData: [],
      });
    }
  }

  mapFilterData = () => {
    const filterData = [
      {
        id: 'timestamp',
        label: COMMON_TEXT.MERAKI_CONFIG_CHANGE_TIMESTAMP,
        value: new Date(),
      },
      {
        id: 'admin_name',
        label: COMMON_TEXT.MERAKI_CONFIG_CHANGE_ADMIN_NAME,
        value: '',
      },
      {
        id: 'network_name',
        label: COMMON_TEXT.MERAKI_CONFIG_CHANGE_NETWORK_NAME,
        value: '',
      },
      {
        id: 'ssid_name',
        label: COMMON_TEXT.MERAKI_CONFIG_CHANGE_SSID_NAME,
        value: '',
      },
      {
        id: 'page',
        label: COMMON_TEXT.MERAKI_CONFIG_CHANGE_PAGE,
        value: '',
      },
      {
        id: 'label',
        label: COMMON_TEXT.MERAKI_CONFIG_CHANGE_LABEL,
        value: '',
      },
    ];
    this.setState({ filterData });
  };

  getPayload = () => {
    const { tenant, filterData } = this.state;
    const selectedDate = filterData.filter(data => data.id === 'timestamp')[0]
      .value;
    const admin_name = filterData.filter(data => data.id === 'admin_name')[0]
      .value;
    const network_name = filterData.filter(
      data => data.id === 'network_name'
    )[0].value;
    const ssid_name = filterData.filter(data => data.id === 'ssid_name')[0]
      .value;
    const page = filterData.filter(data => data.id === 'page')[0].value;
    const label = filterData.filter(data => data.id === 'label')[0].value;
    const payload = {
      tenant_id: [tenant],
      timestamp: {
        lte: [format(selectedDate, 'yyyy-MM-dd HH:mm:ss')],
      },
      admin_name: admin_name ? { like: [`%${admin_name}%`] } : [],
      network_name: network_name ? { like: [`%${network_name}%`] } : [],
      ssid_name: ssid_name ? { like: [`%${ssid_name}%`] } : [],
      page: page ? { like: [`%${page}%`] } : [],
      label: label ? { like: [`%${label}%`] } : [],
    };
    return payload;
  };

  getDataTable = async () => {
    const payload = this.getPayload();

    const response = await fetchMerakiConfigurationEvents(payload);
    this.setState({
      isLoading: false,
      tableData: response,
    });
  };

  exportCSV = () => {
    this.dataTableRef.current.exportCSV();
  };

  renderMessageError = () => {
    const { tenant } = this.state;
    if (!tenant) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  onAccordionTabOpen = e => {
    if (!this.isAccordionTabInitOpen) {
      this.isAccordionTabInitOpen = true;
    }
  };

  datePickerOnChanged = (data, value) => {
    if (value) {
      data.value = value;
      this.setState({});
    }
  };

  renderDateandTimePicker(data) {
    const maxDate = new Date();
    return (
      <div className="input-style">
        <div className="custom-calendar date-calendar">
          <Calendar
            value={data.value}
            onChange={e => this.datePickerOnChanged(data, e.value)}
            maxDate={maxDate}
            className="custom-date-picker"
            dateFormat="yy年mm月dd日"
            readOnlyInput
            selectionMode="single"
            clearButtonClassName="hidden"
          />
        </div>
        <div className="custom-calendar time-picker">
          <Calendar
            value={data.value}
            onChange={e => this.datePickerOnChanged(data, e.value)}
            className="custom-date-picker"
            readOnlyInput
            timeOnly
          />
        </div>
      </div>
    );
  }

  renderFilterContainer = () => {
    const onchangeAction = (data, value) => {
      data.value = value;
      this.setState({});
    };

    const submitSearch = () => {
      this.setState({
        isShowTable: true,
        isLoading: true,
      });
      this.getDataTable();
    };

    const renderCustomInput = (data, index) => {
      return (
        <div className="input-container" key={index}>
          <div className="label-text">
            {data.id === 'timestamp' ? 'ログ終了時間' : data.label}
          </div>
          {data.id === 'timestamp' ? (
            this.renderDateandTimePicker(data)
          ) : (
            <div className="input-style">
              <InputText
                value={data.value}
                className="w-full"
                onChange={e => onchangeAction(data, e.target.value)}
              />
            </div>
          )}
        </div>
      );
    };

    return (
      <div className="filter-container">
        <div className="left">
          {this.state.filterData
            .slice(0, 3)
            .map((data, index) => renderCustomInput(data, index))}
        </div>
        <div className="right">
          {this.state.filterData
            .slice(3, this.state.filterData.length)
            .map((data, index) => renderCustomInput(data, index + 3))}
          <div className="button-container">
            <Button className="button-style" onClick={e => submitSearch()}>
              検索
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderChangelogDataTable({ tableData }) {
    const { isLoading } = this.state;
    const paginatorRight = (
      <Button
        type="button"
        icon="pi pi-download"
        text
        iconPos="left"
        severity="info"
        label="CSV ダウンロード"
        onClick={this.exportCSV}
      />
    );
    return (
      <DataTable
        ref={this.dataTableRef}
        dataKey="log_id"
        className="outlook-data-table mt-4"
        value={tableData}
        tableStyle={{ width: '100%' }}
        size="small"
        stripedRows
        rowHover
        scrollable
        scrollHeight="630px"
        showGridlines
        loading={isLoading}
        paginator
        rows={20}
        rowsPerPageOptions={[15, 20, 30, 50, 100]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} - {last} / {totalRecords} 件"
        paginatorLeft={<></>}
        paginatorRight={paginatorRight}
        sortField="timestamp"
        sortOrder={-1}
        filterDisplay="row"
        filters={this.filters}
        emptyMessage={() => {
          return (
            <div className="flex justify-content-center align-items-center">
              <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
            </div>
          );
        }}
        exportFilename="Merakiチェンジログ"
      >
        {this.dataColumns.map((col, i) => {
          return (
            <Column
              dataType={col.dataType ?? 'string'}
              key={col.field}
              field={col.field}
              header={col.header}
              sortable
              align={col.align}
              alignHeader={col.align}
              headerClassName={col.headerClassName}
              bodyClassName={col.bodyClassName}
              headerStyle={col.headerStyle}
              bodyStyle={col.bodyStyle}
              filter
              filterMatchMode={'contains'}
            />
          );
        })}
      </DataTable>
    );
  }

  render() {
    const { tableData, isShowTable } = this.state;

    return (
      <div className="meraki-change-log table-container mt-2">
        <Accordion onTabOpen={this.onAccordionTabOpen}>
          <AccordionTab
            header={
              <div className="accordion-tab-header-container">
                <div className="title">Merakiチェンジログ</div>
                <div
                  className="calendar-container"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                ></div>
              </div>
            }
          >
            <div className="m-0">
              {this.renderFilterContainer()}
              {isShowTable && this.renderChangelogDataTable({ tableData })}
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    );
  }
}

MerakiChangelog.propTypes = {};

export default MerakiChangelog;
