import { COMMON_TEXT } from '@/helpers/common-text';
import {
  EXECUTION_ID_TIMEOUT,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import {
  generateRandomString,
  getConfigurationUrlWithParam,
} from '@/helpers/utility';
import AuthToken from '@/services/auth-token';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { cloneDeep } from 'lodash';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmployeeCSV from './EmployeeCSV';
import EmployeeInfoDialog from './EmployeeInfoDialog';
import { fetchEmployees } from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      dataList: [],
      dialogVisibleCount: 0,
      editingEmployee: {},
    };
    this.toastRef = React.createRef();
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      employeeName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      employeeDepartment: { value: null, matchMode: FilterMatchMode.CONTAINS },
      employeeTeam: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    };
    this.emplRowStatus = {
      UNCHANGED: 0,
      NEW: 1,
      MODIFIED: 2,
      DELETED: 3,
    };
    this.authToken = new AuthToken();
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getData = async () => {
    const { tenant } = this.state;
    this.setState({ isLoading: true });
    const employees = await fetchEmployees({ tenantId: tenant });
    const newEmployeeList = employees.map(employee => {
      return {
        employeeId: employee.employee_id,
        employeeName: employee.employee_name,
        employeeDepartment: employee.department,
        employeeTeam: employee.team,
        status: this.emplRowStatus.UNCHANGED,
        rowIndexId: generateRandomString(16),
      };
    });
    this.setState({
      dataList: cloneDeep(newEmployeeList),
      isLoading: false,
    });
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await this.updateEmployeeeInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  updateEmployeeeInfo = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.EMPLOYEES,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  renderButtonContainer() {
    const { isLoading, isUpdating } = this.state;

    const getUpdatePayload = ({ employeeList }) => {
      const payload = {
        values: [],
      };
      const newEmployees = employeeList.filter(
        employee => employee.status === this.emplRowStatus.NEW
      );
      const newEmployeesSet = new Set();
      newEmployees.forEach(element => {
        newEmployeesSet.add(JSON.stringify(element));
      });
      let newEmployeesArray = [];
      newEmployeesSet.forEach(element => {
        newEmployeesArray.push(JSON.parse(element));
      });

      if (newEmployeesArray && newEmployeesArray.length > 0) {
        newEmployeesArray.forEach(employee => {
          const newEmployee = this.convertEmployeeObjectToPayLoad({ employee });
          payload.values.push(newEmployee);
        });
      }
      const modifiedEmployees = employeeList.filter(
        employee => employee.status === this.emplRowStatus.MODIFIED
      );
      if (modifiedEmployees && modifiedEmployees.length > 0) {
        modifiedEmployees.forEach(employee => {
          const modifiedEmployee = this.convertEmployeeObjectToPayLoad({
            employee,
          });
          payload.values.push(modifiedEmployee);
        });
      }
      const deletedEmployees = employeeList.filter(
        employee => employee.status === this.emplRowStatus.DELETED
      );
      if (deletedEmployees && deletedEmployees.length > 0) {
        deletedEmployees.forEach(employee => {
          const deletedEmployee = {
            employee_id: employee.employeeId,
            employee_number: null,
          };
          payload.values.push(deletedEmployee);
        });
      }
      return { payload };
    };

    const submitButtonOnClicked = () => {
      const { dataList } = this.state;
      const employeeList = dataList.filter(
        employee => employee.status !== this.emplRowStatus.UNCHANGED
      );

      if (employeeList.length > 0) {
        const { payload } = getUpdatePayload({ employeeList });
        this.updateData({ updatePayLoad: payload });
      } else {
        this.toastRef.current.show({
          severity: 'info',
          summary: '情報',
          detail: COMMON_TEXT.NO_CHANGE_TO_BE_SAVED,
          life: 3000,
          closable: true,
        });
        return;
      }
    };

    const addNewEmployeeButtonOnClicked = () => {
      this.setState({
        editingEmployee: {
          employeeId: '',
          employeeName: '',
          employeeNumber: '',
          employeePhoneNumber: '',
          employeeEmail: '',
          employeeBranchId: '',
          employeeFloorId: '',
          employeeChatTools: '',
          employeeDepartment: '',
          employeeImage: '',
          employeeVerkadaId: '',
          employeeTeam: '',
          employeePcMac: '',
          employeeMobileMac: '',
          employeePcName: '',
          employeeMobileName: '',
          status: this.emplRowStatus.NEW,
          rowIndexId: generateRandomString(16),
        },
        dialogVisibleCount: new Date().getTime(),
      });
    };

    return (
      <div className="main-button-container">
        <div className="save-new-button-container">
          <Button
            severity="info"
            type="button"
            label="保存"
            className="submit-button"
            loading={isUpdating}
            onClick={() => submitButtonOnClicked()}
          />
          <Button
            severity="success"
            type="button"
            label="追加"
            disabled={isUpdating || isLoading}
            className="add-new-button"
            onClick={() => addNewEmployeeButtonOnClicked()}
          />
        </div>
        <div className="csv-button-container">
          <EmployeeCSV
            tenantId={this.state.tenant}
            updateCsvData={this.updateCsvData}
          ></EmployeeCSV>
        </div>
      </div>
    );
  }

  convertToEmployeeObject = ({ employee }) => {
    return {
      employeeId: employee.employee_id,
      employeeName: employee.employee_name,
      employeeNumber: employee.employee_number,
      employeePhoneNumber: employee.phone_number,
      employeeEmail: employee.email,
      employeeBranchId: employee.branch_id,
      employeeFloorId: employee.floor_id,
      employeeImage: employee.employee_image,
      employeeChatTools: employee.chat_tools,
      employeeDepartment: employee.department,
      employeeTeam: employee.team,
      employeeVerkadaId: employee.employee_identifer,
      employeePcMac: employee.employee_device_pc_mac,
      employeePcName: employee.employee_device_pc_name,
      employeeMobileMac: employee.employee_device_mobile_mac,
      employeeMobileName: employee.employee_device_mobile_name,
      initEmployee: employee.initEmployee,
      status: employee.status,
      rowIndexId: generateRandomString(16),
    };
  };

  convertEmployeeObjectToPayLoad = ({ employee }) => {
    const {
      employeePcMac,
      employeeMobileMac,
      employeePcName,
      employeeMobileName,
      employeeId,
    } = employee;
    const obj = {
      employee_id: !employeeId || employeeId === 0 ? null : employeeId,
      employee_name: employee.employeeName ?? '',
      employee_number: employee.employeeNumber ?? '',
      phone_number: employee.employeePhoneNumber ?? '',
      email: employee.employeeEmail ?? '',
      branch_id: employee.employeeBranchId ?? '',
      floor_id: employee.employeeFloorId ?? '',
      employee_image: employee.employeeImage ?? '',
      chat_tools: employee.employeeChatTools ?? '',
      department: employee.employeeDepartment ?? '',
      team: employee.employeeTeam ?? '',
      verkada_employee_id: employee.employeeVerkadaId ?? '',
      devices: [],
    };

    const { initEmployee } = employee;
    if (!initEmployee) {
      console.error('initEmployee is not found');
    }
    const { employeePcMac: initPcMac, employeeMobileMac: initMobileMac } =
      initEmployee || {};

    if (employeePcMac || employeePcName) {
      obj.devices.push({
        type: 'pc',
        mac: employeePcMac,
        name: employeePcName,
      });
      if (initPcMac && employeePcMac !== initPcMac) {
        obj.devices.push({
          type: null,
          mac: initPcMac,
        });
      }
    }
    if (employeeMobileMac || employeeMobileName) {
      obj.devices.push({
        type: 'phone',
        mac: employeeMobileMac,
        name: employeeMobileName,
      });
      if (initMobileMac && employeeMobileMac !== initMobileMac) {
        obj.devices.push({
          type: null,
          mac: initMobileMac,
        });
      }
    }
    return obj;
  };

  updateCsvData = data => {
    const convertedDataList = data.map(employee => {
      return this.convertToEmployeeObject({ employee });
    });
    this.setState({
      dataList: convertedDataList,
    });
  };

  renderEmployeeTable() {
    const { dataList, isLoading } = this.state;

    const confirmDeleteEmployee = ({ index, employeeList }) => {
      let employee = employeeList[index];
      const { employeeId } = employee;
      if (employeeId) {
        employee.status = this.emplRowStatus.DELETED;
        employeeList[index] = employee;
      } else {
        employeeList.splice(index, 1);
      }

      this.setState({ dataList: employeeList });
    };

    const deleteEmployeeButtonOnClicked = async (
      e,
      { employee, index, employeeList }
    ) => {
      const { employeeName, employeeDepartment, employeeTeam } = employee;
      confirmDialog({
        message: () => (
          <div
            className="delete-message-container"
            style={{ width: '100%', minWidth: '400px' }}
          >
            <h5>削除してよろしいですか？</h5>
            <div
              className="mt-4 flex align-items-center"
              style={{
                borderBottom: '1px solid #dddddd',
                paddingBottom: '0.25rem',
              }}
            >
              <div style={{ width: '70px' }}>{`${COMMON_TEXT.NAME}`}</div>
              <div>
                <b>{`${employeeName}`}</b>
              </div>
            </div>
            <div
              className="mt-2 flex align-items-center"
              style={{
                borderBottom: '1px solid #dddddd',
                paddingBottom: '0.25rem',
              }}
            >
              <div style={{ width: '70px' }}>{`${COMMON_TEXT.DEPARTMENT}`}</div>
              <div>
                <b>{`${employeeDepartment}`}</b>
              </div>
            </div>
            <div className="mt-2 flex align-items-center" style={{}}>
              <div style={{ width: '70px' }}>{`${COMMON_TEXT.TEAM}`}</div>
              <div>
                <b>{`${employeeTeam}`}</b>
              </div>
            </div>
          </div>
        ),
        header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
        icon: '',
        accept: () => confirmDeleteEmployee({ index, employeeList }),
        reject: () => {},
        acceptClassName: 'p-button-danger',
        rejectClassName: 'p-button-secondary p-button-text',
        appendTo: 'self',
      });
    };

    const editEmployeeButtonOnClicked = (e, { employee, employeeIndex }) => {
      this.setState({
        editingEmployee: employee,
        dialogVisibleCount: new Date().getTime(),
      });
    };

    const getRowIndex = rowData => {
      return dataList.findIndex(data => {
        return data.rowIndexId === rowData.rowIndexId;
      });
    };

    const actionBodyTemplate = rowData => {
      const rowIndex = getRowIndex(rowData);
      const { status } = rowData;
      return (
        <div className="action-body-container">
          <Button
            type="button"
            icon="pi pi-pencil"
            text
            disabled={status === this.emplRowStatus.DELETED}
            severity="info"
            onClick={e =>
              editEmployeeButtonOnClicked(e, {
                employee: rowData,
                employeeIndex: rowIndex,
              })
            }
          />
          <Button
            type="button"
            icon="pi pi-trash"
            text
            disabled={status === this.emplRowStatus.DELETED}
            severity="danger"
            onClick={e =>
              deleteEmployeeButtonOnClicked(e, {
                employee: rowData,
                index: rowIndex,
                employeeList: dataList,
              })
            }
          />
        </div>
      );
    };

    const statusBodyTemplate = (rowData, rowProps) => {
      const { status } = rowData;
      let statusText = '';
      const getSeverity = status => {
        switch (status) {
          case this.emplRowStatus.NEW:
            return 'success';
          case this.emplRowStatus.MODIFIED:
            return 'info';
          case this.emplRowStatus.UNCHANGED:
            return '';
          case this.emplRowStatus.DELETED:
            return 'danger';
          default:
            return '';
        }
      };
      if (status === this.emplRowStatus.NEW) {
        statusText = '新規';
      } else if (status === this.emplRowStatus.MODIFIED) {
        statusText = '修正';
      } else if (status === this.emplRowStatus.UNCHANGED) {
        statusText = '';
      } else if (status === this.emplRowStatus.DELETED) {
        statusText = '削除';
      }
      return (
        <div className="status-body-container">
          {statusText && (
            <Tag value={statusText} severity={getSeverity(status)} />
          )}
        </div>
      );
    };

    return (
      <>
        <DataTable
          value={dataList}
          dataKey="rowIndexId"
          tableStyle={{ minWidth: '60rem' }}
          scrollable
          scrollHeight="calc(100vh - 300px)"
          loading={isLoading}
          size="small"
          stripedRows
          showGridlines
          filterDisplay="row"
          selectionMode="single"
          filters={this.filters}
          emptyMessage={() => {
            return (
              <div className="flex justify-content-center align-items-center">
                <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
              </div>
            );
          }}
        >
          <Column
            field="employeeName"
            header={COMMON_TEXT.NAME}
            sortable
            filter
          />
          <Column
            field="employeeDepartment"
            header={COMMON_TEXT.DEPARTMENT}
            sortable
            filter
          />
          <Column
            field="employeeTeam"
            header={COMMON_TEXT.TEAM}
            sortable
            filter
          />
          <Column
            field="status"
            header={COMMON_TEXT.STATUS}
            body={statusBodyTemplate}
          />
          <Column
            headerStyle={{ width: '5rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={actionBodyTemplate}
          />
        </DataTable>
      </>
    );
  }

  employeeInfoOnModified = ({ employee }) => {
    const { dataList } = this.state;
    const { rowIndexId } = employee;
    const findIndex = dataList.findIndex(
      data => data.rowIndexId === rowIndexId
    );
    if (findIndex >= 0) {
      dataList[findIndex] = {
        ...employee,
      };
    } else {
      dataList.push(employee);
    }
    this.setState({ dataList });
  };

  render() {
    const { dialogVisibleCount, editingEmployee, tenant } = this.state;

    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <EmployeeInfoDialog
          tenantId={tenant}
          dialogVisibleCount={dialogVisibleCount}
          selectedEmployee={editingEmployee}
          updateEmployeeInfo={this.employeeInfoOnModified}
        ></EmployeeInfoDialog>
        <div className="config-container custom-config-container employee-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">
                {COMMON_TEXT.COMPANY_EMPLOYEE_SETTING}
              </div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">
              <>{this.renderEmployeeTable()}</>
              <>{this.renderButtonContainer()}</>
            </div>
          </div>
        </div>
      </>
    );
  }
}

EmployeeSetting.propTypes = {};

export default EmployeeSetting;
