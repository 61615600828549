export const QUERY_TABLES = {
  SETTINGS_UPDATES: 'settings_updates',
  USERS: 'users',
  FLOORS: 'floors',
  BRANCHES: 'branches',
  TENANTS: 'tenants',
  ORGANIZATIONS: 'organizations',
  APIKEYS: 'apikeys',
  AREAS: 'areas',
  CALENDAR_OVERRIDES: 'calendar_overrides',
  DEVICE_STATUS_MART: 'device_status_mart',
  DEVICE_STATUS_MART_LINE: 'device_status_mart_line',
  PEOPLE_COUNTS_MART: 'people_counts_mart',
  PEOPLE_COUNTS_AREA_MART: 'people_counts_area_mart',
  PEOPLE_COUNTS_AREA_MART_LINE: 'people_counts_area_mart_line',
  PEOPLE_COUNTS_MART_BAR: 'people_counts_mart_bar',
  PEOPLE_COUNTS_MART_LINE: 'people_counts_mart_line',
  ROOM_AVAILABILITIES_MART: 'room_availabilities_mart',
  EMPLOYEE_LOCATIONS_MART_TABLE: 'employee_locations_mart_table',
  EMPLOYEE_LOCATIONS_MART: 'employee_locations_mart',
  EMPLOYEE_EVENTS: 'employee_events',
  DATA_RATE_HISTORIES_MART_BAR: 'data_rate_histories_mart_bar',
  DATA_RATE_HISTORIES_MART_BAR_V2: 'data_rate_histories_mart_bar_v2',
  DATA_RATE_HISTORIES_MART_LINE: 'data_rate_histories_mart_line',
  SIGNAL_QUALITY_HISTORIES_MART_HEATMAP:
    'signal_quality_histories_mart_heatmap',
  SIGNAL_QUALITY_HISTORIES_MART_FLOORMAP:
    'signal_quality_histories_mart_floormap',
  CHANNEL_UTILIZATIONS_24_MART_BAR: 'channel_utilizations_24_mart_bar',
  CHANNEL_UTILIZATIONS_24_MART_BAR_V2: 'channel_utilizations_24_mart_bar_v2',
  CHANNEL_UTILIZATIONS_24_MART_LINE: 'channel_utilizations_24_mart_line',
  CHANNEL_UTILIZATIONS_5_MART_BAR: 'channel_utilizations_5_mart_bar',
  CHANNEL_UTILIZATIONS_5_MART_BAR_V2: 'channel_utilizations_5_mart_bar_v2',
  CHANNEL_UTILIZATIONS_5_MART_LINE: 'channel_utilizations_5_mart_line',
  LATENCY_STATS_MART_BAR: 'latency_stats_mart_bar',
  LATENCY_STATS_MART_BAR_V2: 'latency_stats_mart_bar_v2',
  LATENCY_STATS_MART_LINE: 'latency_stats_mart_line',
  PERFORMANCES_MART_BAR: 'performances_mart_bar',
  PERFORMANCES_MART_BAR_V2: 'performances_mart_bar_v2',
  PERFORMANCES_MART_LINE: 'performances_mart_line',
  MEETING_ROOM_MART_LINE: 'meeting_room_mart_line',
  MEETING_ROOM_MART_TABLE: 'meeting_room_mart_table',
  CLIENT_COUNTS_MART_BAR: 'client_counts_mart_bar',
  CLIENT_COUNTS_MART_BAR_V2: 'client_counts_mart_bar_v2',
  CLIENT_COUNTS_MART_LINE: 'client_counts_mart_line',
  MEETING_ROOM_MART_TABLE_RESERVED_WEEKLY:
    'meeting_room_mart_table_reserved_weekly',
  MEETING_ROOM_MART_TABLE_RESERVED_MONTHLY:
    'meeting_room_mart_table_reserved_monthly',
  MEETING_ROOM_MART_TABLE_UNUSED_WEEKLY:
    'meeting_room_mart_table_unused_weekly',
  MEETING_ROOM_MART_TABLE_UNUSED_MONTHLY:
    'meeting_room_mart_table_unused_monthly',
  EMPLOYEES: 'employees',
  EMPLOYEE_DEVICES: 'employee_devices',
  VERKADA_ACCESSLOGS: 'verkada_accesslogs',
  MERAKI_ACCESSLOGS: 'meraki_accesslogs',
  SENSOR_DATA_MART_MAP: 'sensor_data_mart_map',
  SENSOR_DATA_MART_LINE: 'sensor_data_mart_line',
  MERAKI_NETWORK_EVENTS: 'meraki_network_events',
  MERAKI_CONFIGURATION_CHANGES: 'meraki_configuration_changes',
  LINE_CHART_THRESHOLDS: 'line_chart_thresholds',
  VERKADA_AUDITLOGS: 'verkada_auditlogs',
  VERKADA_ACCESSLOGS_MART_ATTENDANCES: 'verkada_accesslogs_mart_attendances',
  VERKADA_DEVICES: 'verkada_devices',
  MERAKI_NETWORKS: 'meraki_networks',
  MERAKI_DEVICES: 'meraki_devices',
  DEVICE_POSITIONS: 'device_positions',
  EMPLOYEE_ATTENDANCE_RATES_BRANCH: 'employee_attendance_rates_branch',
  EMPLOYEE_ATTENDANCE_RATES_FLOOR: 'employee_attendance_rates_floor',
  EMPLOYEE_ATTENDANCE_RATES_DEPARTMENT: 'employee_attendance_rates_department',
  EMPLOYEE_ATTENDANCE_RATES_DAY_BRANCH: 'employee_attendance_rates_day_branch',
  EMPLOYEE_ATTENDANCE_RATES_DAY_FLOOR: 'employee_attendance_rates_day_floor',
  EMPLOYEE_ATTENDANCE_RATES_DAY_DEPARTMENT:
    'employee_attendance_rates_day_department',
  MERAKI_CAMERA_ZONES: 'meraki_camera_zones',
  SIGNAGE_COMPONENTS: 'signage_components',
  SENSOR_DATA_THRESHOLDS: 'sensor_data_thresholds',
  BUTLR_ROOMS: 'butlr_rooms',
  GAROON_FACILITIES: 'garoon_facilities',
  WEBEX_WORKSPACES: 'webex_workspaces',
  SECURECONNECT_REMOTE_ACCESS_EVENTS: 'secureconnect_remote_access_events'
};
