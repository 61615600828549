// Common text for English
const commonTextEn = {
  PEOPLE_COUNTS: 'People Counts',
  PEOPLE_COUNTS_ANALYSIS: 'People Counts Analysis',
  EMPLOYEE_LOCATIONS: 'Employee Locations',
  EMPLOYEE_ACCESS_LOG: 'Employee Access Log',
  AIR_QUALITY: '環境 (Environment)',
  MEETING_ROOM_OUTLOOK_BAR: 'Meeting Room Outlook Bar',
  MEETING_ROOM_OUTLOOK_USAGE: 'Meeting Room Outlook Table',
  MEETING_ROOM_OUTLOOK_BOOKING: 'Meeting Room Outlook Booking',
  MEETING_ROOM_OUTLOOK_UNUSED_BOOKING: 'Meeting Room Outlook Unused Booking',
  MEETING_ROOM_USAGE: 'Meeting Room Usage',
  DEVICE_STATUS_OVERVIEW: 'Device Status Overview',
  NETWORK: 'ワイヤレス (Wireless)',
  NETWORK_USAGE: 'Data Rates (Network Usage)',
  NETWORK_CLIENT_COUNT: 'Network Client Count',
  WIRELESS_LATENCY: 'Wireless Latency',
  WIRELESS_CHANNEL_UTILIZATION: 'Wireless Channel Utilization',
  WIRELESS_SIGNAL: 'Signal Quality',
  NETWORK_PERFORMANCE_SCORE: 'Network Performance Score',
  DEVICE_NAME: 'Device Name',
  DEVICE: 'Device',
  MERAKI_DEVICE_NAME: 'Meraki Device Name',
  STATUS: 'Status',
  LAST_UPDATED_TIME: 'Last Updated',
  VACANT: 'Vacant',
  OCCUPIED: 'Occupied',
  EMPLOYEE_NAME: 'Employee Name',
  ROOM_NAME: 'Room',
  DEPARTMENT: 'Department',
  TEAM: 'Team',
  FLOOR_NOT_SELECTED_PLEASE_SELECT:
    'Floor not selected. Please select a floor.',
  TENANT_NOT_SELECTED_PLEASE_SELECT:
    'Tenant not selected. Please select a tenant.',
  BRANCH_NOT_SELECTED_PLEASE_SELECT:
    'Branch not selected. Please select a branch.',
  NO_DATA: 'No Data',
  CANNOT_LOAD_FLOORMAP: 'Could not load floor map',
  LOADING_DATA: 'Loading data...',
  UPDATING_DATA: 'Updating data...',
  TOP_3: 'Top 3',
  TOP_5: 'Top 5',
  TOP_10: 'Top 10',
  TOP_ALL: 'All',
  REFRESH_BUTTON_TEXT: 'Refresh',
  TENANT: 'Tenant',
  BRANCH: 'Branch',
  FLOOR: 'Floor',
  SELECT: 'Select',
  SELECT_TENANT: 'Select Tenant',
  SELECT_BRANCH: 'Select Branch',
  SELECT_FLOOR: 'Select Floor',
  AVERAGE_SCORE: 'Average Score',
  SCORE_TRANSITION: 'Score Transition',
  BACKGROUND: 'Background',
  BEST_EFFORT: 'Best Effort',
  VIDEO: 'Video',
  VOICE: 'Voice',
  SORT_BY_DEVICE_NAME: 'Device Name',
  SORT_BY_PERFORMANCE_SCORE_DESC: 'Performance Score Desc',
  SORT_BY_PERFORMANCE_SCORE_ASC: 'Performance Score Asc',
  RESERVED: 'Reserved',
  RESERVED_AND_UNUSED: 'Reserved & Unused',
  OCCUPIED_9_18: 'Occupied 9h-18h',
  UNUSED_RESERVATION: 'Unused Reservation',
  RESERVATION_COUNTS: 'Reservation Counts',
  EMPTY_BOOKING_RATE: 'Empty Booking Rate',
  MINUTES: 'Minutes',
  PAST_ONE_WEEK: 'Last 1 Week',
  PAST_ONE_MONTH: 'Last 1 Month',
  NAME: 'Name',
  FREE_BOOKING_TIME: 'Free Booking Time',
  CURRENT_RESERVATION: 'Current Reservation',
  NEXT_RESERVATION: 'Next Reservation',
  RESERVATION_TIME_BY_ROOM: 'Reservation Time by Room',
  COLLAPSE_ALL: 'Collapse All',
  NETWORK_CLIENT_COUNT_DESCRIPTION:
    'The number of terminals connected to the access point',
  PERFORMANCE_SCORE_DESCRIPTION:
    'Scores are accumulated for various indicators.',
  PERFORMANCE_SCORE_LEGEND_HEADER: 'Score',
  WIRELESS_SIGNAL_DESCRIPTION:
    'Access point channel and SNR (signal-to-noise ratio)',
  WIRELESS_SIGNAL_LEGEND_HEADER: 'SNR',
  NETWORK_USAGE_DESCRIPTION:
    'Data transfer rate between access point and terminal',
  WIRELESS_CHANNEL_UTILIZATION_DESCRIPTION:
    'The interference level of the channel used by the access point',
  WIRELESS_LATENCY_DESCRIPTION: 'Delay time for each communication type',
  AVERAGE: 'Average',
  CHANGE: 'Change',
  TEMPERATURE: 'Temperature',
  HUMIDITY: 'Humidity',
  CARBON_DIOXIDE: 'Carbon Dioxide',
  NOISE_LEVEL: 'Noise Level',
  LAST_24_HOUR: 'Last 24 Hours',
  LAST_ONE_WEEK: 'Last 1 Week',
  LAST_ONE_MONTH: 'Last 1 Month',
  MERAKI_NETWORK_EVENTS: 'Meraki Network Events',
  VERKADA_NETWORK_EVENTS: 'Verkada Network Events',
  //CsvExport
  CSV_EXPORT: 'CSV Export',
  // Meraki Network Events Table Headers
  MERAKI_NETWORK_EVENTS_OCCURRED_AT: 'Occurred At',
  MERAKI_NETWORK_EVENTS_NETWORK_NAME: 'Network Name',
  MERAKI_NETWORK_EVENTS_PRODUCT_TYPE: 'Product Type',
  MERAKI_NETWORK_EVENTS_DEVICE_IDENTIFIER: 'Device Identifier',
  MERAKI_NETWORK_EVENTS_DEVICE_NAME: 'Device Name',
  MERAKI_NETWORK_EVENTS_SSID_NUMBER: 'SSID',
  MERAKI_NETWORK_EVENTS_CLIENT_MAC: 'Client MAC',
  MERAKI_NETWORK_EVENTS_CLIENT_ID: 'Client ID',
  MERAKI_NETWORK_EVENTS_CATEGORY: 'Category',
  MERAKI_NETWORK_EVENTS_TYPE: 'Type',
  MERAKI_NETWORK_EVENTS_DESCRIPTION: 'Description',
  // Meraki Network Events Table End
  // Meraki Configuration Change Table Headers
  MERAKI_CONFIG_CHANGE_TIMESTAMP: 'Timestamp',
  MERAKI_CONFIG_CHANGE_ADMIN_NAME: 'Admin Name',
  MERAKI_CONFIG_CHANGE_NETWORK_NAME: 'Network Name',
  MERAKI_CONFIG_CHANGE_SSID_NAME: 'SSID',
  MERAKI_CONFIG_CHANGE_PAGE: 'Page',
  MERAKI_CONFIG_CHANGE_LABEL: 'Label',
  OLD_VALUE: 'Old',
  NEW_VALUE: 'New',
  //  Meraki Configuration Change Table Headers End
  // Verkada Audit Logs Table Headers
  VERKADA_AUDIT_LOGS_TIMESTAMP: 'Timestamp',
  VERKADA_AUDIT_LOGS_DEVICE_NAME: 'Device Name',
  VERKADA_AUDIT_LOGS_EVENT_DESCRIPTION: 'Event Description',
  VERKADA_AUDIT_LOGS_EVENT_NAME: 'Event Name',
  VERKADA_AUDIT_LOGS_IP_ADDRESS: 'IP Address',
  VERKADA_AUDIT_LOGS_USER_NAME: 'User Name',
  VERKADA_AUDIT_LOGS_DETAILS: 'Details',
  // Verkada Audit Logs Table Headers End
  DATA_CONFIGURATION: 'Data Config',
  ACCOUNT: 'Account',
  SUMMARY: 'Summary',
  TK_OVERVIEW: 'Takachiho Trading Overview',
  // Setting
  TENANT_SETTING: 'Tenant',
  ORGANIZATION_SETTING: 'Organization',
  LOCATION_SETTING: 'Location',
  FLOOR_SETTING: 'Floor',
  AREA_SETTING: 'Area',
  VERKADA_DEVICE_SETTING: 'Verkada Device',
  DEVICE_LOCATION_SETTING: 'Device Location',
  COMPANY_EMPLOYEE_SETTING: 'Employee',
  EMPLOYEE_TERMINAL_SETTING: 'Employee Terminal',
  WORKING_HOUR_SETTING: 'Working Hour',
  CAMERA_ZONE_SETTING: 'Camera Zone',
  AIR_QUALITY_SETTING: 'Air Quality',
  // End setting

  // Validation
  REQUIRED: 'Required',
  EMPLOYEE_INFORMATION: 'Employee Information',
  EMPLOYEE_NUMBER: 'Employee Number',
  VERKADA_ID: 'Verkada Employee ID',
  EMPLOYEE_IMAGE: 'Image',
  EMPLOYEE_PHONE_NUMBER: 'Phone Number',
  EMPLOYEE_EMAIL: 'Email',
  EMPLOYEE_TOOL_CHAT: 'Chat Tool',
  EMPLOYEE_DEVICE_PC_MAC: 'PC - Mac Address',
  EMPLOYEE_DEVICE_PC_NAME: 'PC - Device Name',
  EMPLOYEE_DEVICE_MOBILE_MAC: '携帯 - Mac Address',
  EMPLOYEE_DEVICE_MOBILE_NAME: '携帯 - Device Name',

  SAVE: 'Save',
  CANCEL: 'Cancel',
  ADD_NEW: 'Add',
  DELETE_CONFIRMATION_HEADER: 'Delete Confirmation',
  DOWNLOAD: 'Download',
  RESONABLE_VALUE: 'Reasonable Value',
  INFO_COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  EMPLOYEE_NO_EVENTS: 'No events',
  NO_LOCATION_DATA: 'No location data',
  DEVICE_LOCATION_CHANGE_HEADER: 'Device Location Change',
  STATUS_NEW: 'New',
  STATUS_MODIFIED: 'Modified',
  STATUS_DELETED: 'Deleted',
  AREA_CAPACITY: 'Area Capacity',
  MOBILE_DEVICE_TYPE: 'Phone',
  PC_DEVICE_TYPE: 'PC',
  AREA_NAME: 'Area Name',
  COUNTS: 'Counts',
  CAPACITY_RATE: 'Capacity Rate',
  NO_CHANGE_TO_BE_SAVED: 'No change to be saved.',
  EMPLOYEE_ATTENDANCE_RATE: 'Employee Attendance Rate',
  SIGNAGE_MODE: 'Signage Mode',
  LATEST_ATTENDANCE_RATE: 'Latest Attendance Rate',
  LOG: 'Log',
  SET: 'Set',
  BY_TIME: 'By Time',
  BY_DAY: 'By Day',
  FROM_DATE: 'From',
  TO_DATE: 'To',
  TYPE: 'Type',
  NO_ZONE: 'No Zone',
  ZONE: 'Zone',
  NO_DEVICE: 'No Device',
  MAKE_RESERVATION: 'Make Reservation',
  ATTENDANCE_RATE: 'Attendance Rate',
  SHOW_CAMERA: 'Show Camera',
  HIDE_CAMERA: 'Hide Camera',
  SHOW_HIDE_CAMERA: 'Show/Hide Device',
  DEVICE_DISPLAY: 'Device Display',
  THRESHOLD_SETTING: 'Threshold Setting',
  PLEASE_INPUT_VALID_NUMBER: 'Please input valid number',
  HIGH: 'High',
  LOW: 'Low',
  NO_UPDATE: 'There is no update',
  INFO: 'Info',
  REQUEST_TIME_OUT: 'Request time out',
  COMPONENT: 'Component',
  DISPLAY_TIME: 'Display Time',
  CARBON_DIOXIDE_UNIT: 'ppm',
  BUTLR_USERNAME: 'Username',
  BUTLR_API_KEY: 'ApiKey',
  GAROON_USERNAME: 'Username',
  GAROON_API_KEY: 'ApiKey',
  GAROON_SUBDOMAIN: 'Subdomain',
  WEBEX_API_KEY: 'ApiKey',
  WEBEX_REFRESH_TOKEN: 'RefreshToken',
  WEBEX_CLIENT_ID: 'ClientId',
  WEBEX_CLIENT_SECRET: 'Client Secret',
  MICROSOFT_API_KEY: 'ApiKey',
  MICROSOFT_GRAPH_CLIENT_ID: 'Graph Client Id',
  MICROSOFT_GRAPH_TENANT_ID: 'Graph Tenant Id',
  API_KEY: 'ApiKey',
  SCHEDULE_TOOL: 'Schedule Tool',
  PEOPLE_COUNTS_LEGEND: 'Legend',
  ATTENDANCE_RATE_THRESHOLD: 'Attendance Rate Threshold',
  NO_DEVICE_AVAILABLE: 'No Device Available',
  START_SIGNAGE_MODE: 'Start signage mode',
  PRESS_ESC_TO_EXIT: 'Press esc to exit',
};

// Common text for Japanese
const commonTextJp = {
  PEOPLE_COUNTS: '人数検知',
  PEOPLE_COUNTS_ANALYSIS: '人数検知分析',
  EMPLOYEE_LOCATIONS: '社員の所在',
  EMPLOYEE_ACCESS_LOG: '従業員ログ管理',
  AIR_QUALITY: '環境',
  MEETING_ROOM_OUTLOOK_BAR: '会議室 予約状況',
  MEETING_ROOM_OUTLOOK_USAGE: '会議室 利用分析',
  MEETING_ROOM_OUTLOOK_BOOKING: '予約時間',
  MEETING_ROOM_OUTLOOK_UNUSED_BOOKING: '空予約時間',
  MEETING_ROOM_USAGE: '会議室 利用状況',
  DEVICE_STATUS_OVERVIEW: 'デバイスステータス',
  NETWORK: 'ワイヤレス',
  NETWORK_USAGE: 'データレート', // English text is used as fallback
  NETWORK_CLIENT_COUNT: '接続端末数',
  WIRELESS_LATENCY: '遅延', // English text is used as fallback
  WIRELESS_CHANNEL_UTILIZATION: 'チャネル利用率', // English text is used as fallback
  WIRELESS_SIGNAL: '信号品質',
  NETWORK_PERFORMANCE_SCORE: 'パフォーマンススコア', // English text is used as fallback
  DEVICE_NAME: 'デバイス名',
  DEVICE: 'デバイス',
  MERAKI_DEVICE_NAME: '接続先',
  STATUS: 'ステータス',
  LAST_UPDATED_TIME: '更新日時',
  VACANT: '空室',
  OCCUPIED: '在室',
  EMPLOYEE_NAME: '社員名',
  ROOM_NAME: '部屋名',
  DEPARTMENT: '部署',
  TEAM: 'チーム',
  FLOOR_NOT_SELECTED_PLEASE_SELECT:
    'フロアが選択されていません。フロアを選択してください。',
  TENANT_NOT_SELECTED_PLEASE_SELECT:
    'テナントが選択されていません。テナントを選択してください。',
  BRANCH_NOT_SELECTED_PLEASE_SELECT:
    '拠点が選択されていません。拠点を選択してください。',
  NO_DATA: 'データなし',
  CANNOT_LOAD_FLOORMAP: 'フロアマップを読み込めませんでした',
  LOADING_DATA: 'データ読込中...',
  UPDATING_DATA: '更新中...',
  TOP_3: 'トップ3',
  TOP_5: 'トップ5',
  TOP_10: 'トップ10',
  TOP_ALL: '全て',
  REFRESH_BUTTON_TEXT: '更新',
  TENANT: 'テナント',
  BRANCH: '拠点',
  FLOOR: 'フロア',
  SELECT: '選択',
  SELECT_TENANT: 'テナントを選択',
  SELECT_BRANCH: '拠点選択',
  SELECT_FLOOR: 'フロア選択',
  AVERAGE_SCORE: '平均',
  SCORE_TRANSITION: '推移',
  BACKGROUND: 'バックグラウンド',
  BEST_EFFORT: 'ベストエフォート',
  VIDEO: 'ビデオ',
  VOICE: '音声',
  SORT_BY_DEVICE_NAME: '名前順',
  SORT_BY_PERFORMANCE_SCORE_DESC: 'パフォーマンススコア降順',
  SORT_BY_PERFORMANCE_SCORE_ASC: 'パフォーマンススコア昇順',
  RESERVED: '予約時間',
  RESERVED_AND_UNUSED: '使用時間',
  OCCUPIED_9_18: '使用率(9-18時)',
  UNUSED_RESERVATION: '空予約時間',
  RESERVATION_COUNTS: '予約件数',
  EMPTY_BOOKING_RATE: '空予約率',
  MINUTES: '分',
  PAST_ONE_WEEK: '過去１週間',
  PAST_ONE_MONTH: '過去1ヶ月',
  NAME: '名前',
  FREE_BOOKING_TIME: '空予約時間',
  CURRENT_RESERVATION: '現在の予約',
  NEXT_RESERVATION: '次の予約',
  RESERVATION_TIME_BY_ROOM: '部屋別予約時間',
  COLLAPSE_ALL: 'すべて閉じる',
  NETWORK_CLIENT_COUNT_DESCRIPTION: 'アクセスポイントに接続した端末数です',
  PERFORMANCE_SCORE_DESCRIPTION: '各種指標をスコア化し累計した値です',
  PERFORMANCE_SCORE_LEGEND_HEADER: 'スコア',
  WIRELESS_SIGNAL_DESCRIPTION:
    'アクセスポイントのチャネルとSNR (信号対雑音比) です',
  WIRELESS_SIGNAL_LEGEND_HEADER: 'SNR',
  NETWORK_USAGE_DESCRIPTION: 'アクセスポイントと端末間のデータ転送速度です',
  WIRELESS_CHANNEL_UTILIZATION_DESCRIPTION:
    'アクセスポイントが使用するチャネルの干渉度合です',
  WIRELESS_LATENCY_DESCRIPTION: '通信種別毎の遅延時間です',
  AVERAGE: '平均',
  CHANGE: '推移',
  TEMPERATURE: '温度',
  HUMIDITY: '湿度',
  CARBON_DIOXIDE: '二酸化炭素',
  NOISE_LEVEL: '騒音',
  LAST_24_HOUR: '24時間',
  LAST_ONE_WEEK: '1週間',
  LAST_ONE_MONTH: '1ヶ月',
  MERAKI_NETWORK_EVENTS: 'Merakiログ',
  VERKADA_NETWORK_EVENTS: 'Verkadaログ',
  //CsvExport
  CSV_EXPORT: 'CSVダウンロード',
  // Meraki Network Events Table Headers
  MERAKI_NETWORK_EVENTS_OCCURRED_AT: '日時',
  MERAKI_NETWORK_EVENTS_NETWORK_NAME: 'ネットワーク名',
  MERAKI_NETWORK_EVENTS_PRODUCT_TYPE: 'プロダクト',
  MERAKI_NETWORK_EVENTS_DEVICE_IDENTIFIER: 'デバイスシリアル',
  MERAKI_NETWORK_EVENTS_DEVICE_NAME: 'デバイス名',
  MERAKI_NETWORK_EVENTS_SSID_NUMBER: 'SSID',
  MERAKI_NETWORK_EVENTS_CLIENT_MAC: 'クライアントMAC',
  MERAKI_NETWORK_EVENTS_CLIENT_ID: 'クライアント名',
  MERAKI_NETWORK_EVENTS_CATEGORY: 'カテゴリ',
  MERAKI_NETWORK_EVENTS_TYPE: 'イベントタイプ',
  MERAKI_NETWORK_EVENTS_DESCRIPTION: '詳細',
  // Meraki Network Events Table End
  // Meraki Configuration Change Table Headers
  MERAKI_CONFIG_CHANGE_TIMESTAMP: '日時',
  MERAKI_CONFIG_CHANGE_ADMIN_NAME: '変更者',
  MERAKI_CONFIG_CHANGE_NETWORK_NAME: 'ネットワーク名',
  MERAKI_CONFIG_CHANGE_SSID_NAME: 'SSID',
  MERAKI_CONFIG_CHANGE_PAGE: '設定箇所',
  MERAKI_CONFIG_CHANGE_LABEL: '設定内容',
  OLD_VALUE: '旧',
  NEW_VALUE: '新',
  //  Meraki Configuration Change Table Headers End
  // Verkada Audit Logs Table Headers
  VERKADA_AUDIT_LOGS_TIMESTAMP: '日時',
  VERKADA_AUDIT_LOGS_DEVICE_NAME: 'デバイス名',
  VERKADA_AUDIT_LOGS_EVENT_DESCRIPTION: 'イベント概要',
  VERKADA_AUDIT_LOGS_EVENT_NAME: 'イベント名',
  VERKADA_AUDIT_LOGS_IP_ADDRESS: 'IPアドレス',
  VERKADA_AUDIT_LOGS_USER_NAME: 'ユーザー名',
  VERKADA_AUDIT_LOGS_DETAILS: '詳細',
  // Verkada Audit Logs Table Headers End
  DATA_CONFIGURATION: 'データ設定',
  ACCOUNT: 'アカウント',
  SUMMARY: '概要',
  TK_OVERVIEW: '高千穂交易の概要',
  // Setting
  TENANT_SETTING: 'テナント',
  ORGANIZATION_SETTING: 'オーガナイゼーション',
  LOCATION_SETTING: '拠点',
  FLOOR_SETTING: 'フロア',
  AREA_SETTING: 'エリア',
  VERKADA_DEVICE_SETTING: 'Verkadaデバイス',
  DEVICE_LOCATION_SETTING: 'デバイスの位置',
  COMPANY_EMPLOYEE_SETTING: '社員',
  EMPLOYEE_TERMINAL_SETTING: '社員端末',
  WORKING_HOUR_SETTING: '就業日時',
  CAMERA_ZONE_SETTING: 'カメラゾーン',
  AIR_QUALITY_SETTING: '環境',
  // End setting

  // Validation
  REQUIRED: '入力してください',
  EMPLOYEE_INFORMATION: '社員情報',
  EMPLOYEE_NUMBER: '社員番号',
  VERKADA_ID: 'Verkada社員ID',
  EMPLOYEE_IMAGE: '画像',
  EMPLOYEE_PHONE_NUMBER: '電話番号',
  EMPLOYEE_EMAIL: 'メールアドレス',
  EMPLOYEE_TOOL_CHAT: 'チャットツール',
  EMPLOYEE_DEVICE_PC_MAC: 'PC - MACアドレス',
  EMPLOYEE_DEVICE_PC_NAME: 'PC - 端末名',
  EMPLOYEE_DEVICE_MOBILE_MAC: '携帯 - MACアドレス',
  EMPLOYEE_DEVICE_MOBILE_NAME: '携帯 - 端末名',

  SAVE: '保存する',
  CANCEL: 'キャンセル',
  ADD_NEW: '追加',
  DELETE_CONFIRMATION_HEADER: '削除の確認',
  DOWNLOAD: 'ダウンロード',
  RESONABLE_VALUE: '適正値',
  INFO_COPIED_TO_CLIPBOARD: '情報がクリップボードにコピーされました',
  EMPLOYEE_NO_EVENTS: 'イベントなし',
  NO_LOCATION_DATA: '所在データなし',
  DEVICE_LOCATION_CHANGE_HEADER: 'デバイスの位置変更',
  STATUS_NEW: '新規',
  STATUS_MODIFIED: '修正',
  STATUS_DELETED: '削除',
  AREA_CAPACITY: 'エリアの定員',
  MOBILE_DEVICE_TYPE: 'スマートフォン',
  PC_DEVICE_TYPE: 'PC',
  AREA_NAME: 'エリア名',
  COUNTS: '人数',
  CAPACITY_RATE: '占有率',
  NO_CHANGE_TO_BE_SAVED: '保存する変更はありません。',
  EMPLOYEE_ATTENDANCE_RATE: '出社率',
  SIGNAGE_MODE: 'サイネージモード',
  LATEST_ATTENDANCE_RATE: '最新の出社率',
  LOG: '履歴',
  SET: 'セット',
  BY_TIME: '時間別',
  BY_DAY: '日別',
  FROM_DATE: 'から',
  TO_DATE: 'まで',
  TYPE: 'タイプ',
  NO_ZONE: 'ゾーンなし',
  ZONE: 'ゾーン',
  NO_DEVICE: 'デバイスなし',
  MAKE_RESERVATION: '予約する',
  ATTENDANCE_RATE: '出社率%',
  SHOW_CAMERA: 'デバイス表示',
  HIDE_CAMERA: 'デバイス非表示',
  SHOW_HIDE_CAMERA: 'デバイス表示/非表示',
  DEVICE_DISPLAY: 'デバイス表示',
  THRESHOLD_SETTING: '閾値の設定',
  PLEASE_INPUT_VALID_NUMBER: '有効な数値を入力してください',
  HIGH: '高',
  LOW: '低',
  NO_UPDATE: '変更中です しばらくお待ちください',
  INFO: '情報',
  REQUEST_TIME_OUT: 'リクエストタイムアウト',
  COMPONENT: 'コンポーネント',
  DISPLAY_TIME: '表示時間',
  CARBON_DIOXIDE_UNIT: 'ppm',
  BUTLR_USERNAME: 'ユーザー名',
  BUTLR_API_KEY: 'パスワード',
  GAROON_USERNAME: 'ログイン名',
  GAROON_API_KEY: 'パスワード',
  GAROON_SUBDOMAIN: 'サブドメイン',
  WEBEX_API_KEY: 'アクセストークン',
  WEBEX_REFRESH_TOKEN: 'リフレッシュトークン',
  WEBEX_CLIENT_ID: 'クライアントID',
  WEBEX_CLIENT_SECRET: 'クライアントシークレット',
  MICROSOFT_API_KEY: 'クライアントシークレット',
  MICROSOFT_GRAPH_CLIENT_ID: 'クライアントID',
  MICROSOFT_GRAPH_TENANT_ID: 'テナントID',
  API_KEY: 'キー',
  SCHEDULE_TOOL: 'スケジュール管理ツール',
  PEOPLE_COUNTS_LEGEND: '占有率の閾値',
  ATTENDANCE_RATE_THRESHOLD: '出社率の閾値',
  NO_DEVICE_AVAILABLE: 'デバイスがありません',
  START_SIGNAGE_MODE: 'サイネージモードを開始',
  PRESS_ESC_TO_EXIT: '終了はescキー',
};

const langCode = 'jp';

export const COMMON_TEXT = {
  en: commonTextEn,
  jp: commonTextJp,
  // add more language objects here if needed
}[langCode];
