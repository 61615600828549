import { USER_PRIVILEGE, USER_PRIVILEGE_KEY } from '@/helpers/constants';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class RedirectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNavigate: '',
    };
    this.nagivationType = {
      ADMIN: {
        name: 'admin',
        path: '/employee-locations',
      },
      USER: {
        name: 'dashboard',
        path: '/dashboard',
      },
      SIGNAGE: {
        name: 'signage',
        path: '/signage-components',
      },
    };
  }

  componentDidMount() {
    const userPrivilege = this.props.sessionStore[USER_PRIVILEGE_KEY];
    if (!userPrivilege) {
      return;
    }
    this.updatePageNavigate({ userPrivilege });
  }

  componentDidUpdate(prevProps) {
    const userPrivilege = this.props.sessionStore[USER_PRIVILEGE_KEY];
    if (!userPrivilege) {
      return;
    }
    this.updatePageNavigate({ userPrivilege });
  }

  updatePageNavigate = ({ userPrivilege }) => {
    if (!userPrivilege) {
      return;
    }
    if (
      userPrivilege === USER_PRIVILEGE.ADMIN ||
      userPrivilege === USER_PRIVILEGE.SUPERVISOR
    ) {
      this.setState({ pageNavigate: this.nagivationType.ADMIN.name });
    } else if (userPrivilege === USER_PRIVILEGE.SIGNAGE) {
      this.setState({ pageNavigate: this.nagivationType.SIGNAGE.name });
    } else {
      this.setState({ pageNavigate: this.nagivationType.USER.name });
    }
  };

  render() {
    const { pageNavigate } = this.state;
    if (!pageNavigate) {
      return <></>;
    }
    if (pageNavigate === this.nagivationType.ADMIN.name) {
      return <Redirect to={this.nagivationType.ADMIN.path} exact={true} />;
    } else if (pageNavigate === this.nagivationType.USER.name) {
      return <Redirect to={this.nagivationType.USER.path} exact={true} />;
    } else if (pageNavigate === this.nagivationType.SIGNAGE.name) {
      return <Redirect to={this.nagivationType.SIGNAGE.path} exact={true} />;
    }
  }
}

RedirectPage.propTypes = {};

export default RedirectPage;
