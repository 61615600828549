import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { isUsingSampleData } from 'src/helpers/generate-random-data';
import { executeQuery } from 'src/helpers/request';

const buildQueryTableData = (tenantId, fromDate, toDate) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        category: ['select'],
        client_description: ['select'],
        client_id: ['select'],
        client_mac: ['select'],
        description: ['select'],
        device_name: ['select'],
        device_serial: ['select'],
        network_identifer: ['select'],
        network_name: ['select'],
        occurred_at: ['select'],
        product_type: ['select'],
        ssid_number: ['select'],
        tenant_id: ['select'],
        type: ['select'],
        log_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
      occurred_at: {
        gte: [fromDate],
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      occurred_at: 'desc',
    },
    table_name: QUERY_TABLES.MERAKI_NETWORK_EVENTS,
  };
};

const buildRequestQueryBranch = tenantId => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        branch_name: ['select'],
        tenant_id: ['select'],
        branch_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      branch_order: 'asc',
    },
    table_name: QUERY_TABLES.BRANCHES,
  };
};

const buildRequestQueryFloor = branchIdList => {
  const randomBranchId = getRandomNumber900000to999999();
  branchIdList = branchIdList || [];
  branchIdList.push(randomBranchId);
  return {
    data: {
      fields: {
        floor_id: ['select'],
        floorplan_id: ['select'],
        floor_name: ['select'],
        branch_id: ['select'],
        floor_map: ['select'],
        floor_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: branchIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      floor_order: 'asc',
    },
    table_name: QUERY_TABLES.FLOORS,
  };
};

const buildRequestVerkadaDevices = floorIdList => {
  const randomFloorId = getRandomNumber900000to999999();
  floorIdList = floorIdList || [];
  floorIdList.push(randomFloorId);
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        device_type: ['select'],
        door_type: ['select'],
        floor_id: ['select'],
        model: ['select'],
        org_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: floorIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.VERKADA_DEVICES,
  };
};

export const fetchMerakiNetworkEvents = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildQueryTableData(tenantId, fromDate, toDate);
  if (isUsingSampleData) {
    return [];
  }
  return executeQuery(request);
};

export const fetchBranchListByTenent = async tenentId => {
  const request = buildRequestQueryBranch(tenentId);
  return executeQuery(request);
};

export const fetchFloorListByBranchIdList = async branchIdList => {
  const request = buildRequestQueryFloor(branchIdList);
  return executeQuery(request);
};

export const fetchVerkadaDevicesByFloorIdList = async floorIdList => {
  const request = buildRequestVerkadaDevices(floorIdList);
  return executeQuery(request);
};
