import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import { format } from 'date-fns';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchMerakiNetworkEvents } from './query-request';

@connect(state => ({
  querystring: state.querystring,
}))
class MerakiEventlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      tableData: [],
      isLoading: false,
      filterData: [],
      isShowTable: false,
    };
    this.isAccordionTabInitOpen = false;
    this.dataTableRef = React.createRef();
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      occurred_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
      network_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      product_type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      device_serial: { value: null, matchMode: FilterMatchMode.CONTAINS },
      device_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      ssid_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
      client_mac: { value: null, matchMode: FilterMatchMode.CONTAINS },
      client_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      category: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
    this.dataColumns = [
      {
        field: 'occurred_at',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_OCCURRED_AT,
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'network_name',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_NETWORK_NAME,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'product_type',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_PRODUCT_TYPE,
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'device_serial',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_DEVICE_IDENTIFIER,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'device_name',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_DEVICE_NAME,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'ssid_number',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_SSID_NUMBER,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'client_mac',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_CLIENT_MAC,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
        filter: true,
      },
      {
        field: 'client_id',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_CLIENT_ID,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'category',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_CATEGORY,
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'type',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_TYPE,
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'description',
        header: COMMON_TEXT.MERAKI_NETWORK_EVENTS_DESCRIPTION,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
    ];
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.mapFilterData();
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({
        isShowTable: false,
        tableData: [],
      });
    }
  }

  getPayload = () => {
    const { tenant, filterData } = this.state;
    const selectedDate = filterData.filter(data => data.id === 'occurred_at')[0]
      .value;
    const network_name = filterData.filter(
      data => data.id === 'network_name'
    )[0].value;
    const product_type = filterData.filter(
      data => data.id === 'product_type'
    )[0].value;
    const device_serial = filterData.filter(
      data => data.id === 'device_serial'
    )[0].value;
    const device_name = filterData.filter(data => data.id === 'device_name')[0]
      .value;
    const ssid_number = filterData.filter(data => data.id === 'ssid_number')[0]
      .value;
    const client_mac = filterData.filter(data => data.id === 'client_mac')[0]
      .value;
    const client_id = filterData.filter(data => data.id === 'client_id')[0]
      .value;
    const category = filterData.filter(data => data.id === 'category')[0].value;
    const type = filterData.filter(data => data.id === 'type')[0].value;
    const payload = {
      tenant_id: [tenant],
      occurred_at: {
        lte: [format(selectedDate, 'yyyy-MM-dd HH:mm:ss')],
      },
      network_name: network_name ? { like: [`%${network_name}%`] } : [],
      product_type: product_type ? { like: [`%${product_type}%`] } : [],
      device_serial: device_serial ? { like: [`%${device_serial}%`] } : [],
      device_name: device_name ? { like: [`%${device_name}%`] } : [],
      ssid_number: ssid_number ? { like: [`%${ssid_number}%`] } : [],
      client_mac: client_mac ? { like: [`%${client_mac}%`] } : [],
      client_id: client_id ? { like: [`%${client_id}%`] } : [],
      category: category ? { like: [`%${category}%`] } : [],
      type: type ? { like: [`%${type}%`] } : [],
    };
    return payload;
  };

  getDataTable = async () => {
    const payload = this.getPayload();

    const response = await fetchMerakiNetworkEvents(payload);
    this.setState({
      isLoading: false,
      tableData: response,
    });
  };

  mapFilterData = () => {
    const filterData = [
      {
        id: 'occurred_at',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_OCCURRED_AT,
        value: new Date(),
      },
      {
        id: 'network_name',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_NETWORK_NAME,
        value: '',
      },
      {
        id: 'product_type',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_PRODUCT_TYPE,
        value: '',
      },
      {
        id: 'device_serial',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_DEVICE_IDENTIFIER,
        value: '',
      },
      {
        id: 'device_name',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_DEVICE_NAME,
        value: '',
      },
      {
        id: 'ssid_number',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_SSID_NUMBER,
        value: '',
      },
      {
        id: 'client_mac',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_CLIENT_MAC,
        value: '',
      },
      {
        id: 'client_id',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_CLIENT_ID,
        value: '',
      },
      {
        id: 'category',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_CATEGORY,
        value: '',
      },
      {
        id: 'type',
        label: COMMON_TEXT.MERAKI_NETWORK_EVENTS_TYPE,
        value: '',
      },
    ];
    this.setState({ filterData });
  };

  exportCSV = () => {
    this.dataTableRef.current.exportCSV();
  };

  renderMessageError = () => {
    const { tenant } = this.state;
    if (!tenant) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  onAccordionTabOpen = e => {
    if (!this.isAccordionTabInitOpen) {
      this.isAccordionTabInitOpen = true;
    }
  };

  datePickerOnChanged = (data, value) => {
    if (value) {
      data.value = value;
      this.setState({});
    }
  };

  renderDateandTimePicker(data) {
    const maxDate = new Date();
    return (
      <div className="input-style">
        <div className="custom-calendar date-calendar">
          <Calendar
            value={data.value}
            onChange={e => this.datePickerOnChanged(data, e.value)}
            maxDate={maxDate}
            className="custom-date-picker"
            dateFormat="yy年mm月dd日"
            readOnlyInput
            selectionMode="single"
            clearButtonClassName="hidden"
          />
        </div>
        <div className="custom-calendar time-picker">
          <Calendar
            value={data.value}
            onChange={e => this.datePickerOnChanged(data, e.value)}
            className="custom-date-picker"
            readOnlyInput
            timeOnly
          />
        </div>
      </div>
    );
  }

  renderFilterContainer = () => {
    const onchangeAction = (data, value) => {
      data.value = value;
      this.setState({});
    };

    const submitSearch = () => {
      this.setState({
        isShowTable: true,
        isLoading: true,
      });
      this.getDataTable();
    };

    const renderCustomInput = (data, index) => {
      return (
        <div className="input-container" key={`input-container-idx-${index}`}>
          <div className="label-text">
            {data.id === 'occurred_at' ? 'ログ終了時間' : data.label}
          </div>
          {data.id === 'occurred_at' ? (
            this.renderDateandTimePicker(data)
          ) : (
            <div className="input-style">
              <InputText
                value={data.value}
                className="w-full"
                onChange={e => onchangeAction(data, e.target.value)}
              />
            </div>
          )}
        </div>
      );
    };

    return (
      <div className="filter-container">
        <div className="left">
          {this.state.filterData
            .slice(0, 5)
            .map((data, index) => renderCustomInput(data, index))}
        </div>
        <div className="right">
          {this.state.filterData
            .slice(6, this.state.filterData.length)
            .map((data, index) => renderCustomInput(data, index + 6))}
          <div
            className="input-container"
            style={{ padding: 0, paddingLeft: 'calc(35% + 0.25rem)' }}
          >
            <Button className="button-style" onClick={e => submitSearch()}>
              検索
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderEvenlogDataTable({ tableData }) {
    const { isLoading } = this.state;
    const paginatorRight = (
      <Button
        type="button"
        icon="pi pi-download"
        text
        iconPos="left"
        severity="info"
        label="CSV ダウンロード"
        onClick={this.exportCSV}
      />
    );
    return (
      <DataTable
        ref={this.dataTableRef}
        dataKey="log_id"
        className="outlook-data-table mt-4"
        value={tableData}
        tableStyle={{ width: '100%' }}
        size="small"
        stripedRows
        rowHover
        scrollable
        scrollHeight="630px"
        showGridlines
        loading={isLoading}
        paginator
        rows={20}
        rowsPerPageOptions={[15, 20, 30, 50, 100]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} - {last} / {totalRecords} 件"
        paginatorLeft={<></>}
        paginatorRight={paginatorRight}
        sortField="occurred_at"
        sortOrder={-1}
        filterDisplay="row"
        filters={this.filters}
        emptyMessage={() => {
          return (
            <div className="flex justify-content-center align-items-center">
              <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
            </div>
          );
        }}
        exportFilename="MerakiEventLog"
      >
        {this.dataColumns.map((col, i) => {
          return (
            <Column
              dataType={col.dataType ?? 'string'}
              key={col.field}
              field={col.field}
              header={col.header}
              sortable
              align={col.align}
              alignHeader={col.align}
              headerClassName={col.headerClassName}
              bodyClassName={col.bodyClassName}
              headerStyle={col.headerStyle}
              bodyStyle={col.bodyStyle}
              filter
              filterMatchMode={'contains'}
            />
          );
        })}
      </DataTable>
    );
  }

  render() {
    const { tableData, isShowTable } = this.state;

    return (
      <div className="meraki-event-log table-container mt-2">
        <Accordion onTabOpen={this.onAccordionTabOpen}>
          <AccordionTab
            header={
              <div className="accordion-tab-header-container">
                <div className="title">Merakiイベントログ</div>
                <div
                  className="calendar-container"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                ></div>
              </div>
            }
          >
            <div className="m-0">
              {this.renderFilterContainer()}
              {isShowTable && this.renderEvenlogDataTable({ tableData })}
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    );
  }
}

MerakiEventlog.propTypes = {};

export default MerakiEventlog;
