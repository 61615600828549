import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import { format } from 'date-fns';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchVerkadaAuditLogs } from './query-request';

@connect(state => ({
  querystring: state.querystring,
}))
class VerkadaEventlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      selectedDate: new Date(),
      filterData: [],
      isShowTable: false,
    };
    this.isAccordionTabInitOpen = false;
    this.dataTableRef = React.createRef();
    this.columnFields = [
      'timestamp',
      'device_name',
      'event_description',
      'event_name',
      'ip_address',
      'user_name',
      'details',
    ];
    this.filters = {
      timestamp: { value: null, matchMode: FilterMatchMode.CONTAINS },
      device_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      event_description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      event_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      ip_address: { value: null, matchMode: FilterMatchMode.CONTAINS },
      user_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      details: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
    this.dataColumns = [
      {
        field: 'timestamp',
        header: COMMON_TEXT.VERKADA_AUDIT_LOGS_TIMESTAMP,
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'device_name',
        header: COMMON_TEXT.VERKADA_AUDIT_LOGS_DEVICE_NAME,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'event_description',
        header: COMMON_TEXT.VERKADA_AUDIT_LOGS_EVENT_DESCRIPTION,
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'event_name',
        header: COMMON_TEXT.VERKADA_AUDIT_LOGS_EVENT_NAME,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'ip_address',
        header: COMMON_TEXT.VERKADA_AUDIT_LOGS_IP_ADDRESS,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'user_name',
        header: COMMON_TEXT.VERKADA_AUDIT_LOGS_USER_NAME,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'details',
        header: COMMON_TEXT.VERKADA_AUDIT_LOGS_DETAILS,
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
        filter: true,
      },
    ];
  }

  componentDidMount() {
    this.mapFilterData();
  }

  componentDidUpdate(prevProps) {}

  mapFilterData = () => {
    const filterData = [
      {
        id: 'timestamp',
        label: COMMON_TEXT.VERKADA_AUDIT_LOGS_TIMESTAMP,
        value: new Date(),
      },
      {
        id: 'device_name',
        label: COMMON_TEXT.VERKADA_AUDIT_LOGS_DEVICE_NAME,
        value: '',
      },
      {
        id: 'event_description',
        label: COMMON_TEXT.VERKADA_AUDIT_LOGS_EVENT_DESCRIPTION,
        value: '',
      },
      {
        id: 'event_name',
        label: COMMON_TEXT.VERKADA_AUDIT_LOGS_EVENT_NAME,
        value: '',
      },
      {
        id: 'ip_address',
        label: COMMON_TEXT.VERKADA_AUDIT_LOGS_IP_ADDRESS,
        value: '',
      },
      {
        id: 'user_name',
        label: COMMON_TEXT.VERKADA_AUDIT_LOGS_USER_NAME,
        value: '',
      },
    ];
    this.setState({ filterData });
  };

  getPayload = () => {
    const { filterData } = this.state;
    const selectedDate = filterData.filter(data => data.id === 'timestamp')[0]
      .value;
    const device_name = filterData.filter(data => data.id === 'device_name')[0]
      .value;
    const event_description = filterData.filter(
      data => data.id === 'event_description'
    )[0].value;
    const event_name = filterData.filter(data => data.id === 'event_name')[0]
      .value;
    const ip_address = filterData.filter(data => data.id === 'ip_address')[0]
      .value;
    const user_name = filterData.filter(data => data.id === 'user_name')[0]
      .value;
    const payload = {
      timestamp: {
        lte: [format(selectedDate, 'yyyy-MM-dd HH:mm:ss')],
      },
      device_name: device_name ? { like: [`%${device_name}%`] } : [],
      event_description: event_description
        ? { like: [`%${event_description}%`] }
        : [],
      event_name: event_name ? { like: [`%${event_name}%`] } : [],
      ip_address: ip_address ? { like: [`%${ip_address}%`] } : [],
      user_name: user_name ? { like: [`%${user_name}%`] } : [],
    };
    return payload;
  };

  getDataTable = async () => {
    const payload = this.getPayload();

    const response = await fetchVerkadaAuditLogs(payload);
    this.setState({
      isLoading: false,
      tableData: response,
    });
  };

  exportCSV = () => {
    this.dataTableRef.current.exportCSV();
  };

  renderMessageError = () => {
    const { tenant } = this.state;
    if (!tenant) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  onAccordionTabOpen = e => {
    if (!this.isAccordionTabInitOpen) {
      this.isAccordionTabInitOpen = true;
    }
  };

  datePickerOnChanged = (data, value) => {
    if (value) {
      data.value = value;
      this.setState({});
    }
  };

  renderDateandTimePicker(data) {
    const maxDate = new Date();
    return (
      <div className="input-style">
        <div className="custom-calendar date-calendar">
          <Calendar
            value={data.value}
            onChange={e => this.datePickerOnChanged(data, e.value)}
            maxDate={maxDate}
            className="custom-date-picker"
            dateFormat="yy年mm月dd日"
            readOnlyInput
            selectionMode="single"
            clearButtonClassName="hidden"
          />
        </div>
        <div className="custom-calendar time-picker">
          <Calendar
            value={data.value}
            onChange={e => this.datePickerOnChanged(data, e.value)}
            className="custom-date-picker custom-time"
            readOnlyInput
            timeOnly
          />
        </div>
      </div>
    );
  }

  renderFilterContainer = () => {
    const onchangeAction = (data, value) => {
      data.value = value;
      this.setState({});
    };

    const submitSearch = () => {
      this.setState({
        isShowTable: true,
        isLoading: true,
      });
      this.getDataTable();
    };

    const renderCustomInput = (data, index) => {
      return (
        <div className="input-container" key={index}>
          <div className="label-text">
            {data.id === 'timestamp' ? 'ログ終了時間' : data.label}
          </div>
          {data.id === 'timestamp' ? (
            this.renderDateandTimePicker(data)
          ) : (
            <div className="input-style">
              <InputText
                value={data.value}
                className="w-full"
                onChange={e => onchangeAction(data, e.target.value)}
              />
            </div>
          )}
        </div>
      );
    };

    return (
      <div className="filter-container">
        <div className="left">
          {this.state.filterData
            .slice(0, 3)
            .map((data, index) => renderCustomInput(data, index))}
        </div>
        <div className="right">
          {this.state.filterData
            .slice(3, this.state.filterData.length)
            .map((data, index) => renderCustomInput(data, index + 3))}
          <div className="button-container">
            <Button className="button-style" onClick={e => submitSearch()}>
              検索
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderEvenlogDataTable({ tableData }) {
    const { isLoading } = this.state;

    const paginatorRight = (
      <Button
        type="button"
        icon="pi pi-download"
        text
        iconPos="left"
        severity="info"
        label="CSV ダウンロード"
        className="csvButtonStyle"
        onClick={this.exportCSV}
      />
    );
    return (
      <DataTable
        ref={this.dataTableRef}
        dataKey="log_id"
        className="outlook-data-table mt-4"
        value={tableData}
        tableStyle={{ width: '100%' }}
        size="small"
        stripedRows
        rowHover
        scrollable
        scrollHeight="600px"
        showGridlines
        loading={isLoading}
        paginator
        rows={20}
        rowsPerPageOptions={[15, 20, 30, 50, 100]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} - {last} / {totalRecords} 件"
        paginatorLeft={<></>}
        paginatorRight={paginatorRight}
        sortField="timestamp"
        sortOrder={-1}
        filterDisplay="row"
        filters={this.filters}
        emptyMessage={() => {
          return (
            <div className="flex justify-content-center align-items-center">
              <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
            </div>
          );
        }}
        exportFilename="VerkadaAuditログ"
      >
        {this.dataColumns.map((col, i) => {
          return (
            <Column
              dataType={col.dataType ?? 'string'}
              key={col.field}
              field={col.field}
              header={col.header}
              sortable
              align={col.align}
              alignHeader={col.align}
              headerClassName={col.headerClassName}
              bodyClassName={col.bodyClassName}
              headerStyle={col.headerStyle}
              bodyStyle={col.bodyStyle}
              filter
              filterMatchMode={'contains'}
            />
          );
        })}
      </DataTable>
    );
  }

  render() {
    const { tableData, isShowTable } = this.state;

    return (
      <div className="verkada-event-log table-container mt-2">
        <Accordion onTabOpen={this.onAccordionTabOpen}>
          <AccordionTab
            header={
              <div className="accordion-tab-header-container">
                <div className="title">Verkada Auditログ</div>
                <div
                  className="calendar-container"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                ></div>
              </div>
            }
          >
            <div className="m-0">
              {this.renderFilterContainer()}
              {isShowTable && this.renderEvenlogDataTable({ tableData })}
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    );
  }
}

VerkadaEventlog.propTypes = {};

export default VerkadaEventlog;
