import { COMMON_TEXT } from '@/helpers/common-text';
import {
  EXECUTION_ID_TIMEOUT,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import { format, isPast } from 'date-fns';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { Form, Formik } from 'formik';
import { cloneDeep, isEqual } from 'lodash';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { getConfigurationUrlWithParam } from 'src/helpers/utility';
import AuthToken from 'src/services/auth-token';
import {
  fetchCalendarOverridesByTenantId,
  fetchTenantById,
} from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class WorkingHourSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      initialFormValues: {
        workingHour: 0,
        workingMinute: 0,
        isMondayHoliday: false,
        isTuesdayHoliday: false,
        isWednesdayHoliday: false,
        isThursdayHoliday: false,
        isFridayHoliday: false,
        isSaturdayHoliday: false,
        isSundayHoliday: false,
        isNationalHoliday: false,
        holidayDateList: [],
        weekdayDateList: [],
      },
      isShowPastHolidayDate: false,
      isShowPastWeekdayDate: false,
      hasHolidayPastDate: false,
      hasWeekdayPastDate: false,
    };
    this.originalFormValues = {};
    this.authToken = new AuthToken();
    this.toastRef = React.createRef();
    this.holidayType = {
      WEEK_DAY: '平日',
      HOLIDAY: '休日',
    };
    this.holidayOptions = [
      { key: 'monday', name: '月曜日', index: 0, keyString: 'isMondayHoliday' },
      {
        key: 'tuesday',
        name: '火曜日',
        index: 1,
        keyString: 'isTuesdayHoliday',
      },
      {
        key: 'wednesday',
        name: '水曜日',
        index: 2,
        keyString: 'isWednesdayHoliday',
      },
      {
        key: 'thursday',
        name: '木曜日',
        index: 3,
        keyString: 'isThursdayHoliday',
      },
      { key: 'friday', name: '金曜日', index: 4, keyString: 'isFridayHoliday' },
      {
        key: 'saturday',
        name: '土曜日',
        index: 5,
        keyString: 'isSaturdayHoliday',
      },
      { key: 'sunday', name: '日曜日', index: 6, keyString: 'isSundayHoliday' },
      {
        key: 'national',
        name: '祝日',
        index: 7,
        keyString: 'isNationalHoliday',
      },
    ];

    // create hour options from 00 to 23
    this.hourOptions = () => {
      const hourOptions = [];
      for (let i = 0; i < 24; i++) {
        hourOptions.push({
          label: i < 10 ? `0${i}` : `${i}`,
          value: i,
        });
      }
      return hourOptions;
    };

    // create minute options from 00 to 59
    this.minuteOptions = () => {
      const minuteOptions = [];
      for (let i = 0; i < 60; i++) {
        minuteOptions.push({
          label: i < 10 ? `0${i}` : `${i}`,
          value: i,
        });
      }
      return minuteOptions;
    };
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getCheckedHolidays = ({ holidays }) => {
    const result = {
      isMondayHoliday: false,
      isTuesdayHoliday: false,
      isWednesdayHoliday: false,
      isThursdayHoliday: false,
      isFridayHoliday: false,
      isSaturdayHoliday: false,
      isSundayHoliday: false,
      isNationalHoliday: false,
    };
    if (holidays && holidays.length > 0) {
      for (const holidayIndex of holidays) {
        const findIndex = this.holidayOptions.findIndex(
          item => item.index === holidayIndex
        );
        if (findIndex > -1) {
          // set first char is upper case
          result[this.holidayOptions[findIndex].keyString] = true;
        }
      }
    }
    return result;
  };

  getIndexHolidays = ({ formValues }) => {
    if (formValues) {
      const result = [];
      for (const holiday of this.holidayOptions) {
        if (formValues[holiday.keyString]) {
          result.push(holiday.index);
        }
      }
      return result;
    }
    return [];
  };

  getData = async () => {
    const { initialFormValues, tenant } = this.state;
    this.setState({ isLoading: true });
    // fetch data here
    // set time out 1 seconds
    const tenantInfoList = await fetchTenantById({ tenantId: tenant });
    if (tenantInfoList && tenantInfoList.length > 0) {
      const tenantInfo = tenantInfoList[0];
      let { business_hour_begin, holidays, tenant_id } = tenantInfo;
      business_hour_begin = business_hour_begin.toString() || '00:00';
      const workingHourMin = business_hour_begin.split(':');
      const workingHour = +workingHourMin[0];
      const workingMinute = +workingHourMin[1];

      // parse holidays as array, if not parsed, set to empty array
      if (holidays) {
        holidays = JSON.parse(holidays);
      } else {
        holidays = [];
      }
      const checkedHoliday = this.getCheckedHolidays({ holidays });

      const calendarOverrides = await fetchCalendarOverridesByTenantId({
        tenantId: tenant_id,
      });
      const weekdayDateList = [];
      const holidayDateList = [];
      if (calendarOverrides && calendarOverrides.length > 0) {
        for (const calendarOverride of calendarOverrides) {
          const { is_holiday } = calendarOverride;
          if (is_holiday === this.holidayType.WEEK_DAY) {
            weekdayDateList.push(calendarOverride.date);
          } else if (is_holiday === this.holidayType.HOLIDAY) {
            holidayDateList.push(calendarOverride.date);
          }
        }
      }

      const formValues = {
        ...initialFormValues,
        workingHour: workingHour,
        workingMinute: workingMinute,
        ...checkedHoliday,
        weekdayDateList: weekdayDateList,
        holidayDateList: holidayDateList,
      };
      this.originalFormValues = cloneDeep(formValues);
      this.setState(
        {
          initialFormValues: formValues,
          isLoading: false,
        },
        () => {
          this.updateShowPastButtonStatus();
        }
      );
    }
  };

  getUpdateDataPayload = ({ formValues }) => {
    let payload = {
      values: [],
    };

    let { workingHour, workingMinute, holidayDateList, weekdayDateList } =
      formValues;
    const object = {
      business_hour_begin: `${workingHour < 10 ? '0' : ''}${workingHour}:${
        workingMinute < 10 ? '0' : ''
      }${workingMinute}`,
      holidays: this.getIndexHolidays({ formValues }),
      holiday_exceptions: holidayDateList,
      weekday_exceptions: weekdayDateList,
    };
    payload.values.push(object);
    return payload;
  };

  updateBusinessHour = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.BUSINESS_HOURS,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await this.updateBusinessHour({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  updateShowPastButtonStatus = () => {
    const { initialFormValues } = this.state;
    const { holidayDateList, weekdayDateList } = initialFormValues;
    // check if there is any date in holidayDateList or weekdayDateList is in the past
    const hasHolidayPastDate = holidayDateList.some(date => {
      return isPast(new Date(date));
    });
    const hasWeekdayPastDate = weekdayDateList.some(date => {
      return isPast(new Date(date));
    });
    this.setState({
      hasHolidayPastDate,
      hasWeekdayPastDate,
    });
  };

  renderSettingContent() {
    const {
      isLoading,
      isUpdating,
      initialFormValues,
      isShowPastHolidayDate,
      isShowPastWeekdayDate,
      hasHolidayPastDate,
      hasWeekdayPastDate,
    } = this.state;

    const handleSubmit = (values, { setSubmitting, errors }) => {
      if (!errors) {
        if (isEqual(values, this.originalFormValues)) {
          return;
        }
        const updatePayLoad = this.getUpdateDataPayload({
          formValues: values,
        });
        this.updateData({ updatePayLoad });
      } else {
      }
    };

    const datePickerOnSelected = ({ e, fieldName, values }) => {
      let { value } = e;
      value = format(value, 'yyyy-MM-dd');
      const { holidayDateList, weekdayDateList } = values;
      if (fieldName === 'holidayDateList') {
        if (!holidayDateList.includes(value)) {
          holidayDateList.push(value);
          this.setState(
            {
              initialFormValues: {
                ...initialFormValues,
                [fieldName]: holidayDateList,
              },
            },
            () => {
              this.updateShowPastButtonStatus();
            }
          );
        }
      } else if (fieldName === 'weekdayDateList') {
        if (!weekdayDateList.includes(value)) {
          weekdayDateList.push(value);
          this.setState({
            initialFormValues: {
              ...initialFormValues,
              [fieldName]: weekdayDateList,
            },
          });
        }
      }
    };

    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={initialFormValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleChange, touched, errors, resetForm }) => (
              <Form>
                <div className="main-row-container">
                  <div className="left-title">
                    <span>1日の開始時間</span>
                  </div>
                  <div className="right-content">
                    <div className="input-text-field-container workinghour-group">
                      <div className="hour-container">
                        <Dropdown
                          id="workingHour"
                          value={values.workingHour}
                          options={this.hourOptions()}
                          onChange={e => handleChange(e)}
                        ></Dropdown>
                      </div>
                      <div className="ml-1 mr-1">
                        <b>:</b>
                      </div>
                      <div className="minute-container">
                        <Dropdown
                          id="workingMinute"
                          value={values.workingMinute}
                          options={this.minuteOptions()}
                          onChange={e => handleChange(e)}
                        ></Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-row-container">
                  <div className="left-title">
                    <span>休日とする日</span>
                  </div>
                  <div className="right-content">
                    <div className="input-text-field-container checkbox-group">
                      {this.holidayOptions.map(item => {
                        const { key, keyString } = item;
                        return (
                          <div key={key} className="checkbox-item">
                            <Checkbox
                              inputId={key}
                              name={keyString}
                              checked={values[keyString]}
                              onChange={handleChange(keyString)}
                            />
                            <label htmlFor={item.key} className="ml-2">
                              {item.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="main-row-container">
                  <div className="left-title">
                    <span>例外</span>
                  </div>
                  <div className="right-content">
                    <div className="row-container">
                      <div className="product-field">
                        <div className="is-header">
                          <span>休日とする日</span>
                        </div>
                        <Calendar
                          className="custom-date-picker"
                          dateFormat="yy年mm月dd日"
                          readOnlyInput
                          showIcon
                          icon="pi pi-calendar"
                          showButtonBar
                          selectionMode="single"
                          clearButtonClassName="hidden"
                          onSelect={e => {
                            datePickerOnSelected({
                              e,
                              fieldName: 'holidayDateList',
                              values,
                            });
                          }}
                        />
                        <div className="daylist-container">
                          {!values.holidayDateList ||
                          values.holidayDateList.length === 0 ? (
                            <>
                              <div className="day-item no-data">
                                {COMMON_TEXT.NO_DATA}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                {hasHolidayPastDate && (
                                  <Button
                                    type="button"
                                    text
                                    label="過去の日付"
                                    severity="info"
                                    className=""
                                    onClick={e =>
                                      this.setState({
                                        isShowPastHolidayDate: true,
                                      })
                                    }
                                  />
                                )}
                              </div>
                              {values.holidayDateList
                                .sort()
                                .map((item, index) => {
                                  if (
                                    !isShowPastHolidayDate &&
                                    isPast(new Date(item))
                                  ) {
                                    return (
                                      <Fragment
                                        key={`empty-div-1-${index}`}
                                      ></Fragment>
                                    );
                                  }
                                  return (
                                    <div
                                      key={`holiday-date-${index}`}
                                      className="day-item"
                                    >
                                      <div className="day-value">{item}</div>
                                      <div>
                                        <Button
                                          type="button"
                                          icon="pi pi-trash"
                                          text
                                          severity="danger"
                                          className="icon-only-delete-button"
                                          onClick={e => {
                                            const { holidayDateList } = values;
                                            holidayDateList.splice(index, 1);
                                            this.setState({
                                              initialFormValues: {
                                                ...initialFormValues,
                                                holidayDateList,
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="key-field">
                        <div className="is-header">
                          <span>平日とする日</span>
                        </div>
                        <Calendar
                          className="custom-date-picker"
                          dateFormat="yy年mm月dd日"
                          readOnlyInput
                          showIcon
                          icon="pi pi-calendar"
                          showButtonBar
                          selectionMode="single"
                          clearButtonClassName="hidden"
                          onSelect={e => {
                            datePickerOnSelected({
                              e,
                              fieldName: 'weekdayDateList',
                              values,
                            });
                          }}
                        />
                        <div className="daylist-container">
                          {!values.weekdayDateList ||
                          values.weekdayDateList.length === 0 ? (
                            <>
                              <div className="day-item no-data">
                                {COMMON_TEXT.NO_DATA}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                {hasWeekdayPastDate && (
                                  <Button
                                    type="button"
                                    text
                                    label="過去の日付"
                                    severity="info"
                                    className=""
                                    onClick={e =>
                                      this.setState({
                                        isShowPastWeekdayDate: true,
                                      })
                                    }
                                  />
                                )}
                              </div>
                              {values.weekdayDateList
                                .sort()
                                .map((item, index) => {
                                  if (
                                    !isShowPastWeekdayDate &&
                                    isPast(new Date(item))
                                  ) {
                                    return (
                                      <Fragment
                                        key={`empty-div-2-${index}`}
                                      ></Fragment>
                                    );
                                  }
                                  return (
                                    <div
                                      key={`holiday-date-${index}`}
                                      className="day-item"
                                    >
                                      <div className="day-value">{item}</div>
                                      <div>
                                        <Button
                                          type="button"
                                          icon="pi pi-trash"
                                          text
                                          severity="danger"
                                          className="icon-only-delete-button"
                                          onClick={e => {
                                            const { weekdayDateList } = values;
                                            weekdayDateList.splice(index, 1);
                                            this.setState({
                                              initialFormValues: {
                                                ...initialFormValues,
                                                weekdayDateList,
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-row-container">
                  <Button
                    severity="info"
                    type="submit"
                    label="保存"
                    className="submit-button"
                    loading={isUpdating}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <div className="config-container custom-config-container workinghour-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">
                {COMMON_TEXT.WORKING_HOUR_SETTING}
              </div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">{this.renderSettingContent()}</div>
          </div>
        </div>
      </>
    );
  }
}

WorkingHourSetting.propTypes = {};

export default WorkingHourSetting;
