import { QUERY_TABLES } from '@/helpers/constants';
import {
  generateRandomDataMeetingRoomOutLookTable,
  isUsingSampleData,
} from 'src/helpers/generate-random-data';
import { executeQuery } from 'src/helpers/request';

const buildRequestQueryBarChart = (floorId, limit, type) => {
  const tableName =
    type === 'weekly'
      ? QUERY_TABLES.MEETING_ROOM_MART_TABLE_RESERVED_WEEKLY
      : QUERY_TABLES.MEETING_ROOM_MART_TABLE_RESERVED_MONTHLY;
  if (!limit) {
    limit = 0;
  }
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        floor_id: ['select'],
        reserved_minutes: ['select'],
        reserved_rate: ['select'],
        room_name: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: limit,
    },
    filter: {
      floor_id: [floorId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      room_name: 'asc',
    },
    table_name: tableName,
  };
};

const buildQueryLineChart = (floorId, deviceIdList, fromDate, toDate) => {
  return {
    data: {
      fields: {
        date: ['select'],
        day_of_week: ['select'],
        device_identifer: ['select'],
        reserved: ['select'],
        reserved_rate: ['select'],
        reserved_and_unused: ['select'],
        occupied_rate: ['select'],
        unused_reservation: ['select'],
        unused_rate: ['select'],
        room_name: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      device_identifer: deviceIdList,
      date: {
        gte: [fromDate],
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      date: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_TABLE,
  };
};

export const fetchDataBarChart = async payload => {
  const { floorId, limit, type } = payload;
  const request = buildRequestQueryBarChart(floorId, limit, type);
  if (isUsingSampleData) {
    return [];
  }
  return executeQuery(request);
};

export const fetchDataLineChart = async payload => {
  const { floorId, deviceIdList, fromDate, toDate } = payload;
  const request = buildQueryLineChart(floorId, deviceIdList, fromDate, toDate);
  if (isUsingSampleData) {
    return generateRandomDataMeetingRoomOutLookTable();
  }
  return executeQuery(request);
};
