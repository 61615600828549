import CustomBarChart from '@/components/CustomComponent/CustomBarChart';
import CustomCSVDownload from '@/components/CustomComponent/CustomCSVDownload/CustomCSVDownload';
import CustomLineChart from '@/components/CustomComponent/CustomLineChart';
import NetworkTitle from '@/components/CustomComponent/NetworkTitle';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CSV_DOWNLOAD_COMPONENT,
  PERFORMANCE_COMPONENT,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import {
  checkListChanges,
  sortObjectOrArrayByDeviceIdList,
  timestampTickList,
} from '@/helpers/utility';
import { compareAsc, format } from 'date-fns';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDataBarChart, fetchDataLineChart } from './query-request';

@connect(state => ({
  querystring: state.querystring,
}))
class NetworkClientCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartData: [],
      lineChartData: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoadingBarChart: false,
      isLoadingLineChart: false,
      csvDownload: false,
    };
    this.deviceInfoOrderedMap = {};
  }

  componentDidMount() {
    const { deviceInfoOrderedList: deviceList } = this.props;
    this.deviceInfoOrderedMap = deviceList.reduce((acc, item) => {
      acc[item.deviceId] = item;
      return acc;
    }, {});
    this.getAllData();
  }

  componentDidUpdate(prevProps) {
    const { deviceInfoOrderedList: deviceList, selectedDate } = this.props;
    const {
      selectedDate: prevSelectedDate,
      deviceInfoOrderedList: prevDeviceList,
    } = prevProps;
    const isDateChanged = selectedDate !== prevSelectedDate;
    const { elementsChanged, orderChanged } = checkListChanges(
      deviceList,
      prevDeviceList,
      'deviceId'
    );
    const deviceIdList = deviceList.map(item => item.deviceId);
    const floor =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    if (elementsChanged || isDateChanged) {
      const { deviceInfoOrderedList: deviceList } = this.props;
      this.deviceInfoOrderedMap = deviceList.reduce((acc, item) => {
        acc[item.deviceId] = item;
        return acc;
      }, {});
      this.setState({ floor }, () => {
        this.getAllData();
      });
    } else if (orderChanged) {
      const { barChartData, lineChartData } = this.state;
      const { sortedBarChartData, sortedLineChartData } =
        this.triggerUpdateAllDataSorting({
          barChartData,
          lineChartData,
          deviceIdList,
        });
      this.setState({
        lineChartData: sortedLineChartData,
        barChartData: sortedBarChartData,
      });
    }
  }

  triggerUpdateAllDataSorting = ({
    barChartData,
    lineChartData,
    deviceIdList,
  }) => {
    const { sortedDataList: sortedBarChartData } =
      sortObjectOrArrayByDeviceIdList({
        deviceIdList,
        dataObject: barChartData,
        sortKey: 'device_identifer',
      });
    const { sortedDataList: sortedLineChartData } =
      sortObjectOrArrayByDeviceIdList({
        deviceIdList,
        dataObject: lineChartData,
        sortKey: 'deviceId',
      });

    return {
      sortedBarChartData,
      sortedLineChartData,
    };
  };

  getAllData = async () => {
    let {
      floor,
      barChartData: currentBarChartData,
      lineChartData: currentLineChartData,
    } = this.state;
    const {
      deviceInfoOrderedList,
      selectedDate,
      addedDeviceIdList,
      isResetDeviceIdList,
    } = this.props;
    const deviceIdList = deviceInfoOrderedList.map(item => item.deviceId);
    if (!deviceIdList || deviceIdList.length === 0) {
      return;
    }
    if (!addedDeviceIdList || addedDeviceIdList.length === 0) {
      return;
    }
    if (isResetDeviceIdList) {
      currentBarChartData = [];
      currentLineChartData = [];
    }
    this.setState({ isLoadingBarChart: true });
    const { barChartData } = await this.getBarChartData({
      floor,
      deviceIdList: addedDeviceIdList,
      selectedDate,
    });
    currentBarChartData = currentBarChartData.concat(barChartData);

    const { sortedDataList: sortedBarChartData } =
      sortObjectOrArrayByDeviceIdList({
        deviceIdList,
        dataObject: currentBarChartData,
        sortKey: 'device_identifer',
      });
    this.setState({
      isLoadingBarChart: false,
      barChartData: sortedBarChartData,
    });

    this.setState({ isLoadingLineChart: true });
    const { lineChartData } = await this.getLineChartData({
      floor,
      deviceIdList: addedDeviceIdList,
      selectedDate,
    });
    currentLineChartData = currentLineChartData.concat(lineChartData);

    const { sortedDataList: sortedLineChartData } =
      sortObjectOrArrayByDeviceIdList({
        deviceIdList,
        dataObject: currentLineChartData,
        sortKey: 'deviceId',
      });

    this.setState({
      isLoadingLineChart: false,
      lineChartData: sortedLineChartData,
    });
  };

  getBarChartData = async ({ floor, deviceIdList, selectedDate }) => {
    let dataBarChartRes = await fetchDataBarChart({
      floorId: floor,
      deviceIdList: deviceIdList,
      selectedDate: format(selectedDate, 'yyyy-MM-dd'),
    });
    let barChartData = [];
    if (dataBarChartRes && dataBarChartRes.length > 0) {
      barChartData = dataBarChartRes.map(item => ({
        ...item,
        fillColor:
          this.deviceInfoOrderedMap[item.device_identifer]['fillColor'],
      }));
    }
    return { barChartData };
  };

  getLineChartData = async ({ floor, deviceIdList, selectedDate }) => {
    const dataLineChartRes = await fetchDataLineChart({
      floorId: floor,
      fromDate: format(selectedDate, 'yyyy-MM-dd 00:00:00'),
      toDate: format(selectedDate, 'yyyy-MM-dd 23:59:59'),
      deviceIdList: deviceIdList,
    });
    const defaultTimestampTickList = timestampTickList();
    const lineChartData = [];
    if (dataLineChartRes && dataLineChartRes.length > 0) {
      for (const key in this.deviceInfoOrderedMap) {
        if (Object.hasOwnProperty.call(this.deviceInfoOrderedMap, key)) {
          const deviceId = this.deviceInfoOrderedMap[key]['deviceId'];
          const timestampSet = new Set(
            dataLineChartRes.map(item =>
              format(new Date(item.timestamp), 'HH:mm')
            )
          );
          let timestampArray = Array.from(timestampSet).sort((a, b) => a > b);
          const maxTimestamp = timestampArray[timestampArray.length - 1];
          const missingTimestampList = defaultTimestampTickList.filter(
            item => !timestampSet.has(item) && item <= maxTimestamp
          );
          const dataByDeviceId = dataLineChartRes.filter(
            item => item.device_identifer === deviceId
          );
          if (dataByDeviceId.length > 0) {
            lineChartData.push({
              name: this.deviceInfoOrderedMap[key]['deviceName'],
              deviceId: deviceId,
              data: dataByDeviceId
                .map(item => ({
                  timestamp: item.timestamp,
                  counts: item.counts,
                }))
                .concat(
                  missingTimestampList.map(item => ({
                    timestamp: format(selectedDate, `yyyy-MM-dd ${item}:00`),
                    counts: null,
                  })) ?? []
                )
                .sort((a, b) =>
                  compareAsc(new Date(a.timestamp), new Date(b.timestamp))
                ),
              fillColor: this.deviceInfoOrderedMap[key]['fillColor'],
            });
          }
        }
      }
    }
    return { lineChartData };
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderBarChart(barChartData) {
    const { selectedDeviceIdLineList } = this.props;
    const { isLoadingBarChart } = this.state;
    const filteredData = barChartData.filter(item =>
      selectedDeviceIdLineList.some(
        deviceId =>
          deviceId?.toLowerCase() === item['device_identifer']?.toLowerCase()
      )
    );

    return (
      <CustomBarChart
        data={filteredData}
        xAxisDataKey={'device_name'}
        yAxisDataKey={'counts'}
        yAxisLabel={'台'}
        isLoading={isLoadingBarChart}
      />
    );
  }

  getThresholdValue = () => {
    const { lineChartThreshold } = this.props;
    let threshold = null;
    let thresholdDirection = null;
    if (lineChartThreshold) {
      const { client_counts } = lineChartThreshold;
      if (client_counts) {
        threshold = client_counts?.['threshold'] ?? null;
        thresholdDirection = client_counts?.['direction'] ?? 'upper';
      }
    }
    return { threshold, thresholdDirection };
  };

  renderLineChart(lineChartData) {
    const { selectedDeviceIdLineList } = this.props;
    const { isLoadingLineChart } = this.state;
    const filteredLineChartData = lineChartData.filter(item =>
      selectedDeviceIdLineList.some(
        deviceId => deviceId?.toLowerCase() === item.deviceId?.toLowerCase()
      )
    );

    const { threshold, thresholdDirection } = this.getThresholdValue();

    return (
      <CustomLineChart
        data={filteredLineChartData}
        xAxisDataKey={'timestamp'}
        yAxisDataKey={'counts'}
        yAxisLabel={'台'}
        threshold={threshold}
        thresholdDirection={thresholdDirection}
        isLoading={isLoadingLineChart}
      />
    );
  }

  renderDownloadButton() {
    const { csvDownload } = this.state;
    return (
      <>
        <Button
          type="button"
          label={csvDownload ? `戻る` : 'CSVダウンロード'}
          className={csvDownload ? 'back-button' : 'submit-button csv-download'}
          onClick={() => {
            this.setState({
              csvDownload: !csvDownload,
            });
          }}
        />
      </>
    );
  }

  render() {
    const { barChartData, lineChartData, csvDownload, floor } = this.state;
    const { selectedDeviceIdLineList, selectedDate } = this.props;

    const { threshold } = this.getThresholdValue();

    return (
      <div className="network-container network-client-count">
        <div className="network-title-container">
          <div className="text-left">
            <NetworkTitle
              threshold={threshold}
              type={PERFORMANCE_COMPONENT.NETWORK_CLIENT_COUNT}
            ></NetworkTitle>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            <div>{this.renderDownloadButton()}</div>
          </div>
        </div>
        {csvDownload ? (
          <CustomCSVDownload
            fromDate={selectedDate}
            toDate={selectedDate}
            type={CSV_DOWNLOAD_COMPONENT.NETWORK_CLIENT_COUNT}
            deviceIdList={[...selectedDeviceIdLineList]}
            floorId={floor}
          ></CustomCSVDownload>
        ) : (
          <div className="network-content">
            {this.renderMessageError() || (
              <>
                <div className="data-container">
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart"></div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderBarChart(barChartData)}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart"></div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart(lineChartData)}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

NetworkClientCount.propTypes = {};

export default NetworkClientCount;
