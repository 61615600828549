import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumberRange } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const BY_HOUR = 'by_hour';
const BY_DAY = 'by_day';
const LOCATION_TYPE_BRANCH = 'branch';
const LOCATION_TYPE_FLOOR = 'floor';
const LOCATION_TYPE_DEPARTMENT = 'department';

const buildEmployeeAttendanceRateForBranch = ({
  locationId,
  fromDate,
  toDate,
  type,
  locationType,
}) => {
  let tableName = '';
  let requestField = {
  };
  let filterField = {};
  if (type === BY_HOUR) {
    tableName = 'EMPLOYEE_ATTENDANCE_RATES_';
    requestField = {
      ...requestField,
      current_counts: ['select'],
      total_counts: ['select'],
      timestamp: ['select'],
      attendance_zone: ['select'],
    };
    filterField = {
      ...filterField,
      tenant_id: {
        eq: [locationId],
      },
      timestamp: {
        gte: [fromDate],
        lte: [toDate],
      },
    };
  } else if (type === BY_DAY) {
    tableName = 'EMPLOYEE_ATTENDANCE_RATES_DAY_';
    requestField = {
      ...requestField,
      date: ['select'],
    };
    filterField = {
      ...filterField,
      tenant_id: {
        eq: [locationId],
      },
      date: {
        gte: [fromDate],
        lte: [toDate],
      },
    };
  }
  if (locationType === LOCATION_TYPE_BRANCH) {
    tableName += 'BRANCH';
    requestField = {
      ...requestField,
      branch_id: ['select'],
      branch_name: ['select'],
      branch_order: ['select'],
    };
  } else if (locationType === LOCATION_TYPE_FLOOR) {
    tableName += 'FLOOR';
    requestField = {
      ...requestField,
      floor_id: ['select'],
      floor_name: ['select'],
      floor_order: ['select'],
    };
  } else if (locationType === LOCATION_TYPE_DEPARTMENT) {
    tableName += 'DEPARTMENT';
    requestField = {
      ...requestField,
      department: ['select'],
    };
  }
  return {
    data: {
      fields: {
        ...requestField,
        attendance_rate: ['select'],
        tenant_id: ['select'],
      },
      limit: null,
      skip: null,
    },
    filter: {
      ...filterField,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES[tableName],
  };
};

const buildRequestGetLatestTimestamp = ({ locationId, locationType }) => {
  const randomTenantId = getRandomNumberRange({ min: 900000, max: 999999 });
  let tableName = 'EMPLOYEE_ATTENDANCE_RATES_';
  if (locationType === LOCATION_TYPE_BRANCH) {
    tableName += 'BRANCH';
  } else if (locationType === LOCATION_TYPE_FLOOR) {
    tableName += 'FLOOR';
  } else if (locationType === LOCATION_TYPE_DEPARTMENT) {
    tableName += 'DEPARTMENT';
  }
  return {
    data: {
      fields: {
        timestamp: ['select_distinct'],
      },
      limit: 5,
      skip: null,
    },
    filter: {
      tenant_id: {
        eq: [locationId, randomTenantId],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES[tableName],
  };
};

export const fetchLatestTimestampListByHourForBranch = async payload => {
  const { tenantId } = payload;
  const request = buildRequestGetLatestTimestamp({
    locationId: tenantId,
    type: BY_HOUR,
    locationType: LOCATION_TYPE_BRANCH,
  });
  return executeQuery(request);
};

export const fetchLatestTimestampListByHourForFloor = async payload => {
  const { tenantId } = payload;
  const request = buildRequestGetLatestTimestamp({
    locationId: tenantId,
    type: BY_HOUR,
    locationType: LOCATION_TYPE_FLOOR,
  });
  return executeQuery(request);
};

export const fetchLatestTimestampListByHourForDepartment = async payload => {
  const { tenantId } = payload;
  const request = buildRequestGetLatestTimestamp({
    locationId: tenantId,
    type: BY_HOUR,
    locationType: LOCATION_TYPE_DEPARTMENT,
  });
  return executeQuery(request);
};

export const fetchEmployeeAttendanceRateByHourForBranch = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildEmployeeAttendanceRateForBranch({
    locationId: tenantId,
    fromDate,
    toDate,
    type: BY_HOUR,
    locationType: LOCATION_TYPE_BRANCH,
  });
  return executeQuery(request);
};

export const fetchEmployeeAttendanceRateByHourForFloor = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildEmployeeAttendanceRateForBranch({
    locationId: tenantId,
    fromDate,
    toDate,
    type: BY_HOUR,
    locationType: LOCATION_TYPE_FLOOR,
  });
  return executeQuery(request);
};

export const fetchEmployeeAttendanceRateByHourForDepartment = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildEmployeeAttendanceRateForBranch({
    locationId: tenantId,
    fromDate,
    toDate,
    type: BY_HOUR,
    locationType: LOCATION_TYPE_DEPARTMENT,
  });
  return executeQuery(request);
};

export const fetchEmployeeAttendanceRateByDayForBranch = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildEmployeeAttendanceRateForBranch({
    locationId: tenantId,
    fromDate,
    toDate,
    type: BY_DAY,
    locationType: LOCATION_TYPE_BRANCH,
  });
  return executeQuery(request);
};

export const fetchEmployeeAttendanceRateByDayForFloor = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildEmployeeAttendanceRateForBranch({
    locationId: tenantId,
    fromDate,
    toDate,
    type: BY_DAY,
    locationType: LOCATION_TYPE_FLOOR,
  });
  return executeQuery(request);
};

export const fetchEmployeeAttendanceRateByDayForDepartment = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildEmployeeAttendanceRateForBranch({
    locationId: tenantId,
    fromDate,
    toDate,
    type: BY_DAY,
    locationType: LOCATION_TYPE_DEPARTMENT,
  });
  return executeQuery(request);
};
