import EmployeeInfo from '@/components/CustomComponent/EmployeeInfo/EmployeeInfo';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ReactImageMarkerNotDraggable from '@/components/CustomComponent/ReactImageMarkerNotDraggable';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  MEMORY_STORE,
  QUERY_STRING_STORE,
  CHAT_TOOL_PRESENCE_COLOR,
} from '@/helpers/constants';
import { EmployeeMarkerRender } from '@/helpers/employee-marker';
import { transformPositionToMarkerCoordinate } from '@/helpers/utility';
import { generateRandomString } from 'src/helpers/utility';
import { Tooltip } from 'primereact/tooltip';
import FpDataStore from '@/services/FpDatastore';
import { cloneDeep, isEmpty } from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Sidebar } from 'primereact/sidebar';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchDataByBranchId,
  fetchEmployeeLocationByEmployeeIdList,
} from './query-request';

const fpDataStore = new FpDataStore();
@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      branch:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0],
      isLoading: false,
      floorMapError: false,
      floorMapUrl: '',

      // new below
      isAllEmployeeChecked: false,
      checkboxMapByDepartment: {},
      checkboxMapByTeam: {}, // key is departmentName###teamName
      checkboxMapByEmployee: {},
      departmentSeachText: '',
      teamSearchText: '',
      employeeSearchText: '',
      departmentActiveIndex: [],
      teamActiveIndex: {},
      departmentList: [],
      filteredEmpLocGroupedByDeptAndTeam: {},
      sidebarVisible: false,
      selectedEmployeeInfo: null,
      isDisplayAllDeviceChecked: false,
    };
    this.employeeLocationList = [];
    this.empLocGroupedByDeptAndTeam = {};
    this.totalEmployeeMap = {};
  }

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    const floorMap = floor?.floor_map ?? '';
    this.setState({ floorMapUrl: floorMap, floorMapError: isEmpty(floorMap) });
  };

  componentDidMount() {
    const { floor, branch, tenant } = this.state;
    if (floor && tenant && branch) {
      this.getFloorMapUrl(floor);
      this.getDataByBranchId();
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const branch = querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevFloor =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const prevBranch =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const isFloorChanged = floor !== prevFloor;
    const isBranchChanged = branch !== prevBranch;
    if (isBranchChanged || isFloorChanged) {
      this.setState({ branch, tenant, floor }, () => {
        this.getDataByBranchId();
        this.getFloorMapUrl(floor);
      });
    }
  }

  groupEmployeeToDepartmentAndTeam = dataArray => {
    let resultMap = {};
    // Group the data
    dataArray.forEach(employeeInfo => {
      let { department, team } = employeeInfo;
      if (!department) {
        department = 'NO_DEPARTMENT'; // 部署なし
      }
      if (!team) {
        team = 'NO_TEAM'; // チームなし
      }

      if (!resultMap[department]) {
        resultMap[department] = {};
      }

      if (!resultMap[department][team]) {
        resultMap[department][team] = [];
      }

      resultMap[department][team].push(employeeInfo);
    });

    const totalEmployeeMap = {};
    // count the number of employees for each department
    Object.keys(resultMap).forEach(department => {
      let employeeCount = 0;
      Object.keys(resultMap[department]).forEach(team => {
        const employeeList = resultMap[department][team];
        const employeeIdSet = new Set(
          employeeList.map(item => item.employee_id)
        );
        employeeCount += employeeIdSet.size;
        if (!totalEmployeeMap[department]) {
          totalEmployeeMap[department] = {
            count: 0,
            [team]: 0,
          };
        }
        totalEmployeeMap[department][team] = employeeIdSet.size;
      });
      totalEmployeeMap[department]['count'] = employeeCount;
    });

    // if resultMap has no key is NO_DEPARTMENT, add NO_DEPARTMENT
    if (!resultMap['NO_DEPARTMENT']) {
      resultMap['NO_DEPARTMENT'] = {};
    }
    return {
      empLocGroupedByDeptAndTeam: resultMap,
      totalEmployeeMap,
    };
  };

  getDataByBranchId = async () => {
    const { tenant, branch, floor: selectedFloorId } = this.state;
    if (!tenant || !branch || !selectedFloorId) {
      return;
    }
    this.setState({ isLoading: true });
    let employeeDataByBranch = await fetchDataByBranchId({
      branchId: branch,
    });
    if (!employeeDataByBranch) {
      employeeDataByBranch = [];
    }
    // filter out employee data by tenant
    employeeDataByBranch = employeeDataByBranch.filter(
      item => +item['tenant_id'] === +tenant
    );

    // add presence status to employee data
    employeeDataByBranch.forEach(employee => {
      const { current_floor_id: emplCurrentFloorId, employee_image } = employee;
      employee['is_present'] = +emplCurrentFloorId === +selectedFloorId;
      employee['is_out_of_office'] = !emplCurrentFloorId;
      employee['employee_image_url'] =
        'assets/images/default_employee_avatar.jpg';
      if (employee_image) {
        employee['employee_image_url'] =
          fpDataStore.getObjectDownloadUrlWithBucket(employee_image);
      }
    });

    // Extract presence employee id list
    const presenceEmployeeIdList = employeeDataByBranch
      .filter(employee => employee['is_present'])
      .map(employee => +employee['employee_id']);

    // Fetch coordinate by employee id list
    let employeeCoordinateList = await fetchEmployeeLocationByEmployeeIdList({
      employeeIdList: presenceEmployeeIdList,
      tenantId: tenant,
      floorId: selectedFloorId,
    });
    if (!employeeCoordinateList) {
      employeeCoordinateList = [];
    }

    employeeDataByBranch.forEach(employee => {
      const { employee_id } = employee;
      const employeeLocations = employeeCoordinateList.filter(
        item => +item['employee_id'] === +employee_id
      );
      employee['location_info'] = employeeLocations ?? [];
    });

    // set employeeLocationList, empLocGroupedByDeptAndTeam, totalEmployeeMap
    this.employeeLocationList = cloneDeep(employeeDataByBranch);
    const { empLocGroupedByDeptAndTeam, totalEmployeeMap } =
      this.groupEmployeeToDepartmentAndTeam(this.employeeLocationList);
    this.empLocGroupedByDeptAndTeam = cloneDeep(empLocGroupedByDeptAndTeam);
    this.totalEmployeeMap = cloneDeep(totalEmployeeMap);

    let newDepartmentList = Object.keys(this.empLocGroupedByDeptAndTeam)
      .filter(item => item !== 'NO_DEPARTMENT')
      .sort((a, b) => {
        return a.localeCompare(b, 'ja');
      });
    newDepartmentList.push('NO_DEPARTMENT');
    const newUpdatedState = {
      data: this.employeeLocationList,
      departmentList: newDepartmentList,
      filteredEmpLocGroupedByDeptAndTeam: cloneDeep(
        this.empLocGroupedByDeptAndTeam
      ),
      isLoading: false,
    };
    this.setState(newUpdatedState);
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
          className="mb-3"
        />
      );
    }
  };

  renderRefreshButton() {
    const { isLoading, floor } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoading}
        disabled={!floor}
        className="refresh-button"
        severity="info"
        size="small"
        onClick={() => this.getDataByBranchId()}
      />
    );
  }

  disableClickEvent = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  renderSearchContainer = () => {
    const {
      isAllEmployeeChecked,
      departmentSeachText,
      teamSearchText,
      employeeSearchText,
    } = this.state;
    return (
      <div className="search-container">
        <Checkbox
          className=""
          style={{ width: '44px', paddingLeft: '1px' }}
          onChange={this.handleCheckboxAllOnChanged}
          checked={isAllEmployeeChecked}
        />
        <div className="input-container">
          <InputText
            className="department-search"
            value={departmentSeachText}
            placeholder="部署"
            onChange={e => this.handleSearchTextChange(e, 'department')}
          />
          <InputText
            className="team-search"
            value={teamSearchText}
            placeholder="チーム"
            onChange={e => this.handleSearchTextChange(e, 'team')}
          />
          <InputText
            className="employee-search"
            value={employeeSearchText}
            placeholder="名前"
            onChange={e => this.handleSearchTextChange(e, 'employee')}
          />
        </div>
      </div>
    );
  };

  renderDepartmentGroup = ({ departmentList }) => {
    if (!departmentList || departmentList.length === 0) {
      return <div className="no-data-container">{COMMON_TEXT.NO_DATA}</div>;
    }
    const {
      departmentActiveIndex,
      checkboxMapByDepartment,
      filteredEmpLocGroupedByDeptAndTeam,
    } = this.state;
    let newDepartmentList = [...departmentList];
    return (
      <>
        <div className="department-container" style={{ width: '100%' }}>
          <Accordion
            multiple
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            activeIndex={departmentActiveIndex}
            onTabChange={e => this.setState({ departmentActiveIndex: e.index })}
          >
            {newDepartmentList.map((departmentName, index) => {
              const teamGroupByDept =
                filteredEmpLocGroupedByDeptAndTeam[departmentName] ?? {};
              // calculate employee count for each department
              const employeeCount =
                this.totalEmployeeMap[departmentName]?.['count'] ?? 0;
              const isCheckboxChecked = checkboxMapByDepartment[departmentName];
              return (
                employeeCount > 0 && (
                  <AccordionTab
                    key={`department-header-${index}`}
                    header={
                      <div className="header-container">
                        <div
                          className="checkbox-container"
                          onClick={this.disableClickEvent}
                        >
                          <Checkbox
                            className="mr-1"
                            onChange={e =>
                              this.handleCheckboxChange(
                                e,
                                'department',
                                departmentName
                              )
                            }
                            onClick={e =>
                              e.nativeEvent?.stopImmediatePropagation()
                            }
                            checked={checkboxMapByDepartment[departmentName]}
                          />
                        </div>
                        <div
                          className={`title-container ${
                            isCheckboxChecked ? 'is-selected' : ''
                          }`}
                        >
                          <i className="pi pi-building mr-2"></i>
                          <span className="vertical-align-middle">{`${
                            departmentName === 'NO_DEPARTMENT'
                              ? '部署なし'
                              : departmentName
                          }`}</span>
                          <span className="count-title">{`${employeeCount}`}</span>
                        </div>
                      </div>
                    }
                  >
                    {this.renderTeamGroup({
                      department: departmentName,
                      empLocGroupedByTeam: teamGroupByDept,
                    })}
                  </AccordionTab>
                )
              );
            })}
          </Accordion>
        </div>
      </>
    );
  };

  renderTeamGroup = ({ department, empLocGroupedByTeam }) => {
    let teamList = Object.keys(empLocGroupedByTeam);
    const newTeamList = teamList
      .filter(item => item !== 'NO_TEAM')
      .sort((a, b) => {
        return a.localeCompare(b, 'ja');
      });
    if (teamList.includes('NO_TEAM')) {
      newTeamList.push('NO_TEAM');
    }
    const { checkboxMapByTeam, teamActiveIndex, teamSearchText } = this.state;
    if (teamSearchText?.length > 0) {
      // set teamActiveIndex for all department
    }
    return (
      <div className="team-container" style={{ width: '100%' }}>
        <Accordion
          multiple
          activeIndex={teamActiveIndex[department]}
          onTabChange={e => {
            const newTeamActiveIndex = { ...teamActiveIndex };
            newTeamActiveIndex[department] = e.index;
            this.setState({ teamActiveIndex: newTeamActiveIndex });
          }}
        >
          {newTeamList.map((entry, index) => {
            const teamCheckboxKey = `${department}###${entry}`;
            const employeeListCount =
              this.totalEmployeeMap[department]?.[entry] ?? 0;
            const isCheckboxChecked = checkboxMapByTeam[teamCheckboxKey];
            return (
              <AccordionTab
                key={`team-header-${index}`}
                header={
                  <div className="header-container">
                    <div
                      className="checkbox-container"
                      onClick={this.disableClickEvent}
                    >
                      <Checkbox
                        className="mr-1"
                        onChange={e =>
                          this.handleCheckboxChange(e, 'team', teamCheckboxKey)
                        }
                        onClick={e => e.nativeEvent?.stopImmediatePropagation()}
                        checked={checkboxMapByTeam[teamCheckboxKey]}
                      />
                    </div>
                    <div
                      className={`title-container ${
                        isCheckboxChecked ? 'is-selected' : ''
                      }`}
                    >
                      <i className="pi pi-users mr-2"></i>
                      <span className="vertical-align-middle">{`${
                        entry === 'NO_TEAM' ? 'チームなし' : entry
                      }`}</span>
                      <span className="count-title">{`${employeeListCount}`}</span>
                    </div>
                  </div>
                }
              >
                {this.renderEmployeeGroup({
                  employeeList: empLocGroupedByTeam[entry],
                })}
              </AccordionTab>
            );
          })}
        </Accordion>
      </div>
    );
  };

  renderEmployeeGroup = ({ employeeList }) => {
    if (!employeeList || employeeList.length === 0) {
      return <></>;
    }
    employeeList = employeeList.sort((a, b) => {
      const aName = a['employee_name'] ?? '';
      const bName = b['employee_name'] ?? '';
      return aName.localeCompare(bName, 'ja');
    });

    // group employeeList by employee_name and employee_image to show only one employee
    const { checkboxMapByEmployee } = this.state;
    const employeeIdentiferMap = {};
    employeeList.forEach(employee => {
      const { employee_id } = employee;
      if (!employeeIdentiferMap[employee_id]) {
        employeeIdentiferMap[employee_id] = [employee];
      } else {
        employeeIdentiferMap[employee_id].push(employee);
      }
    });

    const employeeNameOnClicked = employee => {
      const empl = {
        ...employee,
        device_type: employee?.location_info[0]?.device_type ?? 'pc',
      };
      this.setState({ sidebarVisible: true, selectedEmployeeInfo: empl });
    };

    return (
      <div className="employee-container" style={{ width: '100%' }}>
        {employeeList &&
          employeeList.length > 0 &&
          employeeList.map((employee, index) => {
            const {
              employee_id,
              is_present: employeePresence,
              employee_name,
              employee_image_url,
              chat_tool_presence,
              chat_tool_presence_color,
            } = employee;
            const employeeName = employee_name;
            const targetId = generateRandomString(8);
            return (
              <div
                className={`info-container ${
                  checkboxMapByEmployee[employee_id] ? 'is-selected' : ''
                }`}
                key={`employee-info-index-${index}-${employee_id}`}
              >
                <div className="checkbox-container">
                  <Checkbox
                    className="mr-1"
                    onChange={e =>
                      this.handleCheckboxChange(e, 'employee', employee_id)
                    }
                    onClick={e => e.nativeEvent?.stopImmediatePropagation()}
                    checked={checkboxMapByEmployee[employee_id]}
                  />
                </div>
                <div className="avatar-container">
                  <img src={employee_image_url} alt="employee"></img>
                </div>
                <div
                  className="name-container"
                  onClick={() => employeeNameOnClicked(employee)}
                >
                  {employeeName}
                </div>
                <div className="icon-container">
                  <div className="presence-icon">
                    {employeePresence === null ? (
                      <span>{`該当なし`}</span>
                    ) : employeePresence === true ? (
                      <span className="presence">{`在室`}</span>
                    ) : (
                      <span className="not-presence">{`不在`}</span>
                    )}
                  </div>
                  {chat_tool_presence != null &&
                  chat_tool_presence_color != null ? (
                    <>
                      <div
                        className={`presence-status-color tooltip-target-${targetId}`}
                        style={{
                          backgroundColor:
                            CHAT_TOOL_PRESENCE_COLOR[
                              chat_tool_presence_color.toUpperCase()
                            ],
                        }}
                      ></div>
                      <Tooltip
                        className="presence-status-tooltip"
                        target={`.tooltip-target-${targetId}`}
                        autoHide={false}
                        baseZIndex={1000}
                        position="top"
                        event="hover"
                        style={{ maxWidth: '250px' }}
                      >
                        <div className="tooltip-content">
                          <div className="tooltip-content-item">
                            <div>{chat_tool_presence}</div>
                          </div>
                        </div>
                      </Tooltip>
                    </>
                  ) : (
                    <div className="presence-status-color"></div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  handleCheckboxAllOnChanged = e => {
    const { isAllEmployeeChecked } = this.state;
    // find all department
    const departmentList = Object.keys(this.empLocGroupedByDeptAndTeam);
    // find all team
    const teamList = [];
    departmentList.forEach(departmentName => {
      const teamListByDepartment = Object.keys(
        this.empLocGroupedByDeptAndTeam[departmentName]
      );
      for (let i = 0; i < teamListByDepartment.length; i++) {
        teamListByDepartment[i] =
          departmentName + '###' + teamListByDepartment[i];
      }
      teamList.push(...teamListByDepartment);
    });
    // find all employee
    const employeeIdentiferList = this.employeeLocationList.map(
      employee => employee['employee_id']
    );
    // set all employee check box is checked
    const newCheckboxMapByEmployee = {};
    employeeIdentiferList.forEach(employeeCustomId => {
      newCheckboxMapByEmployee[employeeCustomId] = e.checked;
    });
    // set all team check box is checked
    const newCheckboxMapByTeam = {};
    teamList.forEach(teamNameWithDepartment => {
      newCheckboxMapByTeam[teamNameWithDepartment] = e.checked;
    });

    // set all department check box is checked
    const newCheckboxMapByDepartment = {};
    departmentList.forEach(item => {
      newCheckboxMapByDepartment[item] = e.checked;
    });
    const updatedState = {
      isAllEmployeeChecked: !isAllEmployeeChecked,
      checkboxMapByDepartment: newCheckboxMapByDepartment,
      checkboxMapByTeam: newCheckboxMapByTeam,
      checkboxMapByEmployee: newCheckboxMapByEmployee,
    };
    this.setState(updatedState);
  };

  handleCheckboxChange = (e, type, data) => {
    // if type is department, data is department name
    // if type is team, data is department name and team name separated by ###
    // if type is employee, data is employee_id
    const {
      checkboxMapByDepartment,
      checkboxMapByTeam,
      checkboxMapByEmployee,
    } = this.state;
    const findEmployeeByDepartment = ({ department }) => {
      return this.employeeLocationList.filter(
        item => item.department === department
      );
    };

    const findEmployeeByDepartmentAndTeam = ({ department, team }) => {
      return this.employeeLocationList.filter(
        item => item.department === department && item.team === team
      );
    };

    let updatedState = {
      checkboxMapByDepartment: {},
      checkboxMapByTeam: {},
      checkboxMapByEmployee: {},
    };

    if (type === 'department') {
      // set checkbox of team same department is checked
      const departmentName = data;
      const employeeList = findEmployeeByDepartment({
        department: departmentName,
      });
      const teamList = employeeList.map(employee => employee.team);
      const newCheckboxMapByTeam = { ...checkboxMapByTeam };
      teamList.forEach(teamName => {
        const key = `${departmentName}###${teamName}`;
        newCheckboxMapByTeam[key] = e.checked;
      });

      // set checkbox of employee same department is checked
      const employeeIdentiferList = employeeList.map(item => item.employee_id);
      const newCheckboxMapByEmployee = { ...checkboxMapByEmployee };
      employeeIdentiferList.forEach(item => {
        newCheckboxMapByEmployee[item] = e.checked;
      });

      updatedState = {
        ...updatedState,
        checkboxMapByDepartment: {
          ...checkboxMapByDepartment,
          [departmentName]: e.checked,
        },
        checkboxMapByTeam: newCheckboxMapByTeam,
        checkboxMapByEmployee: newCheckboxMapByEmployee,
      };
    } else if (type === 'team') {
      // set checkbox of employee same department is checked
      const department = data.split('###')[0];
      const team = data.split('###')[1];
      const employeeListByTeam = findEmployeeByDepartmentAndTeam({
        department,
        team,
      });
      const employeeIdList = employeeListByTeam.map(item => item.employee_id);
      const newCheckboxMapByEmployee = { ...checkboxMapByEmployee };
      employeeIdList.forEach(item => {
        newCheckboxMapByEmployee[item] = e.checked;
      });
      const newCheckboxMapByTeam = { ...checkboxMapByTeam };
      const key = `${department}###${team}`;
      newCheckboxMapByTeam[key] = e.checked;

      updatedState = {
        ...updatedState,
        checkboxMapByEmployee: newCheckboxMapByEmployee,
        checkboxMapByTeam: newCheckboxMapByTeam,
      };

      if (!e.checked) {
        // check if no team is checked, then uncheck department
        const employeeListByDepartment = findEmployeeByDepartment({
          department,
        });
        const teamWithDepartmentNameList = employeeListByDepartment.map(
          item => `${item.department}###${item.team}`
        );
        const isAllTeamUncheck = teamWithDepartmentNameList.every(
          teamWithDepartmentName =>
            !newCheckboxMapByTeam[teamWithDepartmentName]
        );
        const newCheckboxMapByDepartment = { ...checkboxMapByDepartment };
        newCheckboxMapByDepartment[department] = !isAllTeamUncheck;
        updatedState = {
          ...updatedState,
          checkboxMapByDepartment: newCheckboxMapByDepartment,
        };
      } else {
        // set checkbox of department that employee belong to is checked
        const newCheckboxMapByDepartment = { ...checkboxMapByDepartment };
        newCheckboxMapByDepartment[department] = e.checked;
        updatedState = {
          ...updatedState,
          checkboxMapByDepartment: newCheckboxMapByDepartment,
        };
      }
    } else if (type === 'employee') {
      const newCheckboxMapByEmployee = { ...checkboxMapByEmployee };
      newCheckboxMapByEmployee[data] = e.checked;
      const employee = this.employeeLocationList.find(
        item => item.employee_id === data
      );
      const department = employee.department;
      const team = employee.team;
      const teamCheckBoxKey = `${department}###${team}`;

      updatedState = {
        ...updatedState,
        checkboxMapByEmployee: newCheckboxMapByEmployee,
      };
      if (e.checked) {
        // set checkbox of team that employee belong to is checked
        const newCheckboxMapByTeam = { ...checkboxMapByTeam };
        newCheckboxMapByTeam[teamCheckBoxKey] = e.checked;
        // set checkbox of department that employee belong to is checked
        const newCheckboxMapByDepartment = { ...checkboxMapByDepartment };
        newCheckboxMapByDepartment[department] = e.checked;

        updatedState = {
          ...updatedState,
          checkboxMapByDepartment: newCheckboxMapByDepartment,
          checkboxMapByTeam: newCheckboxMapByTeam,
        };
      } else {
        const employeeListByTeam = findEmployeeByDepartmentAndTeam({
          department,
          team,
        });
        const employeeIdList = employeeListByTeam.map(item => item.employee_id);
        const isAllEmployeeUnchecked = employeeIdList.every(
          item => !newCheckboxMapByEmployee[item]
        );
        if (isAllEmployeeUnchecked) {
          const newCheckboxMapByTeam = { ...checkboxMapByTeam };
          newCheckboxMapByTeam[teamCheckBoxKey] = false;
          this.setState({
            checkboxMapByTeam: newCheckboxMapByTeam,
          });
        }
        const newCheckboxMapByTeam = { ...checkboxMapByTeam };
        newCheckboxMapByTeam[teamCheckBoxKey] = !isAllEmployeeUnchecked;

        // get team by department
        const employeeListByDepartment = findEmployeeByDepartment({
          department,
        });
        const teamWithDepartmentNameList = employeeListByDepartment.map(
          item => `${item.department}###${item.team}`
        );
        const isAllTeamUnchecked = teamWithDepartmentNameList.every(
          teamWithDepartmentName =>
            !newCheckboxMapByTeam[teamWithDepartmentName]
        );
        const newCheckboxMapByDepartment = { ...checkboxMapByDepartment };
        newCheckboxMapByDepartment[department] = !isAllTeamUnchecked;

        updatedState = {
          ...updatedState,
          checkboxMapByDepartment: newCheckboxMapByDepartment,
          checkboxMapByTeam: newCheckboxMapByTeam,
        };
      }
    }

    const departmentList = Object.keys(this.empLocGroupedByDeptAndTeam);
    // check if all emloyee is checked
    const isAllEmployeeChecked = departmentList.every(departmentName => {
      const teamMap = this.empLocGroupedByDeptAndTeam[departmentName];
      const teamList = Object.keys(teamMap);
      return teamList.every(teamName => {
        const employeeList =
          this.empLocGroupedByDeptAndTeam[departmentName][teamName];
        const empIdList = employeeList.map(item => item.employee_id);
        return empIdList.every(
          entry => updatedState.checkboxMapByEmployee[entry]
        );
      });
    });
    updatedState = {
      ...updatedState,
      isAllEmployeeChecked: isAllEmployeeChecked,
    };

    this.setState(updatedState);
  };

  handleSearchTextChange = (e, type) => {
    const searchText = e.target.value;
    let {
      departmentSeachText: currentSepartmentSeachText,
      teamSearchText: currentTeamSearchText,
      employeeSearchText: currentEmployeeSearchText,
    } = this.state;

    if (type === 'department') {
      currentSepartmentSeachText = searchText;
    } else if (type === 'team') {
      currentTeamSearchText = searchText;
    } else if (type === 'employee') {
      currentEmployeeSearchText = searchText;
    }

    // filter employee by search text
    const filteredEmpLocGroupedByDeptAndTeam = cloneDeep(
      this.empLocGroupedByDeptAndTeam
    );
    if (currentSepartmentSeachText.length > 0) {
      // filter filteredEmpLocGroupedByDeptAndTeam by departmentSeachText
      let deparmentList = Object.keys(filteredEmpLocGroupedByDeptAndTeam);
      deparmentList.forEach(departmentName => {
        if (
          !departmentName
            .toLocaleLowerCase()
            .includes(currentSepartmentSeachText.toLocaleLowerCase())
        ) {
          delete filteredEmpLocGroupedByDeptAndTeam[departmentName];
        }
      });
    }
    if (currentTeamSearchText.length > 0) {
      let deparmentList = Object.keys(filteredEmpLocGroupedByDeptAndTeam);
      deparmentList.forEach(departmentName => {
        const teamMap = filteredEmpLocGroupedByDeptAndTeam[departmentName];
        const teamList = Object.keys(teamMap);
        let isTeamHasMatchedEmployee = false;
        teamList.forEach(teamName => {
          if (
            !teamName
              .toLocaleLowerCase()
              .includes(currentTeamSearchText.toLocaleLowerCase())
          ) {
            delete filteredEmpLocGroupedByDeptAndTeam[departmentName][teamName];
          } else {
            isTeamHasMatchedEmployee = true;
          }
        });
        if (!isTeamHasMatchedEmployee) {
          delete filteredEmpLocGroupedByDeptAndTeam[departmentName];
        }
      });
    }
    if (currentEmployeeSearchText.length > 0) {
      let deparmentList = Object.keys(filteredEmpLocGroupedByDeptAndTeam);
      deparmentList.forEach(departmentName => {
        const teamMap = filteredEmpLocGroupedByDeptAndTeam[departmentName];
        const teamList = Object.keys(teamMap);
        let isTeamHasMatchedEmployee = false;
        teamList.forEach(teamName => {
          const employeeList =
            filteredEmpLocGroupedByDeptAndTeam[departmentName][teamName];
          const filteredEmployeeList = employeeList.filter(item =>
            item.employee_name
              .toLocaleLowerCase()
              .includes(currentEmployeeSearchText.toLocaleLowerCase())
          );
          if (filteredEmployeeList.length === 0) {
            delete filteredEmpLocGroupedByDeptAndTeam[departmentName][teamName];
          } else {
            filteredEmpLocGroupedByDeptAndTeam[departmentName][teamName] =
              filteredEmployeeList;
            isTeamHasMatchedEmployee = true;
          }
        });
        if (!isTeamHasMatchedEmployee) {
          delete filteredEmpLocGroupedByDeptAndTeam[departmentName];
        }
      });
    }

    let newDepartmentList = Object.keys(filteredEmpLocGroupedByDeptAndTeam)
      .filter(item => item !== 'NO_DEPARTMENT')
      .sort((a, b) => {
        return a.localeCompare(b, 'ja');
      });

    if (type === 'department') {
      this.setState({
        departmentSeachText: searchText,
        departmentList: newDepartmentList,
        filteredEmpLocGroupedByDeptAndTeam,
      });
    } else if (type === 'team') {
      this.setState({
        teamSearchText: searchText,
        departmentList: newDepartmentList,
        filteredEmpLocGroupedByDeptAndTeam,
      });
    } else if (type === 'employee') {
      this.setState({
        employeeSearchText: searchText,
        departmentList: newDepartmentList,
        filteredEmpLocGroupedByDeptAndTeam,
      });
    }
  };

  getFloorMapDataMarkers = ({
    floorMapData,
    selectedEmployeeSet,
    isDisplayAllDeviceChecked,
  }) => {
    const cloneFloorMapDataList = floorMapData.map(item => {
      return { ...item };
    });

    let floorMapDataMarkers = cloneFloorMapDataList.filter(item => {
      const { employee_id, location_info } = item;
      if (!employee_id || !location_info || location_info.length === 0) {
        return false;
      }
      if (!selectedEmployeeSet.has(+employee_id)) {
        return false;
      }
      return true;
    });

    floorMapDataMarkers = floorMapDataMarkers.reduce((array, item) => {
      const { location_info } = item;
      // location_info is an array of location info
      // flaten location_info to array of marker
      if (location_info && location_info.length > 0) {
        if (isDisplayAllDeviceChecked) {
          location_info.forEach(info => {
            const { position_x, position_y } = info;
            const { top, left } = transformPositionToMarkerCoordinate({
              x: position_x,
              y: position_y,
            });
            array.push({
              ...item,
              ...info,
              top,
              left,
            });
          });
        } else {
          // priority display mobile marker first
          let pcMarker = null;
          let mobileMarker = null;
          location_info.forEach(info => {
            if (info.device_type === 'pc') {
              pcMarker = info;
            } else if (info.device_type === 'phone') {
              mobileMarker = info;
            }
          });
          let displayMarker = null;
          if (mobileMarker) {
            displayMarker = mobileMarker;
          } else if (pcMarker) {
            displayMarker = pcMarker;
          }
          const { position_x, position_y } = displayMarker;
          if (position_x && position_y) {
            const { top, left } = transformPositionToMarkerCoordinate({
              x: position_x,
              y: position_y,
            });
            array.push({
              ...item,
              ...displayMarker,
              top,
              left,
            });
          }
        }
      }
      return array;
    }, []);

    for (const dataMarker of floorMapDataMarkers) {
      delete dataMarker.location_info;
    }
    return floorMapDataMarkers;
  };

  render() {
    const {
      data: floorMapData,
      isLoading,
      floorMapError,
      floorMapUrl,
      checkboxMapByEmployee,
      departmentList,
      sidebarVisible,
      selectedEmployeeInfo,
      isDisplayAllDeviceChecked,
    } = this.state;
    const selectedEmployeeSet = new Set();
    Object.keys(checkboxMapByEmployee).forEach(key => {
      if (checkboxMapByEmployee[+key]) {
        selectedEmployeeSet.add(+key);
      }
    });

    const floorMapDataMarkers = this.getFloorMapDataMarkers({
      floorMapData,
      selectedEmployeeSet,
      isDisplayAllDeviceChecked,
    });

    const triggerEmployeeDetailSidebar = marker => {
      this.setState({
        sidebarVisible: true,
        selectedEmployeeInfo: { ...marker },
      });
    };

    return (
      <div className="config-container custom-config-container employee-locations">
        <Sidebar
          visible={sidebarVisible}
          onHide={() =>
            this.setState({ sidebarVisible: false, selectedEmployeeInfo: null })
          }
          closeIcon="pi pi-chevron-left"
          position="right"
          closeOnEscape={true}
          dismissable={true}
        >
          <EmployeeInfo
            employeeId={selectedEmployeeInfo?.['employee_id']}
            deviceType={selectedEmployeeInfo?.['device_type']}
          ></EmployeeInfo>
        </Sidebar>
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">{COMMON_TEXT.EMPLOYEE_LOCATIONS}</div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            {this.renderRefreshButton()}
          </div>
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <div className="map-container">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <div className="image-container">
                      <div className="switch-all-devices-container">
                        <InputSwitch
                          checked={isDisplayAllDeviceChecked}
                          onChange={e =>
                            this.setState({
                              isDisplayAllDeviceChecked: e.target.value,
                            })
                          }
                        />
                        <label>すべてのデバイスを表示する</label>
                      </div>
                      <ReactImageMarkerNotDraggable
                        triggerEmployeeDetailSidebar={
                          triggerEmployeeDetailSidebar
                        }
                        extraClass="custom-image-marker"
                        bufferLeft={0}
                        bufferTop={0}
                        src={floorMapUrl}
                        markerComponent={EmployeeMarkerRender}
                        markers={floorMapError ? [] : floorMapDataMarkers || []}
                        alt=""
                      />
                      <>
                        {floorMapError && (
                          <Message
                            severity="error"
                            text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                          />
                        )}
                      </>
                    </div>
                    <div className="accordion-container">
                      {this.renderSearchContainer()}
                      {this.renderDepartmentGroup({
                        departmentList,
                      })}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

EmployeeLocations.propTypes = {};

export default EmployeeLocations;
