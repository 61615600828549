import EmployeeInfo from '@/components/CustomComponent/EmployeeInfo/EmployeeInfo';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ImageMarker from '@/components/CustomComponent/ReactImageMarker';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  MEMORY_STORE,
  QUERY_STRING_STORE,
  TENANT_SCHEDULE_TOOL_TYPE,
} from '@/helpers/constants';
import { MeetingRoomUsageMarkerRender } from '@/helpers/meeting-room-usage-marker';
import {
  generateRandomString,
  getCurrentFullDateFormattedInTimeZone,
  transformPositionToMarkerCoordinate,
} from '@/helpers/utility';
import { format, isEqual, isWithinInterval } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import { Sidebar } from 'primereact/sidebar';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  fetchData,
  fetchDataOutlookStartAfterStartAndEndAfterEnd,
  fetchDataOutlookStartAfterStartAndEndBeforerEnd,
  fetchDataOutlookStartBeforeStartAndEndAfterEnd,
  fetchDataOutlookStartBeforeStartAndEndAfterStart,
  fetchRoomName,
  fetchTenantById,
} from './query-request';

const ROOM_AVAILABILITY_STATUS = {
  VACANT: 1,
  OCCUPIED: 0,
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class MeetingRoomUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTable: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      tenantId:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      tenantInfo: {},
      isLoading: false,
      floorMapUrl: '',
      floorMapError: false,
      sidebarVisible: false,
      selectedEmployeeId: null,
      isResvSidebarVisible: false,
      selectedRoomData: null,
      timeList: [],
      isLoadingMeetingRoomOutlook: false,
      meetingRoomOutlookData: [],
    };
    this.TEAM_MEETING_URL_BASE =
      'https://teams.microsoft.com/l/meeting/new?subject=ミーティング';
  }

  getTimeList() {
    let timeList = [];
    for (let i = 9; i < 18; i++) {
      const currentHour = i < 10 ? `0${i}` : `${i}`;
      const nextHour = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
      timeList.push({
        label: `${currentHour}:00`,
        fromValue: `${currentHour}:00`,
        toValue: `${currentHour}:30`,
      });
      timeList.push({
        label: `${currentHour}:30`,
        fromValue: `${currentHour}:30`,
        toValue: `${nextHour}:00`,
      });
    }
    timeList.push({
      label: `18:00`,
      fromValue: `18:00`,
      toValue: `18:30`,
    });
    return timeList;
  }

  validateFloorMapUrl(url, successCallback, errorCallback) {
    var img = new Image();
    img.onload = function () {
      successCallback(url);
    };
    img.onerror = function () {
      errorCallback(url);
    };
    img.src = url;
  }

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    this.validateFloorMapUrl(
      floor?.floor_map,
      () => {
        this.setState({ floorMapError: false });
      },
      () => {
        this.setState({ floorMapError: true });
      }
    );
    return floor ? floor.floor_map : '';
  };

  componentDidMount() {
    const { isSignageMode, signageModeData } = this.props;
    if (isSignageMode) {
      const { data, floor } = signageModeData;
      const { updatedFloorMapData, dataFloorMap } = data;
      this.setState({
        data: updatedFloorMapData,
        dataTable: dataFloorMap,
        floor,
        floorMapUrl: this.getFloorMapUrl(floor),
        timeList: this.getTimeList(),
      });
    } else {
      const { querystring } = this.props;
      const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      const tenantId = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
      if (floor && tenantId) {
        this.setState(
          {
            floor,
            tenantId,
            floorMapUrl: this.getFloorMapUrl(floor),
            timeList: this.getTimeList(),
          },
          () => {
            this.fetchDataByFloorId();
            this.getTenantInfo();
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring, isSignageMode, signageModeData } = this.props;
    const {
      querystring: prevQuerystring,
      signageModeData: prevSignageModeData,
    } = prevProps;
    if (isSignageMode) {
      const { data, floor, timestamp } = signageModeData;
      const { timestamp: prevTimestamp } = prevSignageModeData;
      const { updatedFloorMapData, dataFloorMap } = data;
      const isSignageModeDataChanged = timestamp !== prevTimestamp;
      if (isSignageModeDataChanged) {
        this.setState(
          {
            data: updatedFloorMapData,
            dataTable: dataFloorMap,
            floor,
            floorMapUrl: this.getFloorMapUrl(floor),
            timeList: this.getTimeList(),
          },
          () => {}
        );
      }
    } else {
      const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      const prevFloor =
        prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      const tenantId = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
      const prevTenantId =
        prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
      const isFloorChanged = floor !== prevFloor;
      const isTenantChanged = tenantId !== prevTenantId;
      if (isFloorChanged || isTenantChanged) {
        this.setState(
          { floor, tenantId, floorMapUrl: this.getFloorMapUrl(floor) },
          () => {
            this.fetchDataByFloorId();
            this.getTenantInfo();
          }
        );
      }
    }
  }

  getTenantInfo = async () => {
    const { tenantId } = this.state;
    let tenantInfo = await fetchTenantById({ tenantId });
    tenantInfo = tenantInfo?.[0] ?? {};
    this.setState({ tenantInfo });
  };

  fetchDataByFloorId = async () => {
    const { floor } = this.state;
    this.setState({ isLoading: true });
    let dataFloorMap = await fetchData({
      floorId: floor,
    });
    if (!dataFloorMap) {
      dataFloorMap = [];
    }

    dataFloorMap = dataFloorMap
      .sort((a, b) => {
        if (a?.device_name < b?.device_name) {
          return -1;
        }
        if (a?.device_name > b?.device_name) {
          return 1;
        }
        return 0;
      })
      .map((item, index) => ({
        ...item,
        timestamp: format(new Date(item.timestamp), 'MM月dd日 - HH:mm'),
      }));

    const updatedFloorMapData = dataFloorMap.map(item => ({
      position: {
        x: parseFloat(item?.position_x),
        y: parseFloat(item?.position_y),
      },
      data: item ?? null,
    }));

    this.setState({
      data: updatedFloorMapData,
      dataTable: dataFloorMap,
      isLoading: false,
    });
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderRefreshButton() {
    const { isLoading, floor } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoading}
        disabled={!floor}
        className="refresh-button"
        severity="info"
        size="small"
        onClick={() => this.fetchDataByFloorId()}
      />
    );
  }

  getAllMeetingRoomOutlookDataByCase = async ({
    floorId,
    deviceId,
    startDateTime,
    endDateTime,
  }) => {
    // case 1: fetch data with start is before startDateTime and end is after startDateTime and is before endDateTime
    const res1 = await fetchDataOutlookStartBeforeStartAndEndAfterStart({
      floorId,
      startDateTime,
      endDateTime,
      deviceId,
    });
    // case 2: fetch data with start is before startDateTime and end is after endDateTime
    const res2 = await fetchDataOutlookStartBeforeStartAndEndAfterEnd({
      floorId,
      startDateTime,
      endDateTime,
      deviceId,
    });
    // case 3: fetch data with start is after startDateTime and end is before endDateTime
    const res3 = await fetchDataOutlookStartAfterStartAndEndBeforerEnd({
      floorId,
      startDateTime,
      endDateTime,
      deviceId,
    });
    // case 4: fetch data with start is after startDateTime and end is after endDateTime
    const res4 = await fetchDataOutlookStartAfterStartAndEndAfterEnd({
      floorId,
      startDateTime,
      endDateTime,
      deviceId,
    });
    return [...res1, ...res2, ...res3, ...res4];
  };

  getMeetingRoomOutlookData = async ({ deviceId }) => {
    const { floor } = this.state;
    const currentDate = new Date();
    const startDateTime = format(currentDate, 'yyyy-MM-dd 00:00:00');
    const endDateTime = format(currentDate, 'yyyy-MM-dd 23:59:59');
    let dataOutlook = await this.getAllMeetingRoomOutlookDataByCase({
      floorId: floor,
      deviceId,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
    });
    const roomNameData = await fetchRoomName({ floor, deviceId });
    let roomName = roomNameData?.[0]?.room_name ?? '-';
    dataOutlook.forEach(item => {
      item['device_position_room_name'] = roomName;
    });
    if (!dataOutlook) {
      return [];
    }
    return dataOutlook;
  };

  meetingRoomSidebarOnShow = async () => {
    const { selectedRoomData } = this.state;
    if (!selectedRoomData) {
      return;
    }
    const { device_identifer } = selectedRoomData;
    this.setState({ isLoadingMeetingRoomOutlook: true });
    const dataOutlook = await this.getMeetingRoomOutlookData({
      deviceId: device_identifer,
    });
    this.setState({
      isLoadingMeetingRoomOutlook: false,
      meetingRoomOutlookData: dataOutlook,
    });
  };

  markerOnClicked = ({ data }) => {
    this.openMeetingRoomReservationSidebar({ data });
  };

  openMeetingRoomReservationSidebar = ({ data }) => {
    const { tenantInfo } = this.state;
    const { schedule_tool } = tenantInfo;
    if (schedule_tool === TENANT_SCHEDULE_TOOL_TYPE.MICROSOFT) {
      this.setState({
        isResvSidebarVisible: true,
        selectedRoomData: data,
        isLoadingMeetingRoomOutlook: true,
      });
    }
  };

  renderDataTableView() {
    const { dataTable } = this.state;
    const roomStatusBody = item => {
      return (
        <div
          className={`room-status ${
            item['room_availability'] === ROOM_AVAILABILITY_STATUS.VACANT
              ? 'vacant'
              : 'occupied'
          }`}
          onClick={() => {
            roomNameBodyOnClicked({ data: item });
          }}
        ></div>
      );
    };

    const organizerOnClicked = ({ employeeId }) => {
      if (!employeeId) {
        return;
      }
      this.setState({ sidebarVisible: true, selectedEmployeeId: employeeId });
    };

    const roomNameBodyOnClicked = ({ data }) => {
      this.openMeetingRoomReservationSidebar({ data });
    };

    const organizerBody = item => {
      return (
        <Button
          className="organizer-button"
          text
          label={item.organizer ?? '-'}
          severity="secondary"
          aria-label="organizer-button"
          size="small"
          onClick={() => organizerOnClicked({ employeeId: item.employee_id })}
        />
      );
    };

    const roomNameBody = item => {
      return (
        <Button
          className="room-name-button"
          text
          label={item.device_name ?? '-'}
          severity="info"
          aria-label="room-name-button"
          size="small"
          onClick={() => roomNameBodyOnClicked({ data: item })}
        />
      );
    };

    return (
      <DataTable
        value={dataTable}
        showGridlines
        size="small"
        scrollable
        scrollHeight={`calc(100vh - 240px)`}
      >
        <Column
          field="room_availability"
          headerClassName="flex justify-content-center"
          header="状態"
          body={roomStatusBody}
          style={{
            minWidth: '80px',
            maxWidth: '80px',
            textAlign: 'center',
            fontSize: '0.875rem',
          }}
          frozen
        ></Column>
        <Column
          field="device_name"
          header="部屋名"
          style={{ minWidth: '160px', textAlign: 'left', fontSize: '0.875rem' }}
          frozen
          body={roomNameBody}
        ></Column>
        <Column
          field="current_reservation"
          header="現在の予約"
          style={{ minWidth: '120px', textAlign: 'left', fontSize: '0.875rem' }}
        ></Column>
        <Column
          field="organizer"
          header="予約者"
          style={{ minWidth: '200px', textAlign: 'left', fontSize: '0.875rem' }}
          body={organizerBody}
        ></Column>
        <Column
          field="next_reservation"
          header="次の予約"
          style={{ minWidth: '120px', textAlign: 'left', fontSize: '0.875rem' }}
        ></Column>
        <Column
          field="timestamp"
          header="更新日時"
          style={{ minWidth: '145px', textAlign: 'left', fontSize: '0.875rem' }}
        ></Column>
      </DataTable>
    );
  }

  getNewMeetingFullUrl = ({ startTime, endTime, roomName }) => {
    if (!startTime || !endTime || !roomName) {
      console.error('invalid meeting url');
    }
    let startTimeStr = '';
    let endTimeStr = '';
    if (isMobile) {
      startTimeStr = format(new Date(startTime), 'yyyy-MM-dd HH:mm:ss+09:00'); // for JP timezone
      startTimeStr = startTimeStr.replace(' ', 'T');
      endTimeStr = format(new Date(endTime), 'yyyy-MM-dd HH:mm:ss+09:00');
      endTimeStr = endTimeStr.replace(' ', 'T');
    } else {
      startTimeStr = format(new Date(startTime), 'MM/dd/yyyy HH:mm:ss');
      endTimeStr = format(new Date(endTime), 'MM/dd/yyyy HH:mm:ss');
    }
    return `${this.TEAM_MEETING_URL_BASE}&startTime=${startTimeStr}&endTime=${endTimeStr}&attendees=${roomName}`;
  };

  openNewMeetingTab = ({ time, roomName }) => {
    const currentDateStr = getCurrentFullDateFormattedInTimeZone();
    const startTime = new Date(currentDateStr);
    // set time of the current date to the time of the selected time
    startTime.setHours(parseInt(time.split(':')[0]));
    startTime.setMinutes(parseInt(time.split(':')[1]));
    startTime.setSeconds(0);
    // endtime is 30 minutes after the start time
    const endTime = new Date(startTime.getTime() + 30 * 60000);
    const url = this.getNewMeetingFullUrl({ startTime, endTime, roomName });
    window.open(url, '_blank');
  };

  getTimeListFromReservedStartAndEndDateTimes = ({
    reserved_start,
    reserved_end,
  }) => {
    const availableTimeList = [];

    // First, add the start date-time to the list
    availableTimeList.push(reserved_start);

    // Adjust the start time to the next 30-minute interval if it's not already on one
    let currentDateTime = new Date(reserved_start);
    if (
      currentDateTime.getMinutes() !== 0 &&
      currentDateTime.getMinutes() !== 30
    ) {
      currentDateTime.setMinutes(
        currentDateTime.getMinutes() +
          (30 - (currentDateTime.getMinutes() % 30)),
        0,
        0
      );
      if (currentDateTime < reserved_end) {
        // Ensure we don't go past the end date-time
        availableTimeList.push(currentDateTime);
      }
    }

    // Now iterate in 30-minute intervals until we reach the end date-time
    while (currentDateTime < reserved_end) {
      currentDateTime = new Date(currentDateTime.getTime() + 30 * 60000);
      if (currentDateTime < reserved_end) {
        // Add all except the final if it's exact
        availableTimeList.push(currentDateTime);
      }
    }

    // Finally, add the end date-time if it's not already included
    if (
      availableTimeList[availableTimeList.length - 1].getTime() !==
      reserved_end.getTime()
    ) {
      availableTimeList.push(reserved_end);
    }

    return availableTimeList;
  };

  renderMeetingRoomCalendar = () => {
    const {
      timeList,
      isLoadingMeetingRoomOutlook,
      meetingRoomOutlookData,
      selectedRoomData,
      isResvSidebarVisible,
    } = this.state;
    if (!isResvSidebarVisible) {
      return;
    }

    if (isLoadingMeetingRoomOutlook) {
      return (
        <div className="loading-spinner-container">
          <LoadingSpinner />
        </div>
      );
    }
    if (!meetingRoomOutlookData) {
      return;
    }
    const roomName =
      meetingRoomOutlookData?.[0]?.room_name ??
      selectedRoomData?.device_name ??
      '-';
    const dataList = [];
    timeList.forEach(time => {
      dataList.push({
        time: time.label,
        fromTime: time.fromValue,
        toTime: time.toValue,
        isOccupied: false,
        data: null,
        roomName:
          meetingRoomOutlookData?.[0]?.['device_position_room_name'] ??
          roomName,
      });
    });
    meetingRoomOutlookData.forEach((roomData, index) => {
      const { reserved_start, reserved_end } = roomData;
      const reservedDay = format(new Date(reserved_start), 'yyyy-MM-dd');
      const reservedStartDateTime = new Date(reserved_start);
      const reservedEndDateTime = new Date(reserved_end);

      const reservedTimeList = this.getTimeListFromReservedStartAndEndDateTimes(
        {
          reserved_start: reservedStartDateTime,
          reserved_end: reservedEndDateTime,
        }
      );
      for (let i = 0; i < dataList.length; i++) {
        const dataItem = dataList[i];
        const { fromTime, toTime } = dataItem;
        const checkedFromDate = new Date(`${reservedDay} ${fromTime}`);
        const checkedToDate = new Date(`${reservedDay} ${toTime}`);
        for (let j = 0; j < reservedTimeList.length; j++) {
          const reservedTime = reservedTimeList[j];
          const isOccupied = isWithinInterval(reservedTime, {
            start: checkedFromDate,
            end: checkedToDate,
          });
          if (isOccupied) {
            const isEqualFrom = isEqual(reservedTime, checkedFromDate);
            if (isEqualFrom && j < reservedTimeList.length - 1) {
              dataItem['isOccupied'] = true;
              dataItem['data'] = roomData;
            } else if (!isEqualFrom && !isEqual(reservedTime, checkedToDate)) {
              dataItem['isOccupied'] = true;
              dataItem['data'] = roomData;
            }
          }
        }
        dataList[i] = dataItem;
      }
    });
    dataList.sort((a, b) => {
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    });

    const ToolTipContent = ({ data }) => {
      const { reserved_start, reserved_end, subject, organizer } = data;
      const reservedStartTime = reserved_start
        ?.split(' ')?.[1]
        ?.substring(0, 5);
      const reservedEndTime = reserved_end?.split(' ')?.[1]?.substring(0, 5);
      return (
        <div className="tooltip-content">
          <div className="tooltip-content-item">
            <div>{`${reservedStartTime} - ${reservedEndTime}`}</div>
          </div>
          <div className="tooltip-content-item subject">
            <div>
              <b>{subject}</b>
            </div>
          </div>
          <div className="tooltip-content-item">
            <div>{organizer}</div>
          </div>
        </div>
      );
    };
    return (
      <>
        <h4>{roomName}</h4>
        <div className="time-row-item-list">
          {dataList &&
            dataList.length > 0 &&
            dataList.map((dataItem, index) => {
              const randomId = generateRandomString(8);
              return (
                <div
                  key={`time-row-item-${index}`}
                  className={`time-row-item ${
                    !dataItem.isOccupied ? 'not-occupied' : ''
                  }`}
                >
                  <div className="time-value">
                    <span>{`${dataItem.time}`}</span>
                  </div>
                  <div className="time-content">
                    {dataItem.isOccupied ? (
                      <>
                        <div className={`occupied occupied-${randomId}`}></div>
                        <Tooltip
                          className="child-div-tooltip"
                          target={`.occupied-${randomId}`}
                          baseZIndex={2000}
                          autoHide={false}
                          position="top"
                          event="hover"
                          appendTo={document.body}
                        >
                          <ToolTipContent data={dataItem.data} />
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <div className="available">
                          <Button
                            className="new-meeting-button"
                            text
                            label={COMMON_TEXT.MAKE_RESERVATION}
                            severity="secondary"
                            aria-label="new-meeting-button"
                            size="small"
                            onClick={() =>
                              this.openNewMeetingTab({
                                time: dataItem.time,
                                roomName: dataItem.roomName,
                              })
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {/* <div>
          
        </div> */}
      </>
    );
  };

  render() {
    const {
      data: floorMapData,
      isLoading,
      floorMapUrl,
      floorMapError,
      sidebarVisible,
      selectedEmployeeId,
      isResvSidebarVisible,
    } = this.state;
    const floorMapDataMarkers = floorMapData.map(item => {
      const marker = transformPositionToMarkerCoordinate(item.position);
      return {
        ...marker,
        item: item,
      };
    });

    return (
      <div className="config-container custom-config-container meeting-room-usage">
        <Sidebar
          visible={sidebarVisible}
          onHide={() =>
            this.setState({ sidebarVisible: false, selectedEmployeeId: null })
          }
          closeIcon="pi pi-chevron-left"
          position="right"
          closeOnEscape={true}
          dismissable={true}
        >
          <EmployeeInfo employeeId={selectedEmployeeId}></EmployeeInfo>
        </Sidebar>
        <Sidebar
          visible={isResvSidebarVisible}
          onShow={() => this.meetingRoomSidebarOnShow()}
          onHide={() => this.setState({ isResvSidebarVisible: false })}
          closeIcon="pi pi-chevron-left"
          position="right"
          closeOnEscape={true}
          dismissable={true}
          className="meeting-room-usage-sidebar"
          appendTo={document.querySelector('.meeting-room-usage')}
        >
          <>{this.renderMeetingRoomCalendar()}</>
        </Sidebar>
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">{COMMON_TEXT.MEETING_ROOM_USAGE}</div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            {!this.props.isSignageMode && this.renderRefreshButton()}
          </div>
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <div className="main-container">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <div className="image-container">
                      <ImageMarker
                        extraClass="custom-image-marker"
                        bufferLeft={0}
                        bufferTop={0}
                        src={floorMapUrl}
                        markerComponent={MeetingRoomUsageMarkerRender}
                        onMarkerClicked={this.markerOnClicked}
                        markers={floorMapError ? [] : floorMapDataMarkers || []}
                        alt=""
                      />
                      {floorMapError && (
                        <Message
                          severity="error"
                          text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                        />
                      )}
                    </div>
                    <div className="table-container">
                      {this.renderDataTableView()}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

MeetingRoomUsage.propTypes = {};

export default MeetingRoomUsage;
